import { useSigma, useRegisterEvents } from '@react-sigma/core';
import { useEffect, useRef } from 'react';
import { highlightPath, resetAllEdgesColor, leaveAllNodes, highlightSelectedNode } from '_helpers/sigmaEvents';
import { graphTheme } from '_theme/graphTheme';

/**
 * @param {setHoveredNode: (node: string | null) => void} param0
 * @returns
 */
function GraphEventsController({ setHoveredNode, onGetNodeInfo, children, onResetEdges, onNodeClick }) {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();
  const selectedNodeRef = useRef(null);
  const hoveredNodeRef = useRef(null);

  // those modify the mouse style so nodes are like buttons
  const handleEnterNode = () => {
    sigma.getContainer().style.cursor = 'pointer';
  };

  const handleLeaveNode = () => {
    sigma.getContainer().style.cursor = 'default';
  };
  
  // Function to explicitly set a node as selected and color it green
  const selectNode = (nodeId) => {
    console.log("DEBUG: Setting node color to green:", nodeId);
    
    // Store the original color if it hasn't been stored yet
    if (!graph.hasNodeAttribute(nodeId, 'originalColor')) {
      const attr = graph.getNodeAttributes(nodeId);
      if (attr && attr.color && attr.color !== graphTheme.NODE_SELECTED_COLOR) {
        graph.setNodeAttribute(nodeId, 'originalColor', attr.color);
      }
    }
    
    // Clear previous selection if different
    if (selectedNodeRef.current && selectedNodeRef.current !== nodeId) {
      const prevAttr = graph.getNodeAttributes(selectedNodeRef.current) || {};
      const originalColor = prevAttr.originalColor || graphTheme.NODE_DEFAULT_COLOR;
      graph.setNodeAttribute(selectedNodeRef.current, 'color', originalColor);
    }
    
    // Set the node color to the darker shade of green from the theme
    graph.setNodeAttribute(nodeId, 'color', graphTheme.NODE_SELECTED_COLOR);
    
    // Mark the node as selected in its attributes
    graph.setNodeAttribute(nodeId, 'selected', true);
    
    // Store the selected node globally
    selectedNodeRef.current = nodeId;
    sigma.selectedNodeId = nodeId;
    
    // Force a refresh to update the display
    sigma.refresh();
  };
  
  // Function to make sure selected node stays green
  const ensureSelectedNodeIsGreen = () => {
    if (selectedNodeRef.current && graph.hasNode(selectedNodeRef.current)) {
      // Ensure the node is still marked as selected
      graph.setNodeAttribute(selectedNodeRef.current, 'selected', true);
      
      // Always reset to the selected color
      graph.setNodeAttribute(selectedNodeRef.current, 'color', graphTheme.NODE_SELECTED_COLOR);
      
      // Only refresh if the color needed to be updated
      sigma.refresh();
    }
  };
  
  // Custom enter node handler that respects selection
  const customEnterNode = (node) => {
    handleEnterNode();
    
    // Keep track of the hovered node
    hoveredNodeRef.current = node;
    
    // Don't highlight if this is the selected node, but do set cursor
    if (node === selectedNodeRef.current) {
      return;
    }
    
    // Normal hover behavior for non-selected nodes
    setHoveredNode(node);
  };
  
  // Custom leave node handler that respects selection
  const customLeaveNode = () => {
    handleLeaveNode();
    
    // Clear the hovered node reference
    hoveredNodeRef.current = null;
    
    // Clear hover state
    setHoveredNode(null);
    
    // Ensure the selected node stays green, in case hover effects changed it
    ensureSelectedNodeIsGreen();
  };

  /**
   * Initialize settings that require sigma instance
   */
  useEffect(() => {
    // Set up an interval to periodically check the selected node color
    const intervalId = setInterval(ensureSelectedNodeIsGreen, 100);
    
    registerEvents({
      clickNode: ({ node }) => {
        console.log("DEBUG: Node clicked:", node);
        
        // Set the node color to the dark green
        selectNode(node);
        
        // Call the onNodeClick callback with the node data
        if (onNodeClick) {
          // Get node attributes for the callback
          const nodeAttrs = graph.getNodeAttributes(node) || {};
          onNodeClick({ 
            id: node, 
            label: nodeAttrs.label || node,
            annotation: nodeAttrs.annotation || '',
            expression: nodeAttrs.expression || -1,
            CI: nodeAttrs.CI || -1,
            SD: nodeAttrs.SD || -1,
            healthy_expression: nodeAttrs.healthy_expression || -1,
            disease_expression: nodeAttrs.disease_expression || -1,
            log2_fold_change: nodeAttrs.log2_fold_change || 0,
            effect_size: nodeAttrs.effect_size || 0,
            healthy_CI: nodeAttrs.healthy_CI || -1,
            disease_CI: nodeAttrs.disease_CI || -1
          }, sigma);
        }
      },
      enterNode: ({ node }) => {
        customEnterNode(node);
      },
      leaveNode: () => {
        customLeaveNode();
      },
      clickStage: () => {
        // Only clear selection if clicking empty space
        if (selectedNodeRef.current) {
          const attr = graph.getNodeAttributes(selectedNodeRef.current) || {};
          const originalColor = attr.originalColor || graphTheme.NODE_DEFAULT_COLOR;
          graph.setNodeAttribute(selectedNodeRef.current, 'color', originalColor);
          graph.removeNodeAttribute(selectedNodeRef.current, 'selected');
          selectedNodeRef.current = null;
          sigma.selectedNodeId = null;
          sigma.refresh();
        }
        
        resetAllEdgesColor(sigma);
        onResetEdges();
      },
    });
    
    // Override the existing enterNode and leaveNode methods in the sigma events
    const originalEnterNode = sigma.enterNode;
    const originalLeaveNode = sigma.leaveNode;
    
    // Custom override of enterNode
    sigma.enterNode = (node) => {
      // If this is the selected node, don't change its color
      if (node === selectedNodeRef.current) {
        // Just set cursor style
        handleEnterNode();
        return;
      }
      
      // Otherwise, use the original behavior
      if (originalEnterNode) {
        originalEnterNode(node);
      }
    };
    
    // Custom override of leaveNode
    sigma.leaveNode = () => {
      handleLeaveNode();
      
      // Ensure the selected node stays green
      ensureSelectedNodeIsGreen();
      
      // Call the original method
      if (originalLeaveNode) {
        originalLeaveNode();
      }
    };
    
    // Clean up function
    return () => {
      clearInterval(intervalId);
      // Restore original methods
      sigma.enterNode = originalEnterNode;
      sigma.leaveNode = originalLeaveNode;
    };
  }, []);

  return children;
}

export default GraphEventsController;

import { graphTheme } from '_theme/graphTheme';

const enableAIEdgeReplacement = true;

function refreshEdgesColor(graph) {

  graph.edges().forEach((edge) => {
    const { originalColor, hidden, specialColor } = graph.getEdgeAttributes(edge);
    if (hidden) {
      graph.setEdgeAttribute(edge, 'color', graphTheme.MUTED_COLOR);
    } else {
      graph.setEdgeAttribute(edge, 'color', originalColor);
    }

    if (specialColor) {
      graph.setEdgeAttribute(edge, 'color', specialColor);
    }
  });
}

function refreshNodesColor(graph, selectedNodeId = null) {
  graph.nodes().forEach((node) => {
    // Check if this is the selected node
    const isSelected = selectedNodeId && node === selectedNodeId;
    
    if (isSelected) {
      // Always keep the selected node green
      graph.setNodeAttribute(node, 'color', graphTheme.NODE_SELECTED_COLOR);
      return;
    }
    
    const { originalColor, filteron, hidden } = graph.getNodeAttributes(node);
    if (hidden) {
      graph.setNodeAttribute(node, 'color', graphTheme.MUTED_COLOR);
    } else {
      graph.setNodeAttribute(node, 'color', originalColor);
      if (filteron) {
        graph.setNodeAttribute(node, 'color', graphTheme.BORDER_HIGHLIGHT);
      }
    }
  });
}

/**
 * Highlights nodes and edges in the given paths and dims (shadows) all others.
 * @param {object} sigma - The sigma instance.
 * @param {object} pathsDict - A dictionary where each key is an endpoint and the value is an array representing the shortest path.
 */
export function highlightPathsAndShadowOthers(sigma, pathsDict) {
  const graph = sigma.getGraph();
  console.log("DEBUG: Sigma graph nodes:", Object.keys(graph.nodes));
  console.log("DEBUG: Received pathsDict:", pathsDict);

  // First, reset all nodes and edges to their original colors.
  graph.forEachNode((node, attributes) => {
    // Use originalColor if available, otherwise keep current color.
    const original = attributes.originalColor || attributes.color;
    graph.setNodeAttribute(node, 'color', original);
  });
  graph.forEachEdge((edge, attributes) => {
    const original = attributes.originalColor || attributes.color;
    graph.setEdgeAttribute(edge, 'color', original);
    // Optionally, reset edge size.
    graph.setEdgeAttribute(edge, 'size', 0.001);
  });

  // Collect nodes and edges from all paths.
  const highlightedNodes = new Set();
  const highlightedEdges = new Set();
  Object.values(pathsDict).forEach(path => {
    path.forEach(nodeId => highlightedNodes.add(nodeId));
    for (let i = 0; i < path.length - 1; i++) {
      // Create an edge key consistent with your GraphDataController; 
      // e.g., if you add edges with key `${source}>${target}`
      highlightedEdges.add(`${path[i]}>${path[i + 1]}`);
    }
  });

  // Highlight nodes and edges in the computed paths.
  highlightedNodes.forEach(nodeId => {
    // Set a highlight color (red in this case).
    graph.setNodeAttribute(nodeId, 'color', 'red');
  });
  highlightedEdges.forEach(edgeKey => {
    if (graph.hasEdge(edgeKey)) {
      graph.setEdgeAttribute(edgeKey, 'color', 'red');
      // Increase edge thickness for visibility.
      graph.setEdgeAttribute(edgeKey, 'size', 2);
    }
  });

  // Dim nodes not in the highlighted set.
  graph.forEachNode((node, attributes) => {
    if (!highlightedNodes.has(node)) {
      // For example, set color to light gray.
      graph.setNodeAttribute(node, 'color', 'lightgray');
    }
  });

  // Refresh sigma to reflect changes.
  sigma.refresh();
}


export function resetAllEdgesColor(sigma) {
  const graph = sigma.getGraph();
  
  graph.edges().forEach((edge) => {
    const { originalColor, source, target, isPredicted, relation } = graph.getEdgeAttributes(edge);
    graph.setEdgeAttribute(edge, 'color', originalColor);
    graph.setEdgeAttribute(edge, 'size', 1);
  });
  
  // Refresh the graph to ensure changes are visible
  sigma.refresh();
}

export function leaveAllNodes(sigma, selectedNode = null) {
  const graph = sigma.getGraph();
  
  // Get the selected node ID from the sigma instance if not provided directly
  const activeNode = selectedNode || (sigma.selectedNodeId ? sigma.selectedNodeId : null);

  graph.nodes().forEach((node) => {
    // Skip the selected node to preserve its green color (check both parameters and attribute)
    const isNodeSelected = (activeNode && node === activeNode) || 
                          (sigma.selectedNodeId && node === sigma.selectedNodeId) ||
                          graph.getNodeAttribute(node, 'selected');
    
    if (isNodeSelected) {
      // Ensure the selected node keeps its green color
      graph.setNodeAttribute(node, 'color', graphTheme.NODE_SELECTED_COLOR);
      // Mark it as selected for other functions to respect
      graph.setNodeAttribute(node, 'selected', true);
      return;
    }
    
    // Get node attributes with safe defaults
    const attributes = graph.getNodeAttributes(node) || {};
    const originalColor = attributes.originalColor || graphTheme.NODE_DEFAULT_COLOR;
    const originalLabel = attributes.originalLabel || node;
    const filteron = attributes.filteron || false;
    
    if (!filteron) {
      graph.removeNodeAttribute(node, 'highlighted');
      graph.setNodeAttribute(node, 'color', originalColor);
      graph.setNodeAttribute(node, 'label', originalLabel);
    } else {
      graph.setNodeAttribute(node, 'color', graphTheme.BORDER_HIGHLIGHT);
    }
  });
  
  // Refresh the graph to ensure changes are visible
  sigma.refresh();
}

export function createEdgeMap(nodes) {
  let edges = {};
  
  // Check if nodes is iterable
  if (!Array.isArray(nodes)) {
    console.warn("createEdgeMap: nodes is not an array:", nodes);
    return edges;
  }
  
  // Iterate over the nodes array, except the last one, because it has no outgoing edge
  for (let i = 0; i < nodes.length - 1; i++) {
    // Use the current node as a key and the next node as its value
    edges[nodes[i]] = nodes[i + 1];
  }
  return edges;
}

export function highlightSelectedNode(sigma, node) {
  const graph = sigma.getGraph();
  graph.nodes().forEach((n) => {
    if (n === node) {
      graph.setNodeAttribute(node, 'color', graphTheme.NODE_SELECTED_COLOR);
    }
  });
  
  // Force an immediate refresh to update the display
  sigma.refresh();
}

export function highlightPath(sigma, pathNodes, selectedNode) {
  const graph = sigma.getGraph();
  
  // Check if pathNodes is an array and not empty
  if (!Array.isArray(pathNodes) || pathNodes.length === 0) {
    console.warn("highlightPath: pathNodes is not a valid array:", pathNodes);
    return;
  }
  
  const nodes = new Set(pathNodes);
  const pathEdgeMap = createEdgeMap(pathNodes);

  // Process edges first
  pathNodes.forEach((curName) => {
    // Only process edges if the current node exists in the graph:
    if (graph.hasNode(curName)) {
      graph
        .edges(curName)
        .filter((edge) => {
          const parts = edge.split('>');
          // Check that the source exists in our path and that the edge maps correctly:
          return nodes.has(parts[0]) && parts[1] === pathEdgeMap[parts[0]];
        })
        .forEach((edge) => {
          graph.setEdgeAttribute(edge, 'color', graphTheme.SUPER_HIGHLIGHTED_COLOR);
          graph.setEdgeAttribute(edge, 'size', 3);
        });
    } else {
      console.warn(`highlightPath: Node ${curName} not found in graph.`);
    }
  });
  
  // Process node colors separately, explicitly skipping the selected node
  pathNodes.forEach((curName) => {
    // Skip the selected node completely - never change its color
    if (curName === selectedNode) {
      return;
    }
    
    // Process node attributes safely:
    if (graph.hasNode(curName)) {
      // Only color non-selected nodes
      if (curName !== pathNodes[pathNodes.length - 1]) {
        graph.setNodeAttribute(curName, 'color', graphTheme.NODE_IN_PATH_COLOR);
      }
    }
  });
}


export function toggleAiPredictedEdgesOnGraph(graph, isOn, ai_cutoff, string_cutoff, selectedNodeId = null) {
  const shouldBePredicted = true;
  const shouldBeDarkMode = true;
  const stringRelationZero = 0;

  graph.edges().forEach((edge) => {
    const { predicted, ai_relation, string_relation, specialColor } = graph.getEdgeAttributes(edge);
    const edgeColor = getEdgeColor(shouldBePredicted, ai_relation, stringRelationZero, shouldBeDarkMode);

    if (predicted) {
      if (isOn) {
        if (ai_relation >= ai_cutoff) {
          // Show predicted edge if AI threshold is met
          graph.setEdgeAttribute(edge, 'hidden', false);
          graph.setEdgeAttribute(edge, 'color', null);  // Use default color
          graph.setEdgeAttribute(edge, 'specialColor', null);
        } else {
          // Hide predicted edge if AI threshold is not met
          graph.setEdgeAttribute(edge, 'hidden', true);
        }
      } else {
        // Hide all predicted edges if AI toggle is off
        graph.setEdgeAttribute(edge, 'hidden', true);
      }
    } else {
      // Handle non-predicted edges
      if (isOn) {
        if (string_relation >= string_cutoff) {
           // Show edge as STRING-related if STRING threshold is met
          graph.setEdgeAttribute(edge, 'hidden', false);
          graph.setEdgeAttribute(edge, 'color', null); // Use default STRING color
          graph.setEdgeAttribute(edge, 'specialColor', null);
        } else if (ai_relation >= ai_cutoff && enableAIEdgeReplacement) {
          // Show edge as AI-related if AI threshold is met but STRING threshold is not
          graph.setEdgeAttribute(edge, 'hidden', false);
          graph.setEdgeAttribute(edge, 'color', edgeColor);
          graph.setEdgeAttribute(edge, 'specialColor', edgeColor);
        } else {
          // Hide edge if neither threshold is met
          graph.setEdgeAttribute(edge, 'hidden', true);
        }
      } else {
        // When AI toggle is off, only show edges with sufficient string_relation
        // and remove any special AI-based coloring
        if (string_relation >= string_cutoff) {
          graph.setEdgeAttribute(edge, 'hidden', false);
          graph.setEdgeAttribute(edge, 'specialColor', null);
          graph.setEdgeAttribute(edge, 'color', graph.getEdgeAttribute(edge, 'originalColor'));
        } else {
          graph.setEdgeAttribute(edge, 'hidden', true);
        }
      }
    }
  });

  refreshEdgesColor(graph);
  
  // Process nodes, making sure to keep selected node visible
  graph.nodes().forEach((node) => {
    // Check if this is the selected node
    const isSelected = selectedNodeId && node === selectedNodeId;
    
    // Only hide non-selected nodes that don't have visible edges
    if (!isSelected) {
      let isNodeHidden = true;
      graph.edges(node).forEach((e) => {
        const { hidden } = graph.getEdgeAttributes(e);
        if (!hidden) {
          isNodeHidden = false;
        }
      });
      graph.setNodeAttribute(node, 'hidden', isNodeHidden);
    } else {
      // Never hide the selected node
      graph.setNodeAttribute(node, 'hidden', false);
      // Keep it green
      graph.setNodeAttribute(node, 'color', graphTheme.NODE_SELECTED_COLOR);
    }
  });
  
  refreshNodesColor(graph, selectedNodeId);
}


export function filterStringEdges(graph, string_cutoff, ai_cutoff, selectedNodeId = null) {
  const shouldBePredicted = true;
  const shouldBeDarkMode = true;
  const stringRelationZero = 0;

  graph.edges().forEach((edge) => {
    const { predicted, string_relation, ai_relation } = graph.getEdgeAttributes(edge);
    const edgeColor = getEdgeColor(shouldBePredicted, ai_relation, stringRelationZero, shouldBeDarkMode);

    if (!predicted) {
      if (string_relation >= string_cutoff) {
        // Show edge as STRING-related if STRING threshold is met
        graph.setEdgeAttribute(edge, 'hidden', false);
        graph.setEdgeAttribute(edge, 'specialColor', null);
        graph.setEdgeAttribute(edge, 'color', graph.getEdgeAttribute(edge, 'originalColor'));
      } else if (ai_relation >= ai_cutoff && enableAIEdgeReplacement) {
        // Show edge as AI-related if AI threshold is met but STRING threshold is not
        graph.setEdgeAttribute(edge, 'hidden', false);
        graph.setEdgeAttribute(edge, 'color', edgeColor);
        graph.setEdgeAttribute(edge, 'specialColor', edgeColor);
      } else {
        // Hide edge if neither threshold is met
        graph.setEdgeAttribute(edge, 'hidden', true);
        graph.setEdgeAttribute(edge, 'specialColor', null);
      }
    }
  });

  refreshEdgesColor(graph);
  
  // Process nodes visibility, respecting the selected node
  graph.nodes().forEach((node) => {
    // Check if this is the selected node
    const isSelected = selectedNodeId && node === selectedNodeId;
    
    if (!isSelected) {
      let isNodeHidden = true;
      graph.edges(node).forEach((e) => {
        const { hidden } = graph.getEdgeAttributes(e);
        if (!hidden) {
          isNodeHidden = false;
        }
      });
      graph.setNodeAttribute(node, 'hidden', isNodeHidden);
    } else {
      // Never hide the selected node
      graph.setNodeAttribute(node, 'hidden', false);
      // Keep it green
      graph.setNodeAttribute(node, 'color', graphTheme.NODE_SELECTED_COLOR);
    }
  });
  
  refreshNodesColor(graph, selectedNodeId);
}




export function toggleAIPredictedEdges(sigma, isOn, ai_cutoff, string_cutoff) {
  const graph = sigma.getGraph();
  // Pass the selectedNodeId from sigma to the graph function
  toggleAiPredictedEdgesOnGraph(graph, isOn, ai_cutoff, string_cutoff, sigma.selectedNodeId);
  
  // After toggling edges, make sure the selected node (if any) stays green
  if (sigma.selectedNodeId && graph.hasNode(sigma.selectedNodeId)) {
    graph.setNodeAttribute(sigma.selectedNodeId, 'color', graphTheme.NODE_SELECTED_COLOR);
    
    // Make sure the node is not hidden
    graph.setNodeAttribute(sigma.selectedNodeId, 'hidden', false);
    
    // Force a refresh
    sigma.refresh();
  }
}

export function enterNode(sigma, selectedNode, external = false) {
  const graph = sigma.getGraph();
  leaveAllNodes(sigma);
  const highlightedNodes = graph.neighbors(selectedNode);
  // internally sigma highlights entered node. For external usage not
  if (external) {
    highlightedNodes.push(selectedNode);
  }
  const highligtedNeighbors = new Set(highlightedNodes);
  
  // Check if the node is marked as selected
  const isNodeSelected = graph.getNodeAttribute(selectedNode, 'selected') || 
                         (sigma.selectedNodeId && selectedNode === sigma.selectedNodeId);
  
  graph.nodes().forEach((node) => {
    // Never change the color of the selected node
    if (node === selectedNode && isNodeSelected) {
      graph.setNodeAttribute(node, 'color', graphTheme.NODE_SELECTED_COLOR);
      return;
    }
    
    if (node === selectedNode && !external) return;
    if (highligtedNeighbors.has(node)) {
      graph.setNodeAttribute(node, 'highlighted', true);
      
      // Ensure dual expression data is preserved for hover display
      const nodeData = graph.getNodeAttributes(node);
      if (nodeData.healthy_expression !== undefined) {
        // Make sure these attributes are preserved for the hover display
        graph.setNodeAttribute(node, 'healthy_expression', nodeData.healthy_expression);
        graph.setNodeAttribute(node, 'disease_expression', nodeData.disease_expression);
        graph.setNodeAttribute(node, 'log2_fold_change', nodeData.log2_fold_change);
        graph.setNodeAttribute(node, 'effect_size', nodeData.effect_size);
      }
    } else {
      graph.setNodeAttribute(node, 'color', graphTheme.MUTED_COLOR);
      graph.setNodeAttribute(node, 'label', '');
    }
  });
  const highlitedEdges = new Set(graph.edges(selectedNode));
  graph
    .edges()
    .filter((edge) => !highlitedEdges.has(edge))
    .forEach((edge) => {
      graph.setEdgeAttribute(edge, 'color', graphTheme.MUTED_COLOR);
    });
}

export function leaveNode(sigma, selectedNode) {
  const graph = sigma.getGraph();
  const defaultEdgeColor = sigma.getSetting('defaultEdgeColor');
  const highligtedNeighbors = new Set(graph.neighbors(selectedNode));
  graph.nodes().forEach((node) => {
    if (node === selectedNode || highligtedNeighbors.has(node)) {
      graph.removeNodeAttribute(node, 'highlighted');
    } else {
      const { originalColor, originalLabel } = graph.getNodeAttributes(node);
      graph.setNodeAttribute(node, 'color', originalColor);
      graph.setNodeAttribute(node, 'label', originalLabel);
    }
  });
  const highlitedEdges = new Set(graph.edges(selectedNode));
  graph
    .edges()
    .filter((edge) => !highlitedEdges.has(edge))
    .forEach((edge) => {
      graph.setEdgeAttribute(edge, 'color', defaultEdgeColor);
    });
}

export function highlightFilteredNodes(sigma, nodesToHighlight) {
  const graph = sigma.getGraph();
  const nodesSet = new Set(nodesToHighlight);
  
  graph.nodes().forEach((node) => {
    // Check if this is the selected node (either by ID or attribute)
    const isNodeSelected = (sigma.selectedNodeId && node === sigma.selectedNodeId) ||
                          graph.getNodeAttribute(node, 'selected');
    
    if (isNodeSelected) {
      // Always keep the selected node green regardless of filtering
      graph.setNodeAttribute(node, 'color', graphTheme.NODE_SELECTED_COLOR);
      // Ensure it's marked as selected
      graph.setNodeAttribute(node, 'selected', true);
      return;
    }
    
    const { originalColor } = graph.getNodeAttributes(node);
    if (nodesSet.has(node)) {
      graph.setNodeAttribute(node, 'color', graphTheme.BORDER_HIGHLIGHT);
      graph.setNodeAttribute(node, 'filteron', true);
    } else {
      graph.setNodeAttribute(node, 'color', originalColor);
      graph.setNodeAttribute(node, 'filteron', false);
    }
  });
  
  // Refresh the graph to ensure changes are visible
  sigma.refresh();
}



export function blendColors(baseColor, targetColor, factor) {
  const parseHex = (hex) => parseInt(hex, 16);
  const toHex = (int) => int.toString(16).padStart(2, '0');

  const baseR = parseHex(baseColor.slice(1, 3));
  const baseG = parseHex(baseColor.slice(3, 5));
  const baseB = parseHex(baseColor.slice(5, 7));

  const targetR = parseHex(targetColor.slice(1, 3));
  const targetG = parseHex(targetColor.slice(3, 5));
  const targetB = parseHex(targetColor.slice(5, 7));

  const r = Math.round(baseR * (1 - factor) + targetR * factor);
  const g = Math.round(baseG * (1 - factor) + targetG * factor);
  const b = Math.round(baseB * (1 - factor) + targetB * factor);

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function getEdgeColor(isPredicted, ai_relation, string_relation, darkMode) {
  const relation = isPredicted ? ai_relation : string_relation;
  const baseColor = isPredicted
    ? (darkMode ? graphTheme.AI_PRED_EDGE_COLOR_DARK : graphTheme.AI_PRED_EDGE_COLOR)
    : (darkMode ? graphTheme.EDGE_DEFAULT_COLOR_DARK : graphTheme.EDGE_DEFAULT_COLOR);

    const baseBrightness = 0.4; // Adjust this to control the starting brightness
    const adjustedRelation = Math.pow(relation, 2);
    const brightnessFactor = baseBrightness + (1 - baseBrightness) * adjustedRelation;
    
    let targetColor;
    if (darkMode && !isPredicted) {
      targetColor = graphTheme.BACKGROUND_COLOR_DARK; // Dark teal color
    } else {
      targetColor = graphTheme.BACKGROUND_COLOR; // White color
    }
  
  return blendColors(baseColor, targetColor, 1 - brightnessFactor);
}
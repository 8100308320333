import { Content } from 'pdfmake/interfaces';

interface DiseaseProgressionNode {
  keggName: string;
  geneName: string;
  mechanismOfAction: string;
  description: string;
}

// Map of disease names to their progression nodes
const diseaseProgressionNodes: Record<string, DiseaseProgressionNode[]> = {
  'ALS': [
    {
      keggName: 'Dynactin',
      geneName: 'ACTR1B, ACTR1A, DCTN2, DCTN6, DCTN3, DCTN1, DCTN4, ACTR10, DCTN5',
      mechanismOfAction: 'Retrograde transport',
      description: 'If this transport system fails, it directly contributes to axonal dysfunction that characterizes ALS pathology.'
    },
    {
      keggName: 'ATXN2',
      geneName: 'ATXN2, ATXN2L',
      mechanismOfAction: 'Prion-like self-assembly',
      description: 'ATXN2 can form prion-like aggregates in ALS. These aggregates disrupt the normal function of neurons, contributing to the disease\'s progression.'
    },
    {
      keggName: 'kinesin1',
      geneName: 'KLC4, KLC3, KIF5A, KIF5B, KIF5C, KLC1, KLC2',
      mechanismOfAction: 'Anterograde transport',
      description: 'Kinesin-1 is responsible for anterograde transport (from the cell body to the axon terminal) of cellular cargo. In ALS, mutations or dysfunction in kinesin motor proteins, including KLC4, may impair this transport, contributing to neuronal degeneration'
    },
    {
      keggName: 'FUS',
      geneName: 'FUS',
      mechanismOfAction: 'Prion-like self-assembly',
      description: 'FUS is an RNA-binding protein with prion-like domains that facilitate its aggregation under pathological conditions. These prion-like domains enable FUS to undergo liquid-liquid phase separation (LLPS), This allows them to form membraneless organelles such as stress granules'
    },
    {
      keggName: 'hnRNPs',
      geneName: 'HNRNPA1L2, HNRNPA1, HNRNPA3, HNRNPA2B1, HNRNPA1P48',
      mechanismOfAction: 'Prion-like self-assembly',
      description: 'Under pathological conditions, these proteins can undergo aberrant phase separation, leading to the formation of insoluble aggregates with prion-like properties.'
    },
    {
      keggName: 'CASP3',
      geneName: 'CASP3',
      mechanismOfAction: 'Motor neuron death',
      description: 'Dysfunctional mitochondria, a hallmark of ALS, release cytochrome c into the cytoplasm, which activates the caspase cascade (including caspase-9 and subsequently caspase-3). This mitochondrial-mediated apoptosis contributes to motor neuron death'
    },
    {
      keggName: 'ERBB4',
      geneName: 'ERBB4',
      mechanismOfAction: 'Disruptions in NRG1-ErbB4 signaling',
      description: 'In ALS, disruptions in NRG1-ErbB4 signaling can lead to impaired motor neuron survival and contribute to disease progression.'
    },
    {
      keggName: 'XBP1',
      geneName: 'XBP1',
      mechanismOfAction: 'Motor neuron degeneration',
      description: 'Prolonged activation of XBP1 may shift from a protective role to promoting cell death pathways, contributing to motor neuron degeneration'
    },
    {
      keggName: 'TARDBP',
      geneName: 'TARDBP',
      mechanismOfAction: 'Prion-like self-assembly',
      description: 'It\'s similar to other prion-like proteins such as FUS, HnRNPs. Mutation in TARDP or stress-related dysfunctions cause these protein to mislocalize to the cytoplasm and aggregate.'
    },
    {
      keggName: 'F-Actin',
      geneName: 'ACTB, ACTG1',
      mechanismOfAction: 'Inhibition of axon outgrowth',
      description: 'In motor neurons, disruptions in the cytoskeleton can lead to impaired axonal transport'
    },
    {
      keggName: 'ANXA11',
      geneName: 'ANXA11, ANXA7',
      mechanismOfAction: 'Impairment of RNA transport',
      description: 'In ALS, mutant ANXA11 leads to impaired RNA processing and altered stress granule dynamics, which ultimately results in the accumulation of defective RNA and protein aggregates.'
    },
    {
      keggName: 'TAP',
      geneName: 'NXF1, NXF3, NXF2B, NXF2',
      mechanismOfAction: 'Defect mRNA export',
      description: 'In ALS, the mislocalization of RNA-binding proteins like TAP may affect mRNA export, further impairing neuronal function and contributing to motor neuron death'
    },
    {
      keggName: 'CHOP',
      geneName: 'DDIT3',
      mechanismOfAction: 'Motor neuron death',
      description: 'In ALS, chronic activation of DDIT3 contributes to motor neuron death by Enhancing oxidative stress and mitochondrial dysfunction.'
    },
    {
      keggName: 'HAP1',
      geneName: 'HAP1',
      mechanismOfAction: 'Impairment of axonal transport',
      description: 'When HAP1 is absent or malfunctioning, the transport system becomes inefficient, leading to impaired cargo movement and axonal dysfunction.'
    },
    {
      keggName: 'ASK1',
      geneName: 'MAP3K5',
      mechanismOfAction: 'Motor neuron death',
      description: 'ASK1 is activated by ER stress sensors such as IRE1 and contributes to the shift from a protective UPR to apoptosis when the stress is prolonged or unresolved. This leads to the activation of pro-apoptotic proteins like CHOP (DDIT3) and caspases, including CASP3, causing motor neuron death.'
    },
    {
      keggName: 'TUBA',
      geneName: 'TUBA1B, TUBA3E, TUBA3C, TUBA8, TUBA4A, TUBA1A, TUBAL3, TUBA1C',
      mechanismOfAction: 'Impairment of Axonal Transport',
      description: 'Mutations in TUBA4A are associated with familial ALS, affecting microtubule stability and axonal transport'
    },
    {
      keggName: 'SPG11',
      geneName: 'SPG11',
      mechanismOfAction: 'Impairment of Autophagy',
      description: 'SPG11 interacts with proteins involved in autophagosome-lysosome fusion, a critical step in the degradation of cellular waste. Dysfunction in this pathway results in defective autophagy, which is strongly linked to ALS pathology, as the failure to clear damaged components accelerates motor neuron death.'
    },
    {
      keggName: 'SOD1',
      geneName: 'SOD1',
      mechanismOfAction: 'Misfolded protein',
      description: 'Mutations in SOD1 lead to the formation of misfolded proteins with toxic gain-of-function properties.'
    },
    {
      keggName: 'MCU',
      geneName: 'MCU',
      mechanismOfAction: 'Mitochondrial dysfunction',
      description: 'In ALS, dysregulation of calcium homeostasis via MCU can contribute to motor neuron degeneration through Increased Oxidative Stress: Mitochondrial calcium overload increases the production of reactive oxygen species (ROS), further damaging mitochondrial DNA, proteins, and lipids.'
    },
    {
      keggName: 'p53',
      geneName: 'TP53',
      mechanismOfAction: 'Neuron death',
      description: 'Motor neurons in ALS experience high levels of oxidative stress, leading to DNA damage. p53 becomes activated in response to this damage, but instead of promoting repair, chronic activation can lead to apoptotic cell death of motor neurons.'
    }
  ]
};

// Function to normalize disease names for matching with our progression nodes map
function normalizeDiseaseNameForMatching(diseaseName: string): string {
  // Convert to lowercase for comparison
  const lowerCaseName = diseaseName.toLowerCase();
  
  // Check for partial matches
  if (lowerCaseName.includes('als') || lowerCaseName.includes('amyotrophic lateral sclerosis')) {
    return 'ALS';
  }
  
  // Return the original name if no match is found
  return diseaseName;
}

// Function to create disease progression nodes content for the PDF report
export function createDiseaseProgressionNodesSection(diseaseName: string): Content {
  const normalizedDiseaseName = normalizeDiseaseNameForMatching(diseaseName);
  const nodes = diseaseProgressionNodes[normalizedDiseaseName] || [];
  
  if (nodes.length === 0) {
    return {
      text: 'Disease progression nodes information is not available for this disease.',
      style: 'italic',
      color: '#666666',
      margin: [0, 10, 0, 0],
    };
  }
  
  // Create table headers
  const tableHeaders = [
    { text: 'KEGG Name', style: 'tableHeader' },
    { text: 'Gene Name', style: 'tableHeader' },
    { text: 'Mechanism of Action', style: 'tableHeader' },
    { text: 'Description', style: 'tableHeader' }
  ];
  
  // Create table body with data rows
  const tableBody = [tableHeaders];
  
  // Add rows for each node
  nodes.forEach(node => {
    tableBody.push([
      { text: node.keggName, style: 'tableCell' },
      { text: node.geneName, style: 'tableCell' },
      { text: node.mechanismOfAction, style: 'tableCell' },
      { text: node.description, style: 'tableCell' }
    ]);
  });
  
  // Create the content with table
  const content: Content = {
    stack: [
      {
        text: `Disease Progression Nodes for ${diseaseName}`,
        style: 'sectionHeader',
        margin: [0, 10, 0, 10],
      },
      {
        text: 'The following table provides details about key proteins/genes involved in disease progression:',
        style: 'normal',
        margin: [0, 0, 0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: ['15%', '20%', '20%', '45%'],
          body: tableBody
        },
        layout: {
          fillColor: function(rowIndex: number) {
            return (rowIndex === 0) ? '#f2f2f2' : null;
          },
          hLineWidth: function() { return 1; },
          vLineWidth: function() { return 1; },
          hLineColor: function() { return '#ddd'; },
          vLineColor: function() { return '#ddd'; }
        }
      }
    ],
    pageBreak: 'before'
  };
  
  return content;
} 
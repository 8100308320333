import { Button, Modal, Box, TextField, Typography, CircularProgress } from '@mui/material';
import { useState } from 'react';

export default function SaveProjectModal({
  title,
  description,
  open,
  onCancel,
  onSubmit,
  initialName,
}: {
  title?: string;
  description?: string;
  open: boolean;
  onCancel: () => void;
  onSubmit: (name: string) => Promise<void>;
  initialName?: string;
}) {
  const [name, setName] = useState<string>(initialName || '');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  // Handle text field change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  // Handle submit with loading state
  const handleSubmit = async () => {
    try {
      setIsProcessing(true);
      await onSubmit(name);
    } finally {
      // In case navigation doesn't happen, we reset the processing state
      setTimeout(() => {
        setIsProcessing(false);
      }, 5000);
    }
  };

  // Reset state when modal closes
  const handleCancel = () => {
    setName(initialName || '');
    setIsProcessing(false);
    onCancel();
  };

  return (
    <Modal
      open={open}
      onClose={isProcessing ? undefined : handleCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title || 'Save Project'}
        </Typography>
        
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
          {description || 'Enter a name for your project.'}
        </Typography>
        
        <TextField
          autoFocus
          margin="dense"
          label="Project Name"
          type="text"
          fullWidth
          value={name}
          onChange={handleChange}
          variant="standard"
          disabled={isProcessing}
          sx={{ mb: 3 }}
        />
        
        {isProcessing && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            bgcolor: 'rgba(250, 250, 210, 0.2)', 
            p: 2, 
            borderRadius: 1,
            border: '1px solid rgba(245, 245, 160, 0.5)',
            mb: 3
          }}>
            <CircularProgress size={24} sx={{ mr: 2 }} />
            <Typography variant="body2" color="text.secondary">
              Processing your request. The target validation analysis may take several minutes to complete. Please don&apos;t close this window.
            </Typography>
          </Box>
        )}
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCancel} disabled={isProcessing}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!name || isProcessing}
            variant="contained"
            sx={{ ml: 1 }}
          >
            {isProcessing ? 'Processing...' : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

import React from "react";
import "./Loading.css";

const Loading = () => (
  <div className="loader">
    <div className="dna-loader">
      <div className="dna-container">
        {/* Create 10 rungs of the DNA ladder */}
        {[...Array(10)].map((_, index) => (
          <div key={index} className="dna-rung">
            <div className="dna-left"></div>
            <div className="dna-right"></div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Loading;

// src/frontend/src/_pages/TargetValidation/GraphDisease/GraphDiseasePage.jsx
import React, { useState, useEffect, useRef } from 'react';
import targetValidationService from '_services/target.validation.service';
import diseaseService from '_services/disease.service';
import { Box, Container, Typography, Button, Paper, Tooltip, Stack, CircularProgress, Grid, Modal } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import nodeStore from '../SelectedNodeStore';
import SigmaGraph from './SigmaDiseaseGraph';
import AiPredStore from '_pages/TargetValidation/FilterAIPredictionStore'; 
import '@react-sigma/core/lib/react-sigma.min.css';
import '_css/sigma.css';
import { withServiceCallHandling } from '_helpers/decorators';
import InfoTooltipSurrounder from '_components/base/InfoTooltip';
import Loading from '_components/Loading';
import { highlightPathsAndShadowOthers } from '_helpers/sigmaEvents';
import ForceAtlas2 from 'graphology-layout-forceatlas2';
import { toast } from 'react-toastify';
import { styled, alpha, useTheme } from '@mui/material/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import InfoIcon from '@mui/icons-material/Info';
import HubIcon from '@mui/icons-material/Hub';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TVProjectStore from '_pages/TargetValidation/TVProjectStore';
import Chip from '@mui/material/Chip';
//
function HelpText() {
  return (
    <Paper sx={{ padding: 2, maxWidth: '600px', maxHeight: '80vh', overflow: 'auto' }}>
      <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
        BioTarget Model Pathways Instructions
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        The graph illustrates the network of proteins involved in disease pathways. Red nodes (circles) denote disease progression nodes that signify disease progression. By clicking on a node, we visualize the pathways from that node to each disease progression node.
      </Typography>
      <Typography sx={{ mb: 1.5 }}>
        When you click on a node, the Validate button will be enabled. This button launches computations to show the overall score for your target along with pathway details.
      </Typography>
      <Typography>
        Using the filters on the right, you can customize the visualization. You can search for a protein target by name or by annotation keywords, and toggle AI-predicted edges.
      </Typography>
    </Paper>
  );
}

function HelpPopper() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  
  return (
    <>
      <Button 
        id="show-instructions-btn"
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)} 
        size="large"
        startIcon={<InfoIcon />}
        sx={{
          fontWeight: 'bold',
          fontSize: '1rem',
          padding: '8px 16px',
          boxShadow: 3,
          border: '2px solid #ffc107',
          '&:hover': {
            boxShadow: 5,
            bgcolor: theme.palette.secondary.dark,
          }
        }}
      >
        Instructions
      </Button>
      
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="instructions-modal"
        aria-describedby="model-pathways-analysis-instructions"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2
        }}
      >
        <Box sx={{ outline: 'none', boxShadow: 24 }}>
          <HelpText />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

// Styled components for the enhanced loading UI
const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(4),
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: '24px',
  maxWidth: '800px',
  margin: '0 auto',
  boxShadow: '0 8px 32px rgba(31, 38, 135, 0.15)',
}));

const ProgressBar = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '10px',
  borderRadius: '5px',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  position: 'relative',
  overflow: 'hidden',
}));

const ProgressFill = styled(Box)(({ width, theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: `${width}%`,
  borderRadius: '5px',
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
  transition: 'width 0.5s ease-in-out',
}));

const StageIndicator = styled(Box)(({ active, completed, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  '& .indicator-circle': {
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: completed 
      ? theme.palette.primary.main 
      : active 
        ? alpha(theme.palette.primary.main, 0.7) 
        : alpha(theme.palette.primary.main, 0.3),
    color: '#fff',
    marginBottom: theme.spacing(1),
    transition: 'all 0.3s ease',
    zIndex: 1,
  },
  '& .indicator-label': {
    color: completed 
      ? theme.palette.primary.main 
      : active 
        ? theme.palette.text.primary 
        : theme.palette.text.secondary,
    fontWeight: active || completed ? 600 : 400,
    textAlign: 'center',
    fontSize: '0.75rem',
    maxWidth: 120,
    transition: 'all 0.3s ease',
  }
}));

const NeuronAnimation = () => {
  const theme = useTheme();
  
  return (
    <Box sx={{ position: 'relative', width: 280, height: 200, mb: 3 }}>
      <svg width="280" height="200" viewBox="0 0 280 200">
        <defs>
          <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur stdDeviation="2" result="blur" />
            <feComposite in="SourceGraphic" in2="blur" operator="over" />
          </filter>
        </defs>

        {/* Center neuron */}
        <circle 
          cx="140" 
          cy="100" 
          r="15" 
          fill={theme.palette.primary.main} 
          filter="url(#glow)" 
          opacity="0.9"
        >
          <animate 
            attributeName="r" 
            values="15;17;15" 
            dur="3s" 
            repeatCount="indefinite" 
          />
        </circle>

        {/* Connected neurons */}
        {[
          { cx: 80, cy: 60, r: 10, delay: 0 },
          { cx: 200, cy: 60, r: 10, delay: 0.5 },
          { cx: 80, cy: 140, r: 10, delay: 1 },
          { cx: 200, cy: 140, r: 10, delay: 1.5 },
          { cx: 40, cy: 100, r: 8, delay: 2 },
          { cx: 240, cy: 100, r: 8, delay: 2.5 },
        ].map((neuron, i) => (
          <React.Fragment key={i}>
            {/* Connection line */}
            <line 
              x1="140" 
              y1="100" 
              x2={neuron.cx} 
              y2={neuron.cy} 
              stroke={alpha(theme.palette.primary.main, 0.4)} 
              strokeWidth="2"
            >
              <animate 
                attributeName="stroke-opacity" 
                values="0.2;0.6;0.2" 
                dur="3s" 
                begin={`${neuron.delay}s`}
                repeatCount="indefinite" 
              />
            </line>
            
            {/* Neuron */}
            <circle 
              cx={neuron.cx} 
              cy={neuron.cy} 
              r={neuron.r} 
              fill={theme.palette.secondary.main} 
              filter="url(#glow)" 
              opacity="0.8"
            >
              <animate 
                attributeName="r" 
                values={`${neuron.r};${neuron.r + 2};${neuron.r}`} 
                dur="3s" 
                begin={`${neuron.delay}s`}
                repeatCount="indefinite" 
              />
            </circle>

            {/* Pulse animation along connection */}
            <circle r="4" fill={theme.palette.primary.main}>
              <animateMotion 
                path={`M 140,100 ${neuron.cx},${neuron.cy}`} 
                dur="3s" 
                begin={`${neuron.delay}s`}
                repeatCount="indefinite"
                rotate="auto"
              />
              <animate 
                attributeName="opacity" 
                values="0;0.7;0" 
                dur="3s" 
                begin={`${neuron.delay}s`}
                repeatCount="indefinite" 
              />
            </circle>
          </React.Fragment>
        ))}
      </svg>
    </Box>
  );
};

function GraphDiseasePage({ modelId, onGoToNodeProcessPage }) {
  // Add a log to show component render
  console.log("%c 🧠 COMPONENT RENDER 🧠", "background: #222; color: #bada55; font-size: 16px; padding: 10px;");
  
  // Add theme using useTheme hook
  const theme = useTheme();
  
  const [diseaseData, setDiseaseData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedNode, setSelectedNode] = useState({});
  const [remainingRuns, setRemainingRuns] = useState(0);
  const [fullNodeList, setFullNodeList] = useState([]);
  const [isExpanding, setIsExpanding] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isNodeInExpressionData, setIsNodeInExpressionData] = useState(true);
  const [loadTimeout, setLoadTimeout] = useState(null);
  const LOAD_TIMEOUT_MS = 5 * 60 * 1000; // 5 minutes in milliseconds
  
  // Add a ref to track if loading is in progress
  const isLoadingRef = useRef(false);
  
  const setNode = nodeStore((state) => state.setNode);
  // Get tissue type from TVProjectStore
  const tissueType = TVProjectStore((state) => state.getTissueType());
  const { getAccessTokenSilently } = useAuth0();
  const debugNodeRef = useRef(null);
  
  // Get a direct reference to the sigma instance for checking node data
  const sigmaRef = useRef(null);
  
  // Global protection flag - this will be used by our validation guard
  const hasValidExpressionData = useRef(false);
  
  // Log tissue type on component mount
  useEffect(() => {
    console.log("Using tissue type for target validation:", tissueType);
  }, [tissueType]);
  
  // Handle global loading timeout
  useEffect(() => {
    // Clear any existing timeout
    if (loadTimeout) {
      clearTimeout(loadTimeout);
    }
    
    // If loading, set a timeout to redirect after 5 minutes
    if (!isLoaded && isLoadingRef.current) {
      const timeout = setTimeout(() => {
        console.log("Loading timeout reached (5 minutes). Redirecting back to target validation page.");
        toast.error("Loading the disease network took too long. Please try again later or select a different model.");
        
        // Reset loading state
        setIsLoaded(true);
        isLoadingRef.current = false;
        
        // Redirect by simulating a browser back navigation
        window.history.back();
      }, LOAD_TIMEOUT_MS);
      
      setLoadTimeout(timeout);
      
      // Clean up on unmount
      return () => clearTimeout(timeout);
    }
  }, [isLoaded, isLoadingRef.current]);
  
  async function getRemainingCreditsImpl() {
    const accessToken = await getAccessTokenSilently();
    return targetValidationService.getRemainingTargetValidationCredits(accessToken);
  }
  const getRemainingCredits = withServiceCallHandling(getRemainingCreditsImpl, (data) => setRemainingRuns(data));
  
  useEffect(() => {
    getRemainingCredits();
  }, []);

  async function getDiseaseDataImpl() {
    // Prevent concurrent loading requests
    if (isLoadingRef.current) {
      console.log("Loading already in progress, skipping duplicate request");
      return null;
    }
    
    // Set loading flag
    isLoadingRef.current = true;
    
    const accessToken = await getAccessTokenSilently();
    setLoadingMessage('Initializing disease network visualization...');
    setLoadingProgress(5);
    
    try {
      // Set up a progress indicator that increases slower to give more time for loading
      const progressInterval = setInterval(() => {
        setLoadingProgress(prev => {
          // Don't go beyond 90% until we actually get data
          // Make smaller increments (2% instead of 5%) for slower progress
          const newProgress = prev + 2;
          return newProgress > 90 ? 90 : newProgress;
        });
        
        // Update loading message based on progress
        if (loadingProgress > 80) {
          setLoadingMessage('Building network visualization... Almost there!');
        } else if (loadingProgress > 60) {
          setLoadingMessage('Processing network connections... This may take a moment.');
        } else if (loadingProgress > 40) {
          setLoadingMessage('Analyzing disease pathways...');
        } else if (loadingProgress > 20) {
          setLoadingMessage('Loading protein expression data...');
        }
      }, 5000); // Slower updates - every 5 seconds instead of 3
      
      // Include tissue type in the request
      console.log("Requesting disease data with tissue type:", tissueType);
      const result = await diseaseService.getDiseaseData(modelId, accessToken, tissueType);
      
      // Clear the progress interval once we have data
      clearInterval(progressInterval);
      setLoadingProgress(100);
      setLoadingMessage('Network visualization ready!');
      
      // Reset loading flag
      isLoadingRef.current = false;
      
      return result;
    } catch (error) {
      console.error("Error in getDiseaseDataImpl:", error);
      
      // Reset loading flag on error
      isLoadingRef.current = false;
      
      // No retries - just throw the error
      throw error;
    }
  }
  
  const getDiseaseData = withServiceCallHandling(getDiseaseDataImpl, (data) => {
    console.log("Received disease data:", data);
    console.log("Data type:", typeof data);
    
    // Handle both formats: data directly or wrapped in a data property
    let graphData = data;
    if (data && data.data) {
      console.log("Data is wrapped in a data property, unwrapping");
      graphData = data.data;
    }
    
    console.log("Final graph data:", graphData);
    console.log("Graph data keys:", graphData ? Object.keys(graphData) : "No keys (undefined)");
    
    // Validate that we have the required data
    if (!graphData || !graphData.nodes || !graphData.links) {
      console.error("ERROR: Invalid graph data received:", graphData);
      toast.error("Unable to visualize network. The data may be incomplete or unavailable for this model.");
      setIsLoaded(true); // Set isLoaded to true to remove loading indicator
      return;
    }
    
    // Log some statistics about the graph
    console.log("Graph statistics:", {
      nodesCount: graphData.nodes.length,
      linksCount: graphData.links.length,
      disease: graphData.disease
    });
    
    // Check if we have a reasonable number of nodes and links
    if (graphData.nodes.length === 0 || graphData.links.length === 0) {
      console.error("ERROR: Graph data has no nodes or links:", graphData);
      toast.error("The disease network is empty. Please try a different model or refresh the page.");
      setIsLoaded(true); // Set isLoaded to true to remove loading indicator
      return;
    }
    
    setDiseaseData(graphData);
    setIsLoaded(true);
    setLoadingProgress(100);
    setLoadingMessage('Network visualization ready!');
    toast.success("Disease network loaded successfully");
  }, 
  (error) => {
    console.error("ERROR: Failed to load disease data:", error);
    
    // No retries - just show error and set loaded to true
    toast.error("Unable to load the disease network. The model may be too large or temporarily unavailable.");
    setIsLoaded(true); // Set isLoaded to true to remove loading indicator
  },
  "Loading disease network... This may take some time for large models.");
  
  useEffect(() => {
    if (!modelId || modelId === 'undefined'){
      console.warn("Skipping fetching disease data as modelId is invalid:", modelId);
      return;
    }
    
    // If we have already successfully loaded the graph for this model and tissue, don't reload
    const hasLoadedKey = `graph_loaded_${modelId}_${tissueType || 'default'}`;
    const hasLoaded = sessionStorage.getItem(hasLoadedKey);
    
    if (hasLoaded === 'true' && diseaseData && diseaseData.nodes && diseaseData.nodes.length > 0) {
      console.log(`Graph already loaded for model ${modelId} with tissue ${tissueType}, skipping reload`);
      setIsLoaded(true);
      return;
    }
    
    console.log("Fetching disease data for modelId", modelId, "with tissue type", tissueType);
    
    // Add a timestamp to track when we started loading
    window._graphLoadStartTime = Date.now();
    
    getDiseaseData().then((data) => {
      // Mark this combination as loaded
      try {
        sessionStorage.setItem(hasLoadedKey, 'true');
        console.log(`Marked graph as loaded for model ${modelId} with tissue ${tissueType}`);
        
        // Get disease name directly from the loaded data for immediate full nodes fetch
        const graphData = data?.data || data;
        if (graphData && graphData.disease) {
          console.log("Got disease name immediately:", graphData.disease);
          fetchFullNodes(graphData.disease);
        }
      } catch (e) {
        console.warn("Failed to mark graph as loaded:", e);
      }
    });
  }, [modelId, tissueType]); // Only reload if modelId or tissueType changes

  // Fetch the full node list (all nodes in the full graph) from the backend
  // We define this as a separate function so we can call it immediately when data loads
  async function fetchFullNodes(diseaseName) {
    // Skip if we don't have a disease name
    if (!diseaseName) {
      console.log("Skipping fetchFullNodes as disease name is not available");
      return;
    }
    
    // Check if we have cached full nodes for this disease and tissue type
    const fullNodesCacheKey = `full_nodes_${diseaseName}_${tissueType || 'default'}`;
    const cachedFullNodes = sessionStorage.getItem(fullNodesCacheKey);
    
    if (cachedFullNodes) {
      console.log(`Using cached full nodes for disease ${diseaseName} with tissue ${tissueType}`);
      const parsedNodes = JSON.parse(cachedFullNodes);
      setFullNodeList(parsedNodes);
      return;
    }
    
    console.log("Fetching full nodes for disease:", diseaseName, "with tissue type:", tissueType);
    const accessToken = await getAccessTokenSilently();
    const url = `${process.env.REACT_APP_API_SERVER_URL}/api/target-validation/full-nodes?disease=${diseaseName}${tissueType ? `&tissue_type=${tissueType}` : ''}`;
    console.log("Full nodes API URL:", url);
    
    try {
      const response = await fetch(url, { headers: { Authorization: `Bearer ${accessToken}` } });
      console.log("Full nodes API response status:", response.status);
      
      if (response.ok) {
        const data = await response.json();
        console.log("Received full nodes data - type:", typeof data);
        console.log("Received full nodes data - keys:", Object.keys(data));
        
        if (data.nodes) {
          console.log("Nodes array length:", data.nodes.length);
          console.log("First 3 nodes sample:", data.nodes.slice(0, 3));
          
          // Ensure nodes have required fields for search
          const processedNodes = data.nodes.map(node => ({
            ...node,
            // Ensure label exists (for search functionality)
            label: node.label || node.name || node.id
          }));
          
          console.log(`Processed ${processedNodes.length} nodes with proper labels`);
          
          // Cache the full nodes list
          try {
            sessionStorage.setItem(fullNodesCacheKey, JSON.stringify(processedNodes));
            console.log(`Cached full nodes for disease ${diseaseName} with tissue ${tissueType}`);
          } catch (e) {
            console.warn("Failed to cache full nodes:", e);
          }
          
          setFullNodeList(processedNodes);
        } else {
          console.error("Error: nodes property missing from API response");
          setFullNodeList([]);
        }
      } else {
        console.error("Error fetching full node list, status:", response.status);
        const errorText = await response.text();
        console.error("Error response:", errorText);
        setFullNodeList([]);
      }
    } catch (err) {
      console.error("Error fetching full node list:", err);
      setFullNodeList([]);
    }
  }
  
  // This effect ensures full nodes are loaded when the disease data changes
  useEffect(() => {
    // Only trigger if we have disease data loaded but no full nodes yet
    if (diseaseData && diseaseData.disease && fullNodeList.length === 0) {
      fetchFullNodes(diseaseData.disease);
    }
  }, [diseaseData.disease, tissueType]);

  // This effect adds a global validation guard that directly monitors the DOM
  useEffect(() => {
    console.log("%c 🛡️ SETTING UP VALIDATION GUARD 🛡️", "background: #222; color: #ff9800; font-size: 16px; padding: 10px;");
    
    // This is our validation guard function that gets called before validation
    const validateBeforeProcessing = (event) => {
      console.log("%c 🚨 VALIDATE BUTTON CLICKED 🚨", "background: #222; color: #ff0000; font-size: 16px; padding: 10px;");
      console.log("Current hasValidExpressionData flag:", hasValidExpressionData.current);
      
      // No matter what, if hasValidExpressionData.current is false, block the action
      if (!hasValidExpressionData.current) {
        console.log("%c 🚫 VALIDATION BLOCKED BY GUARD 🚫", "background: #ff0000; color: white; font-size: 20px; padding: 10px;");
        console.log("Validation should be blocked for nodes without expression data");
        event.preventDefault();
        event.stopPropagation();
        
        // Make sure the user knows why they can't validate
        toast.error("This node cannot be validated because it lacks expression data.");
        
        // Return false to indicate validation failed
        return false;
      }
      
      console.log("%c ✅ VALIDATION ALLOWED BY GUARD ✅", "background: #00ff00; color: black; font-size: 16px; padding: 10px;");
      console.log("Validation is allowed for this node because it has expression data");
      
      // If we get here, validation is allowed
      return true;
    };
    
    // Find the validate button and add a direct event listener
    // This bypasses all the React state/props and gives us direct control
    const setupButtonGuard = () => {
      const validateButton = document.querySelector('[data-testid="validate-button"]');
      
      if (validateButton) {
        console.log("Found validation button:", validateButton);
        
        // Remove any existing listeners to avoid duplicates
        validateButton.removeEventListener('click', validateBeforeProcessing, true);
        
        // Add the validation guard - using capture phase to run before other handlers
        validateButton.addEventListener('click', validateBeforeProcessing, true);
        console.log("Added validation guard to button");
      } else {
        console.log("%c ⚠️ VALIDATION BUTTON NOT FOUND ⚠️", "background: #ffc107; color: black; font-size: 16px; padding: 10px;");
      }
    };
    
    // Set up initially
    setupButtonGuard();
    
    // Set up a MutationObserver to watch for DOM changes and reattach our handler
    // This ensures our guard stays in place even if React re-renders the button
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          setupButtonGuard();
        }
      }
    });
    
    console.log("Setting up MutationObserver to maintain validation guard");
    
    // Start observing the document body for changes
    observer.observe(document.body, { 
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['disabled']
    });
    
    // Clean up on component unmount
    return () => {
      console.log("Cleaning up validation guard");
      const validateButton = document.querySelector('[data-testid="validate-button"]');
      if (validateButton) {
        validateButton.removeEventListener('click', validateBeforeProcessing, true);
      }
      observer.disconnect();
    };
  }, []);

  // Check if a node has expression data - now updates our global protection flag
  function checkNodeHasExpressionData(node) {
    console.log("%c 🔍 CHECKING NODE EXPRESSION DATA 🔍", "background: #222; color: #2196f3; font-size: 16px; padding: 10px;");
    
    if (!node || !node.id) {
      console.log("%c ⚠️ INVALID NODE RECEIVED ⚠️", "background: #ffc107; color: black; font-size: 16px; padding: 10px;");
      console.log("Received invalid node:", node);
      hasValidExpressionData.current = false;
      return false;
    }
    
    // Add extensive debug information
    console.log("📊 NODE DATA:", {
      id: node.id,
      label: node.label,
      healthy_expression: node.healthy_expression,
      disease_expression: node.disease_expression,
      log2_fold_change: node.log2_fold_change,
      data_quality: node.data_quality,
      effect_size: node.effect_size
    });
    
    // STRICT CHECK: Both expression values must be defined and not -1
    const hasExpressionData = node.healthy_expression !== undefined && 
                              node.disease_expression !== undefined && 
                              node.healthy_expression !== -1 && 
                              node.disease_expression !== -1;
    
    console.log(`📊 EXPRESSION CHECK for ${node.id || 'unknown'}:`, {
      hasExpressionData,
      healthy_expression_defined: node.healthy_expression !== undefined,
      disease_expression_defined: node.disease_expression !== undefined,
      healthy_expression_not_negative_one: node.healthy_expression !== -1,
      disease_expression_not_negative_one: node.disease_expression !== -1
    });
    
    if (hasExpressionData) {
      console.log("%c ✅ NODE HAS EXPRESSION DATA ✅", "background: #00ff00; color: black; font-size: 16px; padding: 10px;");
      console.log("Expected behavior: Validation button should be ENABLED");
    } else {
      console.log("%c ❌ NODE LACKS EXPRESSION DATA ❌", "background: #ff0000; color: white; font-size: 16px; padding: 10px;");
      console.log("Expected behavior: Validation button should be DISABLED");
    }
    
    // Update our global protection flag
    hasValidExpressionData.current = hasExpressionData;
    console.log("Set hasValidExpressionData.current =", hasExpressionData);
    
    // Force state update to ensure UI reflects current status
    setIsNodeInExpressionData(hasExpressionData);
    console.log("Set isNodeInExpressionData state =", hasExpressionData);
    
    return hasExpressionData;
  }

  async function calculateButtonActionGoToNodeProcessPage() {
    console.log("%c 🚀 VALIDATION FUNCTION TRIGGERED 🚀", "background: #222; color: #ff9800; font-size: 16px; padding: 10px;");
    console.log("Selected node:", selectedNode);
    
    // Get the actual node data from sigma graph if available (most accurate source)
    let nodeFromGraph = null;
    if (sigmaRef.current && sigmaRef.current.getGraph && selectedNode.id) {
      try {
        const graph = sigmaRef.current.getGraph();
        if (graph.hasNode(selectedNode.id)) {
          nodeFromGraph = graph.getNodeAttributes(selectedNode.id);
          console.log("📊 NODE FROM GRAPH:", nodeFromGraph);
        }
      } catch (err) {
        console.error("Error getting node from graph:", err);
      }
    }
    
    // Use node data from graph if available, otherwise fall back to selected node
    const nodeToValidate = nodeFromGraph || selectedNode;
    console.log("📊 NODE TO VALIDATE:", nodeToValidate);
    
    // Final validation check using the most accurate data
    const healthyExpr = nodeToValidate.healthy_expression;
    const diseaseExpr = nodeToValidate.disease_expression;
    
    console.log("📊 FINAL VALIDATION CHECK:", { 
      id: nodeToValidate.id,
      healthy_expression: healthyExpr, 
      disease_expression: diseaseExpr 
    });
    
    // Update our global protection flag based on this check
    const isValid = !(healthyExpr === -1 || diseaseExpr === -1 || 
                    healthyExpr === undefined || diseaseExpr === undefined);
    hasValidExpressionData.current = isValid;
    console.log("Updated hasValidExpressionData.current =", isValid);
    
    if (!isValid) {
      console.log("%c 🚫 VALIDATION BLOCKED - Node lacks expression data 🚫", "background: #ff0000; color: white; font-size: 16px; padding: 10px;");
      toast.error("Cannot validate: Node lacks expression data");
      return; // Prevent the validation
    }
    
    console.log("%c ✅ VALIDATION PROCEEDING - Node has expression data ✅", "background: #00ff00; color: black; font-size: 16px; padding: 10px;");
    
    // Debug dump all parameters about to be sent to onGoToNodeProcessPage
    console.log("About to call onGoToNodeProcessPage with:", {
      nodeId: selectedNode.id,
      nodeLabel: selectedNode.label,
      aiPredIsOn: AiPredStore.getState().filterBooleanValue,
      aiPredCutoff: AiPredStore.getState().filterCutoff,
      disease: diseaseData.disease
    });
    
    setNode(selectedNode.id, selectedNode.label);
    const aiPredIsOn = AiPredStore.getState().filterBooleanValue;
    const aiPredCutoff = AiPredStore.getState().filterCutoff;
    if (onGoToNodeProcessPage)
      onGoToNodeProcessPage(selectedNode.id, aiPredIsOn, aiPredCutoff, diseaseData.disease);
  }

  async function handleGetNodeInfo(node) {
    console.log("%c 📝 GET NODE INFO CALLED 📝", "background: #222; color: #4caf50; font-size: 16px; padding: 10px;");
    console.log("Previous node:", selectedNode ? selectedNode.id : "none");
    console.log("New node:", node ? node.id : "none");
    
    // First update the selected node
    setSelectedNode(node || {});
    
    // Then check expression data with the new node
    if (node && node.id) {
      checkNodeHasExpressionData(node);
    } else {
      // If node is reset, also reset expression data state
      console.log("Resetting node and expression data state");
      setIsNodeInExpressionData(true);
    }
    
    // Return an empty array instead of API call result
    return [];
  }
  async function handleExpandNode(proteinIdentifier, sigmaInstance) {
    // Set a local loading state if needed:
    if (isExpanding) {
      console.log("Already expanding a node, please wait...");
      return;
    }
    setIsExpanding(true);
    console.log("%c 🔎 EXPANDING NODE 🔎", "background: #222; color: #4caf50; font-size: 16px; padding: 10px;");
    console.log("Expanding node:", proteinIdentifier);
    
    // Ensure proteinIdentifier is a string
    if (typeof proteinIdentifier !== 'string') {
      if (proteinIdentifier && proteinIdentifier.id) {
        console.log(`Converting node object to ID string: ${proteinIdentifier.id}`);
        proteinIdentifier = proteinIdentifier.id;
      } else {
        console.error("Invalid node identifier:", proteinIdentifier);
        setIsExpanding(false);
        return;
      }
    }
    
    // Extra debug info
    console.log(`EXPAND NODE DEBUG: Attempting to expand '${proteinIdentifier}' of type ${typeof proteinIdentifier}`);
    
    // Check if we have already expanded this node to avoid duplicate requests
    const expandedNodeKey = `expanded_node_${modelId}_${tissueType || 'default'}_${proteinIdentifier}`;
    const hasExpanded = sessionStorage.getItem(expandedNodeKey);
    
    if (hasExpanded === 'true') {
      console.log(`Node ${proteinIdentifier} was already expanded, checking if it's visible in graph`);
      
      // Even if expanded, check if it's actually in the visible graph
      // If not, we should clear the expanded flag and try again
      try {
        const graph = sigmaInstance.getGraph();
        if (graph.hasNode(proteinIdentifier)) {
          console.log(`Node ${proteinIdentifier} is already in visible graph, just centering on it`);
          // Center the view on the node
          sigmaInstance.getCamera().animatedEgoNetwork(
            proteinIdentifier,
            { duration: 500 }
          );
          
          // Get node attributes for highlighting
          const nodeAttrs = graph.getNodeAttributes(proteinIdentifier);
          
          // CHANGE: Fully select the node (not just highlight)
          const hasExprData = checkNodeHasExpressionData(nodeAttrs);
          setSelectedNode(nodeAttrs);
          setIsNodeInExpressionData(hasExprData);
          
          setIsExpanding(false);
          return;
        } else {
          console.log(`Node ${proteinIdentifier} was marked as expanded but is not in visible graph, clearing flag to retry`);
          sessionStorage.removeItem(expandedNodeKey);
        }
      } catch (err) {
        console.error("Error checking node in graph:", err);
        sessionStorage.removeItem(expandedNodeKey);
      }
    }
    
    // Ensure we have a valid disease name
    if (!diseaseData.disease) {
      console.error("Cannot expand node: diseaseData.disease is not available");
      setIsExpanding(false);
      return;
    }
    
    console.log("Using disease name for expansion:", diseaseData.disease);
    console.log("Using model ID for expansion:", modelId);
    console.log("Using tissue type for expansion:", tissueType);
    
    // Debug logging for node ID format
    console.log(`DEBUG: Frontend - Expanding node with ID: '${proteinIdentifier}', type: ${typeof proteinIdentifier}`);
    
    try {
      // Show only one toast message for expansion
      toast.info(`Expanding network...`, { autoClose: 3000 });
      
      // Set node as expanded in session storage
      sessionStorage.setItem(expandedNodeKey, 'true');
      
      // Add more explicit logging about tissue type
      console.log("%c 🧬 EXPANSION WITH TISSUE TYPE DATA 🧬", "background: #222; color: #ff9800; font-size: 16px; padding: 10px;");
      console.log(`Using tissue type '${tissueType || "default"}' for expansion of node ${proteinIdentifier}`);
      
      // CHANGE: Get a better display name from the fullNodeList if possible
      let displayName = proteinIdentifier;
      
      // Try to find a better name in the full node list
      const matchingNode = fullNodeList.find(node => node.id === proteinIdentifier);
      if (matchingNode && (matchingNode.name || matchingNode.label)) {
        displayName = matchingNode.name || matchingNode.label;
        console.log(`Found better display name for ${proteinIdentifier}: ${displayName}`);
      } else {
        // Fallback: Try to get a human-readable name by removing any prefix
        if (typeof proteinIdentifier === 'string' && proteinIdentifier.includes('.')) {
          displayName = proteinIdentifier.split('.').pop() || proteinIdentifier;
        }
      }
      
      // Set selected node with better display name
      setSelectedNode({ 
        id: proteinIdentifier, 
        label: displayName
        // CHANGE: Removed isExpansionOnly flag to properly select the node
      });
      
      const accessToken = await getAccessTokenSilently();
      const payload = {
        disease: diseaseData.disease,
        clicked_node: proteinIdentifier,
        current_subgraph: diseaseData,
        modelId: modelId,
        tissueType: tissueType
      };
      
      console.log("Sending expand node payload:", payload);
      console.log("API endpoint:", `${process.env.REACT_APP_API_SERVER_URL}/api/target-validation/expand-node`);
      
      // Detailed API call for better debugging
      const response = await targetValidationService.expandNode(payload, accessToken);
      console.log("Received expand node response:", response);
      
      // Add explicit logging about tissue data in response
      console.log("%c 🔬 CHECKING RESPONSE FOR TISSUE-SPECIFIC DATA 🔬", "background: #222; color: #ff9800; font-size: 16px; padding: 10px;");
      
      // Check if we have expression data in the response for the target node
      const targetNodeInResponse = response.graph && response.graph.nodes && 
                                  response.graph.nodes.find(n => n.id === proteinIdentifier);
      
      if (targetNodeInResponse) {
        console.log("Target node expression data in response:", {
          id: targetNodeInResponse.id,
          label: targetNodeInResponse.label || targetNodeInResponse.name,
          healthy_expression: targetNodeInResponse.healthy_expression,
          disease_expression: targetNodeInResponse.disease_expression,
          log2_fold_change: targetNodeInResponse.log2_fold_change,
          effect_size: targetNodeInResponse.effect_size,
          tissue_type_used: tissueType || "default"
        });
      } else {
        console.log("Target node not found in response data");
      }
      
      // If response has an error, handle it based on severity
      if (response.error) {
        console.log(`Received error response:`, response);
        
        // Check if this is a recoverable error (like long-running operation)
        if (response.isRecoverable) {
          // For recoverable errors, show an info toast instead of an error
          toast.info(response.error);
          
          // Keep expanding flag true so UI shows loading state
          // Don't remove from sessionStorage so we don't try to expand again
          console.log("Recoverable error - keeping expansion state");
          
          // Set a longer timeout to retry the operation after some time
          setTimeout(() => {
            console.log("Attempting to recover expansion after timeout");
            toast.info("Still processing graph data...");
          }, 10000); // Check again in 10 seconds
          
          return;
        } else {
          // For non-recoverable errors, show error and exit
          console.error(`Error expanding node: ${response.error}`);
          toast.error(response.error);
          sessionStorage.removeItem(expandedNodeKey); // Allow retrying
          setIsExpanding(false);
          return;
        }
      }
      
      const updatedGraphData = response.graph; // should have keys: nodes & links
      
      // Check if we got a valid graph with nodes back
      if (!updatedGraphData || !updatedGraphData.nodes || updatedGraphData.nodes.length === 0) {
        console.error("No valid graph data returned from backend");
        sessionStorage.removeItem(expandedNodeKey); // Allow retrying
        setIsExpanding(false);
        return;
      }
      
      console.log(`Received graph data with ${updatedGraphData.nodes.length} nodes and ${updatedGraphData.links ? updatedGraphData.links.length : 0} links`);
      
      // Ensure the disease field is preserved in the updated graph data
      if (!updatedGraphData.disease && diseaseData.disease) {
        console.log("Setting disease field in updatedGraphData to:", diseaseData.disease);
        updatedGraphData.disease = diseaseData.disease;
      }
      
      const graph = sigmaInstance.getGraph();
      
      // Constants for default values
      const DEFAULT_NODE_SIZE = 6;
      const MAX_NODE_SIZE = 16;
      const MIN_EFFECT_SIZE = 0.1;
      const MAX_EFFECT_SIZE = 1.0;
      const DEFAULT_EFFECT_SIZE = 0.2;
      const DEFAULT_LOG2_FOLD_CHANGE = 0;
      
      // Function to calculate node size based on effect size
      function calculateNodeSize(effect_size) {
        if (effect_size === undefined || effect_size === null || isNaN(effect_size)) {
          return DEFAULT_NODE_SIZE;
        }
        
        // Clamp effect size between MIN and MAX
        const clampedEffectSize = Math.max(MIN_EFFECT_SIZE, Math.min(MAX_EFFECT_SIZE, Math.abs(effect_size)));
        
        // Scale effect size to node size range
        const sizeRange = MAX_NODE_SIZE - DEFAULT_NODE_SIZE;
        const scaleFactor = (clampedEffectSize - MIN_EFFECT_SIZE) / (MAX_EFFECT_SIZE - MIN_EFFECT_SIZE);
        const size = DEFAULT_NODE_SIZE + (sizeRange * scaleFactor);
        
        return size;
      }
      
      // Function to get node color based on log2_fold_change
      function getNodeColor(isEndpoint, log2_fold_change, preserve_endpoint_color) {
        // If this is an endpoint node or has the preserve_endpoint_color flag set, always use endpoint color
        if (isEndpoint || preserve_endpoint_color === true) {
          return "#FF0000"; // Red for endpoints
        }
        
        // For non-endpoint nodes, apply coloring based on log2_fold_change
        if (log2_fold_change !== undefined && log2_fold_change < 0) {
          return "#FFFACD"; // Pale yellow color for negative fold change
        }
        
        // Default node color
        return "#294559"; // Dark blue for regular nodes
      }
      
      console.log(`Processing ${updatedGraphData.nodes.length} nodes from expansion data`);
      let addedNodeCount = 0;
      let updatedNodeCount = 0;
      
      updatedGraphData.nodes.forEach((n) => {
        // Ensure coordinates exist:
        if (n.x == null || n.y == null) {
          n.x = Math.random() * 100;
          n.y = Math.random() * 100;
        }
        // Use node.name if it exists, else node.label, else fallback to node.id:
        const displayName = n.name || n.label || n.id;
        n.name = displayName;
        
        // Get log2 fold change if available
        const log2FoldChange = n.log2_fold_change !== undefined ? n.log2_fold_change : undefined;
        
        // Set node color based on endpoint status and log2 fold change
        const nodeColor = getNodeColor(n.is_endpoint, log2FoldChange, n.preserve_endpoint_color);
        
        // Debug logging for node data
        if (n.id === proteinIdentifier || n.id === updatedGraphData.nodes[0].id) {
          console.log(`DEBUG: Processing node ${n.id} from backend:`, n);
          console.log(`DEBUG: Node has dual data attributes:`, {
            healthy_expression: n.healthy_expression,
            disease_expression: n.disease_expression,
            log2_fold_change: n.log2_fold_change,
            effect_size: n.effect_size
          });
        }
        
        // Check if the node already has dual expression data from the backend
        // The backend enriches nodes with dual expression data in expand_subgraph_with_clicked_node
        const hasBackendDualData = n.healthy_expression !== undefined && 
                                  n.disease_expression !== undefined && 
                                  n.log2_fold_change !== undefined && 
                                  n.effect_size !== undefined &&
                                  n.healthy_expression !== -1 && 
                                  n.disease_expression !== -1;
        
        if (n.id === proteinIdentifier) {
          console.log(`DEBUG: Target node ${n.id} hasBackendDualData:`, hasBackendDualData);
          
          // Additional logging for tissue-specific data
          if (hasBackendDualData) {
            console.log("%c ✅ NODE HAS TISSUE-SPECIFIC EXPRESSION DATA ✅", "background: #00ff00; color: black; font-size: 14px; padding: 8px;");
            console.log(`Tissue type '${tissueType || "default"}' expression data for ${n.id}:`, {
              healthy_expression: n.healthy_expression,
              disease_expression: n.disease_expression,
              log2_fold_change: n.log2_fold_change,
              effect_size: n.effect_size,
              data_quality: n.data_quality || "unknown"
            });
          } else {
            console.log("%c ⚠️ NODE LACKS TISSUE-SPECIFIC EXPRESSION DATA ⚠️", "background: #ffc107; color: black; font-size: 14px; padding: 8px;");
            console.log(`This may indicate the backend is not using the correct tissue type (${tissueType || "default"}) data file`);
          }
        }
        
        // If the node doesn't have backend data, check if it exists in the current graph
        let existingNodeData = null;
        if (!hasBackendDualData && graph.hasNode(n.id)) {
          existingNodeData = graph.getNodeAttributes(n.id);
          if (n.id === proteinIdentifier) {
            console.log(`DEBUG: Found existing node data for ${n.id}:`, existingNodeData);
          }
        }
        
        // Use data from backend, existing node, or default values
        const effectSize = hasBackendDualData ? n.effect_size : 
                          (existingNodeData && existingNodeData.effect_size !== undefined && existingNodeData.effect_size !== -1) ? 
                          existingNodeData.effect_size : DEFAULT_EFFECT_SIZE;
        
        const healthyExpression = hasBackendDualData ? n.healthy_expression :
                                (existingNodeData && existingNodeData.healthy_expression !== undefined && existingNodeData.healthy_expression !== -1) ? 
                                existingNodeData.healthy_expression : -1;
        
        const diseaseExpression = hasBackendDualData ? n.disease_expression :
                                (existingNodeData && existingNodeData.disease_expression !== undefined && existingNodeData.disease_expression !== -1) ? 
                                existingNodeData.disease_expression : -1;
        
        const healthyCI = hasBackendDualData ? n.healthy_CI :
                        (existingNodeData && existingNodeData.healthy_CI !== undefined) ? 
                        existingNodeData.healthy_CI : (-1, -1);
        
        const diseaseCI = hasBackendDualData ? n.disease_CI :
                        (existingNodeData && existingNodeData.disease_CI !== undefined) ? 
                        existingNodeData.disease_CI : (-1, -1);
        
        // Calculate node size based on effect size
        const nodeSize = calculateNodeSize(effectSize);
        
        if (!graph.hasNode(n.id)) {
          // New node: add with all required attributes
          const nodeAttributes = { 
            ...n, 
            label: displayName, 
            originalLabel: displayName,
            size: nodeSize,
            color: nodeColor,
            originalColor: nodeColor,
            // Preserve backend-provided values if they exist
            expression: n.expression !== undefined ? n.expression : -1,
            SD: n.SD !== undefined ? n.SD : -1,
            CI: n.CI !== undefined ? n.CI : (-1, -1),
            healthy_expression: healthyExpression,
            disease_expression: diseaseExpression,
            log2_fold_change: log2FoldChange,
            effect_size: effectSize,
            healthy_CI: healthyCI,
            disease_CI: diseaseCI,
            keywords: n.keywords || [],
            is_endpoint: n.is_endpoint || false
          };
          
          if (n.id === proteinIdentifier) {
            console.log(`DEBUG: Adding new node ${n.id} with attributes:`, nodeAttributes);
          }
          
          graph.addNode(n.id, nodeAttributes);
          addedNodeCount++;
        } else {
          // Existing node: merge attributes without overriding the color
          const nodeAttributes = { 
            label: displayName, 
            originalLabel: displayName,
            size: nodeSize,
            // Preserve backend-provided values if they exist
            expression: n.expression !== undefined ? n.expression : -1,
            SD: n.SD !== undefined ? n.SD : -1,
            CI: n.CI !== undefined ? n.CI : (-1, -1),
            healthy_expression: healthyExpression,
            disease_expression: diseaseExpression,
            log2_fold_change: log2FoldChange,
            effect_size: effectSize,
            healthy_CI: healthyCI,
            disease_CI: diseaseCI,
            keywords: n.keywords || [],
            is_endpoint: n.is_endpoint || false
          };
          
          if (n.id === proteinIdentifier) {
            console.log(`DEBUG: Merging existing node ${n.id} with attributes:`, nodeAttributes);
          }
          
          graph.mergeNodeAttributes(n.id, nodeAttributes);
          updatedNodeCount++;
        }
      });
      
      console.log(`Added ${addedNodeCount} new nodes and updated ${updatedNodeCount} existing nodes`);
      
      // Merge links (note: backend returns "links", not "edges")
      let addedLinksCount = 0;
      let updatedLinksCount = 0;
      
      if (updatedGraphData.links) {
        console.log(`Processing ${updatedGraphData.links.length} links`);
        
        updatedGraphData.links.forEach((e) => {
          try {
            if (!graph.hasEdge(e.source, e.target)) {
              graph.addEdgeWithKey(`${e.source}>${e.target}`, e.source, e.target, e);
              addedLinksCount++;
            } else {
              const existingEdgeKey = graph.edge(e.source, e.target);
              graph.mergeEdgeAttributes(existingEdgeKey, e);
              updatedLinksCount++;
            }
          } catch (err) {
            console.error(`Error adding edge ${e.source} -> ${e.target}:`, err);
          }
        });
        
        console.log(`Added ${addedLinksCount} new links and updated ${updatedLinksCount} existing links`);
      } else {
        console.warn("No 'links' property found in expanded graph data.");
      }
      
      // Optionally run a short layout update to reposition new nodes.
      console.log("Running layout update...");
      const ForceAtlas2 = (await import('graphology-layout-forceatlas2')).default;
      const inferredSettings = ForceAtlas2.inferSettings(graph);
      ForceAtlas2.assign(graph, {
        iterations: 100,
        settings: { ...inferredSettings, barnesHutOptimize: true },
      });
      console.log("Layout update complete");
      
      // Optionally highlight new paths if available.
      if (response.paths) {
        console.log(`Highlighting ${response.paths.length} paths`);
        highlightPathsAndShadowOthers(sigmaInstance, response.paths);
      }
      
      // Update the local state so that subsequent operations use the updated graph.
      const mergedGraphData = {
        ...updatedGraphData,
        nodes: [...updatedGraphData.nodes],
        links: [...(updatedGraphData.links || [])]
      };
      
      // Ensure we preserve the disease field
      mergedGraphData.disease = diseaseData.disease || updatedGraphData.disease;
      
      // Update the local state with the merged data
      console.log("Updating diseaseData state with merged graph data");
      setDiseaseData(mergedGraphData);
      
      // Refresh the visualization
      console.log("Refreshing sigma visualization");
      sigmaInstance.refresh();
      
      // If this was the exact node we searched for, center the view on it
      if (graph.hasNode(proteinIdentifier)) {
        console.log(`Centering view on expanded node: ${proteinIdentifier}`);
        
        // Wait a moment for the graph to stabilize before centering
        setTimeout(() => {
          try {
            sigmaInstance.getCamera().animatedEgoNetwork(
              proteinIdentifier,
              { duration: 800 }
            );
            
            // Get the updated node attributes for highlighting and selection
            const nodeAttrs = graph.getNodeAttributes(proteinIdentifier);
            console.log("Node attributes after expansion:", nodeAttrs);
            
            // CHANGE: Fully select the node for validation (not just highlighting)
            const hasExprData = checkNodeHasExpressionData(nodeAttrs);
            setSelectedNode(nodeAttrs); // No isExpansionOnly flag
            setIsNodeInExpressionData(hasExprData);
            
            // Set direct reference to this node
            if (sigmaInstance) {
              sigmaInstance.selectedNodeId = proteinIdentifier;
            }
          } catch (err) {
            console.error("Error centering on node after expansion:", err);
          }
        }, 500);
      } else {
        console.log(`Node ${proteinIdentifier} not found in graph after expansion`);
      }
      
      console.log("Expansion complete.");
      toast.success(`Expansion complete`);
    } catch (err) {
      console.error("Error in API call:", err);
      
      // Determine if this is a CORS error or network-related error
      const isCorsError = err.message && (
        err.message.includes("CORS") || 
        err.message.includes("Failed to fetch") ||
        err.message.includes("NetworkError") ||
        err.message.includes("network")
      );
      
      if (isCorsError) {
        console.log("Detected CORS or network error - providing user-friendly message");
        // Don't show technical CORS errors to users
        toast.info("The server is processing a large amount of data. Please wait while we complete the operation.");
        
        // Keep the expanding state true for a while to avoid confusion
        setTimeout(() => {
          console.log("Setting isExpanding to false after timeout");
          setIsExpanding(false);
        }, 20000); // Keep the loading indicator for 20 seconds
        
        // Don't clear sessionStorage to prevent repeat attempts
      } else {
        // For other types of errors, show a general error message
        toast.error("Error expanding graph: " + (err.message || "Unknown error"));
        sessionStorage.removeItem(expandedNodeKey); // Allow retrying
        setIsExpanding(false);
      }
      return;
    } finally {
      // For successful operations, we'll reach this and turn off loading
      // For CORS errors with the special handling above, this won't take effect immediately
      if (!setIsExpanding._hasBeenCalled) {
        setIsExpanding(false);
      }
    }
  }
  

  return (
    <Box>
      {/* Layout with title and help button side-by-side */}
      <Box sx={{ 
        marginTop: '15px', 
        marginBottom: '15px', 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 102 // Ensure it appears above other elements
      }}>
        {/* Help button on the left - moved further left with increased margin */}
        <Box sx={{ 
          marginRight: '40px',
          position: 'absolute',
          left: '0px'
        }}>
          <HelpPopper />
        </Box>
        
        {/* Title in the center */}
        <Box sx={{
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography 
            variant="h4" 
            color="primary" 
            sx={{ 
              fontWeight: 600,
              textAlign: 'center',
              background: 'linear-gradient(90deg, #113046 0%, #294559 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 1
            }}
          >
            BioTarget Model Pathways
          </Typography>
          
          {/* Tissue type indicator below the title */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            background: alpha(theme.palette.primary.main, 0.08),
            borderRadius: '16px',
            padding: '4px 12px',
            border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`
          }}>
            <Tooltip title="Currently selected tissue type for expression data">
              <Typography 
                variant="body2" 
                color="primary.main" 
                sx={{ 
                  fontWeight: 500, 
                  display: 'flex', 
                  alignItems: 'center',
                  '& svg': { marginRight: 1 } 
                }}
              >
                <InfoIcon fontSize="small" />
                Tissue: <Box component="span" sx={{ ml: 0.5, fontWeight: 700 }}>
                  {tissueType || "default"}
                </Box>
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ position: 'relative', height: '70vh', width: '80vw' }}>
        {isLoaded && Object.keys(diseaseData).length > 0 ? (
          <SigmaGraph
            key={diseaseData?.id || "graph"}
            diseaseData={diseaseData}
            fullNodeList={fullNodeList}
            onGetNodeInfo={handleGetNodeInfo}
            onResetEdges={() => {
              console.log("%c 🔄 RESET EDGES CALLED 🔄", "background: #222; color: #ff9800; font-size: 16px; padding: 10px;");
              console.log("Clearing selected node");
              setSelectedNode({});
              setIsNodeInExpressionData(true);
              // Reset our global protection flag
              hasValidExpressionData.current = false;
              console.log("Reset hasValidExpressionData.current = false");
            }}
            onNodeClick={(node, sigmaInstance) => {
              console.log("%c 🖱️ NODE CLICK HANDLER 🖱️", "background: #222; color: #ff9800; font-size: 16px; padding: 10px;");
              console.log("Clicked node:", node ? node.id : "none");
              
              // Store reference to sigma for direct data access
              sigmaRef.current = sigmaInstance;
              console.log("Stored sigmaInstance reference");
              
              // Force immediate expression data check
              if (node && node.id) {
                // Save full node data before anything else
                console.log("📊 RAW NODE DATA FROM CLICK:", JSON.stringify(node, null, 2));
                
                const hasExprData = checkNodeHasExpressionData(node);
                console.log("Expression check result:", hasExprData);
                
                // Update state
                setSelectedNode(node);
                console.log("Set selectedNode to:", node.id);
                
                setIsNodeInExpressionData(hasExprData);
                console.log("Set isNodeInExpressionData to:", hasExprData);
                
                // Update our global protection flag
                hasValidExpressionData.current = hasExprData;
                console.log("Set hasValidExpressionData.current to:", hasExprData);
                
                // Check button state
                setTimeout(() => {
                  const button = document.querySelector('[data-testid="validate-button"]');
                  if (button) {
                    console.log("Button disabled status:", button.disabled);
                    console.log("Button should be", hasExprData ? "ENABLED" : "DISABLED");
                    if (button.disabled !== !hasExprData) {
                      console.log("%c ⚠️ BUTTON STATE MISMATCH ⚠️", "background: #ff0000; color: white; font-size: 16px; padding: 10px;");
                    }
                  }
                }, 100);
              } else {
                // Reset if clicking on empty space
                console.log("Empty click or invalid node - resetting state");
                setSelectedNode({});
                setIsNodeInExpressionData(true);
                
                // Reset our global protection flag
                hasValidExpressionData.current = false;
                console.log("Reset hasValidExpressionData.current = false");
              }
              
              // Store the selected node ID on the sigma instance for reference
              if (sigmaInstance) {
                sigmaInstance.selectedNodeId = node ? node.id : null;
                console.log("Set sigmaInstance.selectedNodeId to:", node ? node.id : null);
              }
            }}
            onSearch={(proteinName, sigmaInstance) => {
              console.log("%c 🔍 SEARCH HANDLER 🔍", "background: #222; color: #ff9800; font-size: 16px; padding: 10px;");
              console.log("Searching for:", proteinName);
              
              // Store reference to sigma for direct data access
              sigmaRef.current = sigmaInstance;
              
              // First check if the node already exists in the current visualization (exact match)
              const graph = sigmaInstance.getGraph();
              if (graph.hasNode(proteinName)) {
                console.log(`Node ${proteinName} found in current visualization - highlighting it`);
                
                // Highlight this node instead of expanding it again
                const node = graph.getNodeAttributes(proteinName);
                checkNodeHasExpressionData(node);
                setSelectedNode(node);
                
                // Center the view on this node
                sigmaInstance.getCamera().animatedEgoNetwork(
                  proteinName,
                  { duration: 500 }
                );
                
                // Return an empty promise to satisfy the API
                return Promise.resolve();
              }
              
              // If node is not in current visualization check full nodes list with flexible matching
              if (fullNodeList.length > 0) {
                console.log(`Searching for "${proteinName}" in ${fullNodeList.length} full nodes`);
                
                // Try exact match first
                let exactMatch = fullNodeList.find(node => 
                  node.id === proteinName || 
                  node.label === proteinName || 
                  node.name === proteinName
                );
                
                if (exactMatch) {
                  console.log(`Found exact match for "${proteinName}": ${exactMatch.id}`);
                  // Always use the id field as the identifier
                  const nodeId = exactMatch.id;
                  
                  console.log(`⭐ SEARCH HIT: Using node ID '${nodeId}' for expansion`);
                  
                  // Create a promise that resolves after handleExpandNode completes
                  return new Promise((resolve) => {
                    handleExpandNode(nodeId, sigmaInstance)
                      .then(() => resolve())
                      .catch((err) => {
                        console.error("Error expanding node from search:", err);
                        resolve(); // Still resolve to satisfy API contract
                      });
                  });
                }
                
                // Try case-insensitive exact match
                let caseInsensitiveMatch = fullNodeList.find(node => 
                  (node.id && node.id.toLowerCase() === proteinName.toLowerCase()) || 
                  (node.label && node.label.toLowerCase() === proteinName.toLowerCase()) ||
                  (node.name && node.name.toLowerCase() === proteinName.toLowerCase())
                );
                
                if (caseInsensitiveMatch) {
                  console.log(`Found case-insensitive match for "${proteinName}": ${caseInsensitiveMatch.id}`);
                  // Always use the id field as the identifier
                  const nodeId = caseInsensitiveMatch.id;
                  
                  console.log(`⭐ SEARCH HIT: Using node ID '${nodeId}' for expansion`);
                  
                  // Create a promise that resolves after handleExpandNode completes
                  return new Promise((resolve) => {
                    handleExpandNode(nodeId, sigmaInstance)
                      .then(() => resolve())
                      .catch((err) => {
                        console.error("Error expanding node from search:", err);
                        resolve(); // Still resolve to satisfy API contract
                      });
                  });
                }
                
                // Try "starts with" match first - prioritize these over other substring matches
                let startsWithMatches = fullNodeList.filter(node => 
                  (node.id && node.id.toLowerCase().startsWith(proteinName.toLowerCase())) ||
                  (node.label && node.label.toLowerCase().startsWith(proteinName.toLowerCase())) ||
                  (node.name && node.name.toLowerCase().startsWith(proteinName.toLowerCase()))
                );
                
                if (startsWithMatches.length > 0) {
                  console.log(`Found ${startsWithMatches.length} "starts with" matches for "${proteinName}"`);
                  console.log("First few matches:", startsWithMatches.slice(0, 3).map(n => n.id));
                  
                  // Use the first match that starts with the search term
                  const matchToUse = startsWithMatches[0];
                  const nodeId = matchToUse.id;
                  
                  console.log(`⭐ SEARCH HIT: Using "starts with" match node ID '${nodeId}' for expansion`);
                  
                  return new Promise((resolve) => {
                    handleExpandNode(nodeId, sigmaInstance)
                      .then(() => resolve())
                      .catch((err) => {
                        console.error("Error expanding node from search:", err);
                        resolve(); // Still resolve to satisfy API contract
                      });
                  });
                }
                
                // Only if no "starts with" matches are found, try substring match
                let substringMatches = fullNodeList.filter(node => 
                  (node.id && node.id.toLowerCase().includes(proteinName.toLowerCase()) && !node.id.toLowerCase().startsWith(proteinName.toLowerCase())) || 
                  (node.label && node.label.toLowerCase().includes(proteinName.toLowerCase()) && !node.label.toLowerCase().startsWith(proteinName.toLowerCase())) ||
                  (node.name && node.name.toLowerCase().includes(proteinName.toLowerCase()) && !node.name.toLowerCase().startsWith(proteinName.toLowerCase()))
                );
                
                if (substringMatches.length > 0) {
                  console.log(`Found ${substringMatches.length} substring matches for "${proteinName}" (not starting with the search term)`);
                  console.log("First few matches:", substringMatches.slice(0, 3).map(n => n.id));
                  
                  // Use the first substring match
                  const matchToUse = substringMatches[0];
                  const nodeId = matchToUse.id;
                  
                  console.log(`⭐ SEARCH HIT: Using substring match node ID '${nodeId}' for expansion`);
                  
                  return new Promise((resolve) => {
                    handleExpandNode(nodeId, sigmaInstance)
                      .then(() => resolve())
                      .catch((err) => {
                        console.error("Error expanding node from search:", err);
                        resolve(); // Still resolve to satisfy API contract
                      });
                  });
                }
                
                // No matches found
                console.log(`Node "${proteinName}" not found in full nodes list (tried exact, case-insensitive, starts-with, and substring matches)`);
                toast.warning(`No matches found for "${proteinName}" in the disease network.`);
                return Promise.resolve();
              } else {
                console.log("Full node list not loaded yet, attempting direct expansion");
              }
              
              // Reset our global protection flag since we're searching for a new node
              hasValidExpressionData.current = false;
              console.log("Reset hasValidExpressionData.current = false for search");
              
              // Try direct expansion with the search term as a last resort
              console.log(`⚠️ DIRECT EXPANSION: Attempting expansion with raw search term: '${proteinName}'`);
              
              // Create a promise that resolves after handleExpandNode completes
              return new Promise((resolve) => {
                handleExpandNode(proteinName, sigmaInstance)
                  .then(() => resolve())
                  .catch((err) => {
                    console.error("Error in direct node expansion:", err);
                    resolve(); // Still resolve to satisfy API contract
                  });
              });
            }}
          />
        ) : (
          <LoadingContainer>
            <NeuronAnimation />
            
            <Typography variant="h4" color="primary" gutterBottom sx={{ 
              fontWeight: 600,
              background: 'linear-gradient(90deg, #113046 0%, #294559 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent', 
            }}>
              Building Disease Network
            </Typography>
            
            <Typography variant="h6" color="text.secondary" sx={{ mb: 4, textAlign: 'center' }}>
              {loadingMessage}
            </Typography>
            
            {/* Stage indicators */}
            <Grid container spacing={1} sx={{ width: '100%', mb: 4 }}>
              <Grid item xs={4}>
                <StageIndicator active={loadingProgress > 0} completed={loadingProgress >= 40}>
                  <Box className="indicator-circle">
                    <HubIcon fontSize="small" />
                  </Box>
                  <Typography className="indicator-label">
                    Network Initialization
                  </Typography>
                </StageIndicator>
              </Grid>
              <Grid item xs={4}>
                <StageIndicator active={loadingProgress >= 40} completed={loadingProgress >= 70}>
                  <Box className="indicator-circle">
                    <NetworkCheckIcon fontSize="small" />
                  </Box>
                  <Typography className="indicator-label">
                    Processing Connections
                  </Typography>
                </StageIndicator>
              </Grid>
              <Grid item xs={4}>
                <StageIndicator active={loadingProgress >= 70} completed={loadingProgress === 100}>
                  <Box className="indicator-circle">
                    <InfoIcon fontSize="small" />
                  </Box>
                  <Typography className="indicator-label">
                    Visualization Preparation
                  </Typography>
                </StageIndicator>
              </Grid>
            </Grid>
            
            {loadingProgress > 0 && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <ProgressBar>
                  <ProgressFill width={loadingProgress} />
                </ProgressBar>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {loadingProgress}% Complete
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Preparing visualization
                  </Typography>
                </Box>
                
                {loadingProgress > 70 && (
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      mt: 3, 
                      p: 2, 
                      borderRadius: 2, 
                      backgroundColor: alpha('#FECE61', 0.1),
                      border: `1px solid ${alpha('#FECE61', 0.3)}`
                    }}
                  >
                    <Typography variant="body2" sx={{ textAlign: 'center', fontStyle: 'italic' }}>
                      Large disease networks may take a few minutes to load. The data will be cached 
                      for faster access in future sessions.
                    </Typography>
                  </Paper>
                )}
              </Box>
            )}
          </LoadingContainer>
        )}
        {isExpanding && (
          <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.8)',
            backdropFilter: 'blur(4px)',
            zIndex: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CircularProgress size={40} />
          <Typography variant="h6" color="primary" fontWeight="medium">
            Expanding network with new nodes...
          </Typography>
        </Box>
        )}
      </Box>
      <Box>
        <InfoTooltipSurrounder description="Click on a circle (node) in the network above to select it. To unselect, click the empty space.">
          <Typography sx={{ mt: 1, mb: 1 }}>
            Selected Target: {
              selectedNode?.label ? 
              (typeof selectedNode.label === 'string' && selectedNode.label.includes('.') ? 
                selectedNode.label.split('.').pop() : 
                selectedNode.label) : 
              "No node selected"
            }
          </Typography>
        </InfoTooltipSurrounder>
        <Stack direction="row" spacing={2} alignItems="center">
          <Tooltip title={
            remainingRuns <= 0
              ? "No remaining runs left. Contact your organization."
              : !selectedNode.id
                ? "Select a node first."
                : selectedNode.isExpansionOnly
                  ? "This node was expanded but not selected. Click directly on the node to select it for validation."
                  : !isNodeInExpressionData
                    ? "This node doesn't have expression data for this tissue type and cannot be validated."
                    : `Compute estimation for ${selectedNode.label}.`
          }>
            <div>
              <Button
                variant="contained"
                disabled={(!selectedNode.id) || (remainingRuns <= 0) || !isNodeInExpressionData || selectedNode.isExpansionOnly}
                onClick={(e) => {
    
                  
                  if (hasValidExpressionData.current) {
                    console.log("Expression data check PASSED - proceeding with validation");
                  } else {
                   
                    
                    // Try to block it anyway
                    if (!hasValidExpressionData.current) {
                      console.log("EMERGENCY BLOCK - Stopping propagation");
                      e.preventDefault();
                      e.stopPropagation();
                      return false;
                    }
                  }
                  
                  calculateButtonActionGoToNodeProcessPage();
                }}
                sx={{ 
                  opacity: (!selectedNode.id) || (remainingRuns <= 0) || !isNodeInExpressionData || selectedNode.isExpansionOnly ? 0.5 : 1,
                  // Add a distinctive style when validation is blocked
                  backgroundColor: !isNodeInExpressionData ? '#ccc !important' : undefined,
                  color: !isNodeInExpressionData ? '#666 !important' : undefined,
                  cursor: !isNodeInExpressionData ? 'not-allowed !important' : undefined,
                }}
                data-testid="validate-button"
              >
                Validate
              </Button>
            </div>
          </Tooltip>
          <Typography>You have {remainingRuns} remaining runs left.</Typography>
        </Stack>
        {selectedNode.id && !isNodeInExpressionData && (
          <Box sx={{ mt: 1, mb: 1, p: 1, bgcolor: '#fff3e0', borderRadius: 1 }}>
            <Typography color="warning.main">
              <strong>Note:</strong> This node doesn&apos;t have expression data for this tissue type and cannot be validated.
            </Typography>
          </Box>
        )}
        {selectedNode.id && selectedNode.isExpansionOnly && (
          <Box sx={{ mt: 1, mb: 1, p: 1, bgcolor: '#e3f2fd', borderRadius: 1 }}>
            <Typography color="info.main">
              <strong>Note:</strong> This node was expanded but not selected. Click directly on the node to select it for validation.
            </Typography>
          </Box>
        )}
        {selectedNode.id && (
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mt: 1, mb: 1, width: "60%" }}>
              <strong>Selected Target Description:</strong> {selectedNode.annotation || "No description available"}
            </Typography>
            
            {/* Attribution to STRING database */}
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 2,
                mt: 0.5,
                width: "60%"
              }}
            >
              <Typography variant="caption" color="text.secondary" sx={{ 
                display: 'flex', 
                alignItems: 'center',
                fontSize: '0.75rem',
                fontStyle: 'italic'
              }}>
                <Box component="span" sx={{ mr: 1 }}>Data source:</Box>
                <a 
                  href="https://string-db.org/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: theme.palette.primary.main
                  }}
                >
                  
                  STRING database
                </a>
              </Typography>
            </Box>
            
            {/* Add tissue-specific expression data indicator */}
            {selectedNode.healthy_expression !== undefined && 
             selectedNode.disease_expression !== undefined && 
             selectedNode.healthy_expression !== -1 && 
             selectedNode.disease_expression !== -1 && (
              <Box sx={{ 
                mt: 1, 
                mb: 1, 
                p: 1, 
                bgcolor: alpha(theme.palette.primary.light, 0.1), 
                borderRadius: 1,
                border: `1px solid ${alpha(theme.palette.primary.light, 0.3)}`,
                display: 'flex',
                alignItems: 'center'
              }}>
                <Typography variant="body2" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                  <InfoIcon fontSize="small" sx={{ mr: 1 }} />
                  <strong>Expression data:</strong> Using tissue-specific data from&nbsp;<strong>{tissueType || "default"}</strong>&nbsp;tissue type.
                  {selectedNode.data_quality === 'measured' ? (
                    <Chip 
                      size="small" 
                      label="Measured Data" 
                      color="success" 
                      sx={{ ml: 1, height: 20, fontSize: '0.7rem' }} 
                    />
                  ) : (
                    <Chip 
                      size="small" 
                      label="Imputed Data" 
                      color="warning" 
                      sx={{ ml: 1, height: 20, fontSize: '0.7rem' }} 
                    />
                  )}
                </Typography>
              </Box>
            )}
            
            {/* DEBUG: Display expression data information for debugging */}
            <Box sx={{ mt: 1, p: 1, bgcolor: '#f5f5f5', borderRadius: 1, fontSize: '0.8rem', display: 'none' }}>
              <pre>
                {JSON.stringify({
                  id: selectedNode.id,
                  healthy_expression: selectedNode.healthy_expression,
                  disease_expression: selectedNode.disease_expression,
                  isNodeInExpressionData: isNodeInExpressionData
                }, null, 2)}
              </pre>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default GraphDiseasePage;

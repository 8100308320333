import React from 'react';
import { Paper, Typography, Box, Divider, useTheme } from '@mui/material';
import { DiseaseIntroduction } from './DiseaseIntroduction';
import InfoIcon from '@mui/icons-material/Info';

interface DiseaseIntroductionCardProps {
  diseaseName: string;
  targetName: string;
}

const DiseaseIntroductionCard: React.FC<DiseaseIntroductionCardProps> = ({ diseaseName, targetName }) => {
  const theme = useTheme();
  
  // Get the introduction text
  const introductionText = DiseaseIntroduction({ diseaseName, targetName });
  
  // Split into paragraphs
  const paragraphs = introductionText.split('\n\n').filter(p => p.trim().length > 0);
  
  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 3, 
        borderRadius: 2, 
        mb: 3,
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Background decoration */}
      <Box 
        sx={{ 
          position: 'absolute', 
          top: 0, 
          right: 0,
          width: '150px',
          height: '150px',
          background: `linear-gradient(135deg, ${theme.palette.primary.light}20, ${theme.palette.primary.main}10)`,
          borderRadius: '0 0 0 100%',
          zIndex: 0
        }} 
      />
      
      <Box position="relative" zIndex={1}>
        {/* Header */}
        <Box display="flex" alignItems="center" mb={2}>
          <InfoIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
          <Typography 
            variant="h5" 
            color="primary"
            sx={{ 
              fontWeight: 600,
              background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Introduction to {diseaseName}
          </Typography>
        </Box>
        
        <Divider sx={{ mb: 2 }} />
        
        {/* Paragraphs */}
        {paragraphs.map((paragraph, index) => (
          <Typography 
            key={index} 
            variant="body1" 
            sx={{ 
              mb: 2, 
              color: 'text.primary',
              lineHeight: 1.7,
              textAlign: 'justify'
            }}
          >
            {paragraph}
          </Typography>
        ))}
      </Box>
    </Paper>
  );
};

export default DiseaseIntroductionCard; 
import { toast } from 'react-toastify';

export function withServiceCallHandling(
    target: any,
    successCallback?: (data: any, response: any) => any,
    errorCallback?: (error?: any) => void,
    loading?: boolean | string,
    preFunc?: () => boolean, // logic to exit function in advance
) {
    const originalMethod = target;
    return async function (...args: any[]) {
        if (preFunc && !preFunc()) return;
        let toastId;
        if (loading) {
            toastId = toast.loading(typeof loading === "string" ? loading : "Processing...", {
                // Keep loading toast visible until explicitly dismissed
                autoClose: false,
                closeOnClick: false,
                draggable: true
            });
        }
        try {
            console.log("withServiceCallHandling - Calling original method");
            const response = await originalMethod.apply(this, args);
            console.log("withServiceCallHandling - Original method response:", response);
            
            // Check if response is an object with data property or just a data object itself
            let data, message;
            if (response && typeof response === 'object') {
                if ('data' in response) {
                    // Response has a data property
                    data = response.data;
                    message = response.message;
                } else {
                    // Response is the data itself
                    data = response;
                    message = response.message;
                }
            } else {
                // Response is not an object or is null/undefined
                data = response;
                message = undefined;
            }
            
            console.log("withServiceCallHandling - Extracted data:", data);
            console.log("withServiceCallHandling - Extracted message:", message);
            
            if (toastId) {
                toast.update(toastId, {
                    type: 'success',
                    autoClose: 2000,
                    render: message || "Complete",
                    isLoading: false
                });
            } else if (message) {
                toast.success(message);
            }
            let successReturnVal;
            if (successCallback) {
                console.log("withServiceCallHandling - Calling success callback with data:", data);
                successReturnVal = successCallback(data, response);
                console.log("withServiceCallHandling - Success callback return value:", successReturnVal);
            }
            
            const returnValue = successReturnVal || data || response;
            console.log("withServiceCallHandling - Final return value:", returnValue);
            return returnValue;
        } catch (error: any) {
            if (error === 'unmounted') return false;
            console.error("withServiceCallHandling - Error:", error);
            
            // Check if the error message indicates a timeout or large model processing
            const isLongRunningProcess = error.message && (
                error.message.includes("processing a large") || 
                error.message.includes("may take some time") ||
                error.message.includes("Please be patient")
            );
            
            if (toastId) {
                toast.update(toastId, {
                    type: isLongRunningProcess ? 'info' : 'error',
                    // For long-running processes, keep the toast visible
                    autoClose: isLongRunningProcess ? false : 5000,
                    closeOnClick: !isLongRunningProcess,
                    render: error.message || "An error occurred",
                    isLoading: false
                });
            } else {
                if (isLongRunningProcess) {
                    toast.info(error.message || "Processing large data. Please be patient.", {
                        autoClose: false,
                        closeOnClick: false,
                        draggable: true
                    });
                } else {
                    toast.error(error.message || "An error occurred");
                }
            }
            
            if (errorCallback) errorCallback(error);
            return false;
        }
    };
}

interface ApiResponse {
    message?: string;
    data?: any;
    sse_channel?: string;
}
type APIFunction<T> = (accessToken: string, data?: T) => T | Promise<T> | void;
type SuccessCallback<T> = (data: T) => void;
type ErrorCallback = (error: any) => void;

export function withAPICall<T extends ApiResponse>(
    getAccessToken: () => Promise<string>,
    functions: APIFunction<T>[],
    successCallback?: SuccessCallback<(T | undefined)[]>,
    errorCallback?: ErrorCallback,
    isLoading?: boolean | string,
    preFunc?: () => boolean, // logic to exit function in advance 
) {
    return async function (...args: any[]) {
        if (preFunc && !preFunc()) return;
        let toastId;
        try {
            const accessToken = await getAccessToken();
            if (isLoading) {
                toastId = toast.loading(typeof isLoading === "string" ? isLoading : "Processing...");
            }

            const results: (T | undefined)[] = [];
            let result: T | undefined;

            for (const func of functions) {
                const res = func(accessToken, result);
                if (res instanceof Promise) {
                    result = await res;
                } else if (res) {
                    result = res;
                } else {
                    result = undefined;
                }
                results.push(result);
                if (toastId) {
                    toast.update(toastId, {
                        type: 'success',
                        autoClose: 2000,
                        render: result?.message || "Complete",
                        isLoading: false
                    });
                } else if (result?.message) {
                    toast.success(result.message);
                }
            }

            if (successCallback) successCallback(results);
        } catch (error: any) {
            console.error(error);
            const errorMessage = error.message || "An error occurred";
            if (toastId) {
                toast.update(toastId, {
                    type: 'error',
                    autoClose: false,
                    render: errorMessage,
                    isLoading: false
                });
            } else {
                toast.error(errorMessage);
            }
            if (errorCallback) errorCallback(error);
            return;
        }
    }
}
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const AccountSetupLoader = ({ userEmail }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserAccount = async () => {
      try {
        const response = await fetch(`/api/harbor/users/me?email=${userEmail}`);
        if (response.ok) {
          const data = await response.json();
          if (data.email) {
            setLoading(false);
            history.push('/app'); // redirect to your main app route
          }
        }
      } catch (error) {
        console.log('User account not available yet.');
      }
    };

    // Run immediately and then every 2 seconds
    checkUserAccount();
    const interval = setInterval(checkUserAccount, 2000);
    return () => clearInterval(interval);
  }, [history, userEmail]);

  if (!loading) return null;

  return (
    <div className="loading-modal">
      <div className="modal-content">
        <p>We are setting you up, please wait…</p>
      </div>
    </div>
  );
};

export default AccountSetupLoader;

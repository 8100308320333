import { create } from 'zustand';
import RowType from '_components/base/table/RowType';
import { persist, createJSONStorage } from 'zustand/middleware';

interface TVProjectType extends RowType {
  _id: string;
  name: string;
  disease: string;
  model_id: string;
  aiPredCutoff?: number;
  nodeId?: string;
  project_id?: string;
  tissueType?: string;
}

export interface TVProjectStoreProps {
  initTVProject: TVProjectType | null;
  selectedNodeId: string | null;
  setInitTVProject: (rowResult: RowType) => void;
  setSelectedNodeId: (nodeId: string | null) => void;
  getTissueType: () => string | undefined;
  reset: () => void;
}

const TVProjectStore = create<TVProjectStoreProps>()(
  persist(
    (set, get) => ({
      initTVProject: null,
      selectedNodeId: null,
      setInitTVProject: (rowResult: RowType) => {
        if (rowResult?._id) {
          const { tissueType, ...restProps } = rowResult as any;
          
          set({
            initTVProject: {
              ...restProps,
              tissueType,
              _id: rowResult._id as string,
              name: rowResult.name as string,
              disease: rowResult.disease as string,
              model_id: rowResult._id as string,
            },
          });
        }
      },
      setSelectedNodeId: (nodeId: string | null) => set({ selectedNodeId: nodeId }),
      getTissueType: () => get().initTVProject?.tissueType,
      reset: () => set({ initTVProject: null, selectedNodeId: null }),
    }),
    {
      name: 'target-validation-project-store',
      storage: createJSONStorage(() => localStorage),
    }
  ),
);

export default TVProjectStore;

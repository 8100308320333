import { RowElementType } from '_components/base/table/RowType';
import { Note } from '_components/notepad';
import { handleResponse } from '_helpers/middleware';
import { RecGroupType } from '_pages/biomarkers/routine/RecGroupType';
import { ProjectType } from '_pages/Projects/ProjectTypeEnum';
import { APIOptionsType } from '_types/APIOptionsType';

const URLS = {
    listProjects: '/api/harbor/projects',
    getProject: (projectId: string) => `/api/harbor/projects/${projectId}`,
    saveProject: (projectId: string) => `/api/harbor/projects/${projectId}/save_as_regular`,
    createProject: '/api/harbor/projects',
    deleteProject: (projectId: string) => `/api/harbor/projects/${projectId}`,
    deleteProjects: '/api/harbor/projects',
    renameProject: (projectId: string) => `/api/harbor/projects/${projectId}/rename`,
    updateNotes: (projectId: string) => `/api/harbor/projects/${projectId}/update-notes`,
};

function listProjects(accessToken: string, type?: ProjectType, options?: APIOptionsType): Promise<Response> {
    const url = new URL(URLS.listProjects, window.location.href);
    if (type) {
        url.searchParams.set('type', type);
    }
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
        signal: options?.signal,
    };
    return fetch(url.toString(), requestOptions).then(handleResponse);
}

function getProject(accessToken: string, projectId: string, type?: ProjectType, options?: APIOptionsType): Promise<Response> {
    const url = new URL(URLS.getProject(projectId), window.location.href);
    if (type) url.searchParams.set('type', type);
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
        signal: options?.signal,
    };
    return fetch(url.toString(), requestOptions).then(handleResponse);
}


function getVisProject(accessToken: string, projectId: string, options?: APIOptionsType): Promise<Response> {
    return getProject(accessToken, projectId, ProjectType.Visualization, options);
}

function getTVProject(accessToken: string, projectId: string, options?: APIOptionsType): Promise<Response> {
    return getProject(accessToken, projectId, ProjectType.TargetValidation, options);
}

function getBMProject(accessToken: string, projectId: string, options?: APIOptionsType): Promise<Response> {
    return getProject(accessToken, projectId, ProjectType.Biomarkers, options);
}

/**
 * Save Project by assigning a proper name and converting project from default to a regular
 */
function saveProject(accessToken: string, projectId: string, projectName: string, options?: APIOptionsType): Promise<Response> {
    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: projectName,
        }),
        signal: options?.signal,
    };
    return fetch(URLS.saveProject(projectId), requestOptions).then(handleResponse);
}

interface CreateTVProjectData {
    model_id: string;
    disease?: string;
    nodeId?: string;
    filterToggle?: boolean;
    filterValue?: number;
    tissue_type?: string;
}

// Interface for the response when an existing project is found
interface CreateTVProjectResponse {
    _id?: string;
    existing_project?: boolean;
    existing_project_id?: string;
    message?: string;
    [key: string]: any;
}

function createTVProject(accessToken: string, projectName: string, data: CreateTVProjectData, options?: APIOptionsType): Promise<CreateTVProjectResponse> {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: projectName,
            data: {
                model_id: data.model_id,
                disease: data.disease,
                nodeId: data.nodeId,
                filterToggle: data.filterToggle,
                filterValue: data.filterValue,
                tissue_type: data.tissue_type,
            },
            type: ProjectType.TargetValidation
        }),
        signal: options?.signal,
    };
    return fetch(URLS.createProject, requestOptions)
        .then(handleResponse)
        .then(response => response as CreateTVProjectResponse);
}


interface CreateBMProjectData {
    // RecGroups are adapted to the backend requirements (+ avoid an excess of useless data for backend)
    recGroups: { dataGroups: [RowElementType, string][]; name: string; }[];
    dataGroupInfo: DataGroupItemInfoType[];
    modalitiesFiltered: string[];
    groupsFiltered: string[];
}

async function createBMProject(accessToken: string, projectName: string, data: CreateBMProjectData, options?: APIOptionsType): Promise<any> {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: projectName,
            data: data,
            type: ProjectType.Biomarkers
        }),
        signal: options?.signal,
    };
    const response = await fetch(URLS.createProject, requestOptions);
    return handleResponse(response);
}

interface CreateVisProjectData {
    uploadId: string;
    groupColumn: string;
    groupValues: string[];
    isDefault?: boolean;
}

function createVisProject(accessToken: string, projectName: string, data?: CreateVisProjectData, options?: APIOptionsType): Promise<Response> {
    const bodyJson: { name: string, data?: CreateVisProjectData, type: ProjectType } = {
        name: projectName,
        type: ProjectType.Visualization,
    };
    if (data) {
        bodyJson.data = {
            uploadId: data.uploadId,
            groupColumn: data.groupColumn,
            groupValues: data.groupValues,
            isDefault: data.isDefault ?? true,
        }
    }
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyJson),
        signal: options?.signal,
    };
    return fetch(URLS.createProject, requestOptions).then(handleResponse);
}

function deleteProject(accessToken: string, projectId: string, options?: APIOptionsType): Promise<Response> {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${accessToken}` },
        signal: options?.signal,
    };
    return fetch(URLS.deleteProject(projectId), requestOptions).then(handleResponse);
}

function renameProject(accessToken: string, projectId: string, name: string, newName: string, options?: APIOptionsType): Promise<Response> {
    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, newName }),
        signal: options?.signal,
    };
    return fetch(URLS.renameProject(projectId), requestOptions).then(handleResponse);
}

function deleteProjects(accessToken: string, projectIds: string[], options?: APIOptionsType): Promise<Response> {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_ids: projectIds }),
        signal: options?.signal,
    };
    return fetch(URLS.deleteProjects, requestOptions).then(handleResponse);
}

function updateNotes(accessToken: string, projectId: string, notes: Note[], options?: APIOptionsType): Promise<Response> {
    const url = new URL(URLS.updateNotes(projectId), window.location.href);
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes: notes }),
        signal: options?.signal,
    };
    return fetch(url.toString(), requestOptions).then(handleResponse);
}

const projectsService = {
    listProjects,
    createTVProject,
    createVisProject,
    createBMProject,
    getVisProject,
    getTVProject,
    getBMProject,
    deleteProject,
    deleteProjects,
    renameProject,
    saveProject,
    updateNotes,
    URLS,
};

export default projectsService;

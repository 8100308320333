import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { 
  Container, 
  Stack, 
  Box, 
  Typography, 
  Button, 
  Paper, 
  useTheme,
  useMediaQuery,
  Chip,
  alpha,
  Grid,
  CardMedia,
  CardContent
} from '@mui/material';
import { motion } from 'framer-motion';
import ScienceIcon from '@mui/icons-material/Science';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BasicModal from '_components/base/BasicModal';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { ProjectType } from '_pages/Projects/ProjectTypeEnum';
import config from '_configs';
import Projects, { HopBackInProjectButton, NotesWithBadge } from '_pages/Projects/Projects';
import HeadCellType from '_components/base/table/HeadCellType';
import PageHeader from '_components/base/PageHeader';
import TVRoutine from './TVRoutine';
import TVProjectStore from './TVProjectStore';
import FeatureHomePageContent from '_components/base/FeatureHomePage';
import { styled } from '@mui/material/styles';

// Particle Canvas for background effect (similar to home page)
const ParticleCanvas = () => {
  useEffect(() => {
    const canvas = document.getElementById('tv-particle-canvas') as HTMLCanvasElement;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const particles: {x: number, y: number, size: number, speedX: number, speedY: number, color: string}[] = [];
    const particleCount = 30; // Slightly fewer particles than home page for a subtle effect
    
    // Set canvas size
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    
    // Create particles
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 1.5 + 0.5, // Smaller particles for a more subtle effect
        speedX: (Math.random() - 0.5) * 0.3,
        speedY: (Math.random() - 0.5) * 0.3,
        color: 'rgba(17, 48, 70, 0.3)' // Using the primary color with transparency
      });
    }
    
    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      particles.forEach(particle => {
        // Draw particle
        ctx.fillStyle = particle.color;
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fill();
        
        // Move particle
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        
        // Wrap around screen edges
        if (particle.x < 0) particle.x = canvas.width;
        if (particle.x > canvas.width) particle.x = 0;
        if (particle.y < 0) particle.y = canvas.height;
        if (particle.y > canvas.height) particle.y = 0;
        
        // Draw connections between particles
        particles.forEach(otherParticle => {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < 120) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(17, 48, 70, ${0.05 * (1 - distance/120)})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });
    };
    
    animate();
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);
  
  return <canvas id="tv-particle-canvas" style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

// Styled components
const EnhancedPageHeader = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4, 2, 5, 2),
  background: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: '24px',
  marginBottom: theme.spacing(4),
  boxShadow: '0 8px 32px rgba(31, 38, 135, 0.15)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  position: 'relative',
  overflow: 'hidden',
}));

const GradientOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(135deg, rgba(17, 48, 70, 0.05) 0%, rgba(254, 206, 97, 0.05) 100%)',
  zIndex: 0,
});

const FeatureStep = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 16,
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  padding: theme.spacing(1.5, 4),
  textTransform: 'none',
  fontWeight: 600,
  background: 'linear-gradient(90deg, #113046 0%, #294559 100%)',
  boxShadow: '0 4px 15px rgba(17, 48, 70, 0.3)',
  '&:hover': {
    boxShadow: '0 6px 20px rgba(17, 48, 70, 0.4)',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  padding: theme.spacing(0.5, 0),
  marginBottom: theme.spacing(1.5),
}));

export default function TargetValidation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [animate, setAnimate] = useState(false);
  const setInitTVProject = TVProjectStore((state) => state.setInitTVProject);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => setAnimate(true), 100);
  }, []);

  const headCells: HeadCellType[] = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { 
      id: 'model', 
      numeric: false, 
      disablePadding: false, 
      label: 'Model',
      renderElementImpl: (_id: RowElementType, row: RowType) => {
        // Only show for targetvalidation projects (which will always be the case here)
        if (row.model_id) {
          // Format model ID for display
          const shortModelId = typeof row.model_id === 'string' 
            ? row.model_id.substring(0, 6) + '...' 
            : 'Unknown';
          
          // Return a label with the disease, which is available in project data
          const labelText = row.disease 
            ? `${row.disease} Model` 
            : `Model ID: ${shortModelId}`;
            
          return <Chip 
            label={labelText} 
            size="small" 
            color="primary" 
            variant="outlined" 
            title={`Full Model ID: ${row.model_id}`}
          /> as unknown as RowElementType;
        }
        
        return null as unknown as RowElementType;
      }
    },
    {
      id: 'created_date',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
      isDate: true,
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Last Updated',
      isDate: true,
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: false,
      label: '',
      isEmpty: true,
      isSelfClickable: false,
      renderElementImpl: (_id: RowElementType, row: RowType) => {
        return (
          <NotesWithBadge row={row} />
        )
      }
    },
    {
      id: '_id',
      numeric: false,
      disablePadding: false,
      label: '',
      isEmpty: true,
      isSelfClickable: true,
      renderElementImpl: (_id: RowElementType) => {
        function goToProject() {
          history.push(config.clientUrls.NODE_PROCESS_PATHS__TV_PROJECT(_id));
        }
        return (
          <HopBackInProjectButton onClickFunction={goToProject} />
        );
      },
    },
  ];

  const [modal, setModal] = useState({ open: false });

  function closeModal() {
    setModal({ ...modal, open: false });
  }
  function openModal() {
    setModal({ ...modal, open: true });
  }

  function handleFreshStartRoutineComplete(model: RowType) {
    // Store selected model with tissue type in TVProjectStore
    setInitTVProject(model);
    
    // Log that we're using a specific tissue type if selected
    if (model.tissueType) {
      console.log(`Selected tissue type for target validation: ${model.tissueType}`);
    }
    
    // Navigate to the project creation page
    history.push(config.clientUrls.TARGET_VALIDATION_PROJECT("new_project"));
  }

  // Feature steps data
  const featureSteps = [
    {
      title: "Select Disease & Target",
      description: "specify the disease and choose a drug target to validate its efficacy.",
      icon: <ScienceIcon style={{ fontSize: 40, color: theme.palette.primary.main }} />
    },
    {
      title: "AI Analysis",
      description: "Our AI algorithms analyze the biological pathways to validate the target's potential.",
      icon: <AnalyticsIcon style={{ fontSize: 40, color: theme.palette.primary.main }} />
    },
    {
      title: "View Results",
      description: "Explore detailed pathway analysis and efficacy scores for your chosen target.",
      icon: <RocketLaunchIcon style={{ fontSize: 40, color: theme.palette.primary.main }} />
    }
  ];

  return (
    <>
      <ParticleCanvas />
      <BasicModal
        aria-labelledby="tv-pipeline"
        onClose={closeModal}
        open={modal.open}
        boxSx={{ maxHeight: '95vh', overflow: 'auto', borderRadius: 2, padding: 2 }}
      >
        <TVRoutine onSubmit={handleFreshStartRoutineComplete} />
      </BasicModal>

      <Container sx={{ py: 4 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <EnhancedPageHeader>
            <GradientOverlay />
            <Box position="relative" zIndex={1}>
              <motion.div
                animate={animate ? { scale: [0.98, 1], opacity: [0, 1] } : {}}
                transition={{ duration: 0.7 }}
              >
                <Box mb={2} display="flex" justifyContent="center">
                  <CardMedia
                    component="img"
                    height="140"
                    src="/assets/img/cards/targetvalidation.webp"
                    alt="BioTarget Scoring"
                  />
                </Box>
                <Typography 
                  variant={isMobile ? "h4" : "h3"} 
                  color="primary" 
                  sx={{ 
                    fontWeight: 'bold', 
                    mb: 2,
                    textShadow: '0 2px 10px rgba(0,0,0,0.05)',
                    background: 'linear-gradient(90deg, #113046 0%, #294559 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  BioTarget Scoring
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 3, maxWidth: '800px', mx: 'auto' }}>
                  Validate that a drug target has a chance of impacting disease progression on a biological network 
                  by leveraging our AI disease graph algorithms.
                </Typography>
                <StyledButton 
                  variant="contained" 
                  onClick={openModal}
                >
                  Start New Validation
                </StyledButton>
              </motion.div>
            </Box>
          </EnhancedPageHeader>
        </motion.div>

        {/* How It Works Section */}
        <Box sx={{ mb: 6 }}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <StyledChip label="How It Works" />
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 600, 
                mb: 1,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '50%',
                  bottom: '-12px',
                  width: '60px',
                  height: '3px',
                  background: theme.palette.primary.main,
                  transform: 'translateX(-50%)',
                  borderRadius: '2px',
                }
              }}
            >
              The Validation Process
            </Typography>
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {featureSteps.map((step, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.5 }}
                >
                  <FeatureStep>
                    <Box mb={2}>{step.icon}</Box>
                    <Box mb={1}>
                      <Typography variant="h6" color="primary" fontWeight="bold" align="center">
                        Step {index + 1}: {step.title}
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {step.description}
                    </Typography>
                  </FeatureStep>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Projects Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.7 }}
        >
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              borderRadius: 3, 
              background: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px rgba(31, 38, 135, 0.1)',
            }}
          >
            <Box sx={{ textAlign: 'center', mb: 3 }}>
              <StyledChip label="Your Projects" />
              <Typography 
                variant="h5" 
                sx={{ 
                  fontWeight: 600,
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: '50%',
                    bottom: '-10px',
                    width: '40px',
                    height: '2px',
                    background: theme.palette.primary.main,
                    transform: 'translateX(-50%)',
                    borderRadius: '2px',
                  }
                }}
              >
                BioTarget Scoring Projects
              </Typography>
            </Box>
            
            <Projects
              headCells={headCells}
              asPage={false}
              tableTitle=""
              entityName="Project"
              fixedType={ProjectType.TargetValidation}
              allowInsert={false}
            />
          </Paper>
        </motion.div>
      </Container>
    </>
  );
}

// NodeCounter.jsx
import React, { useEffect, useState } from 'react';
import { useSigma } from '@react-sigma/core';
import { Box, Typography } from '@mui/material';

function NodeCounter() {
  const sigma = useSigma();
  const [nodeCount, setNodeCount] = useState(0);

  useEffect(() => {
    const updateCount = () => {
      // Use sigma.getGraph().order which gives number of nodes
      const count = sigma.getGraph().order;
      setNodeCount(count);
    };

    // Initialize count
    updateCount();

    // Listen for changes in the graph
    const graph = sigma.getGraph();
    graph.on('nodeAdded', updateCount);
    graph.on('nodeRemoved', updateCount);

    // Clean up listeners on unmount
    return () => {
      graph.off('nodeAdded', updateCount);
      graph.off('nodeRemoved', updateCount);
    };
  }, [sigma]);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 16,
        left: 16,
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '4px 8px',
        borderRadius: '4px',
        color: '#fff',
        fontSize: '0.8rem',
        opacity: 0.8,
        '&:hover': {
          opacity: 1
        }
      }}
    >
      <Typography variant="caption">Nodes: {nodeCount}</Typography>
    </Box>
  );
}

export default NodeCounter;

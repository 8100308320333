import { create } from 'zustand';

export interface AiPredStore {
  filterValue: string;
  filterBooleanValue: boolean;
  filterCutoff: number; // AI prediction cutoff
  stringCutoff: number; // STRING edge cutoff
  setValue: (value: string) => void;
  setBoolValue: (value: boolean) => void;
  setCutoff: (value: number) => void;
  setStringCutoff: (value: number) => void;
  reset: () => void;
}

const INIT_FILTER_VALUE = 'true';
const INIT_FILTER_BOOLEAN_VALUE = true;
const INIT_FILTER_CUTOFF = 0.1;
const INIT_STRING_CUTOFF = 0.1;

const AiPredStore = create<AiPredStore>((set, get) => ({
  filterValue: INIT_FILTER_VALUE,
  filterBooleanValue: INIT_FILTER_BOOLEAN_VALUE,
  filterCutoff: INIT_FILTER_CUTOFF,
  stringCutoff: INIT_STRING_CUTOFF,

  setValue: (value: string) => set(() => ({
    filterValue: value,
    filterBooleanValue: value === 'true',
  })),

  setBoolValue: (value: boolean) => set(() => ({
    filterValue: String(value),
    filterBooleanValue: value,
  })),

  // Set AI prediction cutoff with a constraint: must be >= stringCutoff
  setCutoff: (value: number) =>
    set((state) => ({
      filterCutoff: Math.max(value, state.stringCutoff), // Ensure AI cutoff >= STRING cutoff
    })),

  // Set STRING edge cutoff with a constraint: AI cutoff >= STRING cutoff
  setStringCutoff: (value: number) =>
    set((state) => ({
      stringCutoff: value,
      filterCutoff: Math.max(state.filterCutoff, value), // Adjust AI cutoff if needed
    })),

  reset: () =>
    set(() => ({
      filterValue: INIT_FILTER_VALUE,
      filterBooleanValue: INIT_FILTER_BOOLEAN_VALUE,
      filterCutoff: INIT_FILTER_CUTOFF,
      stringCutoff: INIT_STRING_CUTOFF,
    })),
}));

export default AiPredStore;

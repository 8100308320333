export const graphTheme = {
  NODE_DEFAULT_COLOR: '#294559',
  NODE_DEFAULT_COLOR_DARK: '#ffffff',
  EDGE_DEFAULT_COLOR: '#113046',
  EDGE_DEFAULT_COLOR_DARK: '#ffffff',
  MUTED_COLOR: '#ffffff00',
  
  SUPER_HIGHLIGHTED_COLOR: '#ffbbbb',
  PATH_HIGHLIGHTED_COLOR: '#ffaaaa',
  NODE_SELECTED_COLOR: '#3d923a',
  NODE_IN_PATH_COLOR: '#5272f5',
  NODE_OUTSIDE_PATH_COLOR: '#bbbbbb',
  BACKGROUND_COLOR: '#ffffff',
  BACKGROUND_COLOR_DARK: '#113046',
  ENDPOINT_COLOR: '#ff8080',
  NORMAL_COLOR: '#72b9f9',
  BORDER_DEFAULT: '#550055',
  BORDER_HIGHLIGHT: '#f03ede',
  AI_PRED_EDGE_COLOR: '#e6b000',
  AI_PRED_EDGE_COLOR_DARK: '#e6b000',
};

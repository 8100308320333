import { Table, TableCell, TableRow, TableBody, Box, Typography } from '@mui/material';
import { graphTheme } from '_theme/graphTheme';

interface LegendRowEntryProps {
  color: string;
  coloredText: string;
  descriptionText: string;
  isCompact?: boolean;
}

function LegendRowEntry({ color, coloredText, descriptionText, isCompact = false }: LegendRowEntryProps) {
  const textfontsize = isCompact ? '65%' : '75%';
  const isEdge = coloredText === "Known" || coloredText === "Predicted";

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      margin: '0 6px',
      minWidth: isCompact ? '60px' : '80px'
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30px',
        width: '100%'
      }}>
        {isEdge ? (
          // Render a line for edges
          <Box sx={{
            width: '30px',
            height: '3px',
            backgroundColor: color,
            borderRadius: '2px'
          }} />
        ) : (
          // Render a circle for nodes
          <Box sx={{
            width: '12px',
            height: '12px',
            backgroundColor: color,
            borderRadius: '50%'
          }} />
        )}
      </Box>
      <Typography 
        sx={{ 
          fontSize: textfontsize,
          textAlign: 'center',
          maxWidth: isCompact ? '70px' : '90px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {descriptionText}
      </Typography>
    </Box>
  );
}

interface GraphLegendTableProps {
  isCompact?: boolean;
}

export default function GraphLegendTable({ isCompact = true }: GraphLegendTableProps) {
  return (
    <Box sx={{ 
      backgroundColor: 'rgba(255, 255, 255, 0.9)', 
      borderRadius: '4px', 
      padding: isCompact ? '6px' : '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    }}>
      <Typography variant="caption" sx={{ 
        fontWeight: 'bold', 
        textAlign: 'center',
        fontSize: isCompact ? '65%' : '75%',
        marginBottom: '4px'
      }}>
        Graph Legend
      </Typography>
      
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%'
      }}>
        <LegendRowEntry
          color={graphTheme.NODE_DEFAULT_COLOR}
          coloredText="Default"
          descriptionText="Protein Node"
          isCompact={isCompact}
        />
        <LegendRowEntry
          color={graphTheme.NODE_SELECTED_COLOR}
          coloredText="Selected"
          descriptionText="Selected Target"
          isCompact={isCompact}
        />
        <LegendRowEntry
          color={graphTheme.ENDPOINT_COLOR}
          coloredText="Endpoint"
          descriptionText="Disease Progression Node"
          isCompact={isCompact}
        />
        <LegendRowEntry
          color="#FFFACD"
          coloredText="Yellow"
          descriptionText="Down-regulated"
          isCompact={isCompact}
        />
        <LegendRowEntry
          color={graphTheme.EDGE_DEFAULT_COLOR}
          coloredText="Known"
          descriptionText="Known Interaction"
          isCompact={isCompact}
        />
        <LegendRowEntry
          color={graphTheme.AI_PRED_EDGE_COLOR}
          coloredText="Predicted"
          descriptionText="AI-predicted"
          isCompact={isCompact}
        />
      </Box>
    </Box>
  );
}


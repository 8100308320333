import { Content } from 'pdfmake/interfaces';

interface DiseaseIntroductionProps {
  diseaseName: string;
  targetName: string;
}

// Map of disease names to their introductions
const diseaseIntroductions: Record<string, string> = {
  'ALS': `Amyotrophic lateral sclerosis (ALS) is a progressive neurodegenerative disorder characterized
by the degeneration of motor neurons, leading to muscle weakness, paralysis, and eventually
death. Despite advances in understanding ALS pathophysiology, effective therapeutic strategies
remain elusive.

This report outlines the molecular framework of your selected target protein's involvement in ALS and explores its
potential as a therapeutic target. Through the multi-omic analysis, we aim to provide guidance
for future preclinical and clinical work, and contribute to the development of novel therapeutic strategies
for ALS.`,

  // Additional disease descriptions can be added here
  'Alzheimer\'s Disease': `Alzheimer's disease is a progressive neurodegenerative disorder that is the most common cause of dementia, 
affecting millions of people worldwide. It is characterized by the accumulation of amyloid plaques and neurofibrillary 
tangles in the brain, leading to cognitive decline, memory loss, and behavioral changes.

This report examines the molecular pathways connecting your selected target protein to Alzheimer's disease 
progression and evaluates its potential as a therapeutic intervention point. By integrating multiple data sources, 
we provide insights that may guide drug development efforts for this devastating condition.`,

  'Parkinson\'s Disease': `Parkinson's disease is a progressive neurodegenerative disorder primarily affecting the motor system. 
It is characterized by the loss of dopaminergic neurons in the substantia nigra and the presence of Lewy bodies, 
leading to symptoms such as tremor, rigidity, and bradykinesia.

This report analyzes how your selected target protein interacts with the molecular mechanisms underlying Parkinson's disease 
and assesses its therapeutic potential. Our multi-omic approach aims to identify promising pathways for intervention 
and drug development.`,

  'Multiple Sclerosis': `Multiple Sclerosis (MS) is an autoimmune disorder in which the immune system attacks the protective 
covering of nerve fibers, causing communication problems between the brain and the rest of the body. 
MS can cause permanent damage or deterioration of nerves.

This report explores how your selected target protein intersects with MS pathophysiology and evaluates 
its potential as a therapeutic target. By analyzing protein interactions and disease-relevant pathways, 
we provide insights that may contribute to new treatment approaches.`,

  'Breast Cancer': `Breast cancer is one of the most common cancers affecting women worldwide. It forms in the cells of the breasts 
and can invade surrounding tissue or spread to other areas of the body, resulting in metastatic disease. 
Early detection and advances in treatment have improved outcomes, but new therapeutic approaches are still needed.

This report examines the role of your selected target protein in breast cancer progression and evaluates 
its potential as a therapeutic intervention point. Our analysis integrates multiple data sources to identify 
critical pathways and interactions that may inform drug discovery efforts.`,

  'Lung Cancer': `Lung cancer is a leading cause of cancer deaths worldwide. It begins in the lungs and can spread to other 
parts of the body. Despite advances in treatment options, including targeted therapies and immunotherapies, 
the prognosis for many patients remains poor.

This report investigates how your selected target protein contributes to lung cancer development and progression, 
and assesses its value as a therapeutic target. Through comprehensive pathway analysis, we aim to provide 
insights that could lead to novel treatment strategies.`
};

// Function to get the introduction for a specific disease
function getDiseaseIntroduction(diseaseName: string, targetName: string): string {
  // Normalize disease name for matching
  const normalizedDiseaseName = normalizeDiseaseNameForMatching(diseaseName);
  
  // Get introduction for the disease or use a default
  let introduction = diseaseIntroductions[normalizedDiseaseName] || 
    `This report examines the role of your selected target protein in ${diseaseName} and evaluates 
its potential as a therapeutic intervention point. By analyzing protein interactions and disease pathways, 
we aim to provide insights that may guide drug development efforts.`;
  
  // Replace generic "target protein" with actual target name if provided
  if (targetName) {
    introduction = introduction.replace(/your selected target protein/g, targetName);
  }
  
  return introduction;
}

// Function to normalize disease names for matching with our introduction map
function normalizeDiseaseNameForMatching(diseaseName: string): string {
  // Convert to lowercase for comparison
  const lowerCaseName = diseaseName.toLowerCase();
  
  // Check for partial matches
  if (lowerCaseName.includes('als') || lowerCaseName.includes('amyotrophic lateral sclerosis')) {
    return 'ALS';
  }
  if (lowerCaseName.includes('alzheimer') || lowerCaseName.includes('alzheimer\'s')) {
    return 'Alzheimer\'s Disease';
  }
  if (lowerCaseName.includes('parkinson') || lowerCaseName.includes('parkinson\'s')) {
    return 'Parkinson\'s Disease';
  }
  if (lowerCaseName.includes('multiple sclerosis') || lowerCaseName.includes('ms ')) {
    return 'Multiple Sclerosis';
  }
  if (lowerCaseName.includes('breast cancer')) {
    return 'Breast Cancer';
  }
  if (lowerCaseName.includes('lung cancer')) {
    return 'Lung Cancer';
  }
  
  // Return the original name if no match is found
  return diseaseName;
}

// Function to create disease introduction content for the PDF report
export function createDiseaseIntroductionSection(diseaseName: string, targetName: string): Content {
  const introduction = getDiseaseIntroduction(diseaseName, targetName);
  
  // Split the introduction by empty lines to create paragraphs
  const paragraphs = introduction.split('\n\n').filter(para => para.trim().length > 0);
  
  // Create the content structure with proper typing
  const content: Content = {
    stack: [
      {
        text: `Introduction to ${diseaseName}`,
        style: 'sectionHeader',
        margin: [0, 20, 0, 10],
      } as Content,
      ...paragraphs.map(paragraph => ({
        text: paragraph,
        style: 'normal',
        margin: [0, 0, 0, 10],
      } as Content))
    ]
  } as Content;
  
  return content;
}

// For web UI usage
export function DiseaseIntroduction({ diseaseName, targetName }: DiseaseIntroductionProps) {
  const introduction = getDiseaseIntroduction(diseaseName, targetName);
  return introduction;
} 
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { useAuth0 } from '@auth0/auth0-react';
import { graphTheme } from '_theme/graphTheme';
import { useTheme } from '@mui/material/styles';
import { SCORES_DECIMAL } from './NodeProcessPathsPage';

function dashed(isBroken) {
  return isBroken ? '3, 3' : null;
}

function backgroundColor(isBroken) {
  return isBroken ? '#fff8eb' : '#ffffff';
}

// Function to get edge color based on edge type and score
function getEdgeColor(isPredicted, aiScore, stringScore, isHybrid = false) {
  console.log(`DEBUG: getEdgeColor called with isPredicted=${isPredicted}, aiScore=${aiScore}, stringScore=${stringScore}, isHybrid=${isHybrid}`);
  
  try {
    // In hybrid case, use AI score for color intensity 
    if (isHybrid && typeof aiScore === 'number') {
      const colorIntensity = Math.max(0.1, Math.min(1, aiScore));
      console.log(`DEBUG: Using HYBRID color with AI intensity ${aiScore} and STRING presence: rgba(128, 0, 128, ${colorIntensity})`);
      // Use a distinctive purple color for hybrid edges
      return `rgba(128, 0, 128, ${colorIntensity})`;
    }
    // AI prediction case
    else if (isPredicted && typeof aiScore === 'number') {
      const colorIntensity = Math.max(0.1, Math.min(1, aiScore));
      console.log(`DEBUG: Using AI color with intensity ${aiScore}: rgba(255, 136, 0, ${colorIntensity})`);
      // Orange color for AI predictions
      return `rgba(255, 136, 0, ${colorIntensity})`;
    } 
    // STRING database case
    else if (!isPredicted && typeof stringScore === 'number') {
      const colorIntensity = Math.max(0.1, Math.min(1, stringScore));
      console.log(`DEBUG: Using STRING color with intensity ${stringScore}: rgba(17, 48, 70, ${colorIntensity})`);
      // Blue color for STRING database
      return `rgba(17, 48, 70, ${colorIntensity})`;
    } 
    // Default case
  else {
      console.log(`DEBUG: Using default color: rgba(100, 100, 100, 0.5)`);
      return 'rgba(100, 100, 100, 0.5)';
    }
  } catch (error) {
    console.error('Error in getEdgeColor:', error);
    return 'rgba(100, 100, 100, 0.5)';
  }
}

const DEFAULT_MARGIN = 5;

function PathGraph({
  id,
  path,
  selectedNodeId,
  renderAxis = false,
  width = 814,
  height = 420,
  fdaApprovedDrugs = null,
  isInteractive = true,
  fullSize = false,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const d3Container = useRef(null);
  const theme = useTheme();
  
  // Calculate actual SVG dimensions with margins
  const margin = { top: DEFAULT_MARGIN, right: DEFAULT_MARGIN, bottom: DEFAULT_MARGIN, left: DEFAULT_MARGIN };
  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;

  // Add a helper function to get FDA drugs for a protein ID
  function getFdaDrugsForProtein(proteinId) {
    if (!fdaApprovedDrugs || !proteinId) return null;
    
    // Check ensembl IDs first
    if (fdaApprovedDrugs.ensembl_ids && fdaApprovedDrugs.ensembl_ids[proteinId]) {
      return fdaApprovedDrugs.ensembl_ids[proteinId];
    }
    
    // If we have protein info with preferredName, check gene symbols
    if (path && path.protein_info && path.protein_info[proteinId]) {
      const geneSymbol = path.protein_info[proteinId].preferredName;
      if (geneSymbol && fdaApprovedDrugs.gene_symbols && fdaApprovedDrugs.gene_symbols[geneSymbol]) {
        return fdaApprovedDrugs.gene_symbols[geneSymbol];
      }
    }
    
    return null;
  }

  useEffect(() => {
    if (!path) return;
    
    // Extract nodes and links from the path data
    let nodes = [];
    let links = [];
    
    // Handle different path formats
    if (path.path && typeof path.path === 'object' && path.path.nodes && path.path.links) {
      // Old format with nodes and links objects
      nodes = path.path.nodes;
      links = path.path.links;
    } else if (Array.isArray(path.path)) {
      // New format with path as array of node IDs
      nodes = path.path.map(nodeId => {
        const isEndpoint = nodeId === path.endpoint;
        const isTarget = nodeId === selectedNodeId || nodeId === path.target;
        
        // Get preferred name from protein_info which may be in the efficacyData
        let preferredName = nodeId;
        // Check if protein_info exists directly on the path object
        if (path.protein_info && path.protein_info[nodeId] && path.protein_info[nodeId].preferredName) {
          preferredName = path.protein_info[nodeId].preferredName;
        } 
        // If there's no protein_info on the path, check if it's directly on the efficacyData
        else if (path.efficacyData && path.efficacyData.protein_info && 
                path.efficacyData.protein_info[nodeId] && 
                path.efficacyData.protein_info[nodeId].preferredName) {
          preferredName = path.efficacyData.protein_info[nodeId].preferredName;
        }
        // Extract last part of ID as fallback if it's a STRING ID format (e.g., "9606.ENSP00000123456")
        else if (nodeId.includes('.')) {
          const parts = nodeId.split('.');
          preferredName = parts[parts.length - 1];
        }
        
        return {
          id: nodeId,
          name: preferredName,
          is_endpoint: isEndpoint,
          is_target: isTarget
        };
      });
      
      // For each consecutive pair of nodes in the path
      for (let i = 0; i < nodes.length - 1; i++) {
        // Initialize edge data properties with default values
        let isPredicted = false;
        let aiRelation = null;
        let stringRelation = null;
        let relationScore = null; // Don't set a default initially
        let isHybrid = false; // Flag for hybrid connections
        let relInfo = null; // Declare relInfo at the beginning of the loop
        
        const sourceId = nodes[i].id;
        const targetId = nodes[i+1].id;
        const edgeKey = `${sourceId}-${targetId}`;
        
        console.debug(`DEBUG: Processing edge ${edgeKey} for graph ${id}`);
        
        try {
          // Try to find relationship information from the path's relationships
          
          // Check path.relationships first
          if (path.relationships && path.relationships[edgeKey]) {
            relInfo = path.relationships[edgeKey];
            console.debug(`DEBUG: [${id}] Found relationship data in path.relationships for ${edgeKey}`);
          } 
          // Then check efficacyData
        else if (path.efficacyData && path.efficacyData.relationships && 
                  path.efficacyData.relationships[edgeKey]) {
            relInfo = path.efficacyData.relationships[edgeKey];
            console.debug(`DEBUG: [${id}] Found relationship data in path.efficacyData.relationships for ${edgeKey}`);
          }
          // Then check path edge_data
          else if (path.edge_data && path.edge_data[edgeKey]) {
            relInfo = path.edge_data[edgeKey];
            console.debug(`DEBUG: [${id}] Found relationship data in path.edge_data for ${edgeKey}`);
          }
          // Then check efficacyData edge_data
          else if (path.efficacyData && path.efficacyData.edge_data && 
                   path.efficacyData.edge_data[edgeKey]) {
            relInfo = path.efficacyData.edge_data[edgeKey];
            console.debug(`DEBUG: [${id}] Found relationship data in path.efficacyData.edge_data for ${edgeKey}`);
          }
          
          // Dump the entire relInfo object for debugging
          if (relInfo) {
            console.debug(`DEBUG: [${id}] Edge data for ${edgeKey}:`, JSON.stringify(relInfo));
          } else {
            console.debug(`DEBUG: [${id}] No relationship data found for ${edgeKey}, will use defaults`);
          }
          
          // If we found relationship info, extract the needed properties
          if (relInfo) {
            // First, check if the data has an explicit predicted flag
            if (relInfo.predicted === true) {
              isPredicted = true;
              console.debug(`DEBUG: [${id}] ${edgeKey}: explicitly marked as predicted`);
            } 
            // Second method: Edge is AI-predicted if it has ai_relation but STRING is zero or very low
            else if (typeof relInfo.ai_relation === 'number') {
              if (typeof relInfo.string_relation !== 'number' || relInfo.string_relation === 0) {
                isPredicted = true;
                console.debug(`DEBUG: [${id}] ${edgeKey}: marked as AI prediction because STRING relation is zero`);
              } 
              // If STRING relation exists but is very low, consider edge type based on relative values
              else if (relInfo.string_relation < 0.05) {
                isPredicted = true;
                console.debug(`DEBUG: [${id}] ${edgeKey}: marked as AI prediction because STRING relation is very low (${relInfo.string_relation})`);
              }
              // If STRING relation is meaningful, use it as the primary edge type
              else {
                isPredicted = false;
                console.debug(`DEBUG: [${id}] ${edgeKey}: marked as STRING relationship even though AI score exists`);
              }
            }
            
            // Get AI relation score if available - starting with the most reliable source
            if (typeof relInfo.ai_relation === 'number') {
              aiRelation = relInfo.ai_relation;
              console.debug(`DEBUG: [${id}] ${edgeKey}: found ai_relation=${aiRelation}`);
            } else if (typeof relInfo.model_prob === 'number') {
              aiRelation = relInfo.model_prob;
              console.debug(`DEBUG: [${id}] ${edgeKey}: using model_prob=${aiRelation} as ai_relation`);
            } else if (isPredicted && typeof relInfo.relation === 'number') {
              // If it's predicted and has relation but no ai_relation, use relation
              aiRelation = relInfo.relation;
              console.debug(`DEBUG: [${id}] ${edgeKey}: using relation=${aiRelation} as ai_relation for predicted edge`);
            }
            
            // Get STRING relation score if available - starting with the most reliable source
            if (typeof relInfo.string_relation === 'number') {
              stringRelation = relInfo.string_relation;
              console.debug(`DEBUG: [${id}] ${edgeKey}: found string_relation=${stringRelation}`);
            } else if (typeof relInfo.string_prob === 'number') {
              stringRelation = relInfo.string_prob;
              console.debug(`DEBUG: [${id}] ${edgeKey}: using string_prob=${stringRelation} as string_relation`);
            } else if (!isPredicted && typeof relInfo.relation === 'number') {
              // If it's not predicted and has relation but no string_relation, use relation
              stringRelation = relInfo.relation;
              console.debug(`DEBUG: [${id}] ${edgeKey}: using relation=${stringRelation} as string_relation for known edge`);
            }
            
            // Get the main relation score based on edge type
            if (typeof relInfo.relation === 'number') {
              relationScore = relInfo.relation;
              console.debug(`DEBUG: [${id}] ${edgeKey}: using relation=${relationScore} from API`);
            } else if (isPredicted && aiRelation !== null) {
              relationScore = aiRelation;
              console.debug(`DEBUG: [${id}] ${edgeKey}: setting relation=${relationScore} from ai_relation for predicted edge`);
            } else if (!isPredicted && stringRelation !== null) {
              relationScore = stringRelation;
              console.debug(`DEBUG: [${id}] ${edgeKey}: setting relation=${relationScore} from string_relation for known edge`);
            } else {
              // Only use default if we couldn't find any actual scores
              relationScore = 0.7;
              console.debug(`DEBUG: [${id}] ${edgeKey}: no scores found, using default relation=${relationScore}`);
            }
            
            // Check for hybrid edges - based on specific criteria
            // Only mark as hybrid when AI score is significantly higher than STRING score
            // AND STRING score is non-zero
            isHybrid = relInfo.hybrid === true || 
                      (typeof aiRelation === 'number' && 
                       typeof stringRelation === 'number' && 
                       stringRelation > 0 &&         // STRING score must be non-zero
                       aiRelation > stringRelation + 0.2);  // AI must be significantly higher (0.2 threshold)
            
            if (isHybrid) {
              console.debug(`DEBUG: [${id}] ${edgeKey}: identified as hybrid with AI=${aiRelation} significantly higher than STRING=${stringRelation}`);
            }
          } else {
            // No relationship info found, use defaults
            isPredicted = false;
            relationScore = 0.7;
            stringRelation = 0.7;
            console.debug(`DEBUG: [${id}] ${edgeKey}: no relationship data, using defaults (predicted=${isPredicted}, relation=${relationScore})`);
          }
          
          // Log the final classification for clearer debugging
          let edgeClassification = "UNKNOWN";
          if (isHybrid) {
            edgeClassification = "HYBRID";
          } else if (isPredicted) {
            edgeClassification = "AI";
          } else {
            edgeClassification = "STRING";
          }
          
          console.debug(`DEBUG: [${id}] Final edge ${edgeKey} classification: ${edgeClassification}`);
          console.debug(`DEBUG: [${id}] Final edge ${edgeKey} data: ${JSON.stringify({
            source: nodes[i].id,
            target: nodes[i+1].id,
            ai_relation: aiRelation,
            string_relation: stringRelation,
            predicted: isPredicted,
            hybrid: isHybrid,
            relation: relationScore
          })}`);
        } catch (error) {
          console.error(`Error processing edge data for ${edgeKey}:`, error);
          // Use safe defaults
          isPredicted = false;
          relationScore = 0.7;
          // Don't reset relInfo here, in case it was already set
          console.debug(`${edgeKey}: error during processing, using defaults (predicted=${isPredicted}, relation=${relationScore})`);
        }
        
        // FOR HYBRID CASE: Create two links instead of one
        if (isHybrid && aiRelation !== null && stringRelation !== null) {
          console.log(`DEBUG: Creating hybrid edge links for ${edgeKey}`);
          
          // Create STRING link (solid)
          const stringLinkObj = {
            source: nodes[i].id,
            target: nodes[i+1].id,
            predicted: false,
            isStringLink: true,
            hybrid: true, // Mark as part of a hybrid connection
            relation: stringRelation,
            string_relation: stringRelation,
            offset: -2 // Offset to avoid exact overlap
          };
          
          // Create AI link (dashed)
          const aiLinkObj = {
            source: nodes[i].id,
            target: nodes[i+1].id,
            predicted: true,
            isAiLink: true, 
            hybrid: true, // Mark as part of a hybrid connection
            relation: aiRelation,
            ai_relation: aiRelation,
            offset: 2 // Offset in opposite direction
          };
          
          console.debug(`DEBUG: [${id}] Created two links for hybrid edge ${edgeKey}:`);
          console.debug(`- STRING link: ${JSON.stringify(stringLinkObj)}`);
          console.debug(`- AI link: ${JSON.stringify(aiLinkObj)}`);
          
          // Add both links
          links.push(stringLinkObj);
          links.push(aiLinkObj);
        }
        // For non-hybrid case, create single link as before
        else {
          // Create link object with all the data - ensure we don't pass undefined/null values
          const linkObj = {
            source: nodes[i].id,
            target: nodes[i+1].id,
            predicted: isPredicted
          };
          
          // Only add relation score if we have it (don't use defaults)
          if (relationScore !== null) {
            linkObj.relation = relationScore;
          }
          
          // Only add ai_relation if we actually have it
          if (aiRelation !== null) {
            linkObj.ai_relation = aiRelation;
          }
          
          // Only add string_relation if we actually have it
          if (stringRelation !== null) {
            linkObj.string_relation = stringRelation;
          }
          
          // Log the complete link object being added
          console.debug(`DEBUG: [${id}] Created link object for ${nodes[i].id}-${nodes[i+1].id}:`, JSON.stringify(linkObj));
          
          links.push(linkObj);
        }
      }
    }

    // Clear existing SVG content
    const container = d3.select(d3Container.current);
    container.selectAll("*").remove();
    
    // Create SVG element
    const svg = container.append("svg")
      .attr("width", svgWidth)
      .attr("height", svgHeight)
      .style("background-color", backgroundColor(false));
      
    // Add a group for the graph
    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create simulation
    const simulation = d3.forceSimulation()
      .force('link', d3.forceLink().id(d => d.id).distance(100))
      .force('charge', d3.forceManyBody().strength(-300))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collision', d3.forceCollide().radius(20));

    // Validate our links have valid sources and targets
    const validLinks = links.filter(link => {
      const sourceExists = nodes.some(node => node.id === link.source);
      const targetExists = nodes.some(node => node.id === link.target);
      if (!sourceExists || !targetExists) {
        console.warn(`Invalid link: ${link.source} -> ${link.target}. One or both nodes don't exist.`);
      }
      return sourceExists && targetExists;
    });

    // Create links - now with proper handling of hybrid edge offsets
    const link = g.selectAll('.link')
      .data(validLinks)
      .enter()
      .append('line')
      .attr('class', 'link')
      .style('stroke-width', d => d.hybrid ? 2 : 1.5) // Make hybrid edges slightly thicker
      .style('stroke', d => {
        try {
          // Simplified edge color determination based on type
          if (d.isAiLink) {
            // AI links are orange
            return `rgba(255, 136, 0, ${Math.max(0.3, Math.min(1, d.ai_relation || 0.7))})`;
          } else if (d.isStringLink) {
            // STRING links are blue
            return `rgba(17, 48, 70, ${Math.max(0.3, Math.min(1, d.string_relation || 0.7))})`;
          } else if (d.predicted) {
            // Standard AI predicted edges
            const aiRelation = d.ai_relation || d.relation || 0.7;
            return `rgba(255, 136, 0, ${Math.max(0.3, Math.min(1, aiRelation))})`;
          } else {
            // Standard STRING edges
            const stringRelation = d.string_relation || d.relation || 0.7;
            return `rgba(17, 48, 70, ${Math.max(0.3, Math.min(1, stringRelation))})`;
          }
        } catch (error) {
          console.error(`DEBUG: [${id}] Error determining edge color:`, error);
          // Default gray color as fallback
          return 'rgba(100, 100, 100, 0.5)';
        }
      })
      .style('stroke-dasharray', d => {
        // AI edges are always dashed (both dedicated AI links and standard predicted edges)
        return (d.predicted || d.isAiLink) ? '3, 3' : null;
      })
      .on('mouseover', function(event, d) {
        try {
        // Show tooltip with interaction information
        const pointX = event.pageX;
        const pointY = event.pageY;
        
        const tooltipDiv = d3.select('body')
          .append('div')
          .attr('class', 'tooltip')
          .style('position', 'absolute')
          .style('background-color', 'white')
          .style('border', '1px solid #ddd')
          .style('border-radius', '4px')
          .style('padding', '10px')
          .style('box-shadow', '0 0 10px rgba(0,0,0,0.1)')
          .style('z-index', '10')
          .style('top', `${pointY + 10}px`)
          .style('left', `${pointX + 10}px`)
          .style('visibility', 'visible');
          
          const sourceId = d.source.id || d.source;
          const targetId = d.target.id || d.target;
          const edgeKey = `${sourceId}-${targetId}`;
          console.log(`DEBUG: Showing tooltip for edge ${edgeKey} in graph ${id}`);
          console.log(`DEBUG: Edge data:`, JSON.stringify(d));
          
          // Check if we have this edge in the original path's edge_data
          const pathEdgeData = path.edge_data && path.edge_data[edgeKey] ? path.edge_data[edgeKey] : null;
          if (pathEdgeData) {
            console.log(`DEBUG: [${id}] Tooltip - Found original edge data for ${edgeKey} in path.edge_data:`, 
              JSON.stringify(pathEdgeData));
          }
            
          // Set the tooltip title based on edge type
          let tooltipTitle;
          if (d.hybrid) {
            tooltipTitle = 'Hybrid Interaction (AI + STRING)';
          } else if (d.isAiLink || d.predicted) {
            tooltipTitle = 'AI-Predicted Interaction';
          } else if (d.isStringLink || (!d.predicted && d.string_relation)) {
            tooltipTitle = 'Known Interaction (STRING database)';
          } else {
            tooltipTitle = 'Protein Interaction';
          }
          
          tooltipDiv.append('div')
            .style('font-weight', 'bold')
            .style('margin-bottom', '5px')
            .text(tooltipTitle);
            
          // Determine scores to show in tooltip - more reliable handling
          let aiScore = null;
          let stringScore = null;
          
          // Get AI score from various possible sources
          if (typeof d.ai_relation === 'number' && !isNaN(d.ai_relation)) {
            aiScore = d.ai_relation;
          } else if (d.predicted && typeof d.relation === 'number' && !isNaN(d.relation)) {
            aiScore = d.relation;
          } else if (pathEdgeData && typeof pathEdgeData.ai_relation === 'number' && !isNaN(pathEdgeData.ai_relation)) {
            aiScore = pathEdgeData.ai_relation;
          }
          
          // Get STRING score from various possible sources
          if (typeof d.string_relation === 'number' && !isNaN(d.string_relation)) {
            stringScore = d.string_relation;
          } else if (!d.predicted && typeof d.relation === 'number' && !isNaN(d.relation)) {
            stringScore = d.relation;
          } else if (pathEdgeData && typeof pathEdgeData.string_relation === 'number' && !isNaN(pathEdgeData.string_relation)) {
            stringScore = pathEdgeData.string_relation;
          }
          
          // Display score based on edge type - for hybrid show both, otherwise only show the relevant score
          if (d.hybrid) {
            // For hybrid edges, show both scores
            if (aiScore !== null) {
              tooltipDiv.append('div')
                .style('margin-bottom', '3px')
                .html(`<b>AI Score:</b> ${(aiScore * 100).toFixed(SCORES_DECIMAL)}%`);
            }
            
            if (stringScore !== null) {
              tooltipDiv.append('div')
                .style('margin-bottom', '3px')
                .html(`<b>STRING Score:</b> ${(stringScore * 100).toFixed(SCORES_DECIMAL)}%`);
            }
            
            tooltipDiv.append('div')
              .style('font-style', 'italic')
              .style('font-size', '12px')
              .style('color', '#666')
              .style('margin-top', '3px')
              .text('This connection has both AI prediction and STRING database support.');
          }
          else if (d.isAiLink || d.predicted) {
            // AI edge - show only AI score
            const scoreText = aiScore !== null
              ? `${(aiScore * 100).toFixed(SCORES_DECIMAL)}%` 
              : '70% (default)';
            
            tooltipDiv.append('div')
              .html(`<strong>AI Score:</strong> ${scoreText}`);
          }
          else {
            // STRING edge - show only STRING score
            const scoreText = stringScore !== null
              ? `${(stringScore * 100).toFixed(SCORES_DECIMAL)}%` 
              : '70% (default)';
            
            tooltipDiv.append('div')
              .html(`<strong>STRING Score:</strong> ${scoreText}`);
          }
          
          // Source/Target - with defensive code
          let sourceNode, targetNode;
          try {
            sourceNode = nodes.find(n => n.id === sourceId);
            targetNode = nodes.find(n => n.id === targetId);
          } catch (error) {
            // Handle case where source/target is not properly formatted
            sourceNode = { name: sourceId.toString() };
            targetNode = { name: targetId.toString() };
          }
        
        tooltipDiv.append('div')
          .style('margin-top', '5px')
            .html(`<strong>From:</strong> ${sourceNode && sourceNode.name ? sourceNode.name : sourceId.toString()}`);
          
        tooltipDiv.append('div')
            .html(`<strong>To:</strong> ${targetNode && targetNode.name ? targetNode.name : targetId.toString()}`);

        // Add FDA-approved drug information if available
          try {
        const fdaDrugs = getFdaDrugsForProtein(d.id);
        if (fdaDrugs && fdaDrugs.length > 0) {
          const drugsDiv = tooltipDiv.append('div')
            .style('margin-top', '8px')
            .style('padding-top', '8px')
            .style('border-top', '1px solid #eee');
          
          drugsDiv.append('div')
            .style('font-weight', 'bold')
            .style('color', '#28a745')
            .text('FDA-Approved Drugs:');
          
          fdaDrugs.forEach(drug => {
            drugsDiv.append('div')
              .style('margin-left', '10px')
              .style('margin-top', '3px')
              .html(`• ${drug}`);
          });
            }
          } catch (drugError) {
            console.error("Error displaying FDA approved drugs:", drugError);
          }
        } catch (error) {
          console.error("Error displaying tooltip:", error);
        }
      })
      .on('mouseout', function() {
        d3.select('.tooltip').remove();
      });

    // Create nodes
    const node = g.selectAll('.node')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .on('mouseover', function(event, d) {
        try {
        // Hide any existing tooltips
        d3.selectAll('.tooltip').remove();
        
        const pointX = event.pageX;
        const pointY = event.pageY;
        
        const tooltipDiv = d3.select('body')
          .append('div')
          .attr('class', 'tooltip')
          .style('position', 'absolute')
          .style('background-color', 'white')
          .style('border', '1px solid #ddd')
          .style('border-radius', '4px')
          .style('padding', '10px')
          .style('box-shadow', '0 0 10px rgba(0,0,0,0.1)')
          .style('max-width', '300px')
          .style('z-index', '10')
          .style('top', `${pointY + 10}px`)
          .style('left', `${pointX + 10}px`)
          .style('visibility', 'visible');
        
        // Add title with protein name
        tooltipDiv.append('div')
          .style('font-weight', 'bold')
          .style('margin-bottom', '5px')
          .style('font-size', '14px')
            .text(d.name || d.id || "Unknown Protein");
        
        // Add STRING ID
        tooltipDiv.append('div')
          .style('margin-bottom', '5px')
            .html(`<strong>STRING ID:</strong> ${d.id || "Unknown"}`);
        
        // Add protein type
        if (d.annotation) {
          tooltipDiv.append('div')
            .style('margin-bottom', '5px')
            .html(`<strong>Type:</strong> ${d.annotation}`);
        }
        
        // Add FDA-approved drugs information if available
          try {
        const fdaDrugs = getFdaDrugsForProtein(d.id);
        if (fdaDrugs && fdaDrugs.length > 0) {
          const drugsDiv = tooltipDiv.append('div')
            .style('margin-top', '8px')
            .style('padding-top', '8px')
            .style('border-top', '1px solid #eee');
          
          drugsDiv.append('div')
            .style('font-weight', 'bold')
            .style('color', '#28a745')
            .text('FDA-Approved Drugs:');
          
          fdaDrugs.forEach(drug => {
            drugsDiv.append('div')
              .style('margin-left', '10px')
              .style('margin-top', '3px')
              .html(`• ${drug}`);
          });
            }
          } catch (error) {
            console.error("Error displaying FDA approved drugs:", error);
          }
        } catch (error) {
          console.error("Error displaying node tooltip:", error);
        }
      })
      .on('mouseout', function() {
        d3.select('.tooltip').remove();
      })
      .call(d3.drag()
        .on('start', function(event, d) {
          if (!isInteractive) return;
          if (!event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        })
        .on('drag', function(event, d) {
          if (!isInteractive) return;
          d.fx = event.x;
          d.fy = event.y;
        })
        .on('end', function(event, d) {
          if (!isInteractive) return;
          if (!event.active) simulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
        })
      );

    // Add circles for nodes
    node.append('circle')
      .attr('r', 7)
      .style('fill', d => {
        if (d.is_endpoint || d.id === path.endpoint) {
          return graphTheme.ENDPOINT_COLOR;
        } else if (d.is_target || d.id === path.target || d.id === selectedNodeId) {
          return graphTheme.NODE_SELECTED_COLOR;
        } else {
          return graphTheme.NODE_IN_PATH_COLOR;
        }
      });

    // Add labels to nodes
    node.append('text')
      .attr('dy', -10)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .attr('fill', '#000000')
      .text(d => d.name);

    // Update positions during simulation
    simulation.on('tick', () => {
      link
        .attr('x1', d => {
          const source_x = Math.max(0, Math.min(width, d.source.x));
          // Apply offset for hybrid edges
          if (d.offset) {
            // Get position of target node
            const target_x = Math.max(0, Math.min(width, d.target.x));
            // Calculate perpendicular vector
            const dx = target_x - source_x;
            const dy = Math.max(0, Math.min(height, d.target.y)) - Math.max(0, Math.min(height, d.source.y));
            const len = Math.sqrt(dx * dx + dy * dy);
            // Apply offset perpendicular to the edge direction
            if (len > 0) {
              const offsetX = -dy / len * d.offset;
              return source_x + offsetX;
            }
          }
          return source_x;
        })
        .attr('y1', d => {
          const source_y = Math.max(0, Math.min(height, d.source.y));
          // Apply offset for hybrid edges
          if (d.offset) {
            // Get position of target node
            const target_y = Math.max(0, Math.min(height, d.target.y));
            // Calculate perpendicular vector
            const dx = Math.max(0, Math.min(width, d.target.x)) - Math.max(0, Math.min(width, d.source.x));
            const dy = target_y - source_y;
            const len = Math.sqrt(dx * dx + dy * dy);
            // Apply offset perpendicular to the edge direction
            if (len > 0) {
              const offsetY = dx / len * d.offset;
              return source_y + offsetY;
            }
          }
          return source_y;
        })
        .attr('x2', d => {
          const target_x = Math.max(0, Math.min(width, d.target.x));
          // Apply offset for hybrid edges
          if (d.offset) {
            // Get position of source node 
            const source_x = Math.max(0, Math.min(width, d.source.x));
            // Calculate perpendicular vector
            const dx = target_x - source_x;
            const dy = Math.max(0, Math.min(height, d.target.y)) - Math.max(0, Math.min(height, d.source.y));
            const len = Math.sqrt(dx * dx + dy * dy);
            // Apply offset perpendicular to the edge direction
            if (len > 0) {
              const offsetX = -dy / len * d.offset;
              return target_x + offsetX;
            }
          }
          return target_x;
        })
        .attr('y2', d => {
          const target_y = Math.max(0, Math.min(height, d.target.y));
          // Apply offset for hybrid edges
          if (d.offset) {
            // Get position of source node
            const source_y = Math.max(0, Math.min(height, d.source.y));
            // Calculate perpendicular vector
            const dx = Math.max(0, Math.min(width, d.target.x)) - Math.max(0, Math.min(width, d.source.x));
            const dy = target_y - source_y;
            const len = Math.sqrt(dx * dx + dy * dy);
            // Apply offset perpendicular to the edge direction
            if (len > 0) {
              const offsetY = dx / len * d.offset;
              return target_y + offsetY;
            }
          }
          return target_y;
        });

      node
        .attr('transform', d => `translate(${Math.max(0, Math.min(width, d.x))},${Math.max(0, Math.min(height, d.y))})`);
    });

    // Start simulation with the nodes and links
    simulation.nodes(nodes);
    simulation.force('link').links(validLinks);

  }, [path, width, height, selectedNodeId, isInteractive, fdaApprovedDrugs]);

  return <div ref={d3Container} className={`d3-component-${id}`} />;
}

// Add PropTypes for type checking
PathGraph.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string,
  renderAxis: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  // FDA approved drugs data with ensembl_ids and gene_symbols mappings
  fdaApprovedDrugs: PropTypes.any, // This can be null, undefined, or an object with mappings
  isInteractive: PropTypes.bool,
  fullSize: PropTypes.bool
};

export default PathGraph;

/**
 * A simplified version of PathGraph specifically for rendering thumbnails
 */
export const SmallPathGraph = ({ pathData }) => {
  const id = `small-graph-${Math.random().toString(36).substring(2, 10)}`;
  const graphRef = useRef(null);

  useEffect(() => {
    if (!graphRef.current || !pathData || !pathData.path) return;

    // Clear any existing content
    const container = d3.select(graphRef.current);
    container.selectAll('*').remove();

    // Create SVG
    const width = 240;
    const height = 180;
    const svg = container.append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet');

    // Extract nodes and links from path data
    const nodes = [];
    const links = [];

    try {
      // Create nodes from path
      if (Array.isArray(pathData.path)) {
        pathData.path.forEach((nodeId, index) => {
          const name = pathData.protein_info && pathData.protein_info[nodeId] 
            ? pathData.protein_info[nodeId].preferredName || nodeId 
            : nodeId;
          
          nodes.push({
            id: nodeId,
            name,
            index
          });
        });

        // Create links between consecutive nodes
        for (let i = 0; i < pathData.path.length - 1; i++) {
          const source = pathData.path[i];
          const target = pathData.path[i + 1];
          
          links.push({
            source,
            target,
            id: `${source}-${target}`
          });
        }
      }
    } catch (error) {
      console.error('Error preparing data for small path graph:', error);
    }

    if (nodes.length === 0) return;

    // Create a simplified force simulation
    const simulation = d3.forceSimulation(nodes)
      .force('link', d3.forceLink(links).id(d => d.id).distance(50))
      .force('charge', d3.forceManyBody().strength(-200))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .stop();

    // Run simulation for a few iterations to get a stable layout
    for (let i = 0; i < 50; i++) simulation.tick();

    // Draw links
    svg.selectAll('.link')
      .data(links)
      .enter()
      .append('line')
      .attr('class', 'link')
      .attr('x1', d => {
        const sourceNode = nodes.find(n => n.id === d.source.id || n.id === d.source);
        return sourceNode ? sourceNode.x : 0;
      })
      .attr('y1', d => {
        const sourceNode = nodes.find(n => n.id === d.source.id || n.id === d.source);
        return sourceNode ? sourceNode.y : 0;
      })
      .attr('x2', d => {
        const targetNode = nodes.find(n => n.id === d.target.id || n.id === d.target);
        return targetNode ? targetNode.x : 0;
      })
      .attr('y2', d => {
        const targetNode = nodes.find(n => n.id === d.target.id || n.id === d.target);
        return targetNode ? targetNode.y : 0;
      })
      .attr('stroke', '#2F80ED')
      .attr('stroke-width', 2);

    // Draw nodes
    svg.selectAll('.node')
      .data(nodes)
      .enter()
      .append('circle')
      .attr('class', 'node')
      .attr('r', 7)
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
      .attr('fill', '#4F4F4F')
      .attr('stroke', '#FFFFFF')
      .attr('stroke-width', 1);

  }, [pathData]);

  return <div ref={graphRef} style={{ width: '100%', height: '100%' }}></div>;
};
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Paper, Typography, Button, Stack } from '@mui/material';
import Loading from '_components/Loading';
import { useHistory } from 'react-router-dom';

function EmailVerificationWait() {
  const { user, isAuthenticated, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [verifyStatus, setVerifyStatus] = useState('idle');
  const history = useHistory();

  // If not authenticated or user not loaded, show spinner
  if (!isAuthenticated || !user) {
    return <Loading />;
  }

  const userEmail = user.email || 'your email';

  // Poll every 10 seconds to force a fresh login if the token updates
  useEffect(() => {
    if (user && !user.email_verified) {
      const interval = setInterval(async () => {
        try {
          const tokenResponse = await getAccessTokenSilently({
            detailedResponse: true,
            ignoreCache: true,
          });
          if (tokenResponse?.idTokenClaims?.email_verified) {
            setVerifyStatus('verified');
            clearInterval(interval);
            // Force a full login to refresh the session
            loginWithRedirect({ prompt: 'login' });
          }
        } catch (err) {
          console.error('Error checking verification status:', err);
        }
      }, 10000); // every 10 seconds
      return () => clearInterval(interval);
    }
  }, [user, isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  const handleLogoutAndRedirect = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    history.push('/');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper elevation={2} sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Verify Your Email
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          A verification link was sent to <strong>{userEmail}</strong>. Please check your inbox and click the link.
        </Typography>
        <Stack spacing={2} alignItems="center">
          <Button
            variant="contained"
            onClick={() => loginWithRedirect({ prompt: 'login' })}
          >
            Retry Now
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleLogoutAndRedirect}
          >
            Exit
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
}

export default EmailVerificationWait;

import AnimationBackground from './AnimationBackground';
import './frontpage.scss';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const audience = process.env.REACT_APP_API_SERVER_URL;

function Logo() {
    return (
        <Box className='logo-container'>
            <img 
                src="/assets/img/logo-reverse-text.png" 
                alt="Simmunome Logo" 
                style={{ 
                    maxWidth: '280px',
                    height: 'auto',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.3))'
                }} 
            />
        </Box>
    );
}

function TagLine() {
    return (
        <Box className='tagline'>
            <Typography color={"#ffffff"} variant='h4' align='center' sx={{ 
                fontWeight: 600,
                textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                mb: 1,
                letterSpacing: '0.5px'
            }}>
                Simmunome
            </Typography>
            <Typography color={"#ffffff"} variant='h5' align='center' sx={{
                fontWeight: 300,
                textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                maxWidth: '600px',
                mx: 'auto',
                letterSpacing: '0.5px'
            }}>
                Move your pipeline forward, with confidence.
            </Typography>
        </Box>
    )
}


function Login() {
    const { loginWithRedirect } = useAuth0();
    
    return (
        <Box className='loginContainer'>
            <Button 
                className='loginButton' 
                variant='contained' 
                size="large"
                onClick={() => loginWithRedirect()}
                sx={{
                    backgroundColor: '#FECE61',
                    color: '#113046',
                    fontWeight: 'bold',
                    py: 1.2,
                    px: 3.5,
                    fontSize: '1rem',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    '&:hover': {
                        backgroundColor: '#f8b830',
                        transform: 'translateY(-2px)',
                        transition: 'all 0.2s',
                        boxShadow: '0 6px 12px rgba(0,0,0,0.25)',
                    }
                }}
            >
                Login to Dashboard
            </Button>
        </Box>
    )
}

const App = () => {
    return (
        <Box sx={{ 
            width: "100%", 
            height: "100%", 
            position: "fixed"
        }}>
            <AnimationBackground />
            
            <Box className="content-container" sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10
            }}>
                <Logo />
                <TagLine />
                <Login />
            </Box>
        </Box>
    )
}
export default App;

// SearchField.jsx
import { useEffect, useState, useMemo } from 'react';
import { useSigma } from '@react-sigma/core';
import { Autocomplete, Container } from '@mui/material';
import TextField from '@mui/material/TextField';

/**
 * This component is a modified version of the React-Sigma search control.
 * It accepts a `fullNodeList` prop which should contain all node objects
 * from the full graph.
 */
function SearchField({ filters, onSearch, fullNodeList = [] }) {
  const sigma = useSigma();
  const [values, setValues] = useState([]); // fallback list from sigma.getGraph()
  const [selected, setSelected] = useState(null); 
  const [inputValue, setInputValue] = useState('');

  const effectiveNodeList = fullNodeList.length > 0 ? fullNodeList : values;

  const filteredValues = useMemo(() => {
    if (!inputValue) return effectiveNodeList.slice(0, 100);
    
    const lower = inputValue.toLowerCase();
    
    // First, find items that START with the search term (higher priority)
    const startsWithMatches = effectiveNodeList.filter(item =>
      (item.label && item.label.toLowerCase().startsWith(lower)) ||
      (item.preferredName && item.preferredName.toLowerCase().startsWith(lower))
    );
    
    // Then, find items that CONTAIN the search term but don't start with it
    const containsMatches = effectiveNodeList.filter(item => {
      const labelMatches = item.label && item.label.toLowerCase().includes(lower) && 
                          !(item.label && item.label.toLowerCase().startsWith(lower));
      const nameMatches = item.preferredName && item.preferredName.toLowerCase().includes(lower) && 
                         !(item.preferredName && item.preferredName.toLowerCase().startsWith(lower));
      return labelMatches || nameMatches;
    });
    
    // Combine both results, with startsWithMatches first
    const combinedResults = [...startsWithMatches, ...containsMatches];
    
    // Return up to 100 results
    return combinedResults.slice(0, 100);
  }, [effectiveNodeList, inputValue]);

  const refreshValues = () => {
    const newValues = [];
    sigma.getGraph().forEachNode((key, attributes) => {
      if (!attributes.hidden && attributes.label) {
        newValues.push({ id: key, label: attributes.label, annotation: attributes.annotation });
      }
    });
    setValues(newValues);
  };

  function handleRefreshFocus() {
    if (values.length === 0) {
      refreshValues();
    }
  }
  
  // Refresh fallback values when filters update:
  useEffect(() => {
    requestAnimationFrame(refreshValues);
  }, [filters]);

  useEffect(() => {
    if (!selected) return;
    // Check if the selected node exists in the current graph:
    if (!sigma.getGraph().hasNode(selected.id)) {
      // Node not found: trigger expansion via the onSearch callback.
      if (onSearch) {
        onSearch(selected.id, sigma);
      }
      return; // Do not attempt to highlight.
    }
    // Otherwise, highlight the node.
    sigma.getGraph().setNodeAttribute(selected.id, 'highlighted', true);
    const nodeDisplayData = sigma.getNodeDisplayData(selected.id);
    if (nodeDisplayData) {
      sigma.getCamera().animate(
        { ...nodeDisplayData, ratio: 0.05 },
        { duration: 600 }
      );
    }
    return () => {
      if (sigma.getGraph().hasNode(selected.id)) {
        sigma.getGraph().setNodeAttribute(selected.id, 'highlighted', false);
      }
    };
  }, [selected, sigma, onSearch]);

  return (
    <Container sx={{ padding: 1 }}>
      <Autocomplete
        disablePortal
        freeSolo
        options={filteredValues}
        value={typeof selected === 'object' ? selected : null}
        onChange={(_, newValue) => {
          if (typeof newValue === 'string') {
            if (onSearch) onSearch(newValue, sigma);
            setSelected(null);
          } else {
            setSelected(newValue);
          }
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.label || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={handleRefreshFocus}
            label="Search node"
            placeholder="Node"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && (!selected || typeof selected === 'string')) {
                if (onSearch) onSearch(inputValue, sigma);
              }
            }}
          />
        )}
      />
    </Container>
  );
}

export default SearchField;

import { Container, FormControlLabel, Switch, Autocomplete, TextField, Box, } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSigma } from '@react-sigma/core';
import { resetAllEdgesColor, highlightFilteredNodes, leaveAllNodes, toggleAIPredictedEdges } from '_helpers/sigmaEvents';
import AiPredStore from '_pages/TargetValidation/FilterAIPredictionStore';
import AiSlider from './AiSlider';
import InfoTooltipSurrounder from '_components/base/InfoTooltip';
import { graphTheme } from '_theme/graphTheme';

// Define a custom type that extends the Sigma object
interface ExtendedSigma {
  getGraph: () => any;
  refresh: () => void;
  selectedNodeId?: string | null;
  [key: string]: any; // Allow any other properties
}

function addStringToKey(dictionary: any, key: string, str: string) {
  if (!dictionary[key]) {
    dictionary[key] = [str];
  } else {
    dictionary[key].push(str);
  }
}

function Filters() {
  // Cast sigma to our extended type
  const sigma = useSigma() as unknown as ExtendedSigma;
  const setAiPredValue = AiPredStore((state) => state.setValue);
  const [keywords, setKeywords] = useState<any[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<any[]>([]);
  const [keywordsToNodes, setKeywordsToNodes] = useState<any>({});
  const [AiToggle, setAiToggle] = useState<boolean>(AiPredStore.getState().filterBooleanValue);
  const [andKwToggle, setAndKwToggle] = useState<boolean>(true);
  const [allNodes, setAllNodes] = useState<any[]>([]);

  const refreshValues = () => {
    var newValues: any[] = [];
    var seen = new Set();
    var mapping = {};
    var nodesAccum: any[] = [];
    sigma.getGraph().forEachNode((node: string, attributes: any) => {
      nodesAccum.push(node);
      if (!attributes.hidden && attributes.keywords) {
        attributes.keywords.forEach((keyword: string) => {
          if (!seen.has(keyword)) {
            seen.add(keyword);
            newValues.push({ keyword: keyword });
          }
          addStringToKey(mapping, keyword, node);
        });
      }
    });
    setAllNodes(nodesAccum);
    setKeywordsToNodes(mapping);
    setKeywords(newValues);
  };

  useEffect(() => {
    resetAllEdgesColor(sigma);
    
    // Use the selectedNodeId safely with our extended type
    const selectedNodeId = sigma.selectedNodeId;
    
    // Cast to any to bypass TypeScript checking for the call
    leaveAllNodes(sigma, selectedNodeId as any);
    
    var nodes: any[] = [];
    if (andKwToggle) {
      if (selectedKeywords.length) {
        nodes = allNodes;
      }
      selectedKeywords.forEach((w) => {
        nodes = nodes.filter((value) => keywordsToNodes[w.keyword].includes(value));
      });
    } else {
      selectedKeywords.forEach((w) => {
        nodes = nodes.concat(keywordsToNodes[w.keyword]);
      });
    }
    
    // Highlight filtered nodes
    highlightFilteredNodes(sigma, nodes);
    
    // Ensure selected node stays green if it exists
    if (selectedNodeId) {
      sigma.getGraph().setNodeAttribute(selectedNodeId, 'color', graphTheme.NODE_SELECTED_COLOR);
      sigma.refresh();
    }
  }, [selectedKeywords, andKwToggle]);

  useEffect(() => {
    resetAllEdgesColor(sigma);
    
    // Use the selectedNodeId safely with our extended type
    const selectedNodeId = sigma.selectedNodeId;
    
    // Cast to any to bypass TypeScript checking for the call
    leaveAllNodes(sigma, selectedNodeId as any);
    
    toggleAIPredictedEdges(sigma, AiToggle, AiPredStore.getState().filterCutoff, AiPredStore.getState().stringCutoff);
    
    // Ensure selected node stays green if it exists
    if (selectedNodeId) {
      sigma.getGraph().setNodeAttribute(selectedNodeId, 'color', graphTheme.NODE_SELECTED_COLOR);
      sigma.refresh();
    }
  }, [AiToggle]);

  const handleAndKwToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAndKwToggle(event.target.checked);
  };

  const handleAiToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAiToggle(event.target.checked);
    setAiPredValue(String(event.target.checked));
  };

  function handleKeywordsRefreshFocus() {
    if (keywords.length === 0) {
      refreshValues();
    }
  }

  var AiToggleFilter = () => (
    <Container disableGutters sx={{ marginTop: 1, marginBottom: 1 }}>
      <FormControlLabel
        control={<Switch color="secondary" checked={AiToggle} onChange={handleAiToggle} />}
        label="AI Predictions"
      />
    </Container>
  );

  var AiSliderFilter = () => (
    <Box>
      {AiToggle ? (
        <AiSlider showPredictionSlider={true} />
      ) : (
        <AiSlider showPredictionSlider={false} />
      )}
    </Box>
  );

  var AndKwToggleFilter = () => (
    <Container disableGutters sx={{ marginTop: 1, marginBottom: 1 }}>
      <FormControlLabel
        control={<Switch color="secondary" checked={andKwToggle} onChange={handleAndKwToggle} />}
        label="Keywords exclude"
      />
    </Container>
  );

  return (
    <Container sx={{ width: 250 }}>
      <Autocomplete
        sx={{ maxWidth: 250 }}
        multiple
        limitTags={4}
        id="tags-standard"
        options={keywords}
        getOptionLabel={(option) => option.keyword}
        onChange={(_, newInputValue) => {
          setSelectedKeywords(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Keywords"
            placeholder="Keywords"
            onFocus={handleKeywordsRefreshFocus}
          />
        )}
      />
      <InfoTooltipSurrounder description="If switched on (default) the highlighted nodes have ALL of the selected keywords. If switched off, the highlighted nodes have ANY of the selected keywords.">
        <AndKwToggleFilter />
      </InfoTooltipSurrounder>
      <InfoTooltipSurrounder description="Each orange line is an interaction predicted by our AI model. Predicted interactions have a probability, or a confidence level computed by the AI. You can control if predicted interations are displayed with the toggle, and even adjust the prediction confidence level with the slidebar.">
        <AiToggleFilter />
      </InfoTooltipSurrounder>
      <AiSliderFilter />
    </Container>
  );
}

export default Filters;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { InterruptedPath, ProteinInfo, IndividualProteinInfo } from './TargetEfficacyInterface';
import { SCORES_DECIMAL } from './NodeProcessPathsPage';
import { createGlossarySection } from './GlossarySection';
import { createDiseaseIntroductionSection } from './DiseaseIntroduction';
import { createDiseaseProgressionNodesSection } from './DiseaseProgressionNodes';
import { createDatasetsSectionForPdf } from './DatasetsSection';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
var testMode = true; // if true just opens the pdf instead of downloading it

// Function to wrap content with a border
function wrapWithBorder(content: any): any {
  return {
    table: {
      body: [
        [
          {
            // Content inside the bordered section
            stack: Array.isArray(content) ? content : [content],
            margin: [10, 10, 10, 10], // Adjust padding as needed
          },
        ],
      ],
    },
    margin: [0, 20, 0, 20], // Margin around the table (left, top, right, bottom)
    layout: {
      hLineWidth: () => 1, // Top and bottom border thickness
      vLineWidth: () => 1, // Left and right border thickness
      hLineColor: () => 'black', // Border color
      vLineColor: () => 'black', // Border color
    },
  };
}

// Helper function to create a separator in the PDF
function Separator(x_pos: number = -230): Content {
  return {
    canvas: [
      {
        type: 'line',
        x1: x_pos,
        y1: 5,
        x2: 300,
        y2: 5,
        lineWidth: 1,
        dash: { length: 2 },
      },
    ],
    margin: [0, 10, 0, 10],
  };
}

// Define table cell interface to fix TypeScript errors
interface TableCell {
  text: string;
  style: string;
  alignment?: string;
  fillColor?: string;
  bold?: boolean;
}

// Function to create heatmap for PDF
function createHeatmapForPdf(
  targetName: string, 
  interruptedPaths: InterruptedPath[],
  proteinInfo: any,
  fdaApprovedDrugs: any,
  targetId: string
): Content {
  // Don't proceed if there are no paths
  if (!interruptedPaths || interruptedPaths.length === 0) {
    return {
      text: 'No path data available for heatmap.',
      style: 'normal',
      margin: [0, 10, 0, 0],
    };
  }

  // Function to get a user-friendly name for a protein ID
  const getProteinName = (nodeId: string): string => {
    // First try to get from protein info
    if (proteinInfo[nodeId] && proteinInfo[nodeId].preferredName) {
      return proteinInfo[nodeId].preferredName;
    }
    
    // If no preferred name, try to get gene symbol from id
    if (nodeId.includes('.')) {
      const shortId = nodeId.split('.').pop() || nodeId;
      
      // If it's an ENSP ID, try to find a name in proteinInfo that matches last part
      if (shortId.startsWith('ENSP')) {
        // Try to see if we have any info for this protein elsewhere
        for (const key in proteinInfo) {
          if (key.includes(shortId) && proteinInfo[key].preferredName) {
            return proteinInfo[key].preferredName;
          }
        }
        
        // Still an ENSP ID, check if we have a gene symbol anywhere
        for (const key in proteinInfo) {
          if (key.includes(shortId) && proteinInfo[key].geneSymbol) {
            return proteinInfo[key].geneSymbol;
          }
        }
      }
      
      return shortId;
    }
    
    return nodeId;
  };

  // Helper function to extract path node names
  const getPathNodeNames = (path: InterruptedPath): string[] => {
    // For paths with path_with_names
    if (path.path_with_names && Array.isArray(path.path_with_names)) {
      return path.path_with_names.map(node => {
        // If the node has a name, use it
        if (node.name && !node.name.startsWith('ENSP')) {
          return node.name;
        }
        
        // Otherwise try to get a better name
        return getProteinName(node.id || '');
      });
    }
    
    // For array-style paths without names, extract from IDs
    if (Array.isArray(path.path)) {
      return path.path.map(nodeId => getProteinName(nodeId));
    }
    
    // For old-style object paths
    if (path.path && (path.path as any).nodes) {
      return (path.path as any).nodes.map((node: any) => getProteinName(node.id));
    }
    
    return ['Path format not recognized'];
  };

  // Structure to store target data
  interface TargetData {
    id: string;
    name: string;
    endpointMap: Map<string, {
      endpointName: string;
      bestScore: number;
      bestPath: string[];
      paths: Array<{
        score: number;
        path: string[];
      }>;
    }>;
    isCurrentTarget: boolean;
  }
  
  // Create a map of all targets (current + FDA drugs)
  const targetsMap = new Map<string, TargetData>();
  
  // Add current target
  targetsMap.set(targetId, {
    id: targetId,
    name: targetName,
    endpointMap: new Map(),
    isCurrentTarget: true
  });
  
  // Process current target's paths
  interruptedPaths.forEach(path => {
    const endpoint = path.endpoint;
    const endpointName = getProteinName(endpoint);
    const pathScore = path.path_score || path.score;
    const pathNodes = getPathNodeNames(path);
    
    // Get or create endpoint data in the map
    const targetData = targetsMap.get(targetId)!;
    if (!targetData.endpointMap.has(endpoint)) {
      targetData.endpointMap.set(endpoint, {
        endpointName,
        bestScore: pathScore,
        bestPath: pathNodes,
        paths: [{
          score: pathScore,
          path: pathNodes
        }]
      });
    } else {
      // Add this path to the existing endpoint
      const endpointData = targetData.endpointMap.get(endpoint)!;
      endpointData.paths.push({
        score: pathScore,
        path: pathNodes
      });
      
      // Update best score if this path is better
      if (pathScore > endpointData.bestScore) {
        endpointData.bestScore = pathScore;
        endpointData.bestPath = pathNodes;
      }
    }
  });
  
  // Add FDA drug targets
  if (fdaApprovedDrugs?.ensembl_ids) {
    Object.entries(fdaApprovedDrugs.ensembl_ids).forEach(([ensemblId, data]: [string, any]) => {
      // Skip if it's the current target
      if (ensemblId === targetId) return;
      
      // Get drug name(s) for label
      const drugs = Array.isArray(data.drugs) ? data.drugs : [];
      const geneSymbol = data.gene_symbol || ensemblId.split('.').pop() || ensemblId;
      const targetName = `${geneSymbol} (${drugs.join(', ')})`;
      
      // Create target entry
      targetsMap.set(ensemblId, {
        id: ensemblId,
        name: targetName,
        endpointMap: new Map(),
        isCurrentTarget: false
      });
      
      // Process endpoint paths
      if (Array.isArray(data.endpoint_paths)) {
        data.endpoint_paths.forEach((pathData: any) => {
          if (!pathData.endpoint) return;
          
          const endpoint = pathData.endpoint;
          const endpointName = getProteinName(endpoint);
          
          // Check if path_with_names is available to use directly
          let pathNodes: string[] = [];
          
          if (pathData.path_with_names && Array.isArray(pathData.path_with_names)) {
            // Use the pre-generated path_with_names (preferred)
            pathNodes = pathData.path_with_names.map((node: any) => node.name || node.id || '?');
          } else if (Array.isArray(pathData.path)) {
            // Extract node names from path IDs if no path_with_names
            pathNodes = pathData.path.map((nodeId: string) => getProteinName(nodeId));
          } else {
            pathNodes = ['Path not available'];
          }
          
          const pathScore = pathData.score || 0;
          
          // Get or create endpoint data in the map
          const targetData = targetsMap.get(ensemblId)!;
          if (!targetData.endpointMap.has(endpoint)) {
            targetData.endpointMap.set(endpoint, {
              endpointName,
              bestScore: pathScore,
              bestPath: pathNodes,
              paths: [{
                score: pathScore,
                path: pathNodes
              }]
            });
          } else {
            // Add this path to the existing endpoint
            const endpointData = targetData.endpointMap.get(endpoint)!;
            endpointData.paths.push({
              score: pathScore,
              path: pathNodes
            });
            
            // Update best score if this path is better
            if (pathScore > endpointData.bestScore) {
              endpointData.bestScore = pathScore;
              endpointData.bestPath = pathNodes;
            }
          }
        });
      }
    });
  }
  
  // Get the current target's endpoints sorted by best score
  const currentTarget = targetsMap.get(targetId);
  if (!currentTarget || currentTarget.endpointMap.size === 0) {
    return {
      text: 'No endpoints available for the current target.',
      style: 'normal',
      margin: [0, 10, 0, 0],
    };
  }
  
  // Convert endpoints to array and sort by score
  const endpointEntries = Array.from(currentTarget.endpointMap.entries());
  endpointEntries.sort((a, b) => b[1].bestScore - a[1].bestScore);
  
  // Take top 10 endpoints
  const topEndpoints = endpointEntries.slice(0, 10);
  
  // Convert targets to array
  const targets = Array.from(targetsMap.values());
  
  // Create the table
  interface EndpointTableRow {
    endpointId: string;
    endpointName: string;
    targetColumns: Array<{
      score: number | null;
      path: string[];
    }>;
  }
  
  // Create rows for each endpoint
  const endpointRows: EndpointTableRow[] = topEndpoints.map(([endpointId, endpointData]) => {
    const row: EndpointTableRow = {
      endpointId,
      endpointName: endpointData.endpointName,
      targetColumns: targets.map(target => {
        if (target.endpointMap.has(endpointId)) {
          const data = target.endpointMap.get(endpointId)!;
          return {
            score: data.bestScore,
            path: data.bestPath
          };
        } else {
          return {
            score: null,
            path: []
          };
        }
      })
    };
    return row;
  });
  
  // Create table headers
  const tableHeaders = [
    { text: 'Disease Progression Node', style: 'tableHeader', alignment: 'left' },
    ...targets.map(target => ({ 
      text: target.isCurrentTarget ? 
        `${target.name} (Current Target)` : 
        target.name, 
      style: 'tableHeader',
      alignment: 'center',
      bold: target.isCurrentTarget
    }))
  ];
  
  // Create table body
  const tableBody = [tableHeaders];
  
  // Add rows for each endpoint
  endpointRows.forEach(row => {
    const tableRow = [
      { text: row.endpointName, style: 'tableCell', bold: true, alignment: 'left' }
    ];
    
    // Add columns for each target
    row.targetColumns.forEach(column => {
      if (column.score === null) {
        tableRow.push({ 
          text: '-', 
          style: 'tableCell', 
          alignment: 'center',
          bold: false
        });
      } else {
        // Use text with newline separator instead of stack
        const scoreText = column.score.toFixed(SCORES_DECIMAL);
        // Use plain ASCII characters for arrow
        const pathText = column.path.join(' -> ');
        tableRow.push({ 
          text: `${scoreText}\n${pathText}`,
          style: 'tableCell',
          alignment: 'center',
          bold: false
        });
      }
    });
    
    tableBody.push(tableRow);
  });
  
  // Return the final structure with custom layout for coloring
  return {
    stack: [
      {
        text: 'Top Disease Progression Nodes Pathway Comparison',
        style: 'sectionTitle',
        margin: [0, 0, 0, 10],
        alignment: 'center'
      },
      {
        text: 'Disease progression nodes comparison across targets',
        style: 'normal',
        margin: [0, 0, 0, 10],
        alignment: 'center'
      },
      {
        table: {
          headerRows: 1,
          widths: ['*', ...Array(targets.length).fill('*')],
          body: tableBody as any[][]
        },
        layout: {
          fillColor: function(rowIndex: number, node: any, columnIndex: number) {
            if (rowIndex === 0) return '#f3f3f3';
            
            // Apply color based on score for cells with data
            if (rowIndex > 0 && columnIndex > 0) {
              const targetIndex = columnIndex - 1;
              if (targetIndex >= 0 && targetIndex < endpointRows[rowIndex - 1].targetColumns.length) {
                const score = endpointRows[rowIndex - 1].targetColumns[targetIndex].score;
                if (score !== null) {
                  return getScoreColor(score);
                }
              }
            }
            return null;
          }
        }
      },
      {
        text: 'The table shows the best scoring pathway for each disease progression node. For each pathway, the score is shown in color with the full path listed below it.',
        style: 'caption',
        margin: [0, 10, 0, 0],
        alignment: 'center'
      }
    ] as any
  };
}

// Helper function to get color for a score value
function getScoreColor(score: number): string {
  if (score >= 0.8) return '#67000d';      // Very dark red
  else if (score >= 0.6) return '#a50f15'; // Dark red
  else if (score >= 0.4) return '#ef3b2c'; // Medium red
  else if (score >= 0.2) return '#fc9272'; // Light red
  else return '#fee0d2';                   // Very light red
}

// Function to create edge frequency analysis table
function createEdgeFrequencyTable(interruptedPaths: InterruptedPath[]): Content {
  // Don't proceed if there are no paths
  if (!interruptedPaths || interruptedPaths.length === 0) {
    return {
      text: 'No path data available for edge frequency analysis.',
      style: 'normal',
      margin: [0, 10, 0, 0],
    };
  }

  // Get the top 200 paths by score
  const topPaths = [...interruptedPaths]
    .sort((a, b) => (b.path_score || b.score) - (a.path_score || a.score))
    .slice(0, 200);
  
  console.log(`Analyzing edge frequency across top ${topPaths.length} paths`);
  
  // Create a map to count edge occurrences and store edge data
  const edgeFrequencyMap = new Map<string, {
    sourceNode: string,
    targetNode: string,
    count: number,
    stringProb: number | null,
    aiProb: number | null,
    showAi: boolean,
    sourceNodeName: string,
    targetNodeName: string
  }>();
  
  // Process all paths to count edges
  topPaths.forEach(path => {
    // Handle different path formats
    let pathNodes: string[] = [];
    
    if (Array.isArray(path.path)) {
      // New format - array of node IDs
      pathNodes = path.path;
    } else if (path.path && (path.path as any).nodes) {
      // Old format - extract node IDs from nodes array
      pathNodes = (path.path as any).nodes.map((node: any) => node.id);
    } else {
      // Invalid format - skip this path
      return;
    }
    
    // Process edges in this path
    for (let i = 0; i < pathNodes.length - 1; i++) {
      const sourceNode = pathNodes[i];
      const targetNode = pathNodes[i + 1];
      const edgeKey = `${sourceNode}-${targetNode}`;
      
      // Get edge data from path if available
      let stringProb: number | null = null;
      let aiProb: number | null = null;
      let showAi = false;
      
      if (path.edge_data && path.edge_data[edgeKey]) {
        const edgeData = path.edge_data[edgeKey];
        stringProb = typeof edgeData.string_relation === 'number' ? edgeData.string_relation : null;
        aiProb = typeof edgeData.ai_relation === 'number' ? edgeData.ai_relation : null;
        
        // Determine if AI prediction should be shown (when AI is significantly higher than STRING)
        if (aiProb !== null && stringProb !== null) {
          // Show AI prediction if it's significantly higher than STRING
          showAi = aiProb > stringProb + 0.05;
        } else if (aiProb !== null && stringProb === null) {
          showAi = true;
        }
      }
      
      // Extract node names (preferred name or last part of ID)
      const sourceNodeName = path.path_with_names ? 
        path.path_with_names[i]?.name || sourceNode.split('.').pop() || sourceNode :
        sourceNode.split('.').pop() || sourceNode;
        
      const targetNodeName = path.path_with_names ? 
        path.path_with_names[i+1]?.name || targetNode.split('.').pop() || targetNode :
        targetNode.split('.').pop() || targetNode;
      
      if (edgeFrequencyMap.has(edgeKey)) {
        // Increment count for existing edge
        edgeFrequencyMap.get(edgeKey)!.count++;
      } else {
        // Add new edge to the map
        edgeFrequencyMap.set(edgeKey, {
          sourceNode,
          targetNode,
          sourceNodeName,
          targetNodeName,
          count: 1,
          stringProb,
          aiProb,
          showAi
        });
      }
    }
  });
  
  // Convert the map to an array, filter edges with count ≥ 2, and sort by frequency
  const edgeFrequencies = Array.from(edgeFrequencyMap.entries())
    .map(([key, value]) => ({
      edgeKey: key,
      ...value
    }))
    .filter(edge => edge.count >= 2) // Only keep edges that appear at least twice
    .sort((a, b) => b.count - a.count);
  
  // Create table headers
  const tableBody: TableCell[][] = [
    [
      { text: 'Interaction', style: 'tableHeader' },
      { text: 'STRING probability', style: 'tableHeader', alignment: 'center' },
      { text: 'AI prediction\n(when > STRING by 0.05+)', style: 'tableHeader', alignment: 'center' },
      { text: 'Number of times edge appears in overall top 200 paths', style: 'tableHeader', alignment: 'center' }
    ]
  ];
  
  // Add edge data rows
  edgeFrequencies.forEach(edge => {
    tableBody.push([
      { text: `${edge.sourceNodeName} -> ${edge.targetNodeName}`, style: 'tableCell' },
      { 
        text: edge.stringProb !== null ? 
          (edge.stringProb === 0 ? '-' : edge.stringProb.toFixed(3)) : 
          '-', 
        style: 'tableCell', 
        alignment: 'center' 
      },
      { 
        text: edge.showAi && edge.aiProb !== null ? edge.aiProb.toFixed(3) : '-', 
        style: 'tableCell', 
        alignment: 'center' 
      },
      { 
        text: edge.count.toString(), 
        style: 'tableCell', 
        alignment: 'center' 
      }
    ]);
  });
  
  return {
    stack: [
      {
        text: 'Edge Frequency Analysis',
      style: 'sectionTitle',
        margin: [0, 0, 0, 10],
        alignment: 'center'
      },
      {
        text: 'Interactions involved in at least 2 of the overall top 200 paths for target',
        style: 'normal',
        margin: [0, 0, 0, 10],
        alignment: 'center'
      },
      {
    table: {
          headerRows: 1,
          widths: ['*', 'auto', 'auto', 'auto'],
          body: tableBody as any[][]
        },
        layout: {
          fillColor: function(rowIndex: number, node: any, columnIndex: number) {
            return (rowIndex === 0) ? '#f3f3f3' : null;
          }
        }
      },
      {
        text: 'This table shows interactions occurring at least twice across the top 200 canonical pathways. AI predictions are shown only when they exceed STRING probability by 0.05 or more. Frequency indicates how critical an interaction is across different pathways.',
        style: 'caption',
        margin: [0, 10, 0, 0],
        alignment: 'center'
      }
    ] as any
  };
}

// Function to create FDA approved drugs section for PDF
function createFdaApprovedDrugsSection(
  fdaApprovedDrugs: any,
  currentTargetId: string,
  proteinInfo: { [key: string]: ProteinInfo },
  currentTargetScore: string
): Content {
  // Don't proceed if there are no FDA drugs
  if (!fdaApprovedDrugs?.ensembl_ids || Object.keys(fdaApprovedDrugs.ensembl_ids).length === 0) {
    return {
      text: 'No FDA approved drugs data available.',
      style: 'normal',
      margin: [0, 10, 0, 0],
    };
  }
  
  // Structure for target drug groups
  interface TargetDrugGroup {
    targetId: string;
    displayName: string;
    drugs: string[];
    efficacyScore: number | string;
    isCurrentTarget: boolean;
  }
  
  // Group drugs by target
  const targetGroups: TargetDrugGroup[] = [];
  
  // Add current target first
  const currentProteinInfo = proteinInfo[currentTargetId];
  const currentTargetDisplayName = currentProteinInfo && typeof currentProteinInfo.preferredName === 'string' 
    ? currentProteinInfo.preferredName 
    : (currentTargetId.split('.').pop() || currentTargetId);
  
  targetGroups.push({
    targetId: currentTargetId,
    displayName: currentTargetDisplayName,
    drugs: [], 
    efficacyScore: currentTargetScore, // Use the score passed to the main report function
    isCurrentTarget: true
  });
  
  // Process drugs from ensembl_ids
  Object.entries(fdaApprovedDrugs.ensembl_ids).forEach(([targetId, data]: [string, any]) => {
    if (Array.isArray(data.drugs) && data.drugs.length > 0) {
      // Skip if it's the current target (we already added it)
      if (targetId === currentTargetId) return;
    
      // Create a new target group
      const displayName = data.gene_symbol || targetId.split('.').pop() || targetId;
      
      targetGroups.push({
        targetId,
        displayName,
        drugs: data.drugs,
        efficacyScore: data.efficacy_score || 0,
        isCurrentTarget: false
      });
    }
  });
  
  // If no drugs are found (other than the current target)
  if (targetGroups.length === 1 && targetGroups[0].isCurrentTarget) {
    return {
      text: 'No FDA approved drugs found for comparable targets.',
      style: 'normal',
      margin: [0, 10, 0, 0],
    };
  }
  
  // Sort targets by efficacy score
  targetGroups.sort((a, b) => {
    // Current target always at the top
    if (a.isCurrentTarget) return -1;
    if (b.isCurrentTarget) return 1;
    
    // Convert both scores to numbers for comparison
    const scoreA = typeof a.efficacyScore === 'string' ? parseFloat(a.efficacyScore) : a.efficacyScore;
    const scoreB = typeof b.efficacyScore === 'string' ? parseFloat(b.efficacyScore) : b.efficacyScore;
    
    // Otherwise sort by efficacy score
    return scoreB - scoreA;
  });
  
  // Create table headers
  const tableBody: TableCell[][] = [
    [
      { text: 'Target', style: 'tableHeader' },
      { text: 'FDA Approved Drugs', style: 'tableHeader' },
      { text: 'Efficacy Score', style: 'tableHeader', alignment: 'center' }
    ]
  ];
  
  // Add target rows
  targetGroups.forEach(group => {
    // Convert efficacy score to number for coloring
    const numericScore = typeof group.efficacyScore === 'string' ? 
      parseFloat(group.efficacyScore) : group.efficacyScore;
    
    tableBody.push([
      { 
        text: group.displayName + (group.isCurrentTarget ? ' (Current Target)' : ''), 
        style: 'tableCell', 
        bold: group.isCurrentTarget 
      },
      { 
        text: group.isCurrentTarget ? 'N/A' : group.drugs.join(', '), 
        style: 'tableCell' 
      },
      { 
        text: typeof group.efficacyScore === 'string' ? 
          group.efficacyScore : // Keep original string format if it's a string
          group.efficacyScore.toFixed(SCORES_DECIMAL), 
        style: 'tableCell', 
        alignment: 'center',
        fillColor: getScoreColor(numericScore)
      }
    ]);
  });
  
  return {
    stack: [
      {
        text: 'FDA Approved Drugs',
        style: 'sectionTitle',
        margin: [0, 0, 0, 10],
        alignment: 'center'
      },
      {
        text: 'Target comparison with FDA approved drugs',
        style: 'normal',
        margin: [0, 0, 0, 10],
        alignment: 'center'
      },
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', 'auto'],
          body: tableBody as any[][]
        },
        layout: {
          fillColor: function(rowIndex: number, node: any, columnIndex: number) {
            return (rowIndex === 0) ? '#f3f3f3' : null;
          }
        }
      },
      {
        text: 'This table compares your target with FDA approved drugs for targets with similar disease progression node interactions.',
        style: 'caption',
        margin: [0, 10, 0, 0],
        alignment: 'center'
      }
    ]
  };
}

// Function to create pathway comparison for top individual paths
function createIndividualPathsHeatmapForPdf(
  targetName: string, 
  interruptedPaths: InterruptedPath[],
  proteinInfo: any,
  fdaApprovedDrugs: any,
  targetId: string
): Content {
  // Don't proceed if there are no paths
  if (!interruptedPaths || interruptedPaths.length === 0) {
    return {
      text: 'No path data available for individual paths comparison.',
      style: 'normal',
      margin: [0, 10, 0, 0],
    };
  }

  // Function to get a user-friendly name for a protein ID (same as in original heatmap)
  const getProteinName = (nodeId: string): string => {
    // First try to get from protein info
    if (proteinInfo[nodeId] && proteinInfo[nodeId].preferredName) {
      return proteinInfo[nodeId].preferredName;
    }
    
    // If no preferred name, try to get gene symbol from id
    if (nodeId.includes('.')) {
      const shortId = nodeId.split('.').pop() || nodeId;
      
      // If it's an ENSP ID, try to find a name in proteinInfo that matches last part
      if (shortId.startsWith('ENSP')) {
        // Try to see if we have any info for this protein elsewhere
        for (const key in proteinInfo) {
          if (key.includes(shortId) && proteinInfo[key].preferredName) {
            return proteinInfo[key].preferredName;
          }
        }
        
        // Still an ENSP ID, check if we have a gene symbol anywhere
        for (const key in proteinInfo) {
          if (key.includes(shortId) && proteinInfo[key].geneSymbol) {
            return proteinInfo[key].geneSymbol;
          }
        }
      }
      
      return shortId;
    }
    
    return nodeId;
  };

  // Helper function to extract path node names (same as in original heatmap)
  const getPathNodeNames = (path: InterruptedPath): string[] => {
    // For paths with path_with_names
    if (path.path_with_names && Array.isArray(path.path_with_names)) {
      return path.path_with_names.map(node => {
        // If the node has a name, use it
        if (node.name && !node.name.startsWith('ENSP')) {
          return node.name;
        }
        
        // Otherwise try to get a better name
        return getProteinName(node.id || '');
      });
    }
    
    // For array-style paths without names, extract from IDs
    if (Array.isArray(path.path)) {
      return path.path.map(nodeId => getProteinName(nodeId));
    }
    
    // For old-style object paths
    if (path.path && (path.path as any).nodes) {
      return (path.path as any).nodes.map((node: any) => getProteinName(node.id));
    }
    
    return ['Path format not recognized'];
  };

  // Structure to store target data
  interface TargetData {
    id: string;
    name: string;
    pathData: Array<{
      endpointId: string;
      endpointName: string;
      score: number;
      path: string[];
    }>;
    isCurrentTarget: boolean;
  }
  
  // Create a map of all targets (current + FDA drugs)
  const targetsMap = new Map<string, TargetData>();
  
  // Add current target
  targetsMap.set(targetId, {
    id: targetId,
    name: targetName,
    pathData: [],
    isCurrentTarget: true
  });
  
  // Process current target's paths
  interruptedPaths.forEach(path => {
    const endpoint = path.endpoint;
    const endpointName = getProteinName(endpoint);
    const pathScore = path.path_score || path.score;
    const pathNodes = getPathNodeNames(path);
    
    // Add this path to the current target's path data
    targetsMap.get(targetId)!.pathData.push({
      endpointId: endpoint,
      endpointName,
      score: pathScore,
      path: pathNodes
    });
  });
  
  // Sort and take only top 10 paths for the current target
  const currentTarget = targetsMap.get(targetId);
  if (!currentTarget) {
    return {
      text: 'Target data not available for pathway comparison.',
      style: 'normal',
      margin: [0, 10, 0, 0],
    };
  }
  
  // Sort paths by score and take top 10
  currentTarget.pathData.sort((a, b) => b.score - a.score);
  const topPaths = currentTarget.pathData.slice(0, 10);
  
  // Create the table
  interface PathwayTableRow {
    pathIndex: number;
    endpointName: string;
    pathScore: number;
    currentTargetPath: string[];
  }
  
  // Create rows for each of the top paths
  const pathwayRows: PathwayTableRow[] = topPaths.map((pathData, index) => {
    return {
      pathIndex: index + 1,
      endpointName: pathData.endpointName,
      pathScore: pathData.score,
      currentTargetPath: pathData.path
    };
  });
  
  // Create table headers - simplified to just rank and target path with score
  const tableHeaders = [
    { text: 'Rank', style: 'tableHeader', alignment: 'center' },
    { text: `${targetName} Path & Score`, style: 'tableHeader', alignment: 'center', bold: true }
  ];
  
  // Create table body
  const tableBody = [tableHeaders];
  
  // Add rows for each path
  pathwayRows.forEach(row => {
    const score = row.pathScore;
    const scoreColor = getScoreColor(score);
    
    tableBody.push([
      { text: `#${row.pathIndex}`, style: 'tableCell', alignment: 'center', bold: true },
      { 
        text: `${score.toFixed(SCORES_DECIMAL)}\n${row.currentTargetPath.join(' -> ')}`,
        style: 'tableCell',
        alignment: 'center',
        bold: true
      }
    ]);
  });
  
  // Return the final structure with custom layout for coloring
  return {
    stack: [
      {
        text: 'Top 10 Individual Pathways',
        style: 'sectionTitle',
        margin: [0, 0, 0, 10],
        alignment: 'center'
      },
      {
        text: 'Top 10 individual pathways ranked by score',
        style: 'normal',
        margin: [0, 0, 0, 10],
        alignment: 'center'
      },
      {
        table: {
          headerRows: 1,
          widths: ['auto', '*'],
          body: tableBody as any[][]
        },
        layout: {
          fillColor: function(rowIndex: number, node: any, columnIndex: number) {
            if (rowIndex === 0) return '#f3f3f3';
            
            // Apply color based on score for cells with data
            if (rowIndex > 0 && columnIndex === 1) {
              // Get the corresponding score from the pathwayRows array
              const score = pathwayRows[rowIndex - 1].pathScore;
              return getScoreColor(score);
            }
            return null;
          }
        }
      },
      {
        text: 'The table shows the top 10 individual pathways for your target, ranked by score. For each pathway, the score and full path are shown.',
        style: 'caption',
        margin: [0, 10, 0, 0],
        alignment: 'center'
      }
    ] as any
  };
}

// Main function to create the PDF report
function createPdfReport(
  projectName: string | undefined,
  AiPredictionThreshold: number | undefined,
  targetName: string,
  targetId: string,
  diseaseName: string,
  interruptedPaths: InterruptedPath[],
  interruptedEndpointsNames: string[],
  score: string,
  protein_info: { [key: string]: ProteinInfo },
  unreachedEndpoints: string[],
  modelName: string | undefined,
  modelVersion: string = 'version 1',
  isSandboxUser: boolean = false,
  fdaApprovedDrugs: any = null,
  selectedTissueType: string | undefined = undefined
) {
  // Log debug information
  console.log("DEBUG: Creating PDF report with paths:", {
    pathCount: interruptedPaths.length,
    firstPathFormat: interruptedPaths.length > 0 ? 
      (Array.isArray(interruptedPaths[0].path) ? "array" : "object") : "none",
    targetId: targetId,
    tissueType: selectedTissueType
  });

  // Extract endpoint names for displaying in summary
  const endpointNames = Array.from(new Set(
    interruptedPaths.map(path => {
      const endpoint = path.endpoint;
      return protein_info[endpoint]?.preferredName || 
             (endpoint.includes('.') ? endpoint.split('.').pop() : endpoint) || 
             endpoint;
    })
  ));

  // Create a modified summary section with disease progression nodes integrated
  const enhancedSummarySection = wrapWithBorder([
    {
      text: 'Summary Information',
      style: 'sectionTitle',
      margin: [0, 0, 0, 10],
    },
    {
      text: 'Report Metadata:',
      margin: [0, 5, 0, 0],
      bold: true,
    },
    {
      layout: 'noBorders',
      table: {
        headerRows: 0,
        body: [
          [{ text: 'Project:', alignment: 'right' }, projectName || 'Unknown'],
          [{ text: 'Model:', alignment: 'right' }, `${modelName || 'Unknown'} (${modelVersion})`],
          [{ text: 'Disease:', alignment: 'right' }, diseaseName || 'Unknown'],
          ...(selectedTissueType ? [[{ text: 'Tissue Type:', alignment: 'right' }, selectedTissueType]] : []),
          [{ text: 'Target:', alignment: 'right' }, `${targetName} (${targetId})`],
          [{ text: 'Efficacy Score:', alignment: 'right', bold: true }, { text: score, bold: true }],
          ...(AiPredictionThreshold !== undefined ? [[{ text: 'AI Prediction Cutoff:', alignment: 'right' }, AiPredictionThreshold]] : []),
        ],
      },
    },
    {
      text: 'Disease Progression Nodes:',
      margin: [0, 10, 0, 5],
      bold: true,
    },
    {
      text: endpointNames.length > 0 
        ? endpointNames.join(', ') 
        : 'No disease progression nodes found',
      style: endpointNames.length > 0 ? 'normal' : 'italic',
      color: endpointNames.length > 0 ? 'black' : '#666666',
    }
  ]);

  // Add disease-specific introduction section
  const diseaseIntroductionSection = createDiseaseIntroductionSection(diseaseName, targetName);
  
  // Add disease progression nodes section
  const diseaseProgressionNodesSection = createDiseaseProgressionNodesSection(diseaseName);
  
  // Add datasets section filtered by tissue type if available
  const datasetsSection = createDatasetsSectionForPdf(diseaseName, selectedTissueType || null);

  // Add watermark for sandbox users
  let watermarkElement;
  if (isSandboxUser) {
    watermarkElement = {
      text: 'DEMO',
      color: 'blue',
      fontSize: 250,
      opacity: 0.4,
      bold: true,
      italics: false,
    };
  }
  
  // Define the document content
  const docDefinition: TDocumentDefinitions = {
    watermark: watermarkElement,
    content: [
      Separator(440),
      {
        text: 'Simmunome Report for Target ' + targetName,
        style: 'header',
      },
      {
        canvas: [
          {
            type: 'rect',
            x: -100,
            y: 1,
            w: 620,
            h: 10,
            r: 7,
            color: '#113046',
          },
        ],
      },

      // Enhanced summary section at the top with disease progression nodes integrated
      enhancedSummarySection,

      // Add disease-specific introduction section
      diseaseIntroductionSection,
      
      // Add disease progression nodes section
      diseaseProgressionNodesSection,
      
      // Add datasets section
      datasetsSection,

      Separator(),
      
      // FDA approved drugs section
      createFdaApprovedDrugsSection(
        fdaApprovedDrugs,
        targetId,
        protein_info,
        score
      ),

      Separator(),
            
      // Heatmap section for disease progression nodes
      createHeatmapForPdf(targetName, interruptedPaths, protein_info, fdaApprovedDrugs, targetId),
      
      Separator(),
      
      // New section: Individual paths heatmap
      createIndividualPathsHeatmapForPdf(targetName, interruptedPaths, protein_info, fdaApprovedDrugs, targetId),
      
      Separator(),
      
      // Edge frequency analysis
      createEdgeFrequencyTable(interruptedPaths),
      
      Separator(),
      
      // For any unreached endpoints information
      ...(unreachedEndpoints && unreachedEndpoints.length > 0 ? [
        {
          text: 'Unreached Endpoints',
          style: 'sectionTitle',
          margin: [0, 0, 0, 10],
          alignment: 'center'
        } as Content,
        
        {
          ol: unreachedEndpoints.map(endpoint => {
            const name = protein_info[endpoint]?.preferredName || endpoint;
            return { text: name };
          }),
          style: 'normal',
          margin: [20, 0, 0, 10]
        } as any,
        
        Separator()
      ] : []),
      
      // Add the glossary section
      createGlossarySection(),
    ],

    // Document styles
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 5],
      },
      nodeInformation: { 
        margin: 4, 
        fontSize: 10 
      },
      sectionTitle: {
        fontSize: 14,
        bold: true,
      },
      subsectionTitle: {
        fontSize: 12,
        bold: true,
      },
      normal: {
        fontSize: 10,
      },
      italic: {
        fontSize: 10,
        italics: true,
      },
      sectionHeader: {
        fontSize: 16,
        bold: true,
        decoration: 'underline',
        color: '#113046',
      },
      subheader: {
        fontSize: 14,
        bold: true,
        color: '#31708f',
      },
      tableHeader: {
        fontSize: 12,
        bold: true,
        color: '#31708f',
      },
      tableCell: {
        fontSize: 10,
        margin: [0, 3, 0, 3],
      },
      caption: {
        fontSize: 10,
        italics: true,
        color: '#666666'
      },
      link: {
        decoration: 'underline',
        color: '#0074D9'
      },
      table: {
        margin: [0, 5, 0, 15]
      },
      tableCellBold: {
        fontSize: 10,
        bold: true,
        margin: [2, 5, 2, 5]
      },
    },
  };

  // Create and open/download the PDF
  if (testMode) {
    pdfMake.createPdf(docDefinition).open();
  } else {
    pdfMake.createPdf(docDefinition).download('SimmunomeTargetReport_' + targetName + '.pdf');
  }
}

export default createPdfReport;

import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Button, 
  Tooltip, 
  CircularProgress, 
  Link, 
  Stack,
  Divider,
  Chip,
  Select,
  MenuItem,
  SelectChangeEvent,
  Container, 
  InputLabel, 
  FormControl, 
  Table, 
  TableCell, 
  TableRow,
  IconButton,
  LinearProgress,
  Alert,
  AlertTitle,
  GlobalStyles
} from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PathGraph from './PathGraph';
import history from '_helpers/history';
import reportService from '_services/report.service';
import { saveAs } from 'file-saver';
import '_css/d3.css';
import './NodeProcessPathsPage.css';
import NodeInfoAccordion from './NodeInfoAccordion';
import createPdfReport from './Report';
import { withServiceCallHandling } from '_helpers/decorators';
import Loading from '_components/Loading';
import TVProjectType from '../TVProjectType';
import projectsService from '_services/harbor/projects.service';
import { useIsMounted } from '_hooks/useIsMounted';
import config from '_configs';
import { useIsSandboxUser } from '_hooks/useIsSandboxUser';
import disease_name_readable from '_helpers/format';
import Notepad from '_components/notepad';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EndpointHeatmap from './EndpointHeatmap';
import { InterruptedPath, RelationshipMap } from './TargetEfficacyInterface';
import ScienceIcon from '@mui/icons-material/Science';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BlockIcon from '@mui/icons-material/Block';
import ErrorIcon from '@mui/icons-material/Error';
import NotesIcon from '@mui/icons-material/Notes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InfoIcon from '@mui/icons-material/Info';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faFileMedical } from '@fortawesome/free-solid-svg-icons';
import TVProjectStore from '../TVProjectStore';
import targetValidationService from '_services/target.validation.service';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import MenuIcon from '@mui/icons-material/Menu';
import NearMeIcon from '@mui/icons-material/NearMe';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DehazeIcon from '@mui/icons-material/Dehaze';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';
import SaveIcon from '@mui/icons-material/Save';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CloseIcon from '@mui/icons-material/Close';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WarningIcon from '@mui/icons-material/Warning';
import ListIcon from '@mui/icons-material/List';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GlossaryModal from './GlossaryModal';
import DiseaseIntroductionCard from './DiseaseIntroductionCard';

// Define responsive width modifiers - use numbers for dimensions
const SELECTED_GRAPH_WIDTH = 900; // Default width
const SELECTED_GRAPH_HEIGHT = 420; // Height
export const SCORES_DECIMAL = 3;

// Define interfaces for the component
interface IndividualProteinInfo {
  preferredName: string;
  annotation: string;
  stringId: string;
  queryIndex: number;
  ncbiTaxonId: number;
  taxonName: string;
  keywords: string[];
  tissues: string[];
  wikipathways: string[];
  pathways?: string[];
}

interface ProteinInfo {
  [key: string]: IndividualProteinInfo;
}

interface ReachedEndpoint {
  endpoint: string;
  score: number;
}

// Add this interface before FdaApprovedDrugs interface
interface DrugData {
  name: string;
  efficacy_score: number;
}

// Add new interface for FDA approved drugs
interface FdaApprovedDrugs {
  ensembl_ids?: {
    [key: string]: {
      gene_symbol: string;
      drugs: string[];
      efficacy_score: number;
      endpoint_paths?: Array<{
        endpoint: string;
        path: string[];
        score: number;
      }>;
    };
  };
  gene_symbols?: {
    [key: string]: {
      ensembl_id: string;
      drugs: string[];
      efficacy_score: number;
      endpoint_paths?: Array<{
        endpoint: string;
        path: string[];
        score: number;
      }>;
    };
  };
}

// Add this after the existing FdaApprovedDrugs interface
// List of valid tissue types
const TISSUE_TYPE_NAMES: Record<string, string> = {
  "brain_FCX": "Brain - Frontal Cortex",
  "brain_cereb": "Brain - Cerebellum",
  "brain_motor": "Brain - Motor Cortex",
  "spinal_cervical": "Spinal Cord - Cervical",
  "spinal_lumbar": "Spinal Cord - Lumbar",
  "blood_blood": "Blood"
};

// Helper function to get a human-readable tissue type name
function getTissueTypeName(tissueType: string): string {
  return TISSUE_TYPE_NAMES[tissueType] || tissueType;
}

interface TargetEfficacyData {
  disease?: string;
  model_id?: string;
  score: number;
  interrupted_paths: InterruptedPath[];
  protein_info: ProteinInfo;
  unreached_endpoints: string[];
  report_id: string;
  expression_results: {
    patient_efficacies: {
      [key: string]: any;
    };
    global_score: number;
  };
  // Add FDA approved drugs data
  fda_approved_drugs?: FdaApprovedDrugs;
  // Add edge data from API response
  edge_data?: { 
    [key: string]: { 
      source: string;
      target: string;
      predicted?: boolean;
      ai_relation?: number;
      string_relation?: number;
      relation?: number;
    } 
  };
}

/**
 * Process path data to create nodes and relationships for the graph
 */
const processPathData = (
  efficacyData: any,
  getDetailedProtein: any,
  showThumbnail = false
): any => {
  console.log("Processing path data, thumbnail mode:", showThumbnail);
  
  if (!efficacyData) {
    console.error("No efficacy data provided for processing");
    // Return a safe object with empty structures instead of null
    return {
      details: {
        nodes: {},
        relationships: {}
      },
      path: []
    };
  }

  // Determine if we're processing a single path or full efficacy data
  // A single path has a 'path' property that is an array, but doesn't have interrupted_paths
  const isSinglePath = Array.isArray(efficacyData.path) && !efficacyData.interrupted_paths;
  
  console.log("Processing mode:", isSinglePath ? "single path" : "full efficacy data");
  console.log("Input efficacy data:", efficacyData);
  
  // Create a safe copy to avoid modifying the original
  let data;
  try {
    data = JSON.parse(JSON.stringify(efficacyData));
  } catch (error) {
    console.error("Error cloning data:", error);
    data = efficacyData; // Use original if cloning fails
  }
  
  // Setup return structure
  let returnParams: any = {
    details: {
      nodes: {},
      relationships: {},
    }
  };
  
  // If processing a single path, copy all properties and then add details
  if (isSinglePath) {
    // Include the path properties in the return object
    Object.assign(returnParams, data);
    
    // Make sure we preserve the edge_data
    if (data.edge_data) {
      console.log("Path has edge_data, preserving it:", data.edge_data);
    }
    
    // Process the single path
    processNodesAndRelationships(data, returnParams.details, getDetailedProtein);
  }
  // If processing full efficacy data
  else {
    returnParams.data = data;
    
    // Check if interrupted_paths exists and is an array
    if (!data.interrupted_paths || !Array.isArray(data.interrupted_paths)) {
      console.error("No interrupted paths found in efficacy data");
      // Return what we have so far, even if incomplete
      return returnParams;
    }
    
    // Process each path in the interrupted_paths array
    data.interrupted_paths.forEach((path: any, pathIndex: number) => {
      if (!Array.isArray(path.path)) {
        console.error("Path is not an array:", path);
        return;
      }
      
      // Process this path
      processNodesAndRelationships(path, returnParams.details, getDetailedProtein);
    });
  }

  // For thumbnails, limit to essential data
  if (showThumbnail && !isSinglePath) {
    delete returnParams.data;
  }

  console.log("Processed path data result:", returnParams);
  return returnParams;
};

// Helper function to process nodes and relationships for a path
function processNodesAndRelationships(path: any, details: any, getDetailedProtein: any) {
  if (!Array.isArray(path.path)) {
    console.error("Path is not a valid array:", path);
    return;
  }
  
  // Create a map of all nodes in the path
  path.path.forEach((node: string) => {
    if (!details.nodes[node]) {
      // Get detailed protein information if available
      let detailedProtein;
      try {
        detailedProtein = typeof getDetailedProtein === 'function' ? getDetailedProtein(node) : null;
      } catch (error) {
        console.error("Error getting detailed protein info:", error);
        detailedProtein = null;
      }
      
      // Create node entry with safe fallbacks
      details.nodes[node] = {
        id: node,
        label: (detailedProtein && detailedProtein.preferredName) || node,
        protein: detailedProtein || { id: node },
      };
    }
  });

  // Process all edges in the path
  for (let i = 0; i < path.path.length - 1; i++) {
    const source = path.path[i];
    const target = path.path[i + 1];
    const edgeKey = `${source}-${target}`;
    
    // Skip if we've already processed this relationship
    if (details.relationships[edgeKey]) {
      continue;
    }

    // Create relationship with default values
    const relationship: any = {
      source,
      target,
      predicted: false,
      relation: 0.7 // Default score
    };
    
    // Try to find edge data in the path
    try {
      if (path.edge_data && path.edge_data[edgeKey]) {
        const edgeData = path.edge_data[edgeKey];
        
        // Copy all properties from edge data
        Object.assign(relationship, edgeData);
        
        // Ensure predicted flag is set correctly
        if (edgeData.predicted !== true && typeof edgeData.ai_relation === 'number') {
          if (!edgeData.string_relation || edgeData.string_relation < 0.05) {
            relationship.predicted = true;
          }
        }
        
        // Ensure relation value is set
        if (typeof edgeData.relation !== 'number') {
          if (relationship.predicted && typeof edgeData.ai_relation === 'number') {
            relationship.relation = edgeData.ai_relation;
          } else if (!relationship.predicted && typeof edgeData.string_relation === 'number') {
            relationship.relation = edgeData.string_relation;
          }
        }
      }
    } catch (error) {
      console.error(`Error processing edge data for ${edgeKey}:`, error);
    }
    
    // Store the relationship
    details.relationships[edgeKey] = relationship;
  }
}

function renderPathGraph(
  path: InterruptedPath,
  id: string,
  nodeId: string | undefined,
  renderAxis = false,
  width = SELECTED_GRAPH_WIDTH,
  height = SELECTED_GRAPH_HEIGHT,
  effectData: TargetEfficacyData | null = null,
  approvedDrugs: any = null
) {
  const safeNodeId = nodeId || '';
  
  // Define a type for edge data that includes all possible properties
  interface EdgeData {
    source: string;
    target: string;
    predicted?: boolean;
    hybrid?: boolean; // New flag for edges that have both AI and STRING probabilities
    relation?: number;
    ai_relation?: number;
    string_relation?: number;
    model_prob?: number;
    string_prob?: number;
    prob?: number;
    [key: string]: any; // Allow for other properties we might not know about
  }
  
  // Create a function to get detailed protein info from efficacyData
  const getDetailedProtein = (proteinId: string) => {
    if (!effectData || !effectData.protein_info) return null;
    return effectData.protein_info[proteinId] || null;
  };
  
  // Process the path
  console.log(`Processing path for graph rendering (id: ${id}):`, path);
  
  // Make sure we have edge_data for the path
  const pathWithEdgeData = { ...path };

  // Add protein_info to the path for use in PathGraph component
  if (!pathWithEdgeData.protein_info && effectData && effectData.protein_info) {
    pathWithEdgeData.protein_info = effectData.protein_info as any;
    console.log("DEBUG: Added protein_info to path");
  }
  
  // Enhanced edge data handling
  // IMPORTANT: Only add edge_data if it's COMPLETELY missing, not just empty
  // This allows our thumbnail preprocessing to work properly
  if (pathWithEdgeData && pathWithEdgeData.edge_data === undefined && effectData && effectData.edge_data) {
    console.log("DEBUG: Adding edge_data to path from efficacyData - path had no edge_data at all");
    console.log("DEBUG: Original API edge data:", effectData.edge_data);
    
    // Create edge_data object if it doesn't exist
    pathWithEdgeData.edge_data = {};
    
    // For each pair of consecutive nodes in the path
    if (Array.isArray(pathWithEdgeData.path)) {
      for (let i = 0; i < pathWithEdgeData.path.length - 1; i++) {
        const source = pathWithEdgeData.path[i];
        const target = pathWithEdgeData.path[i + 1];
        const edgeKey = `${source}-${target}`;
        const reverseEdgeKey = `${target}-${source}`;
        
        console.log(`DEBUG: Looking for edge data for ${edgeKey} or ${reverseEdgeKey}`);
        
        // Create a new default edge data object - DON'T use default scores initially
        let edgeDataObj: any = {
          source: source,
          target: target,
          predicted: false,    // Default to known interaction
        };
        
        // Look for edge data in efficacyData
        let foundEdgeData: EdgeData | null = null;
        if (effectData.edge_data[edgeKey]) {
          foundEdgeData = effectData.edge_data[edgeKey] as EdgeData;
          console.log(`DEBUG: Found direct edge data for ${edgeKey}:`, foundEdgeData);
        } else if (effectData.edge_data[reverseEdgeKey]) {
          // Copy and swap source/target for reverse direction
          const reverseData = { ...effectData.edge_data[reverseEdgeKey], source, target } as EdgeData;
          foundEdgeData = reverseData;
          console.log(`DEBUG: Found reverse edge data for ${reverseEdgeKey}:`, foundEdgeData);
      } else {
          console.log(`DEBUG: No edge data found for ${edgeKey}, will need defaults`);
        }
        
        // If we found edge data, merge it with our base object
        if (foundEdgeData) {
          Object.assign(edgeDataObj, foundEdgeData);
        }
        
        // Store the processed edge data
        pathWithEdgeData.edge_data[edgeKey] = edgeDataObj;
      }
    }
  } else if (pathWithEdgeData.edge_data && Object.keys(pathWithEdgeData.edge_data).length === 0) {
    // If edge_data exists but is empty, fill it with data from effectData
    console.log("DEBUG: Path has empty edge_data object, adding data from efficacyData");
    
    if (Array.isArray(pathWithEdgeData.path) && pathWithEdgeData.path.length > 1) {
      for (let i = 0; i < pathWithEdgeData.path.length - 1; i++) {
        const source = pathWithEdgeData.path[i];
        const target = pathWithEdgeData.path[i + 1];
        const edgeKey = `${source}-${target}`;
        
        if (effectData && effectData.edge_data && effectData.edge_data[edgeKey]) {
          pathWithEdgeData.edge_data[edgeKey] = JSON.parse(JSON.stringify(effectData.edge_data[edgeKey]));
          console.log(`DEBUG: Added edge data for ${edgeKey}:`, pathWithEdgeData.edge_data[edgeKey]);
        }
      }
    }
  } else {
    console.log(`DEBUG: Path already has edge_data with ${Object.keys(pathWithEdgeData.edge_data || {}).length} entries, respecting it`);
  }

  // Log the path that will be rendered
  console.log(`DEBUG: Path being sent to PathGraph (id: ${id}):`, {
    pathLength: pathWithEdgeData.path?.length || 0,
    hasEdgeData: !!pathWithEdgeData.edge_data,
    edgeDataKeys: Object.keys(pathWithEdgeData.edge_data || {}).length,
    hasProteinInfo: !!pathWithEdgeData.protein_info,
    endpoint: pathWithEdgeData.endpoint
  });

  return <PathGraph
      id={id}
    path={pathWithEdgeData}
      selectedNodeId={safeNodeId}
      renderAxis={renderAxis}
      width={width}
      height={height}
      fdaApprovedDrugs={approvedDrugs}
  />;
}

function findSelectedNodeName(nodeid: string, data: TargetEfficacyData): string {
  if (!nodeid || !data || !data.protein_info) {
    return nodeid || 'Unknown';
  }
  
  // Check if we have protein info for this node
  if (data.protein_info[nodeid] && data.protein_info[nodeid].preferredName) {
    return data.protein_info[nodeid].preferredName;
  }
  
  // If we don't have protein info, try to extract a name from the node ID
  // String IDs often have format like "9606.ENSP00000000233"
  if (nodeid.includes('.')) {
    const parts = nodeid.split('.');
    return parts[parts.length - 1]; // Return the last part
  }
  
  return nodeid;
}


interface UnreachedEndpointsSectionInterface {
  unreachedEndpoints: string[];
  proteinInfo: ProteinInfo;
}

function UnreachedEndpointsSection({ unreachedEndpoints, proteinInfo }: UnreachedEndpointsSectionInterface) {
  // Ensure unreachedEndpoints is an array and proteinInfo is an object
  const safeUnreachedEndpoints = Array.isArray(unreachedEndpoints) ? unreachedEndpoints : [];
  const safeProteinInfo = proteinInfo || {};
  
  return (
    <Box>
      <div className="titlesection">
        <p>Unreached Endpoints</p>
      </div>
      <Paper className="roundedBorderContainer" sx={{ mb: 3 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, color: 'var(--primary-dark)', mb: 2 }}>
            Endpoints Not Reached by This Target
          </Typography>
          
          {safeUnreachedEndpoints.length > 0 ? (
            <Box className="endpointsListContainer" sx={{ 
              maxHeight: '500px', 
              overflow: 'auto',
              pr: 1
            }}>
              {safeUnreachedEndpoints.map((endpointId, index) => (
                <Box 
                  key={`${endpointId}-${index}`}
                  sx={{ 
                    mb: 2,
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'translateY(-2px)'
                    }
                  }}
                >
                  {safeProteinInfo[endpointId] && NodeInfoAccordion(safeProteinInfo[endpointId], endpointId)}
                </Box>
              ))}
            </Box>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                py: 4,
                borderRadius: '8px',
                backgroundColor: 'rgba(245, 247, 250, 0.7)',
                border: '1px solid var(--border-color)'
              }}
            >
              <Typography 
                variant="body1" 
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  color: 'var(--success-color)',
                  fontWeight: 500
                }}
              >
                <ScienceIcon fontSize="small" />
                All endpoints were successfully reached by this target.
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export interface NodeProcessPathsPageProps {
  initProjectData?: TVProjectType;
  asPage?: boolean;
}

function createUpdatedPdfReport(
  projectName: string,
  aiPredCutoff: number,
  selectedNodeName: string,
  nodeId: string,
  diseaseName: string,
  interruptedPaths: InterruptedPath[],
  endpointNames: string[],
  score: string,
  proteinInfo: any,
  unreachedEndpoints: string[],
  modelName: string,
  version: string,
  isSandboxUser: boolean,
  fdaApprovedDrugsData: any,
  selectedTissueType: string | null
) {
  console.log("DEBUG: Creating updated PDF report with:", {
    pathCount: interruptedPaths.length,
    firstPathFormat: interruptedPaths.length > 0 ? 
      (Array.isArray(interruptedPaths[0].path) ? "array" : "object") : "none",
    nodeId: nodeId,
    proteinInfo: proteinInfo ? "provided" : "missing",
    fdaData: fdaApprovedDrugsData ? "provided" : "missing",
    tissueType: selectedTissueType
  });

  try {
    // Cast the interruptedPaths to the expected type for createPdfReport
    createPdfReport(
      projectName,
      aiPredCutoff,
      selectedNodeName,
      nodeId,
      diseaseName,
      interruptedPaths as any,
      endpointNames,
      score,
      proteinInfo as any,
      unreachedEndpoints,
      modelName,
      version,
      isSandboxUser,
      fdaApprovedDrugsData,
      selectedTissueType || undefined
    );
  } catch (error) {
    console.error("ERROR: Failed to create PDF report:", error);
    toast.error("Failed to create PDF report. Please try again.");
  }
}

// Add constants for polling at the beginning of the file
const INITIAL_POLLING_DELAY = 2000; // 2 seconds
const POLLING_INTERVAL = 10000;     // 10 seconds (increased from 5s)
const MAX_POLLING_ATTEMPTS = 90;   // 15 minutes total (increased from 5 min)
const POLLING_DELAY = 3000; // 3 seconds initial delay

function NodeProcessPathsPage({ initProjectData, asPage = true }: NodeProcessPathsPageProps) {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const isSandboxUser = useIsSandboxUser();
  const { projectId } = useParams<{ projectId: string; disease: string; nodeId: string }>();
  const [projectData, setProjectData] = useState<TVProjectType>();
  const _id = projectData?._id;
  const disease = projectData?.disease;
  const nodeId = projectData?.nodeId;
  const aiPredIsOn = projectData?.filterToggle;
  const aiPredCutoff = projectData?.filterValue;
  const modelId = projectData?.model_id;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPath, setSelectedPath] = useState<InterruptedPath>({
    id: 0,
    endpoint: '',
    score: 0,
    path: []
  });
  const [showCluster, setShowCluster] = useState(false);
  const [efficacyData, setEfficacyData] = useState<any>(null);
  const [fdaApprovedDrugsData, setFdaApprovedDrugsData] = useState<any>(null);
  const [selectedNodeName, setSelectedNodeName] = useState('');
  const [selectedTissueType, setSelectedTissueType] = useState<string | null>(null);
  const [glossaryModalOpen, setGlossaryModalOpen] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);
  const [dataFetchAttempted, setDataFetchAttempted] = useState(false);

  // Create a ref to the selected path container
  const selectedPathContainerRef = useRef<HTMLDivElement>(null);

  // Track scroll position and selected path index
  const pathsListRef = useRef<HTMLDivElement>(null);
  const [selectedPathIndex, setSelectedPathIndex] = useState<number>(-1);
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const initialScrollRestored = useRef<boolean>(false);

  // Add these states to the NodeProcessPathsPage component
  const [isPollingForResults, setIsPollingForResults] = useState<boolean>(false);
  const [pollingAttempts, setPollingAttempts] = useState<number>(0);
  const [validationInProgress, setValidationInProgress] = useState<boolean>(false);
  const pollingTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  // Add a new state for loading progress
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  
  // Add this function to check if results are ready via direct API call
  async function checkForResults() {
    if (!projectId) return false;
    
    try {
      const accessToken = await getAccessTokenSilently();
      // Direct API call to the database
      const response = await fetch(`/api/harbor/projects/${projectId}`, {
        method: 'GET',
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        console.error(`API error: ${response.status} ${response.statusText}`);
        return false;
      }
      
      const responseData = await response.json();
      console.log("DEBUG: Project data check response:", responseData);
      
      if (!responseData || !responseData.data) {
        console.log("DEBUG: No response data");
        return false;
      }
      
      const projectData = responseData.data;
      
      // Check if validation results are ready
      if (projectData.validation_results && 
          Object.keys(projectData.validation_results).length > 0) {
        console.log("DEBUG: Found validation_results in project data!");
        return true;
      }
      
      // Also check frontend_data
      if (projectData.frontend_data && 
          Object.keys(projectData.frontend_data).length > 0) {
        console.log("DEBUG: Found frontend_data in project data!");
        return true;
      }
      
      return false;
    } catch (error) {
      console.error("Error checking for results:", error);
      return false;
    }
  }

  // Add this useEffect to start polling immediately when the page loads
  useEffect(() => {
    // Only start if we have a projectId and no data yet
    if (projectId && initialLoading && !isPollingForResults && pollingAttempts === 0) {
      console.log("DEBUG: Starting initial polling for results...");
      
      // Check immediately (with a small delay)
      const initialTimer = setTimeout(async () => {
        const resultsReady = await checkForResults();
          
        if (resultsReady) {
          console.log("DEBUG: Results are already available, refreshing data");
          // Results are ready, refresh the page to show them
          window.location.reload();
        } else {
          console.log("DEBUG: Results not yet available, starting polling");
          
          // Show toast to inform user validation is in progress
          toast.info(
            "Validation analysis in progress... This may take a few minutes. The page will update automatically when complete.",
            { autoClose: false }
          );
          
          setValidationInProgress(true);
          setIsPollingForResults(true);
          startPolling();
        }
      }, INITIAL_POLLING_DELAY);
      
      return () => clearTimeout(initialTimer);
    }
  }, [projectId, initialLoading]);
  
  // Function to start polling
  function startPolling() {
    // Clear any existing timer
    if (pollingTimerRef.current) {
      clearTimeout(pollingTimerRef.current);
    }
    
    // Set up polling
    pollingTimerRef.current = setTimeout(async () => {
      if (pollingAttempts >= MAX_POLLING_ATTEMPTS) {
        console.log("DEBUG: Max polling attempts reached");
        setValidationInProgress(false);
        setInitialLoading(false);
        setLoading(false);
        setIsPollingForResults(false);
        return;
      }
      
      try {
        console.log(`DEBUG: Polling attempt ${pollingAttempts + 1}/${MAX_POLLING_ATTEMPTS}`);
        
        // Update loading progress - more realistic looking if not entirely linear
        const progressIncrement = Math.random() * 5 + 3; // 3-8% increment per poll
        const newProgress = Math.min(95, loadingProgress + progressIncrement); // Cap at 95% until complete
        setLoadingProgress(newProgress);
        
        const resultsReady = await checkForResults();
        
        if (resultsReady) {
          console.log("DEBUG: Results found during polling!");
          setValidationInProgress(false);
          setIsPollingForResults(false);
          setLoading(false);
          setInitialLoading(false);
          setLoadingProgress(100); // Set to 100% when complete
          
          // Dismiss any existing toasts
          toast.dismiss();
          toast.success("Validation results are ready");
          
          // Short delay to show 100% progress before reload
          setTimeout(() => {
            // Reload the page to show the results
            window.location.reload();
          }, 1000);
        } else {
          // Increment attempts and continue polling
          setPollingAttempts(prev => prev + 1);
          startPolling(); // Continue polling
        }
      } catch (error) {
        console.error("DEBUG: Error during polling:", error);
        setPollingAttempts(prev => prev + 1);
        startPolling(); // Continue despite error
      }
    }, POLLING_INTERVAL);
  }

  // Clean up polling on unmount
  useEffect(() => {
    return () => {
      if (pollingTimerRef.current) {
        clearTimeout(pollingTimerRef.current);
      }
    };
  }, []);

  // Fetch FDA approved drugs data when the component mounts or when tissue type changes
  useEffect(() => {
    const fetchFdaApprovedDrugs = async (useFallback = false) => {
      try {
        // Clear existing FDA data and show loading state
        setFdaApprovedDrugsData(null);
        setLoading(true);
        
        // STRICTLY check if the FDA data from frontend_data matches EXACTLY the current tissue type
        const frontendFdaData = projectData?.frontend_data && (projectData.frontend_data as any)?.fda_approved_drugs;
        
        if (frontendFdaData && selectedTissueType) {
          // Check if the frontend data has the EXACT same tissue type
          const frontendTissueType = frontendFdaData.actualTissueType || frontendFdaData.requestedTissueType;
          
          if (frontendTissueType === selectedTissueType) {
            console.log(`Using EXACT MATCH FDA data from frontend_data for tissue: ${selectedTissueType}`);
            // Make sure tissue info is explicitly set
            frontendFdaData.actualTissueType = selectedTissueType;
            frontendFdaData.requestedTissueType = selectedTissueType;
            frontendFdaData.tissueTypeDisplay = `${getTissueTypeName(selectedTissueType)} Tissue`;
            
            setFdaApprovedDrugsData(frontendFdaData);
            setLoading(false);
            return;
          } else {
            console.log(`REJECTED precomputed FDA data - tissue mismatch: Want ${selectedTissueType}, found ${frontendTissueType || 'unknown'}`);
          }
        }

        // Get the access token from Auth0
        const accessToken = await getAccessTokenSilently();
        
        // CRITICAL FIX: NEVER request FDA data without a tissue type
        // Use appropriate fallbacks to ensure we always have a tissue type
        let tissueTypeToUse = selectedTissueType;
        
        // If no tissue type is explicitly selected, try getting it from efficacy data or project data
        if (!tissueTypeToUse && efficacyData && typeof efficacyData === 'object' && 'tissue_type' in efficacyData) {
          tissueTypeToUse = efficacyData.tissue_type as string;
          console.log(`No tissue type explicitly selected, using tissue type from efficacy data: ${tissueTypeToUse}`);
        } else if (!tissueTypeToUse && projectData?.tissue_type) {
          // Prioritize the project's direct tissue_type field
          tissueTypeToUse = typeof projectData.tissue_type === 'string' ? projectData.tissue_type : String(projectData.tissue_type);
          console.log(`No tissue type explicitly selected, using tissue type directly from project data: ${tissueTypeToUse}`);
        } else if (!tissueTypeToUse && projectData?.frontend_data && 
                  typeof projectData.frontend_data === 'object' && 
                  'tissue_type' in projectData.frontend_data) {
          const frontendData = projectData.frontend_data as { tissue_type: string };
          tissueTypeToUse = frontendData.tissue_type;
          console.log(`No tissue type explicitly selected, using tissue type from project frontend data: ${tissueTypeToUse}`);
        } else if (!tissueTypeToUse) {
          // Instead of defaulting to spinal_cervical, we log the issue but don't set a fallback
          // The API should handle this case and return appropriate data
          console.log(`WARNING: No tissue type found anywhere. Requesting FDA data without a specific tissue type.`);
        }
        
        // ALWAYS include tissue type in the URL if we have one
        const url = tissueTypeToUse 
          ? `/api/target-validation/fda_approved_drugs?tissue_type=${encodeURIComponent(tissueTypeToUse)}`
          : `/api/target-validation/fda_approved_drugs`;
        
        console.log(`Fetching FDA approved drugs data ${tissueTypeToUse ? `for tissue type: ${tissueTypeToUse}` : 'without specific tissue type'}`);
        console.log(`Making FDA approved drugs request to: ${url}`);
        
        // Include the authorization token in the request
        const requestOptions = {
          method: 'GET',
          headers: { 
            Authorization: `Bearer ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        };
        
        console.log("Making FDA approved drugs request to:", url);
        const apiResponse = await fetch(url, requestOptions);
        console.log("Response status:", apiResponse.status, apiResponse.statusText);
        
        // For detailed debugging
        if (!apiResponse.ok) {
          console.error("Response headers:", apiResponse.headers);
          console.error("Response type:", apiResponse.type);
          
          // Get the response text for debugging
          try {
            const responseText = await apiResponse.text();
            console.error("Error response body:", responseText);
            
            // Try to parse the response text as JSON if possible
            try {
              const errorData = JSON.parse(responseText);
              console.error("Parsed error data:", errorData);
              
              if (errorData && errorData.message) {
                toast.error(`Server error: ${errorData.message}`);
              }
            } catch (parseError) {
              console.error("Could not parse error response as JSON");
            }
          } catch (textError) {
            console.error("Could not read error response text");
          }
          
          if (!useFallback && selectedTissueType) {
            console.log(`Server error (${apiResponse.status}) with tissue-specific data. Falling back to general data.`);
            toast.warning(`Tissue-specific data unavailable (${apiResponse.statusText}). Trying general data instead.`);
            fetchFdaApprovedDrugs(true);
          } else {
            toast.error(`Server error: ${apiResponse.statusText}. Please try again later.`);
            setFdaApprovedDrugsData({});
            setLoading(false);
          }
          return;
        }
        
        // Parse the response
        const responseData = await apiResponse.json();
        console.log("FDA approved drugs API response:", responseData);
        
        if (responseData.status === 'success') {
          // Check if the data is empty and handle appropriately
          if (responseData.data && Object.keys(responseData.data).length > 0) {
            // Store which tissue type the API actually returned (if available)
            const actualTissueType = responseData.tissue_type || null;
            
            // Log the tissue type information
            console.log(`API returned tissue type: ${actualTissueType || 'not specified'}, requested: ${selectedTissueType || 'not specified'}`);
            
            // Process the data based on its structure
            let processedData: FdaApprovedDrugs = { 
              ensembl_ids: {}, 
              gene_symbols: {} 
            };
            
            // Record the actual tissue type used
            (processedData as any).actualTissueType = actualTissueType;
            
            // First, determine what format the data is in
            const responseKeys = Object.keys(responseData.data);
            
            // CASE 1: The response contains tissue types (entire fda_approved_drugs_by_tissue structure)
            if (responseKeys.some(key => key in TISSUE_TYPE_NAMES)) {
              console.log('Response contains full tissue structure, extracting specific tissue data');
              
              // FIXED LOGIC - Strongly prioritize the selected tissue type
              let tissueToUse = "";
              
              if (selectedTissueType && responseData.data[selectedTissueType]) {
                // First priority: Use the explicitly selected tissue type if available
                tissueToUse = selectedTissueType;
                console.log(`Using explicitly selected tissue type: ${tissueToUse}`);
              } else if (selectedTissueType && !responseData.data[selectedTissueType]) {
                // Second priority: If selected not available, find closest match
                console.log(`Requested tissue ${selectedTissueType} not available, finding closest match`);
                if (selectedTissueType.includes("_")) {
                  // Try to match the same general tissue type (brain, spinal, blood)
                  const generalType = selectedTissueType.split("_")[0];
                  const matchingTissue = responseKeys.find(key => 
                    key.startsWith(`${generalType}_`) && key in TISSUE_TYPE_NAMES
                  );
                  
                  if (matchingTissue) {
                    tissueToUse = matchingTissue;
                    console.log(`Found matching tissue type from same category: ${tissueToUse}`);
                    console.info(`Using data for '${getTissueTypeName(tissueToUse)}' tissue (requested '${getTissueTypeName(selectedTissueType)}' not available)`);
                  }
                }
                
                // If still no match, use any available tissue
                if (!tissueToUse) {
                  tissueToUse = responseKeys.find(key => key in TISSUE_TYPE_NAMES) || "";
                  if (tissueToUse) {
                    console.log(`Using fallback tissue type: ${tissueToUse}`);
                    console.info(`Using data for '${getTissueTypeName(tissueToUse)}' tissue (requested '${getTissueTypeName(selectedTissueType)}' not available)`);
                  }
          }
        } else {
                // Last priority: No selection, use any available tissue
                tissueToUse = responseKeys.find(key => key in TISSUE_TYPE_NAMES) || "";
                console.log(`No tissue type selected, using first available: ${tissueToUse}`);
              }
              
              if (tissueToUse && responseData.data[tissueToUse]) {
                console.log(`Using tissue data for: ${tissueToUse}`);
                
                // Mark the tissue type used for debugging and tracking
                (processedData as any).tissueTypeUsed = tissueToUse;
                
                // Extract just this tissue's data
                const tissueData = responseData.data[tissueToUse];
                
                // Process ensembl_ids
                if (tissueData.ensembl_ids) {
                  Object.entries(tissueData.ensembl_ids).forEach(([id, data]: [string, any]) => {
                    if (data && data.drugs && Array.isArray(data.drugs)) {
                      if (processedData.ensembl_ids) {
                        processedData.ensembl_ids[id] = {
                          gene_symbol: data.gene_symbol || id.split('.').pop() || id,
                          drugs: data.drugs,
                          efficacy_score: data.efficacy_score || 0.5,
                          endpoint_paths: data.endpoint_paths || []
                        };
                      }
                    }
                  });
                }
                
                // Process gene_symbols
                if (tissueData.gene_symbols) {
                  Object.entries(tissueData.gene_symbols).forEach(([symbol, data]: [string, any]) => {
                    if (data && data.drugs && Array.isArray(data.drugs)) {
                      if (processedData.gene_symbols) {
                        processedData.gene_symbols[symbol] = {
                          ensembl_id: data.ensembl_id || '',
                          drugs: data.drugs,
                          efficacy_score: data.efficacy_score || 0.5,
                          endpoint_paths: data.endpoint_paths || []
                        };
                      }
                    }
                  });
                }
              } else {
                console.warn('No valid tissue data found in response');
              }
            }
            // CASE 2: Response contains direct tissue-specific data structure
            else if (responseData.data.ensembl_ids && typeof responseData.data.ensembl_ids === 'object') {
              console.log('Processing direct tissue-specific FDA drugs data');
              
              // Just directly copy the entire data structure - this preserves endpoint_paths
              processedData = responseData.data;
              
              // Log if we have endpoint_paths in the data
              let hasEndpointPaths = false;
              let pathCount = 0;
              
              if (processedData.ensembl_ids) {
                for (const id in processedData.ensembl_ids) {
                  const data = processedData.ensembl_ids[id];
                  if (data && data.endpoint_paths && Array.isArray(data.endpoint_paths)) {
                    hasEndpointPaths = true;
                    pathCount += data.endpoint_paths.length;
                  }
                }
              }
              
              console.log(`FDA data contains endpoint_paths: ${hasEndpointPaths ? `Yes (${pathCount} total paths)` : 'No'}`);
            }
            // CASE 3: Old format with just drug arrays
            else if (responseData.data.ensembl_ids || responseData.data.gene_symbols) {
              console.log('Processing old format FDA drugs data');
              
              // Process the older format (just arrays of drug names)
              if (responseData.data.ensembl_ids) {
                Object.entries(responseData.data.ensembl_ids).forEach(([id, drugs]: [string, any]) => {
                  if (Array.isArray(drugs)) {
                    if (processedData.ensembl_ids) {
                      processedData.ensembl_ids[id] = {
                        gene_symbol: id.split('.').pop() || id,
                        drugs: drugs,
                        efficacy_score: 0.5,
                        endpoint_paths: []
                      };
                    }
                  }
                });
              }
              
              if (responseData.data.gene_symbols) {
                Object.entries(responseData.data.gene_symbols).forEach(([symbol, drugs]: [string, any]) => {
                  if (Array.isArray(drugs)) {
                    if (processedData.gene_symbols) {
                      processedData.gene_symbols[symbol] = {
                        ensembl_id: '',
                        drugs: drugs,
                        efficacy_score: 0.5,
                        endpoint_paths: []
                      };
                    }
                  }
                });
              }
            }
            
            // Log the data format to help debug structure issues
            console.log('FDA approved drugs data structure (after processing):', {
              hasEnsemblIds: !!processedData.ensembl_ids,
              hasGeneSymbols: !!processedData.gene_symbols,
              ensemblIdsCount: processedData.ensembl_ids ? Object.keys(processedData.ensembl_ids).length : 0,
              geneSymbolsCount: processedData.gene_symbols ? Object.keys(processedData.gene_symbols).length : 0
            });
            
            // Store the requested tissue type with the data to help identify stale data
            (processedData as any).requestedTissueType = selectedTissueType;
            (processedData as any).actualTissueType = responseData.tissue_type || null;
            (processedData as any).tissueTypeDisplay = responseData.tissue_type ? 
              `${getTissueTypeName(responseData.tissue_type)} Tissue` : 
              "Unknown Tissue";
            
            setFdaApprovedDrugsData(processedData);
            console.log('FDA approved drugs data loaded (after processing):', processedData);
            
            // Show detailed information about tissue type to the user
            if (responseData.note) {
              console.info("API Note:", responseData.note);
              // Remove toast to reduce UI clutter
              console.info("API Note info:", responseData.note);
            }
            
            if (useFallback) {
              console.info("Using general FDA approved drugs data (tissue-specific data unavailable)");
            } else if (responseData.tissue_type) {
              if (responseData.tissue_type === selectedTissueType) {
                console.info(`Using FDA drugs data for requested tissue: ${getTissueTypeName(responseData.tissue_type)}`);
              } else {
                console.info(`Using FDA approved drugs data for '${getTissueTypeName(responseData.tissue_type)}' tissue ${
                  selectedTissueType ? `(requested '${getTissueTypeName(selectedTissueType)}' not available)` : ''
                }`);
              }
            }
          } else {
            console.warn('FDA approved drugs data is empty');
            console.warn("No FDA approved drugs found for this model");
            setFdaApprovedDrugsData({});
          }
        } else {
          console.error('Error in FDA approved drugs response:', responseData.message || 'Unknown error');
          if (!useFallback && selectedTissueType) {
            console.log("Attempting fallback to general FDA approved drugs data");
            fetchFdaApprovedDrugs(true);
          } else {
            toast.error(`Error: ${responseData.message || "Failed to fetch FDA approved drugs data"}`);
            setFdaApprovedDrugsData({});
          }
        }
      } catch (error) {
        console.error('Error fetching FDA approved drugs data:', error);
        
        // Get more details from the error
        if (error instanceof Error) {
          console.error("Error name:", error.name);
          console.error("Error message:", error.message);
          console.error("Error stack:", error.stack);
        }
        
        if (!useFallback && selectedTissueType) {
          console.log("Exception occurred. Attempting fallback to general FDA approved drugs data");
          fetchFdaApprovedDrugs(true);
        } else {
          toast.error("Failed to fetch FDA approved drugs data. Please try again later.");
          setFdaApprovedDrugsData({});
        }
      } finally {
        // Always ensure loading state is cleared
        setLoading(false);
      }
    };
    
    // Clear previous data and initiate fetch
    fetchFdaApprovedDrugs();
  }, [getAccessTokenSilently, selectedTissueType, projectData]);

  // Update selected tissue type when project data changes
  useEffect(() => {
    if (projectData) {
      // Log project data for debugging
      console.log("DEBUG: Project data updated:", {
        _id: projectData._id,
        disease: projectData.disease,
        nodeId: projectData.nodeId,
        modelId: projectData.model_id,
        hasValidationResults: !!projectData.validation_results,
        hasFrontendData: !!projectData.frontend_data,
        hasTissueType: !!projectData.tissue_type,
        tissueType: projectData.tissue_type
      });
      
      // Important: Set the tissue type only if it's different from the current one
      if (projectData.tissue_type && typeof projectData.tissue_type === 'string' && 
          projectData.tissue_type !== selectedTissueType) {
      setSelectedTissueType(projectData.tissue_type);
      console.log(`Set tissue type from project data: ${projectData.tissue_type}`);
    }
      
      // Check if the frontend_data contains all the necessary components
      if (projectData.frontend_data && typeof projectData.frontend_data === 'object') {
        const frontendData = projectData.frontend_data as any; // Cast to any to avoid TypeScript errors
        console.log("DEBUG: Frontend data contains:", {
          hasInterruptedPaths: Array.isArray(frontendData.interrupted_paths),
          pathsCount: Array.isArray(frontendData.interrupted_paths) ? frontendData.interrupted_paths.length : 0,
          hasProteinInfo: !!frontendData.protein_info,
          hasFdaApprovedDrugs: !!frontendData.fda_approved_drugs
        });
        
        // If we have FDA approved drugs data in the frontend_data, we only use it if we don't have tissue type
        // or if it matches the current tissue type
        if (frontendData.fda_approved_drugs) {
          // Only use precomputed data if we don't have a tissue type selected or if we can confirm 
          // it's for the correct tissue
          if (!selectedTissueType || (frontendData.tissue_type === selectedTissueType)) {
            console.log("DEBUG: Using FDA approved drugs from frontend_data for matching tissue type");
            setFdaApprovedDrugsData(frontendData.fda_approved_drugs);
          } else {
            console.log("DEBUG: Not using precomputed FDA data because tissue types don't match", {
              selectedTissueType,
              frontendDataTissueType: frontendData.tissue_type
            });
          }
        }
      }
    }
  }, [projectData, selectedTissueType]);

  // Add a separate effect specifically for ensuring FDA data is refreshed when 
  // both project data and tissue type are confirmed
  useEffect(() => {
    // Only proceed if we have both confirmed project data and a confirmed tissue type
    if (projectData && selectedTissueType) {
      console.log(`FDA data refresh trigger: Project loaded with tissue type ${selectedTissueType}`);
      
      // Explicitly fetch fresh FDA approved drugs data when project is fully loaded
      // with the confirmed tissue type, rather than waiting for manual refresh
      const refreshFdaData = async () => {
        console.log(`🔄 Auto-refreshing FDA approved drugs data for tissue type: ${selectedTissueType}`);
        try {
          // Clear previous data
          setFdaApprovedDrugsData({});
          // Set loading state
          setLoading(true);
          
          // Get token
          const accessToken = await getAccessTokenSilently();
          
          // Construct the URL with the specific tissue type
          const url = new URL('/api/target-validation/fda-approved-drugs', window.location.href);
          url.searchParams.set('disease', disease || '');
          url.searchParams.set('target', nodeId || '');
          url.searchParams.set('tissueType', selectedTissueType);
          
          console.log(`Making fresh FDA data request to: ${url.toString()}`);
          
          const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          
          const responseData = await response.json();
          
          // Only use the data if it's for the current tissue type
          if (responseData.success && responseData.data) {
            if (responseData.tissue_type === selectedTissueType) {
              console.log(`✅ Auto-refresh successful: Got FDA data for ${selectedTissueType}`);
              setFdaApprovedDrugsData(responseData.data);
            } else {
              console.warn(`❌ Auto-refresh returned wrong tissue type: expected ${selectedTissueType}, got ${responseData.tissue_type}`);
            }
          } else {
            console.warn('FDA approved drugs data is empty from auto-refresh');
          }
        } catch (error) {
          console.error('Error in FDA approved drugs auto-refresh:', error);
        } finally {
          setLoading(false);
        }
      };
      
      // Execute the refresh
      refreshFdaData();
    }
  }, [projectData?._id, selectedTissueType, disease, nodeId, getAccessTokenSilently]);

  function displaySelectedPath() {
    console.log("Displaying selected path:", selectedPath);
    console.log("Using efficacyData:", efficacyData);
    
    // Calculate container width
    const containerWidth = selectedPathContainerRef.current?.clientWidth || SELECTED_GRAPH_WIDTH;
    
    return renderPathGraph(selectedPath, 'main', nodeId, true, containerWidth, SELECTED_GRAPH_HEIGHT, efficacyData, fdaApprovedDrugsData);
  }

  // Add effect to update graph when container size changes
  useEffect(() => {
    const handleResize = () => {
      // Force redraw when window size changes to update graph dimensions
      if (selectedPath) {
        setSelectedPath({ ...selectedPath });
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [selectedPath]);

  // Add a timeout to avoid showing the fallback UI too quickly
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoading(false);
    }, 1000); // Wait 1 second before considering initial loading complete
    
    return () => clearTimeout(timer);
  }, []);
  
  // Set dataFetchAttempted when we try to fetch data
  useEffect(() => {
    if (disease && nodeId && modelId) {
      setDataFetchAttempted(true);
    }
  }, [disease, nodeId, modelId]);

  async function getTVProjectImpl() {
    console.log("DEBUG: Fetching TV project with ID:", projectId);
    if (!projectId) {
      console.error("ERROR: projectId is undefined or null");
      return Promise.reject(new Error("Project ID is required"));
    }
    
    try {
    const accessToken = await getAccessTokenSilently();
      console.log("DEBUG: Access token obtained, making API call to get TV project");
      
      // First, try to get the project directly from the database
      try {
        // Create a direct API call to check the project in the database
        const response = await fetch(`/api/harbor/projects/${projectId}?debug=true`, {
          method: 'GET',
          headers: { 
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
        const rawData = await response.json();
        console.log("DEBUG: Raw project data from direct API call:", rawData);
        
        // If we have data from the direct API call, use it
        if (rawData && rawData.data && Object.keys(rawData.data).length > 0) {
          console.log("DEBUG: Using data from direct API call");
          const projectData = rawData.data;
          
          // If the model is missing, create a minimal model object
          if (!projectData.model && projectData.model_id) {
            console.warn("Model not found in models collection, attempting to fetch from base_models");
            
            try {
              // Try to fetch the base model directly
              const baseModelResponse = await fetch(`/api/harbor/base_models`, {
                method: 'GET',
                headers: { 
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json'
                }
              });
              
              if (baseModelResponse.ok) {
                const baseModelsData = await baseModelResponse.json();
                
                // Find the matching base model by ID
                if (baseModelsData.data && Array.isArray(baseModelsData.data)) {
                  const matchingBaseModel = baseModelsData.data.find(
                    (model: any) => model._id && model._id.toString() === projectData.model_id.toString()
                  );
                  
                  if (matchingBaseModel) {
                    console.log("DEBUG: Found matching base model:", matchingBaseModel);
                    // Create a model object from the base model
            projectData.model = {
              _id: projectData.model_id,
                      name: matchingBaseModel.name || "Base Model",
                      disease: matchingBaseModel.disease || projectData.disease || "Unknown",
                    };
                    console.log("DEBUG: Created model object from base model:", projectData.model);
                    return projectData;
                  }
                }
              }
              
              console.warn("Base model not found, creating fallback object");
            } catch (baseModelError) {
              console.error("Error fetching base model:", baseModelError);
            }
            
            // Fallback if base model fetch fails
            projectData.model = {
              _id: projectData.model_id,
              name: projectData.model_name || "Base Model",
              disease: projectData.disease || "Unknown",
            };
            console.log("DEBUG: Created fallback model object:", projectData.model);
          }
          
          // Ensure all required fields are present
          if (!projectData.nodeId && projectData.node_id) {
            projectData.nodeId = projectData.node_id;
          }
          
          // Make sure we have disease information
          if (!projectData.disease && projectData.model && projectData.model.disease) {
            projectData.disease = projectData.model.disease;
          }
          
          console.log("DEBUG: Returning enhanced project data:", projectData);
          return projectData;
        }
      } catch (directError) {
        console.error("DEBUG: Error in direct API call:", directError);
      }
      
      const result: any = await projectsService.getTVProject(accessToken, projectId);
      console.log("DEBUG: TV project data received:", result);
      console.log("DEBUG: TV project data keys:", Object.keys(result));
      console.log("DEBUG: TV project data type:", typeof result);
      
      // Check if the result is empty or doesn't have required fields
      if (!result || Object.keys(result).length === 0) {
        console.error("ERROR: Received empty project data from API");
        toast.error("Could not load project data. The project may not exist or you may not have access to it.");
        
        // Try to create a minimal project data object with the projectId
        console.log("DEBUG: Attempting to create minimal project data");
        const minimalProject = {
          _id: projectId,
          name: "Project " + projectId.substring(0, 8),
          disease: "Unknown",
          model_id: "unknown",
          model: {
            _id: "unknown",
            name: "Unknown Model",
            disease: "Unknown"
          },
          nodeId: "unknown"
        };
        console.log("DEBUG: Created minimal project data:", minimalProject);
        return minimalProject;
      }
      
      // If the model is missing, try to create a minimal model object with the data we have
      if (!result.model && result.model_id) {
        console.warn("Model not found in models collection, attempting to fetch from base_models");
        
        try {
          // Try to fetch the base model directly
          const baseModelResponse = await fetch(`/api/harbor/base_models`, {
            method: 'GET',
            headers: { 
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          });
          
          if (baseModelResponse.ok) {
            const baseModelsData = await baseModelResponse.json();
            console.log("DEBUG: Base models data:", baseModelsData);
            
            // Find the matching base model by ID
            if (baseModelsData.data && Array.isArray(baseModelsData.data)) {
              const matchingBaseModel = baseModelsData.data.find(
                (model: any) => model._id && model._id.toString() === result.model_id.toString()
              );
              
              if (matchingBaseModel) {
                console.log("DEBUG: Found matching base model:", matchingBaseModel);
                // Create a model object from the base model
        result.model = {
          _id: result.model_id,
                  name: matchingBaseModel.name || "Base Model",
                  disease: matchingBaseModel.disease || result.disease || "Unknown",
                };
                console.log("DEBUG: Created model object from base model:", result.model);
                return result;
              }
            }
          }
          
          console.warn("Base model not found, creating fallback object");
        } catch (baseModelError) {
          console.error("Error fetching base model:", baseModelError);
        }
        
        // Fallback if base model fetch fails
        result.model = {
          _id: result.model_id,
          name: result.model_name || "Base Model",
          disease: result.disease || "Unknown",
        };
        console.log("DEBUG: Created fallback model object:", result.model);
      }
      
      // Ensure all required fields are present
      if (!result.nodeId && result.node_id) {
        result.nodeId = result.node_id;
      }
      
      // Make sure we have disease information
      if (!result.disease && result.model && result.model.disease) {
        result.disease = result.model.disease;
      }
      
      console.log("DEBUG: Returning enhanced result:", result);
      return result;
    } catch (error) {
      console.error("ERROR: Failed to fetch TV project:", error);
      
      // If there's an error, create a minimal project data object
      console.log("DEBUG: Creating minimal project data due to error");
      const minimalProject = {
        _id: projectId,
        name: "Project " + projectId.substring(0, 8),
        disease: "Unknown",
        model_id: "unknown",
        model: {
          _id: "unknown",
          name: "Unknown Model",
          disease: "Unknown"
        },
        nodeId: "unknown"
      };
      console.log("DEBUG: Created minimal project data:", minimalProject);
      return minimalProject;
    }
  }

  const getTVProjectInfo = withServiceCallHandling(
    getTVProjectImpl,
    (data) => {
      console.log("DEBUG: Setting project data:", data);
      
      // If data is empty or undefined, log an error but don't create a minimal project
      // since getTVProjectImpl already returns a minimal project in case of errors
      if (!data) {
        console.error("ERROR: Project data is undefined in success callback");
          toast.error("Could not load project data. The project may not exist or you may not have access to it.");
        return;
        }
      
      if (Object.keys(data).length === 0) {
        console.error("ERROR: Project data is empty in success callback");
        toast.error("Could not load project data. The project may not exist or you may not have access to it.");
        return;
      }
      
      // Log the actual data structure to help with debugging
      console.log("DEBUG: Project data structure:", {
        hasNodeId: !!data.nodeId,
        hasNodeId2: !!data.node_id,
        hasDisease: !!data.disease,
        hasModelId: !!data.model_id,
        hasModel: !!data.model,
        modelKeys: data.model ? Object.keys(data.model) : []
      });
      
      // Ensure we have the required fields for the node validation page
      const enhancedData = { ...data };
      
      // If we're missing nodeId but have node_id, use that
      if (!enhancedData.nodeId && enhancedData.node_id) {
        console.log("DEBUG: Using node_id as nodeId:", enhancedData.node_id);
        enhancedData.nodeId = enhancedData.node_id;
      }
      
      // If we're missing disease but have it in the model, use that
      if (!enhancedData.disease && enhancedData.model && enhancedData.model.disease) {
        console.log("DEBUG: Using model.disease as disease:", enhancedData.model.disease);
        enhancedData.disease = enhancedData.model.disease;
      }
      
      // If we're missing model_id but have it in the model, use that
      if (!enhancedData.model_id && enhancedData.model && enhancedData.model._id) {
        console.log("DEBUG: Using model._id as model_id:", enhancedData.model._id);
        enhancedData.model_id = enhancedData.model._id;
      }
      
      // Make sure we have a model object
      if (!enhancedData.model && enhancedData.model_id) {
        console.log("DEBUG: Creating model object from model_id:", enhancedData.model_id);
        enhancedData.model = {
          _id: enhancedData.model_id,
          name: enhancedData.model_name || "Base Model",
          disease: enhancedData.disease || "Unknown"
        };
      }
      
      // If we're missing model_id but have it in the model, use that
      if (!enhancedData.model_id && enhancedData.model && enhancedData.model._id) {
        console.log("DEBUG: Using model._id as model_id:", enhancedData.model._id);
        enhancedData.model_id = enhancedData.model._id;
      }
      
      // Make sure we have a model object
      if (!enhancedData.model && enhancedData.model_id) {
        console.log("DEBUG: Creating model object from model_id:", enhancedData.model_id);
        
        // Fetch base model name asynchronously
        const fetchBaseModelName = async () => {
          try {
            // Get access token
            const token = await getAccessTokenSilently();
            
            // Try to fetch the base model directly
            const baseModelResponse = await fetch(`/api/harbor/base_models`, {
              method: 'GET',
              headers: { 
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            
            if (baseModelResponse.ok) {
              const baseModelsData = await baseModelResponse.json();
              
              // Find the matching base model by ID
              if (baseModelsData.data && Array.isArray(baseModelsData.data)) {
                const matchingBaseModel = baseModelsData.data.find(
                  (model: any) => model._id && model._id.toString() === enhancedData.model_id.toString()
                );
                
                if (matchingBaseModel) {
                  console.log("DEBUG: Found matching base model:", matchingBaseModel);
                  // Create a model object from the base model
                  enhancedData.model = {
                    _id: enhancedData.model_id,
                    name: matchingBaseModel.name || "Base Model",
                    disease: matchingBaseModel.disease || enhancedData.disease || "Unknown"
                  };
                  console.log("DEBUG: Created model object from base model:", enhancedData.model);
                  setProjectData({...enhancedData});
                  return;
                }
              }
            }
            
            // If we get here, we didn't find a matching base model
            console.warn("Base model not found, using fallback name");
            enhancedData.model = {
              _id: enhancedData.model_id,
              name: enhancedData.model_name || "Base Model",
              disease: enhancedData.disease || "Unknown"
            };
            console.log("DEBUG: Created fallback model object:", enhancedData.model);
            setProjectData({...enhancedData});
          } catch (error) {
            console.error("Error fetching base model:", error);
            // Use fallback on error
            enhancedData.model = {
              _id: enhancedData.model_id,
              name: enhancedData.model_name || "Base Model",
              disease: enhancedData.disease || "Unknown"
            };
            setProjectData({...enhancedData});
          }
        };
        
        // Start the async fetch but don't wait for it
        fetchBaseModelName();
        
        // Create a temporary model object for immediate display
        enhancedData.model = {
          _id: enhancedData.model_id,
          name: "Loading model name...",
          disease: enhancedData.disease || "Unknown"
        };
      }
      
      console.log("DEBUG: Setting enhanced project data:", enhancedData);
      setProjectData(enhancedData);
    },
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    if (!initProjectData) {
      console.log("DEBUG: No initProjectData provided, fetching from API with projectId:", projectId);
      getTVProjectInfo();
    } else {
      console.log("DEBUG: Using provided initProjectData:", initProjectData);
      setProjectData(initProjectData);
    }
  }, [initProjectData]);

  // In case nodeId is not set in projectData - rollback to the main target validation project list
  useEffect(() => {
    console.log("DEBUG: Project data updated:", { 
      _id, 
      nodeId, 
      disease, 
      modelId,
      projectData: projectData ? "exists" : "missing" 
    });
    
    if (_id && !nodeId) {
      console.error("Node ID is missing in project data");
      toast.error('Node was not processed for this project');
      history.push(config.clientUrls.TargetValidation);
    }
  }, [_id, nodeId, projectData]);

  function handleSetSelectedPath(i: number) {
    if (!efficacyData || !efficacyData.interrupted_paths || i >= efficacyData.interrupted_paths.length) {
      console.error('Missing efficacyData or invalid path index');
      return;
    }
    
    console.debug(`DEBUG: handleSetSelectedPath called for path index ${i}`);
    let rawPath = efficacyData.interrupted_paths[i];
    console.debug('DEBUG: Raw path from efficacyData:', JSON.stringify({
      pathLength: rawPath.path?.length || 0,
      hasEdgeData: !!rawPath.edge_data,
      edgeDataKeys: Object.keys(rawPath.edge_data || {}).length,
      endpoint: rawPath.endpoint
    }));
    
    // Create a deep copy of the path to avoid mutating the original
    const enhancedRawPath = JSON.parse(JSON.stringify(rawPath));
    
    // Add protein_info to the enhancedRawPath
    enhancedRawPath.protein_info = efficacyData.protein_info;
    
    // IMPORTANT: Check if thumbnail already has edge_data that we need to preserve
    if (enhancedRawPath.edge_data && Object.keys(enhancedRawPath.edge_data).length > 0) {
      console.debug('DEBUG: Path already has edge_data from thumbnail, preserving it:', {
        edgeCount: Object.keys(enhancedRawPath.edge_data).length,
        firstEdge: Object.keys(enhancedRawPath.edge_data)[0]
      });
    }
    // If path doesn't have edge_data, retrieve it from master source
    else if (efficacyData.edge_data) {
      console.debug('DEBUG: Path has no edge_data, retrieving from efficacyData.edge_data');
      enhancedRawPath.edge_data = {};
      
      // For each pair of nodes in the path, find the corresponding edge data
      if (Array.isArray(enhancedRawPath.path)) {
        for (let j = 0; j < enhancedRawPath.path.length - 1; j++) {
          const sourceId = enhancedRawPath.path[j];
          const targetId = enhancedRawPath.path[j+1];
          const edgeKey = `${sourceId}-${targetId}`;
          const reverseEdgeKey = `${targetId}-${sourceId}`;
          
          console.debug(`DEBUG: Looking for edge data for ${edgeKey} or ${reverseEdgeKey}`);
          
          // Create a base edge data object with defaults that we'll override if we find data
          let edgeData: any = {
            source: sourceId,
            target: targetId,
            predicted: false,
            // Don't set defaults for scores - we'll add them only if we don't find them
          };
          
          let foundEdgeData = false;
          
          // Try to find edge data in efficacyData.edge_data
          if (efficacyData.edge_data[edgeKey]) {
            console.debug(`DEBUG: Found direct edge data for ${edgeKey}:`, efficacyData.edge_data[edgeKey]);
            Object.assign(edgeData, efficacyData.edge_data[edgeKey]);
            foundEdgeData = true;
          } 
          // Check for reverse direction edge
          else if (efficacyData.edge_data[reverseEdgeKey]) {
            console.debug(`DEBUG: Found reverse edge data for ${reverseEdgeKey}:`, efficacyData.edge_data[reverseEdgeKey]);
            // Create a copy with source/target swapped
            const reverseData = { ...efficacyData.edge_data[reverseEdgeKey], source: sourceId, target: targetId };
            Object.assign(edgeData, reverseData);
            foundEdgeData = true;
          }
          
          // If we still haven't found edge data, check in other paths
          if (!foundEdgeData) {
            for (let k = 0; k < efficacyData.interrupted_paths.length; k++) {
              if (k === i) continue; // Skip current path
              
              const otherPath = efficacyData.interrupted_paths[k];
              if (otherPath.edge_data && otherPath.edge_data[edgeKey]) {
                console.debug(`DEBUG: Found edge data in path ${k} for ${edgeKey}:`, otherPath.edge_data[edgeKey]);
                Object.assign(edgeData, otherPath.edge_data[edgeKey]);
                foundEdgeData = true;
                break;
              } else if (otherPath.edge_data && otherPath.edge_data[reverseEdgeKey]) {
                console.debug(`DEBUG: Found reverse edge data in path ${k} for ${reverseEdgeKey}:`, otherPath.edge_data[reverseEdgeKey]);
                const reverseData = { ...otherPath.edge_data[reverseEdgeKey], source: sourceId, target: targetId };
                Object.assign(edgeData, reverseData);
                foundEdgeData = true;
                break;
              }
            }
          }
          
          // As a last resort, check if the edge data is in another part of the JSON structure
          if (!foundEdgeData && efficacyData.relationships) {
            if (efficacyData.relationships[edgeKey]) {
              console.debug(`DEBUG: Found edge data in relationships for ${edgeKey}:`, efficacyData.relationships[edgeKey]);
              Object.assign(edgeData, efficacyData.relationships[edgeKey]);
              foundEdgeData = true;
            } else if (efficacyData.relationships[reverseEdgeKey]) {
              console.debug(`DEBUG: Found reverse edge data in relationships for ${reverseEdgeKey}:`, efficacyData.relationships[reverseEdgeKey]);
              const reverseData = { ...efficacyData.relationships[reverseEdgeKey], source: sourceId, target: targetId };
              Object.assign(edgeData, reverseData);
              foundEdgeData = true;
            }
          }
          
          // Only use defaults as absolute last resort
          if (!foundEdgeData) {
            console.debug(`DEBUG: No edge data found for ${edgeKey}, using defaults`);
            edgeData.relation = 0.7;
            edgeData.string_relation = 0.7;
          }
          
          // Make sure we have the right relation type
          if (foundEdgeData) {
            // Make sure relation is set correctly
            if (edgeData.predicted === true) {
              if (typeof edgeData.ai_relation === 'number' && typeof edgeData.relation !== 'number') {
                edgeData.relation = edgeData.ai_relation;
                console.debug(`DEBUG: Setting relation to ai_relation: ${edgeData.ai_relation}`);
              }
            } else if (typeof edgeData.string_relation === 'number' && typeof edgeData.relation !== 'number') {
              edgeData.relation = edgeData.string_relation;
              console.debug(`DEBUG: Setting relation to string_relation: ${edgeData.string_relation}`);
            }
          }
          
          // Store the processed edge data
          enhancedRawPath.edge_data[edgeKey] = edgeData;
          console.debug(`DEBUG: Final edge data for ${edgeKey}:`, edgeData);
        }
      }
    } else {
      console.debug('DEBUG: No source of edge data found');
    }
    
    // Add relationships from efficacyData if they exist (for backward compatibility)
    if (efficacyData.relationships) {
      enhancedRawPath.relationships = efficacyData.relationships;
      console.debug('DEBUG: Added relationships from efficacyData to path');
    }
    
    // CRITICAL: Skip processPathData, as it's destroying our carefully prepared edge data
    console.debug('DEBUG: Enhanced path ready, applying DIRECTLY to selectedPath state');
    console.debug('DEBUG: Enhanced path with edge data:', {
      pathLength: enhancedRawPath.path?.length || 0,
      hasEdgeData: !!enhancedRawPath.edge_data,
      edgeDataKeys: Object.keys(enhancedRawPath.edge_data || {}).length
    });
    
    // Update state with the enhanced path
    setSelectedPath(enhancedRawPath);
  }

  // Add an effect that runs when selectedPath changes
  useEffect(() => {
    // No action if we don't have a valid reference or not ready to render
    if (!selectedPathContainerRef.current || !selectedPath?.endpoint) return;
    
    // Get the current state of the container
    const container = selectedPathContainerRef.current;
    
    // Instead of scrolling to a specific position, ensure the container is in view
    // This way the user remains wherever they are in the page as long as the container is visible
    const containerRect = container.getBoundingClientRect();
    const isVisible = 
      containerRect.top >= 0 && 
      containerRect.bottom <= window.innerHeight;
      
    // Only scroll if the container is not fully visible
    if (!isVisible) {
      // Keep the current horizontal scroll position
      const currentHorizontalScroll = window.pageXOffset;
      
      // Calculate how far to scroll to make it visible but maintain relative position
      // This approach keeps the user close to where they were, just ensuring the content is visible
      const scrollAdjustment = containerRect.top < 0 
        ? containerRect.top - 20 // Scroll up if container is above viewport
        : containerRect.bottom - window.innerHeight + 20; // Scroll down if container is below viewport
        
      window.scrollBy({
        top: scrollAdjustment,
        left: 0,
        behavior: 'auto'
      });
    }
  }, [selectedPath]);

  async function getDataImpl() {
    console.log("DEBUG: Checking for precomputed results in project data:", projectData);
    
    // First, try to get precomputed results from project data
    if (projectData?.validation_results || projectData?.frontend_data) {
      const precomputedResults = projectData?.validation_results || projectData?.frontend_data;
      console.log("DEBUG: Found precomputed results:", precomputedResults);
      
      // Check if the precomputed results include a tissue type
      const precomputedTissueType = (precomputedResults as any)?.tissue_type;
      
      // TISSUE TYPE MATCHING: Only use precomputed results if either:
      // 1. No selectedTissueType (user hasn't chosen yet), or
      // 2. selectedTissueType matches the precomputed tissue type
      if (!selectedTissueType || selectedTissueType === precomputedTissueType) {
        console.log(`DEBUG: Using precomputed results (tissue type: ${precomputedTissueType})`);
        return precomputedResults;
      } else {
        console.log(`DEBUG: Not using precomputed results because tissue types don't match (selected: ${selectedTissueType}, precomputed: ${precomputedTissueType})`);
      }
    }
    
    // If we didn't return precomputed results, fetch new data from API
    console.log("DEBUG: Fetching data from API...");
    
    // Verify required data is available
    const accessToken = await getAccessTokenSilently();
    if (!disease || !nodeId || !modelId) {
      console.error("DEBUG: Missing required data for API call:", {
        disease, nodeId, modelId
      });
      
      let missingFields = [];
      if (!disease) missingFields.push("disease");
      if (!nodeId) missingFields.push("nodeId");  
      if (!modelId) missingFields.push("modelId");
      
      toast.error(`Missing required data: ${missingFields.join(", ")}`);
      
          return Promise.reject(new Error("Missing required data for API call"));
    }
    
    // Determine tissue type to use for API call
    // IMPORTANT: Use selectedTissueType if available, otherwise use project's tissue type
    // If neither are available, use "brain_FCX" as a last resort default
    const tissueTypeForApiCall = selectedTissueType || 
                                projectData?.tissue_type || 
                                "brain_FCX";
    
    console.log("DEBUG: Making API call with:", { 
      disease, 
      nodeId, 
      aiPredIsOn, 
      aiPredCutoff, 
      projectId: projectData?._id,
      modelId,
      tissueType: tissueTypeForApiCall
    });

    try {
      const result = await targetValidationService.getScores(
          disease,
          nodeId,
          aiPredIsOn,
          aiPredCutoff,
          projectData?._id,
          accessToken,
          modelId,
          tissueTypeForApiCall // Pass determined tissue type to the service
      );
      
      console.log("DEBUG: API call successful:", result);
      return result;
    } catch (error: unknown) {
      console.error("ERROR: Failed to get scores:", error);
      
      // If the error is related to the model not being found, it might be a base model
      if (error instanceof Error && error.message.includes("model")) {
        console.warn("Error might be related to model lookup, trying to use base model ID directly");
        toast.info("Using base model for validation");
        
        try {
          return await targetValidationService.getScores(
        disease,
        nodeId,
        aiPredIsOn,
        aiPredCutoff,
        projectData?._id,
        accessToken,
            modelId,
            tissueTypeForApiCall // Pass determined tissue type to the service
    );
        } catch (secondError: unknown) {
          console.error("ERROR: Failed to get scores with base model ID:", secondError);
          toast.error("Failed to load validation data. Please try again.");
          throw secondError;
        }
      }
      
      throw error;
    }
}


  const fetchData = withServiceCallHandling(
    getDataImpl,
    (data: any) => {
        console.log("API Response (Node Process Paths):", data); // <-- Log full API response
        setLoading(false);

        // Handle redirection to existing project
        if (data && data.existing_project && data.existing_project_id) {
            console.log("DEBUG: Server found an existing project with the same parameters, redirecting to:", data.existing_project_id);
            toast.info(data.message || "Using existing project with the same parameters");
            
            // Redirect to the existing project page
            const existingProjectUrl = `${config.clientUrls.TargetValidation}/${data.existing_project_id}`;
            
            // Only redirect if we're not already on that page
            if (window.location.pathname !== existingProjectUrl) {
                console.log("DEBUG: Redirecting to existing project:", existingProjectUrl);
                history.push(existingProjectUrl);
                return;
            } else {
                console.log("DEBUG: Already on the correct project page, not redirecting");
            }
        }
        
        if (!data) {
            console.error("API returned null or empty response. No data to display.");
            toast.error("Failed to load validation data. Please try again.");
            return;
        }

        if (data.error) {
            console.error("API returned error:", data.error);
            toast.error(`Error: ${data.error}`);
            return;
        }

        // For base models, the data might be wrapped in a 'data' property
        let efficacyResult = data.data || data;
        
        // Handle direct_load responses (pre-computed results from projects)
        if (data.direct_load && data.status === "complete" && data.results) {
            console.log("DEBUG: Using directly loaded pre-computed results");
            efficacyResult = data.results;
        }
        
        // NEW MODEL RESPONSE FORMAT HANDLING
        // Check if we have the new model response format with targets
        if (efficacyResult.targets && nodeId && efficacyResult.targets[nodeId]) {
            console.log("DEBUG: Detected new model response format, transforming to match expected structure");
            
            const targetData = efficacyResult.targets[nodeId];
            
            // Create a new transformed result
            const transformedData: TargetEfficacyData = {
                disease: efficacyResult.disease || disease || "",
                model_id: efficacyResult.model_id || modelId || "",
                score: targetData.overall_score || 0,
                interrupted_paths: [],
                protein_info: {},
                unreached_endpoints: [],
                report_id: "",
                expression_results: { 
                    patient_efficacies: {}, 
                    global_score: 0 
                },
                // Add FDA approved drugs data
                fda_approved_drugs: efficacyResult.fda_approved_drugs || {}
            };
            
            // Transform paths data to interrupted_paths format
            if (targetData.paths && Array.isArray(targetData.paths)) {
                const endpointScores = targetData.endpoint_scores || [];
                const endpointScoreMap = new Map<string, number>();
                
                // Create a map of endpoint -> score for quick lookup
                endpointScores.forEach((item: {endpoint: string; score: number}) => {
                    if (item.endpoint && typeof item.score === 'number') {
                        endpointScoreMap.set(item.endpoint, item.score);
                    }
                });
                
                // Group paths by endpoint
                const pathsByEndpoint = new Map<string, Array<{path: string[]; endpoint: string; score: number}>>();
                targetData.paths.forEach((pathItem: {path: string[]; endpoint: string; score: number}) => {
                    if (pathItem.path && Array.isArray(pathItem.path) && pathItem.endpoint) {
                        if (!pathsByEndpoint.has(pathItem.endpoint)) {
                            pathsByEndpoint.set(pathItem.endpoint, []);
                        }
                        pathsByEndpoint.get(pathItem.endpoint)?.push(pathItem);
                    }
                });
                
                // Create interrupted_paths entries for each endpoint
                let pathIndex = 0;
                pathsByEndpoint.forEach((paths, endpoint) => {
                    // Sort paths by score descending
                    paths.sort((a, b) => (b.score || 0) - (a.score || 0));
                    
                    // Take only the highest scoring path for each endpoint
                    const bestPath = paths[0];
                        // Use joint endpoint score for consistent display with FDA drug targets
                    
                    if (bestPath && bestPath.path) {
                        const jointScore = endpointScoreMap.get(endpoint);
                        transformedData.interrupted_paths.push({
                            id: pathIndex++,
                            path: bestPath.path,
                            endpoint: endpoint,
                            score: jointScore !== undefined ? jointScore : bestPath.score || 0
                        });
                        
                        // Add basic protein info for each node in the path
                        if (bestPath.path && Array.isArray(bestPath.path)) {
                            bestPath.path.forEach((nodeId: string) => {
                                transformedData.protein_info[nodeId] = {
                                    preferredName: nodeId.split('.').pop() || nodeId,
                                    annotation: "",
                                    stringId: nodeId,
                                    queryIndex: 0,
                                    ncbiTaxonId: 9606,
                                    taxonName: "Homo sapiens",
                                    keywords: [],
                                    tissues: [],
                                    wikipathways: []
                                };
                            });
                        }
                    }
                });
            }
            
            console.log("DEBUG: Transformed data:", transformedData);
            efficacyResult = transformedData;
        }
        
        console.log("DEBUG: Efficacy result structure:", {
            hasInterruptedPaths: !!efficacyResult.interrupted_paths,
            pathsCount: efficacyResult.interrupted_paths ? efficacyResult.interrupted_paths.length : 0,
            hasProteinInfo: !!efficacyResult.protein_info,
            hasScore: typeof efficacyResult.score !== 'undefined',
            hasUnreachedEndpoints: !!efficacyResult.unreached_endpoints,
            hasExpressionResults: !!efficacyResult.expression_results
        });
        
        if (!efficacyResult || Object.keys(efficacyResult).length === 0) {
            console.error("No efficacy data found in API response");
            toast.error("No validation data available for this model and node");
            return;
        }

        // Create a complete efficacy result with all required properties
        const completeEfficacyResult = {
            // Default values for all required properties
            interrupted_paths: [],
            protein_info: {},
            unreached_endpoints: [],
            score: 0,
            report_id: '',
            expression_results: { 
                patient_efficacies: {}, 
                global_score: 0 
            },
            // Override with actual values from the API response
            ...efficacyResult
        };
        
        // Ensure we have all required properties
        if (!completeEfficacyResult.interrupted_paths) {
            console.warn("No interrupted_paths in efficacy data, creating empty array");
            completeEfficacyResult.interrupted_paths = [];
        }
        
        if (!completeEfficacyResult.protein_info) {
            console.warn("No protein_info in efficacy data, creating empty object");
            completeEfficacyResult.protein_info = {};
        }
        
        if (!completeEfficacyResult.unreached_endpoints) {
            console.warn("No unreached_endpoints in efficacy data, creating empty array");
            completeEfficacyResult.unreached_endpoints = [];
        }
        
        if (!completeEfficacyResult.expression_results) {
            console.warn("No expression_results in efficacy data, creating default object");
            completeEfficacyResult.expression_results = { 
                patient_efficacies: {}, 
                global_score: 0 
            };
        } else if (!completeEfficacyResult.expression_results.patient_efficacies) {
            console.warn("No patient_efficacies in expression_results, creating empty object");
            completeEfficacyResult.expression_results.patient_efficacies = {};
        }
        
        // Ensure we have a valid score
        if (typeof completeEfficacyResult.score !== 'number' || isNaN(completeEfficacyResult.score)) {
            console.warn("Invalid score in efficacy data, setting to default");
            
            // If we have interrupted paths, calculate an average score
            if (completeEfficacyResult.interrupted_paths.length > 0) {
                const pathScores = completeEfficacyResult.interrupted_paths
                    .map((path: InterruptedPath) => path.score)
                    .filter((score: number) => typeof score === 'number' && !isNaN(score));
                
                if (pathScores.length > 0) {
                    const avgScore = pathScores.reduce((sum: number, score: number) => sum + score, 0) / pathScores.length;
                    completeEfficacyResult.score = avgScore;
                } else {
                    completeEfficacyResult.score = 0;
                }
            } else {
                completeEfficacyResult.score = 0;
            }
        }
        
        // Ensure global score is valid
        if (typeof completeEfficacyResult.expression_results.global_score !== 'number' || 
            isNaN(completeEfficacyResult.expression_results.global_score)) {
            console.warn("Invalid global score in expression results, setting to default");
            completeEfficacyResult.expression_results.global_score = completeEfficacyResult.score;
        }
        
        // Set default selected path if we have paths
        if (completeEfficacyResult.interrupted_paths.length > 0 && 
            (!selectedPath || !selectedPath.endpoint || selectedPath.endpoint === '')) {
            setSelectedPath(completeEfficacyResult.interrupted_paths[0]);
        }
        
        // Enhance protein info for nodes that don't have it
        if (nodeId && !completeEfficacyResult.protein_info[nodeId]) {
            completeEfficacyResult.protein_info[nodeId] = {
                preferredName: findSelectedNodeName(nodeId, completeEfficacyResult),
                annotation: "Target node",
                stringId: nodeId,
                queryIndex: 0,
                ncbiTaxonId: 9606,
                taxonName: "Homo sapiens",
                keywords: [],
                tissues: [],
                wikipathways: []
            };
        }
        
        // Add protein info for endpoints if missing
        completeEfficacyResult.interrupted_paths.forEach((path: InterruptedPath) => {
            if (path.endpoint && !completeEfficacyResult.protein_info[path.endpoint]) {
                completeEfficacyResult.protein_info[path.endpoint] = {
                    preferredName: findSelectedNodeName(path.endpoint, completeEfficacyResult),
                    annotation: "Endpoint node",
                    stringId: path.endpoint,
                    queryIndex: 0,
                    ncbiTaxonId: 9606,
                    taxonName: "Homo sapiens",
                    keywords: [],
                    tissues: [],
                    wikipathways: []
                };
            }
        });
        
        // Check if there is edge data in the response (might be under links, edges, or relationships)
        let edgeData = efficacyResult.edges || efficacyResult.links || efficacyResult.relationships || {};
        console.log("DEBUG: Edge data from API:", edgeData);
        console.log("DEBUG: API response keys:", Object.keys(efficacyResult));
        
        // Try to find relationships in other potential locations
        if (Object.keys(edgeData).length === 0) {
          console.log("DEBUG: No direct edge data found, checking alternative locations");
          
          // Check if data is under a 'data' property
          if (efficacyResult.data && typeof efficacyResult.data === 'object') {
            console.log("DEBUG: Checking efficacyResult.data:", Object.keys(efficacyResult.data));
            const dataEdges = efficacyResult.data.edges || efficacyResult.data.links || efficacyResult.data.relationships || {};
            if (Object.keys(dataEdges).length > 0) {
              console.log("DEBUG: Found edge data in efficacyResult.data:", dataEdges);
              // Use this data instead
              edgeData = dataEdges;
            }
          }
          
          // Check if data is under a 'network' property
          if (efficacyResult.network && typeof efficacyResult.network === 'object') {
            console.log("DEBUG: Checking efficacyResult.network:", Object.keys(efficacyResult.network));
            const networkEdges = efficacyResult.network.edges || efficacyResult.network.links || {};
            if (Object.keys(networkEdges).length > 0) {
              console.log("DEBUG: Found edge data in efficacyResult.network:", networkEdges);
              // Use this data instead
              edgeData = networkEdges;
            }
          }
          
          // Check first path to see if edge data is stored there
          if (efficacyResult.interrupted_paths && 
              efficacyResult.interrupted_paths.length > 0 && 
              efficacyResult.interrupted_paths[0].edge_data) {
            console.log("DEBUG: Found edge data in first path:", efficacyResult.interrupted_paths[0].edge_data);
          }
        }
        
        // For each path in interrupted_paths, extract the edge data
        if (Array.isArray(completeEfficacyResult.interrupted_paths)) {
          console.log("DEBUG: Processing edge data for interrupted paths...");
          console.log("DEBUG: Raw edge data from API:", edgeData);
          
          completeEfficacyResult.interrupted_paths = completeEfficacyResult.interrupted_paths.map((path: InterruptedPath, pathIndex: number) => {
            // Only process array-type paths
            if (Array.isArray(path.path)) {
              console.log(`DEBUG: Processing path ${pathIndex}, length ${path.path.length}`);
              
              // Create edge_data field to store relationship information
              const pathEdgeData: { [key: string]: any } = {};
              
              // For each consecutive pair of nodes in the path
              for (let i = 0; i < path.path.length - 1; i++) {
                const sourceNode = path.path[i];
                const targetNode = path.path[i+1];
                const relationKey = `${sourceNode}-${targetNode}`;
                const reverseRelationKey = `${targetNode}-${sourceNode}`;
                
                console.log(`DEBUG: Looking for edge data for ${relationKey} or ${reverseRelationKey}`);
                
                // Create a new edge data object with safe defaults
                let edgeDataObj: any = {
                    source: sourceNode,
                    target: targetNode,
                  predicted: false, // Default to known interaction
                  relation: 0.7,    // Default relation score
                  string_relation: 0.7 // Default STRING score
                };
                
                // Look for edge data in the API response
                let foundEdgeData = null;
                if (edgeData[relationKey]) {
                  foundEdgeData = edgeData[relationKey];
                  console.log(`DEBUG: Found direct edge data for ${relationKey}:`, foundEdgeData);
                } 
                // Check for reverse direction edge
                else if (edgeData[reverseRelationKey]) {
                  foundEdgeData = edgeData[reverseRelationKey];
                  console.log(`DEBUG: Found reverse edge data for ${reverseRelationKey}:`, foundEdgeData);
                } else {
                  console.log(`DEBUG: No edge data found for ${relationKey}`);
                }
                
                if (foundEdgeData) {
                  // Copy all properties from the found edge data
                  Object.assign(edgeDataObj, foundEdgeData);
                  
                  // Enhanced edge prediction detection
                  // Check if this is an AI-predicted edge based on multiple criteria
                  let isPredicted = false;
                  
                  // 1. Check explicit predicted flag
                  if (foundEdgeData.predicted === true) {
                    isPredicted = true;
                    console.log(`DEBUG: Edge ${relationKey} marked as predicted by explicit flag`);
                  }
                  // 2. Check for ai_relation field without string_relation
                  else if (typeof foundEdgeData.ai_relation === 'number') {
                    if (typeof foundEdgeData.string_relation !== 'number' || foundEdgeData.string_relation < 0.05) {
                      isPredicted = true;
                      console.log(`DEBUG: Edge ${relationKey} marked as predicted based on ai_relation ${foundEdgeData.ai_relation} vs string_relation ${foundEdgeData.string_relation}`);
                    }
                  }
                  // 3. Check alternate field names
                  else if (foundEdgeData.model_prob !== undefined && 
                         (foundEdgeData.string_prob === undefined || foundEdgeData.string_prob < 0.05)) {
                    isPredicted = true;
                    console.log(`DEBUG: Edge ${relationKey} marked as predicted based on model_prob ${foundEdgeData.model_prob} vs string_prob ${foundEdgeData.string_prob}`);
                  }
                  
                  // Set the predicted flag
                  edgeDataObj.predicted = isPredicted;
                  
                  // Handle the relationship scores - extract from various possible field names
                  if (isPredicted) {
                    console.log(`DEBUG: Processing AI-predicted edge ${relationKey}`);
                    // For AI predicted edges, ensure we have ai_relation
                    if (typeof foundEdgeData.ai_relation === 'number') {
                      edgeDataObj.ai_relation = foundEdgeData.ai_relation;
                      console.log(`DEBUG: Using ai_relation: ${foundEdgeData.ai_relation}`);
                    } 
                    else if (typeof foundEdgeData.model_prob === 'number') {
                      edgeDataObj.ai_relation = foundEdgeData.model_prob;
                      console.log(`DEBUG: Using model_prob as ai_relation: ${foundEdgeData.model_prob}`);
                    }
                    else if (typeof foundEdgeData.prob === 'number') {
                      edgeDataObj.ai_relation = foundEdgeData.prob;
                      console.log(`DEBUG: Using prob as ai_relation: ${foundEdgeData.prob}`);
                    }
                    else if (typeof foundEdgeData.relation === 'number') {
                      edgeDataObj.ai_relation = foundEdgeData.relation;
                      console.log(`DEBUG: Using relation as ai_relation: ${foundEdgeData.relation}`);
                    }
                    else {
                      edgeDataObj.ai_relation = 0.7; // Default AI relation
                      console.log(`DEBUG: No valid AI relation found, using default: 0.7`);
                    }
                    
                    // Ensure the main relation field is populated
                    if (typeof edgeDataObj.relation !== 'number') {
                      edgeDataObj.relation = edgeDataObj.ai_relation;
                      console.log(`DEBUG: Setting main relation to ai_relation: ${edgeDataObj.ai_relation}`);
                    }
                  } else {
                    console.log(`DEBUG: Processing STRING edge ${relationKey}`);
                    // For known edges, ensure we have string_relation
                    if (typeof foundEdgeData.string_relation === 'number') {
                      edgeDataObj.string_relation = foundEdgeData.string_relation;
                      console.log(`DEBUG: Using string_relation: ${foundEdgeData.string_relation}`);
                    }
                    else if (typeof foundEdgeData.string_prob === 'number') {
                      edgeDataObj.string_relation = foundEdgeData.string_prob;
                      console.log(`DEBUG: Using string_prob as string_relation: ${foundEdgeData.string_prob}`);
                    }
                    else if (typeof foundEdgeData.prob === 'number') {
                      edgeDataObj.string_relation = foundEdgeData.prob;
                      console.log(`DEBUG: Using prob as string_relation: ${foundEdgeData.prob}`);
                    }
                    else if (typeof foundEdgeData.relation === 'number') {
                      edgeDataObj.string_relation = foundEdgeData.relation;
                      console.log(`DEBUG: Using relation as string_relation: ${foundEdgeData.relation}`);
                    }
                    else {
                      edgeDataObj.string_relation = 0.7; // Default STRING relation
                      console.log(`DEBUG: No valid STRING relation found, using default: 0.7`);
                    }
                    
                    // Ensure the main relation field is populated
                    if (typeof edgeDataObj.relation !== 'number') {
                      edgeDataObj.relation = edgeDataObj.string_relation;
                      console.log(`DEBUG: Setting main relation to string_relation: ${edgeDataObj.string_relation}`);
                    }
                  }
                }
                
                // Store the processed edge data
                pathEdgeData[relationKey] = edgeDataObj;
                console.log(`DEBUG: Final edge data for ${relationKey}:`, edgeDataObj);
              }
              
              // Add the edge_data to the path
              return {
                ...path,
                edge_data: pathEdgeData
              };
            }
            return path;
          });
          
          console.log("DEBUG: Edge data processing complete");
        }
        
        // CRITICAL FIX: We need to show the original API data in completeEfficacyResult
        if (completeEfficacyResult.interrupted_paths.length > 0) {
          console.log("DEBUG: Original API edge data:", efficacyResult.edge_data || "None");
          
          // For each path in interrupted_paths, extract the edge data directly from API
          completeEfficacyResult.interrupted_paths = completeEfficacyResult.interrupted_paths.map((path: InterruptedPath, pathIndex: number) => {
            // Only process array-type paths
            if (Array.isArray(path.path)) {
              console.log(`DEBUG: Processing API edge data for path ${pathIndex}, length ${path.path.length}`);
              
              // Create or reset edge_data field
              const pathEdgeData: { [key: string]: any } = {};
              
              // For each consecutive pair of nodes in the path
              for (let i = 0; i < path.path.length - 1; i++) {
                const sourceNode = path.path[i];
                const targetNode = path.path[i+1];
                const edgeKey = `${sourceNode}-${targetNode}`;
                const reverseEdgeKey = `${targetNode}-${sourceNode}`;
                
                console.log(`DEBUG: Looking for original API edge data for ${edgeKey} or ${reverseEdgeKey}`);
                
                // Create a new edge data object with minimal defaults
                let edgeDataObj: any = {
                    source: sourceNode,
                    target: targetNode,
                  predicted: false, // Default to known interaction
                };
                
                // Look for real edge data in API response
                let foundAPIData = false;
                
                // First look in efficacyResult.edge_data (primary API data source)
                if (efficacyResult.edge_data) {
                  if (efficacyResult.edge_data[edgeKey]) {
                    foundAPIData = true;
                    console.log(`DEBUG: Found DIRECT API edge data for ${edgeKey}:`, efficacyResult.edge_data[edgeKey]);
                    Object.assign(edgeDataObj, efficacyResult.edge_data[edgeKey]);
                  } else if (efficacyResult.edge_data[reverseEdgeKey]) {
                    foundAPIData = true;
                    console.log(`DEBUG: Found REVERSE API edge data for ${reverseEdgeKey}:`, efficacyResult.edge_data[reverseEdgeKey]);
                    // Copy and swap source/target for reverse direction
                    const reverseData = { ...efficacyResult.edge_data[reverseEdgeKey], source: sourceNode, target: targetNode };
                    Object.assign(edgeDataObj, reverseData);
                  }
                }
                
                // Also check efficacyResult.relationships as secondary source
                if (!foundAPIData && efficacyResult.relationships) {
                  if (efficacyResult.relationships[edgeKey]) {
                    foundAPIData = true;
                    console.log(`DEBUG: Found API relationship data for ${edgeKey}:`, efficacyResult.relationships[edgeKey]);
                    Object.assign(edgeDataObj, efficacyResult.relationships[edgeKey]);
                  } else if (efficacyResult.relationships[reverseEdgeKey]) {
                    foundAPIData = true;
                    console.log(`DEBUG: Found reverse API relationship data for ${reverseEdgeKey}:`, efficacyResult.relationships[reverseEdgeKey]);
                    // Copy and swap source/target for reverse direction
                    const reverseData = { ...efficacyResult.relationships[reverseEdgeKey], source: sourceNode, target: targetNode };
                    Object.assign(edgeDataObj, reverseData);
                  }
                }
                
                // Check if we found real API data
                if (foundAPIData) {
                  // First, preserve ALL original probability values
                  
                  // Set predicted flag based on data evaluation
                  if (typeof edgeDataObj.ai_relation === 'number' && 
                      (typeof edgeDataObj.string_relation !== 'number' || 
                       edgeDataObj.ai_relation > edgeDataObj.string_relation + 0.05)) {
                    
                    // CASE: AI probability is significantly higher OR string doesn't exist
                    if (typeof edgeDataObj.string_relation === 'number' && edgeDataObj.string_relation > 0.05) {
                      // We have BOTH valid probabilities - mark as hybrid
                      edgeDataObj.hybrid = true;
                      edgeDataObj.predicted = true; // AI is primary
                      console.log(`DEBUG: Path ${pathIndex} edge ${edgeKey} marked as HYBRID with ai_relation=${edgeDataObj.ai_relation} and string_relation=${edgeDataObj.string_relation}`);
                    } else {
                      // Regular AI prediction
                      edgeDataObj.hybrid = false;
                      edgeDataObj.predicted = true;
                      console.log(`DEBUG: Path ${pathIndex} edge ${edgeKey} marked as AI-predicted based on having ai_relation=${edgeDataObj.ai_relation}`);
                    }
                  } else if (typeof edgeDataObj.string_relation === 'number') {
                    // CASE: String probability is dominant or AI doesn't exist
                    edgeDataObj.hybrid = false;
                    edgeDataObj.predicted = false;
                    console.log(`DEBUG: Path ${pathIndex} edge ${edgeKey} marked as STRING edge based on having string_relation=${edgeDataObj.string_relation}`);
                  }
                  
                  // Make sure the main relation field is set based on the prediction type
                  if (edgeDataObj.predicted && typeof edgeDataObj.ai_relation === 'number' && typeof edgeDataObj.relation !== 'number') {
                    edgeDataObj.relation = edgeDataObj.ai_relation;
                    console.log(`DEBUG: Setting relation to ai_relation for path ${pathIndex} edge ${edgeKey}: ${edgeDataObj.ai_relation}`);
                  } else if (!edgeDataObj.predicted && typeof edgeDataObj.string_relation === 'number' && typeof edgeDataObj.relation !== 'number') {
                    edgeDataObj.relation = edgeDataObj.string_relation;
                    console.log(`DEBUG: Setting relation to string_relation for path ${pathIndex} edge ${edgeKey}: ${edgeDataObj.string_relation}`);
                  }
                }
                
                // Store the processed edge data
                pathEdgeData[edgeKey] = edgeDataObj;
                console.log(`DEBUG: Final API edge data for path ${pathIndex} edge ${edgeKey}:`, edgeDataObj);
              }
              
              // Add the edge_data to the path
              return {
                ...path,
                edge_data: pathEdgeData
              };
            }
            return path;
          });
          
          console.log("DEBUG: API edge data processing complete");
        }
        
        // Add edge data to efficacyData
        setEfficacyData({
          ...completeEfficacyResult,
          // Store the raw edge data from API for reference
          edge_data: efficacyResult.edge_data || {}
        });
        
        // Set the selectedNodeName based on the protein info
        if (nodeId && completeEfficacyResult.protein_info && completeEfficacyResult.protein_info[nodeId]) {
          const preferredName = completeEfficacyResult.protein_info[nodeId].preferredName;
          if (preferredName) {
            setSelectedNodeName(preferredName);
          }
        }

        // Show a success message
        toast.success("Target validation data loaded successfully");
        
        // Log information about the paths found
        if (completeEfficacyResult.interrupted_paths.length > 0) {
            console.log(`Found ${completeEfficacyResult.interrupted_paths.length} interrupted paths`);
        } else {
            console.warn("No interrupted paths found in the response");
        }
    },
    () => {
      setLoading(true);
      toast.info("Loading target validation data... This may take some time for large disease models.");
    },
    "Loading target validation data...",
    () => isMounted(),
  );

  // Add a custom error handler for API errors
  useEffect(() => {
    const handleApiError = (error: Error) => {
      setLoading(false);
      console.error("ERROR: Failed to fetch data:", error);
      
      // Check if the error message indicates a timeout or large model
      if (error && error.message && (
          error.message.includes("processing a large") || 
          error.message.includes("may take some time") ||
          error.message.includes("Please be patient")
      )) {
          // Show a more informative toast for timeouts
          toast.info(error.message, { 
              autoClose: false,
              closeOnClick: false,
              draggable: true,
              closeButton: true
          });
      } else {
          // Show a generic error message for other errors
          toast.error("Failed to load validation data. Please try again.");
      }
    };

    // Add event listener for API errors
    window.addEventListener('api-error', (e: any) => {
      if (e.detail && e.detail.error) {
        handleApiError(e.detail.error);
      }
    });

    return () => {
      // Remove event listener on cleanup
      window.removeEventListener('api-error', (e: any) => {
        if (e.detail && e.detail.error) {
          handleApiError(e.detail.error);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (!nodeId || !disease || !modelId) {
      console.warn("Missing required data for API call:", { nodeId, disease, modelId });
      
      // If we have a projectId but missing other data, try to use the project data
      if (projectId && projectData) {
        console.log("DEBUG: We have projectId and projectData, but missing required fields");
        console.log("DEBUG: ProjectData:", projectData);
        
        // Try to use the project data we have
        const useDisease = disease || projectData.disease || (projectData.model && projectData.model.disease);
        const useNodeId = nodeId || projectData.nodeId || projectData.node_id;
        const useModelId = modelId || projectData.model_id || (projectData.model && projectData.model._id);
        const useTissueType = selectedTissueType || projectData.tissue_type;
        
        if (useDisease && useNodeId && useModelId) {
          console.log("DEBUG: Using project data fields for API call:", { 
            disease: useDisease, 
            nodeId: useNodeId, 
            modelId: useModelId,
            tissueType: useTissueType
          });
          
          // Call fetchData directly with the values from project data
          try {
            console.log("DEBUG: Calling fetchData with project data fields");
            // We'll use the values directly in the getDataImpl function
            // instead of trying to update the state variables
            fetchData();
          } catch (error) {
            console.error("ERROR: Failed to fetch data with project data fields:", error);
          }
          return;
        } else {
          console.error("ERROR: Still missing required data after using project data fields:", { 
            useDisease, 
            useNodeId, 
            useModelId,
            useTissueType
          });
          toast.error("Missing required data for validation. Please select a node in the graph view.");
          return;
        }
      }
      
      return;
    }
    console.log("Fetching data with:", { nodeId, disease, modelId });
    fetchData();
  }, [disease, nodeId, modelId, projectData]);

  // After efficacyData state updates, update selectedNodeName if needed
  useEffect(() => {
    if (efficacyData && nodeId && efficacyData.protein_info && efficacyData.protein_info[nodeId]) {
      const preferredName = efficacyData.protein_info[nodeId].preferredName;
      if (preferredName) {
        setSelectedNodeName(preferredName);
      }
    }
  }, [efficacyData, nodeId]);

  async function getReportImpl(projectId: string) {
    const accessToken = await getAccessTokenSilently();
    return reportService.getReport(projectId, accessToken);
  }
  const handleRequestReport = withServiceCallHandling(
    getReportImpl,
    (data, blob) => {
      if (!isSandboxUser) saveAs(blob, 'report.csv');
    },
    undefined,
    false,
    () => isMounted(),
  );

  // Replace the handleForceRefresh function to be empty (we're removing this button)
  const handleForceRefresh = () => {
    // Intentionally empty - button will be removed
    console.log("Refresh button clicked but is now disabled");
  };

  if (initialLoading || validationInProgress) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '70vh',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '800px',
        mx: 'auto',
        p: 3
      }}>
        {/* Main loading message */}
        <Typography variant="h5" color="primary" sx={{ 
          mb: 4, 
          fontWeight: 500,
          textAlign: 'center'
        }}>
          {validationInProgress 
            ? "Target Validation Analysis in Progress" 
            : "Loading Target Validation Results"}
        </Typography>

        {/* Animated progress card */}
        <Box sx={{ 
          width: '100%', 
          bgcolor: 'background.paper', 
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          p: 4,
          border: '1px solid rgba(0, 0, 0, 0.05)',
          position: 'relative',
          overflow: 'hidden'
        }}>
          {/* Circular progress for indeterminate loading */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <CircularProgress size={60} thickness={4} sx={{ color: '#2196f3' }} />
          </Box>

          {/* Status message */}
          <Typography variant="body1" sx={{ 
            fontWeight: 400, 
            mb: 3,
            textAlign: 'center',
            color: 'text.primary'
          }}>
            {validationInProgress
              ? "Analyzing protein pathways and calculating target efficacy scores..."
              : "Finalizing results and preparing visualization..."}
          </Typography>

          {/* Information box */}
          <Box sx={{ 
            bgcolor: 'rgba(33, 150, 243, 0.05)', 
            p: 2.5, 
            borderRadius: 2,
            border: '1px solid rgba(33, 150, 243, 0.1)',
            mb: 2
          }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
              <InfoIcon sx={{ color: 'info.main', mr: 1.5, mt: 0.25 }} fontSize="small" />
              <Typography variant="subtitle2" color="text.primary" sx={{ fontWeight: 500 }}>
                Almost there! Finalizing your results
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
              {validationInProgress 
                ? "The system is analyzing complex biological pathways to determine the most effective targets for the selected disease model."
                : "We're preparing the comprehensive target validation analysis results for your review."}
              <br />
              <strong>The page will update automatically when complete.</strong>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  if (!projectData || !nodeId || !disease) {
    console.warn("Missing project data, nodeId, or disease. Rendering fallback UI.");
    console.log("DEBUG: Current state:", {
      projectId,
      projectData: projectData ? JSON.stringify(projectData) : "null",
      nodeId,
      disease,
      modelId,
      initialLoading,
      loading,
      dataFetchAttempted,
      initProjectData: initProjectData ? "provided" : "not provided"
    });
    
    // Only show fallback UI if we're not in initial loading and not in regular loading
    // And either we've attempted to fetch data or we're not getting data from props
    if (!initialLoading && !loading && (dataFetchAttempted || !initProjectData)) {
      return (
        <Box sx={{ p: 4, textAlign: 'center', maxWidth: '600px', margin: '0 auto', mt: 4 }}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Typography variant="h5" color="error" gutterBottom>
              No data available for this model
            </Typography>
            
            <Typography variant="body1" sx={{ mb: 2 }}>
              Missing required information: {!projectData ? 'Project data' : ''} {!nodeId ? 'Node ID' : ''} {!disease ? 'Disease' : ''}
            </Typography>
            
            <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 3 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Possible reasons for this error:</strong>
              </Typography>
              <ul style={{ textAlign: 'left' }}>
                <li>The project ID ({projectId}) may not exist or you may not have access to it</li>
                <li>The model associated with this project (ID: {modelId || (projectData && projectData.model_id) || "unknown"}) may be a base model that requires special handling</li>
                <li>You haven&apos;t selected a node to validate in the graph view</li>
                <li>The project data is incomplete or corrupted</li>
              </ul>
            </Box>
            
            <Box sx={{ bgcolor: '#e8f4ff', p: 2, borderRadius: 1, mb: 3 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Troubleshooting steps:</strong>
              </Typography>
              <ol style={{ textAlign: 'left' }}>
                <li>Return to the graph view and select a node to validate</li>
                <li>If using a base model or mock disease model, ensure it has all required fields</li>
                <li>Try creating a new project with the same model</li>
                <li>Check the browser console for more detailed error messages</li>
              </ol>
            </Box>
            
            <Typography variant="body2" sx={{ mb: 3 }}>
              Please return to the BioTarget Scoring page and create a new project with a valid model.
            </Typography>
            
            <Box mt={4} textAlign="center">
            <Button 
              variant="contained" 
              color="primary"
              onClick={() => history.push(config.clientUrls.TargetValidation)}
            >
                Return to BioTarget Scoring
            </Button>
            </Box>
          </Paper>
        </Box>
      );
    }
    
    // Show loading state instead of fallback UI if we're still loading
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '70vh',
        flexDirection: 'column',
        gap: 3
      }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          {validationInProgress ? 
            "Validation analysis in progress..." :
            "Loading target validation data..."}
        </Typography>
        {/* Add refresh button */}
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleForceRefresh}
          startIcon={<DehazeIcon />}
          sx={{ mt: 2 }}
        >
          Refresh Results
        </Button>
        <Typography variant="body2" sx={{ mt: 1 }}>
          This might take a few minutes, please be patient.
        </Typography>
        {validationInProgress && (
          <Box sx={{ 
            maxWidth: 500, 
            textAlign: 'center',
            p: 2,
            bgcolor: 'rgba(255, 255, 210, 0.2)',
            borderRadius: 2,
            border: '1px solid rgba(245, 245, 160, 0.5)',
          }}>
            <Typography variant="body2" color="text.secondary">
              The system is analyzing pathways and calculating efficacy scores. 
              This process may take several minutes depending on the complexity of the model.
              <br /><br />
              <strong>The page will update automatically when complete.</strong>
            </Typography>
          </Box>
        )}
        {pollingAttempts > 0 && (
          <Typography variant="body2" color="text.secondary">
            Time elapsed: {Math.floor(pollingAttempts * POLLING_INTERVAL / 60000)} min {Math.floor((pollingAttempts * POLLING_INTERVAL % 60000) / 1000)} sec
          </Typography>
        )}
      </Box>
    );
  }

  if (!efficacyData || !efficacyData.interrupted_paths) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '70vh',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '800px',
        mx: 'auto',
        p: 3
      }}>
        {/* Main loading message */}
        <Typography variant="h5" color="primary" sx={{ 
          mb: 4, 
          fontWeight: 500,
          textAlign: 'center'
        }}>
          {validationInProgress 
            ? "Target Validation Analysis in Progress" 
            : "Loading Target Validation Results"}
        </Typography>

        {/* Animated progress card */}
        <Box sx={{ 
          width: '100%', 
          bgcolor: 'background.paper', 
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          p: 4,
          border: '1px solid rgba(0, 0, 0, 0.05)',
          position: 'relative',
          overflow: 'hidden'
        }}>
          {/* Circular progress for indeterminate loading */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <CircularProgress size={60} thickness={4} sx={{ color: '#2196f3' }} />
          </Box>

          {/* Status message */}
          <Typography variant="body1" sx={{ 
            fontWeight: 400, 
            mb: 3,
            textAlign: 'center',
            color: 'text.primary'
          }}>
            {validationInProgress
              ? "Analyzing protein pathways and calculating target efficacy scores..."
              : "Finalizing results and preparing visualization..."}
          </Typography>

          {/* Information box */}
          <Box sx={{ 
            bgcolor: 'rgba(33, 150, 243, 0.05)', 
            p: 2.5, 
            borderRadius: 2,
            border: '1px solid rgba(33, 150, 243, 0.1)',
            mb: 2
          }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
              <InfoIcon sx={{ color: 'info.main', mr: 1.5, mt: 0.25 }} fontSize="small" />
              <Typography variant="subtitle2" color="text.primary" sx={{ fontWeight: 500 }}>
                Almost there! Finalizing your results
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
              {validationInProgress 
                ? "The system is analyzing complex biological pathways to determine the most effective targets for the selected disease model."
                : "We're preparing the comprehensive target validation analysis results for your review."}
              <br />
              <strong>The page will update automatically when complete.</strong>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  const hasInterruptedPaths = efficacyData && efficacyData.interrupted_paths && efficacyData.interrupted_paths.length > 0;
  
  const handleThumbnailClick = (item: any, index: number) => {
    // Save current scroll position before changing the selected path
    if (pathsListRef.current) {
      const currentPosition = pathsListRef.current.scrollTop;
      sessionStorage.setItem('pathsScrollPosition', currentPosition.toString());
      
      // Update the selected path
      setSelectedPathIndex(index);
      setSelectedPath(item);
      
      // Restore scroll position after state update
      requestAnimationFrame(() => {
        if (pathsListRef.current) {
          pathsListRef.current.scrollTop = currentPosition;
        }
      });
    } else {
      // Fallback if the ref is not available
      setSelectedPathIndex(index);
      setSelectedPath(item);
    }
  };

  // Create a simple thumbnail path graph component
  const ThumbnailPathGraph = ({ path, selected }: { path: any, selected: boolean }) => {
    // Calculate positions for nodes in the path
    const nodeCount = path.path ? path.path.length : 0;
    if (nodeCount === 0) return null;
    
    // Use ref to measure container width
    const containerRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 200, height: 70 });
    
    // Update dimensions when container size changes
    useEffect(() => {
      const updateDimensions = () => {
        if (containerRef.current) {
          const { width } = containerRef.current.getBoundingClientRect();
          setDimensions({ width, height: 70 });
        }
      };
      
      // Initial measurement
      updateDimensions();
      
      // Add resize listener
      window.addEventListener('resize', updateDimensions);
      
      // Cleanup
      return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    
    const { width, height } = dimensions;
    const margin = 20;
    const nodeSize = 12;
    const availableWidth = width - 2 * margin;
    const spacing = nodeCount > 1 ? availableWidth / (nodeCount - 1) : 0;
    
    // Create node elements
    const nodes = path.path.map((nodeId: string, i: number) => {
      const x = margin + i * spacing;
      const y = height / 2;
      
      // Get protein info if available
      const proteinInfo = efficacyData?.protein_info?.[nodeId];
      const nodeName = proteinInfo?.preferredName || nodeId.split('.').pop() || nodeId;
      
      // Determine if this is the target or endpoint
      const isTarget = i === 0;
      const isEndpoint = i === nodeCount - 1;
      
      return (
        <div 
          key={`node-${i}`}
          style={{
            position: 'absolute',
            left: `${x}px`,
            top: `${y}px`,
            width: `${nodeSize}px`,
            height: `${nodeSize}px`,
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: isTarget ? '#2b8a3e' : isEndpoint ? '#e03131' : '#339af0',
            border: '2px solid #fff',
            boxShadow: '0 0 4px rgba(0,0,0,0.3)',
            zIndex: 2
          }}
          title={nodeName}
        />
      );
    });
    
    // Create edge elements
    const edges = [];
    for (let i = 0; i < nodeCount - 1; i++) {
      const source = path.path[i];
      const target = path.path[i + 1];
      const x1 = margin + i * spacing;
      const x2 = margin + (i + 1) * spacing;
      const y = height / 2;
      
      // Get edge data if available
      const edgeKey = `${source}-${target}`;
      const edgeData = efficacyData?.edge_data?.[edgeKey];
      
      // Determine edge color based on type
      let color = '#adb5bd'; // Default color
      if (edgeData?.predicted) {
        color = '#ff922b'; // AI edge (orange)
      } else if (edgeData && !edgeData.predicted) {
        color = '#74c0fc'; // STRING edge (blue)
      }
      
      edges.push(
        <div 
          key={`edge-${i}`}
          style={{
            position: 'absolute',
            left: `${x1}px`,
            top: `${y}px`,
            width: `${x2 - x1}px`,
            height: '2px',
            backgroundColor: color,
            zIndex: 1
          }}
        />
      );
    }
    
    return (
      <div 
        ref={containerRef}
        style={{ 
          position: 'relative', 
          width: '100%', 
          height: `${height}px`,
          border: selected ? '2px solid #4c6ef5' : '1px solid #dee2e6',
          borderRadius: '8px',
          backgroundColor: '#f8f9fa',
          padding: '4px',
          overflow: 'hidden'
        }}
      >
        {edges}
        {nodes}
      </div>
    );
  };

  const gridItems = hasInterruptedPaths ? efficacyData.interrupted_paths.map((item: any, index: number) => (
    <Grid item xs={12} md={12} key={`path-${index}`}>
      <Paper 
      className={`smallPathContainer ${selectedPathIndex === index ? 'selected-path' : ''}`} 
        sx={{ 
          p: 2.5, 
          borderRadius: 2,
          cursor: 'pointer',
          transition: 'all 0.2s',
          border: selectedPathIndex === index ? '2px solid #4c6ef5' : '1px solid #dee2e6',
          '&:hover': {
            boxShadow: 3
          },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          mb: 2
        }}
      onClick={() => handleThumbnailClick(item, index)}
    >
        <Typography variant="subtitle2" sx={{ mb: 1.5, fontWeight: 'bold' }}>
          {item.endpoint 
            ? `Path ${item.path_rank !== undefined ? (item.path_rank + 1) : index + 1} to ${efficacyData?.protein_info?.[item.endpoint]?.preferredName || item.endpoint.split('.').pop() || item.endpoint}` 
            : `Path ${index + 1}`}
          {item.is_best_path && (
            <Tooltip title="Highest scoring path for this specific disease progression node" arrow>
              <span style={{ 
                marginLeft: '5px', 
                color: '#2e7d32', // Green color for better visibility
                fontSize: '0.8rem',
                backgroundColor: 'rgba(46, 125, 50, 0.1)', // Light green background
                padding: '2px 4px',
                borderRadius: '4px',
                fontWeight: 'bold'
              }}>
                (Best)
              </span>
            </Tooltip>
          )}
        </Typography>
        
        <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Chip 
            label={`Score: ${typeof item.path_score === 'number' ? item.path_score.toFixed(2) : (typeof item.score === 'number' ? item.score.toFixed(2) : 'N/A')}`}
            size="small"
            color={selectedPathIndex === index ? "secondary" : "default"}
          />
          <Typography variant="caption" color="text.secondary">
            {item.path ? `${item.path.length} nodes` : ''}
          </Typography>
        </Box>
        
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
        <ThumbnailPathGraph path={item} selected={selectedPathIndex === index} />
        </Box>
      </Paper>
    </Grid>
  )) : [];

  interface TopOverlayProps {
    projectData?: any;
    modelId?: string;
    disease?: string;
    nodeId?: string;
    selectedNodeName?: string;
  }

  const TopOverlay = ({ projectData, modelId, disease, nodeId, selectedNodeName }: TopOverlayProps) => {
    const history = useHistory();
    
    // Get the correct disease name from available sources
    const getDiseaseName = () => {
      // First try the disease prop
      if (disease) return disease_name_readable(disease);
      
      // Then try projectData.disease
      if (projectData?.disease) return disease_name_readable(projectData.disease);
      
      // Finally try projectData.model.disease
      if (projectData?.model?.disease) return disease_name_readable(projectData.model.disease);
      
      // Default fallback
      return "Unknown Disease";
    };

    return (
      <Box className="project-details-card">
        <Box>
          <Typography className="project-title">{projectData?.name || 'BioTarget Scoring Results'}</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
            <Box className="project-details-tag" sx={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faBriefcase} />
              <Typography className="project-details-label">{getDiseaseName()}</Typography>
            </Box>
            <Box className="project-details-tag" sx={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faFileMedical} />
              <Typography className="project-details-label">BioTarget Scoring: {selectedNodeName || nodeId || 'Unknown Target'}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button
            variant="outlined"
            className="action-button"
            startIcon={<MenuBookIcon />}
            onClick={() => setGlossaryModalOpen(true)}
            size="small"
          >
            View Glossary
          </Button>
          <Button
            variant="outlined"
            className="action-button"
            startIcon={<PictureAsPdfIcon />}
            onClick={() => {
              // Make a safe copy of the score with proper null checking
              const score = efficacyData && typeof efficacyData.score === 'number' ? 
                efficacyData.score.toFixed(SCORES_DECIMAL) : '0.000';
              
              // Get endpoint names
              const endpointNames = efficacyData?.interrupted_paths
                ? (() => {
                    // Create a map of unique endpoints 
                    const uniqueEndpoints = new Map<string, string>();
                    
                    efficacyData.interrupted_paths.forEach((path: InterruptedPath) => {
                    const endpoint = path.endpoint;
                      const displayName = efficacyData?.protein_info?.[endpoint]?.preferredName || 
                        (endpoint.includes('.') ? endpoint.split('.').pop() || endpoint : endpoint);
                        
                      if (!uniqueEndpoints.has(endpoint)) {
                        uniqueEndpoints.set(endpoint, displayName);
                      }
                    });
                    
                    return Array.from(uniqueEndpoints.values());
                  })()
                : [];
              
              createUpdatedPdfReport(
                projectData?.name || 'Unknown Project',
                aiPredCutoff || 0,
                selectedNodeName || 'Unknown Target',
                nodeId || 'Unknown Node ID',
                disease ? disease_name_readable(disease) : 'Unknown Disease',
                efficacyData?.interrupted_paths || [],
                endpointNames.map((name: string) => String(name)),
                score,
                efficacyData?.protein_info || {},
                efficacyData?.unreached_endpoints || [],
                projectData?.model?.name || 'Unknown Model',
                'version 1',
                isSandboxUser,
                fdaApprovedDrugsData,
                selectedTissueType
              );
            }}
            size="small"
          >
            Download PDF Report
          </Button>
          <Button
            startIcon={<ArrowBackIcon />}
            className="secondary-action-button"
            onClick={() => history.push(config.clientUrls.TargetValidation)}
            sx={{ height: 'fit-content' }}
          >
            Return to BioTarget Scoring
          </Button>
        </Box>
      </Box>
    );
  };


  interface InterruptedPathwaysSectionInterface {
    effScore: number;
    gridItems: any;
    nodeId: string;
  }

  function InterruptedPathwaysSection({ effScore, gridItems, nodeId }: InterruptedPathwaysSectionInterface) {
    // Ensure effScore is a number before calling toFixed
    const formattedEffScore = typeof effScore === 'number' ? effScore.toFixed(SCORES_DECIMAL) : '0.000';
    
    // Check if we have any interrupted paths to display
    const hasInterruptedPaths = Array.isArray(gridItems) && gridItems.length > 0;
    
    // Add state for showing/hiding the legend
    const [showLegend, setShowLegend] = useState(true);
    
    // Load saved scroll position on initial render
    useEffect(() => {
      if (!initialScrollRestored.current && pathsListRef.current && efficacyData?.interrupted_paths && efficacyData.interrupted_paths.length > 0) {
        const savedScrollPosition = sessionStorage.getItem('pathsScrollPosition');
        if (savedScrollPosition) {
          // Wait for the UI to fully render before setting scroll
          setTimeout(() => {
            if (pathsListRef.current) {
              pathsListRef.current.scrollTop = parseInt(savedScrollPosition, 10);
              initialScrollRestored.current = true;
            }
          }, 50);
        }
      }
    }, [efficacyData]);
    
    // Save scroll position when scrolling - debounced for performance
    const handleScroll = useCallback(() => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      
      scrollTimeoutRef.current = setTimeout(() => {
        if (pathsListRef.current) {
          sessionStorage.setItem('pathsScrollPosition', pathsListRef.current.scrollTop.toString());
        }
      }, 100);
    }, []);
    
    // Clean up timeout on unmount
    useEffect(() => {
      return () => {
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
      };
    }, []);
    
    return (
      <Box>
        <div className="titlesection" style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '8px' }}>Canonical pathways included</p>
          
          {/* Info icon with tooltip about disease progression nodes */}
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
                  Disease Progression Node
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
                  A node representing the end of a canonical pathway of a disease. In a cascade of biological 
                  interactions, a disease progression node is the closest node (i.e. directly pointing to) 
                  to disease phenotypes.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, lineHeight: 1.5, fontStyle: 'italic' }}>
                  Multiple paths may exist for each disease progression node. The highest-scoring path for each node is marked as <span style={{ fontWeight: 'bold' }}>Best</span>.
                </Typography>
              </Box>
            }
            arrow
            placement="right"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                backgroundColor: '#e3f2fd',
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  background: 'rgba(33, 150, 243, 0.3)',
                  animation: 'pulse 2s infinite',
                },
                '@keyframes pulse': {
                  '0%': {
                    transform: 'scale(0.95)',
                    opacity: 0.7,
                  },
                  '70%': {
                    transform: 'scale(1.1)',
                    opacity: 0.3,
                  },
                  '100%': {
                    transform: 'scale(0.95)',
                    opacity: 0.7,
                  },
                },
              }}
            >
              <InfoIcon 
                sx={{ 
                  color: '#1565c0', // Darker blue color
                  fontSize: '18px',
                  cursor: 'help',
                  zIndex: 1,
                  '&:hover': { 
                    color: '#0d47a1',
                  },
                }} 
              />
            </Box>
          </Tooltip>
        </div>
        
        <Grid container spacing={3}>
          {/* Paths list panel */}
          <Grid item xs={12} md={4}>
            <Paper 
              className="roundedBorderContainer"
              sx={{ height: '570px', display: 'flex', flexDirection: 'column' }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 600, color: 'var(--primary-dark)', mb: 1 }}>
                  Pathways Overview
                </Typography>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Overall Score: 
                  </Typography>
                  
                  <Chip 
                    label={formattedEffScore}
                    sx={{ 
                      fontWeight: 'bold',
                      background: 'linear-gradient(90deg, var(--primary-light) 0%, var(--primary-color) 100%)',
                      color: 'white'
                    }}
                  />
                </Box>
                
                <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', fontSize: '0.85rem' }}>
                  Multiple paths may exist for each disease progression node. Paths are numbered and the best scoring path for each node is highlighted.
                </Typography>
              </Box>
              
              <Box sx={{ flex: 1, overflow: 'hidden' }}>
                <div 
                  ref={pathsListRef}
                  className="pathsList" 
                  style={{ 
                    overflowY: 'auto', 
                    maxHeight: '350px',
                    marginTop: '20px',
                    width: '100%',
                    padding: '0 10px'
                  }}
                  onScroll={handleScroll}
                >
                  {hasInterruptedPaths ? (
                    <Grid container spacing={3} sx={{ width: '100%', m: 0 }}>
                      {gridItems}
                    </Grid>
                  ) : (
                    <Box sx={{ 
                      p: 4, 
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%'
                    }}>
                      <BlockIcon sx={{ fontSize: 48, color: 'text.disabled', mb: 2 }} />
                      <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
                        No interrupted pathways found for this target.
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        This may indicate that the selected target has no significant impact on the disease endpoints.
                      </Typography>
                    </Box>
                  )}
                </div>
              </Box>
            </Paper>
          </Grid>
          
          {/* Main graph display area */}
          {hasInterruptedPaths && (
            <Grid item xs={12} md={8}>
              <Paper 
                className="roundedBorderContainer" 
                ref={selectedPathContainerRef}
                sx={{ 
                  height: '570px', 
                  display: 'flex', 
                  flexDirection: 'column',
                  width: '100%' // Ensure full width of grid cell
                }}
              >
                <Box sx={{ 
                  p: 2, 
                  borderBottom: '1px solid var(--border-color)', 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600, color: 'var(--primary-dark)' }}>
                      {selectedPath?.path_rank !== undefined 
                        ? `Path ${selectedPath.path_rank + 1} to ${efficacyData?.protein_info[selectedPath?.endpoint]?.preferredName || selectedPath?.endpoint}`
                        : `Path to ${efficacyData?.protein_info[selectedPath?.endpoint]?.preferredName || selectedPath?.endpoint}`
                      }
                      {selectedPath?.is_best_path && (
                        <Tooltip title="Highest scoring path for this specific disease progression node" arrow>
                          <span style={{ 
                            marginLeft: '8px', 
                            color: '#2e7d32', 
                            fontSize: '0.9rem',
                            backgroundColor: 'rgba(46, 125, 50, 0.1)', 
                            padding: '2px 6px',
                            borderRadius: '4px',
                            fontWeight: 'bold'
                          }}>
                            BEST PATH
                          </span>
                        </Tooltip>
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Interactive pathway visualization from target to endpoint
                    </Typography>
                  </Box>
                  
                  <Chip 
                    label={`Joint path score for ${efficacyData?.protein_info[selectedPath?.endpoint]?.preferredName || selectedPath?.endpoint}: ${(selectedPath?.score ?? 0).toFixed(SCORES_DECIMAL)}`} 
                    color="success"
                    sx={{ 
                      fontWeight: 'bold',
                      bgcolor: '#10b981',
                      '& .MuiChip-label': { px: 2 }
                    }}
                  />
                </Box>

                <Box sx={{ 
                  flex: 1, 
                  position: 'relative',
                  bgcolor: 'rgba(245, 247, 250, 0.5)',
                  border: '1px solid var(--border-color)',
                  borderTop: 'none',
                  borderBottomLeftRadius: 'var(--card-radius)',
                  borderBottomRightRadius: 'var(--card-radius)',
                  overflow: 'hidden',
                  width: '100%', // Ensure full width
                  '& .pathway-graph-container': {
                    width: '100% !important',
                    maxWidth: '100%'
                  },
                  '& .pathway-graph-container > svg': {
                    width: '100% !important',
                    maxWidth: '100%'
                  }
                }}>
                  {displaySelectedPath()}
                  
                  {/* Toggle button for showing/hiding the legend */}
                  <Box 
                    sx={{ 
                      position: 'absolute',
                      bottom: showLegend ? 70 : 16,
                      right: 16,
                      zIndex: 10
                    }}
                  >
                    <Tooltip title={showLegend ? "Hide Legend" : "Show Legend"}>
                      <IconButton 
                        size="small"
                        onClick={() => setShowLegend(!showLegend)}
                        sx={{ 
                          backgroundColor: 'white',
                          border: '1px solid var(--border-color)',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          '&:hover': {
                            backgroundColor: 'rgba(245, 247, 250, 0.9)',
                          }
                        }}
                      >
                        {showLegend ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                  
                  {/* Compact inline legend within the graph container */}
                  {showLegend && (
                    <Box sx={{ 
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      gap: 2,
                      p: 1.5,
                      position: 'absolute',
                      bottom: 16,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      maxWidth: '90%',
                      borderRadius: '8px',
                      backgroundColor: 'rgba(255, 255, 255, 0.95)',
                      border: '1px solid var(--border-color)',
                      fontSize: '0.75rem',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                      zIndex: 0,
                      transition: 'opacity 0.2s ease-in-out'
                    }}>
                      {/* Node types section */}
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#4caf50', mr: 1 }}></Box>
                          <Typography variant="caption" sx={{ fontWeight: 500 }}>Target</Typography>
                        </Box>
                        
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#2196f3', mr: 1 }}></Box>
                          <Typography variant="caption" sx={{ fontWeight: 500 }}>Intermediate</Typography>
                        </Box>
                        
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#e57373', mr: 1 }}></Box>
                          <Typography variant="caption" sx={{ fontWeight: 500 }}>Endpoint</Typography>
                        </Box>
                      </Box>

                      {/* Edge types */}
                      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                      
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <span className="edge-line string-edge"></span>
                          <Typography variant="caption" sx={{ fontWeight: 500 }}>STRING</Typography>
                        </Box>
                        
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <span className="edge-line ai-edge"></span>
                          <Typography variant="caption" sx={{ fontWeight: 500 }}>AI</Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }

  interface SummaryItemInterface {
    mainText: string | number;
    descriptor: string;
    isTargetItem?: boolean;
    stringId?: string;
  }

  function SummaryItem({ mainText, descriptor, isTargetItem, stringId }: SummaryItemInterface) {
    // Determine the appropriate CSS class based on the descriptor
    let itemClass = "summary-item";
    if (isTargetItem) {
      itemClass += " target-summary-item";
    } else if (descriptor === "Overall Score") {
      itemClass += " score-summary-item";
    } else if (descriptor === "Disease") {
      itemClass += " disease-summary-item";
    }
    
    return (
      <Paper className={itemClass} elevation={0}>
        <Box sx={{ mb: 1 }}>
          <Typography className="descriptor" variant="subtitle2">
            {descriptor}
          </Typography>
        </Box>
        <Typography className="main-value" variant="h4">
          {mainText}
        </Typography>
        {isTargetItem && stringId && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block', mb: 0.5 }}>
              <strong>String ID:</strong> {stringId}
            </Typography>
            <Link 
              href={`https://string-db.org/network/${stringId}`} 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{ 
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              View on String DB
              <OpenInNewIcon fontSize="small" sx={{ ml: 0.5, fontSize: '1rem' }} />
            </Link>
          </Box>
        )}
      </Paper>
    );
  }

  // Updated component to display FDA approved drugs for the entire model with bar chart
  interface FdaApprovedDrugsProps {
    fdaDrugs: any;
    currentTargetId: string | undefined;
    selectedTissueType?: string | null;
    isLoading?: boolean;
  }

  function FdaApprovedDrugs({ fdaDrugs, currentTargetId, selectedTissueType, isLoading }: FdaApprovedDrugsProps) {
    // If loading or no data, show a message
    if (isLoading) {
      return (
        <Paper sx={{ p: 3, backgroundColor: '#f5f5f5', borderRadius: 2, textAlign: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', py: 2 }}>
            <CircularProgress size={30} sx={{ mb: 2 }} />
            <Typography variant="body2" color="text.secondary">
              Loading FDA approved drugs data...
            </Typography>
            {selectedTissueType && (
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                Fetching data for {getTissueTypeName(selectedTissueType)}
              </Typography>
            )}
          </Box>
        </Paper>
      );
    }
    
    if (!fdaDrugs) {
      return (
        <Paper sx={{ p: 3, backgroundColor: '#f5f5f5', borderRadius: 2, textAlign: 'center', mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            No FDA approved drugs data available.
          </Typography>
        </Paper>
      );
    }

    // Structure to hold drugs grouped by target
    interface TargetDrugGroup {
      targetId: string;
      targetSymbol: string;
      displayName: string;  // Name to display (prefer symbol over ID)
      drugs: DrugData[];
      maxEfficacy: number;  // Highest efficacy score among all drugs for this target
      isCurrentTarget: boolean;
    }

    // Group drugs by target
    const targetGroups: TargetDrugGroup[] = [];
    
    // Process drugs from ensembl_ids
    if (fdaDrugs.ensembl_ids) {
      Object.entries(fdaDrugs.ensembl_ids).forEach(([targetId, data]: [string, any]) => {
          // Create a new target group
        const displayName = data.gene_symbol || targetId.split('.').pop() || targetId;
        
        // Handle both new and old format data
        let drugsList = [];
        if (Array.isArray(data.drugs)) {
          // New format - direct drugs array
          drugsList = data.drugs.map((drugName: string): DrugData => ({
            name: drugName,
            efficacy_score: data.efficacy_score || 0.5
          }));
        } else if (data.drugs && typeof data.drugs === 'object') {
          // Handle different drug object formats if needed
          drugsList = Object.entries(data.drugs).map(([drugName, drugData]: [string, any]): DrugData => ({
            name: drugName,
            efficacy_score: drugData.efficacy_score || data.efficacy_score || 0.5
          }));
        }
        
        if (drugsList.length > 0) {
          const group: TargetDrugGroup = {
            targetId,
            targetSymbol: data.gene_symbol || '',
            displayName,
            drugs: drugsList,
            maxEfficacy: data.efficacy_score || Math.max(...drugsList.map((d: DrugData) => d.efficacy_score)),
            isCurrentTarget: targetId === currentTargetId
          };
          
          targetGroups.push(group);
        }
      });
    }
    
    // Process drugs from gene_symbols and merge with existing groups
    if (fdaDrugs.gene_symbols) {
      Object.entries(fdaDrugs.gene_symbols).forEach(([geneSymbol, data]: [string, any]) => {
        // Handle both new and old format data
        let drugsList = [];
        if (Array.isArray(data.drugs)) {
          // New format - direct drugs array
          drugsList = data.drugs.map((drugName: string): DrugData => ({
            name: drugName,
            efficacy_score: data.efficacy_score || 0.5
          }));
        } else if (data.drugs && typeof data.drugs === 'object') {
          // Handle different drug object formats if needed
          drugsList = Object.entries(data.drugs).map(([drugName, drugData]: [string, any]): DrugData => ({
            name: drugName,
            efficacy_score: drugData.efficacy_score || data.efficacy_score || 0.5
          }));
        }
        
        if (drugsList.length > 0) {
          // Try to find a matching target group by comparing drugs
          let matchingGroupIndex = -1;
          
          // For each existing group, check if there's significant overlap in drug names
          for (let i = 0; i < targetGroups.length; i++) {
            const group = targetGroups[i];
            const existingDrugNames = new Set(group.drugs.map(d => d.name));
            const matchingDrugs = drugsList.filter((d: DrugData) => existingDrugNames.has(d.name));
            
            // If more than half of the drugs match, consider this the same target
            if (matchingDrugs.length > 0 && matchingDrugs.length >= drugsList.length / 2) {
              matchingGroupIndex = i;
              break;
            }
          }
          
          if (matchingGroupIndex !== -1) {
            // Add the gene symbol to the existing group
            const group = targetGroups[matchingGroupIndex];
            group.targetSymbol = geneSymbol;
            group.displayName = geneSymbol;  // Prefer gene symbol for display
            
            // Add any new drugs not already in the group
            const existingDrugNames = new Set(group.drugs.map(d => d.name));
            drugsList.forEach((drug: DrugData) => {
              if (!existingDrugNames.has(drug.name)) {
                group.drugs.push(drug);
                existingDrugNames.add(drug.name);
              }
            });
            
            // Update maximum efficacy
            group.maxEfficacy = Math.max(group.maxEfficacy, ...drugsList.map((d: DrugData) => d.efficacy_score));
          } else {
            // Create a new group
            targetGroups.push({
              targetId: data.ensembl_id || '',  // Use ensembl_id if available
              targetSymbol: geneSymbol,
              displayName: geneSymbol,
              drugs: [...drugsList],
              maxEfficacy: Math.max(...drugsList.map((d: DrugData) => d.efficacy_score)),
              isCurrentTarget: (data.ensembl_id && data.ensembl_id === currentTargetId) || false
            });
          }
        }
      });
    }

    // If no drugs are found
    if (targetGroups.length === 0) {
      return (
        <Paper sx={{ p: 3, backgroundColor: '#f5f5f5', borderRadius: 2, textAlign: 'center', mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            No FDA approved drugs found for this model.
          </Typography>
        </Paper>
      );
    }
    
    // Add the current target if not already in the list
    let currentTargetAdded = false;
    let currentTargetScore = 0;
    
    // Check if the current target is already in the list
    const currentTargetGroup = targetGroups.find(g => g.isCurrentTarget);
    if (currentTargetGroup) {
      currentTargetScore = currentTargetGroup.maxEfficacy;
      currentTargetAdded = true;
    }
    
    // If we have efficacy data but the current target is not in the list, add it
    if (!currentTargetAdded && efficacyData && currentTargetId) {
      // Get the current target's preferred name
      let targetDisplayName = currentTargetId;
      if (efficacyData.protein_info && efficacyData.protein_info[currentTargetId]) {
        if (efficacyData.protein_info[currentTargetId].preferredName) {
          targetDisplayName = efficacyData.protein_info[currentTargetId].preferredName;
        }
      }
      
      // Add it with the overall efficacy score
      currentTargetScore = efficacyData.score;
      targetGroups.push({
        targetId: currentTargetId,
        targetSymbol: '',
        displayName: targetDisplayName,
        drugs: [], // No drugs for this one
        maxEfficacy: efficacyData.score,
        isCurrentTarget: true
      });
    }

    // Sort target groups by max efficacy score (highest first)
    const sortedTargetGroups = [...targetGroups].sort((a, b) => b.maxEfficacy - a.maxEfficacy);

    // Calculate the maximum efficacy score for scaling the bars
    const maxEfficacyScore = Math.max(...sortedTargetGroups.map(g => g.maxEfficacy));
    
    // Dynamic color calculation based on the current target's score
    const getBarColor = (score: number) => {
      // If we have a current target, make colors relative to it
      if (currentTargetScore > 0) {
        if (score >= currentTargetScore * 1.1) return '#4caf50';  // Significantly better than current target (>10% higher)
        if (score >= currentTargetScore * 0.9) return '#26a69a';  // Similar to current target (±10%)
        if (score >= currentTargetScore * 0.7) return '#ff9800';  // Somewhat lower than current target (10-30% lower)
        return '#f44336';  // Much lower than current target (>30% lower)
      } else {
        // Fallback if no current target score
        if (score > 0.3) return '#4caf50';
        if (score > 0.2) return '#ff9800';
        return '#f44336';
      }
    };
    
    // Get a label for the score comparison
    const getComparisonLabel = (score: number) => {
      if (!currentTargetScore || !score) return '';
      
      const percentDiff = ((score / currentTargetScore) - 1) * 100;
      
      if (Math.abs(percentDiff) < 5) return '(Similar to target)';
      if (percentDiff > 0) return `(${percentDiff.toFixed(0)}% higher than target)`;
      return `(${Math.abs(percentDiff).toFixed(0)}% lower than target)`;
    };
    
    return (
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, flexWrap: 'wrap' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main', mr: 2 }}>
            FDA Approved Drugs by Target
              </Typography>
            
            {/* Tissue type indicator badge */}
          
          </Box>
          
          {/* Show warning if there's a tissue type mismatch */}
          {selectedTissueType && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, flexWrap: 'wrap' }}>
             
              {fdaDrugs && (fdaDrugs as any).requestedTissueType && (fdaDrugs as any).requestedTissueType !== selectedTissueType && (
                <Typography variant="caption" color="warning.main" sx={{ ml: 1 }}>
                  (Using data from {getTissueTypeName((fdaDrugs as any).requestedTissueType)})
                </Typography>
              )}
            </Box>
          )}
        </Box>
        
        <Paper sx={{ p: 3, mb: 3 }}>
          {/* Bar chart visualization */}
          <Box sx={{ mb: 3 }}>
            {sortedTargetGroups.map((group, index) => (
              <Box 
                key={`target-group-${index}`} 
                  sx={{ 
                  mb: 2, 
                  border: group.isCurrentTarget ? '2px solid #4c6ef5' : 'none',
                  borderRadius: 1,
                  padding: group.isCurrentTarget ? 1 : 0,
                  backgroundColor: group.isCurrentTarget ? 'rgba(76, 110, 245, 0.05)' : 'transparent'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      fontWeight: group.isCurrentTarget ? 'bold' : 'normal',
                      color: group.isCurrentTarget ? 'primary.main' : 'text.primary',
                      mr: 1
                    }}
                  >
                    {group.displayName}
                    {group.isCurrentTarget && (
                      <Chip 
                        label="Current Target" 
                        size="small" 
                        color="secondary" 
                        sx={{ ml: 1, height: 20, fontSize: '0.7rem' }}
                      />
                    )}
                  </Typography>
                  
                  <Tooltip title={`Efficacy score: ${group.maxEfficacy.toFixed(2)} ${getComparisonLabel(group.maxEfficacy)}`}>
                    <Chip 
                      label={`Score: ${typeof group.maxEfficacy === 'number' ? group.maxEfficacy.toFixed(2) : 'N/A'}`}
                      size="small"
                      color="default"
                    />
                  </Tooltip>
                </Box>
                
                {/* Bar chart */}
                <Box sx={{ position: 'relative', height: 24, bgcolor: '#f5f5f5', borderRadius: 1, overflow: 'hidden' }}>
                      <Box
                        sx={{
                      position: 'absolute', 
                          top: 0,
                          left: 0,
                      height: '100%', 
                      width: `${(group.maxEfficacy / maxEfficacyScore) * 100}%`,
                      bgcolor: getBarColor(group.maxEfficacy),
                      transition: 'width 0.3s ease-in-out'
                    }}
                  />
                  
                  {/* If it's not the current target but we have one, add a marker for comparison */}
                  {!group.isCurrentTarget && currentTargetScore > 0 && (
                    <Box
                      sx={{
                          position: 'absolute',
                        top: 0,
                        left: `${(currentTargetScore / maxEfficacyScore) * 100}%`,
                        height: '100%',
                        width: '2px',
                        bgcolor: '#4c6ef5',
                        zIndex: 2
                      }}
                    />
                  )}
                    </Box>
                    
                {/* Drugs list */}
                {group.drugs.length > 0 && (
                  <Box sx={{ mt: 1, pl: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                      <strong>Drugs:</strong> {group.drugs.map(d => d.name).join(', ')}
                      </Typography>
                    </Box>
                )}
                  </Box>
            ))}
                </Box>
          
          {/* Updated Legend */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, borderTop: '1px solid #e0e0e0', pt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 12, height: 12, bgcolor: '#4caf50', mr: 1 }} />
                <Typography variant="caption">Higher than target</Typography>
            </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 12, height: 12, bgcolor: '#26a69a', mr: 1 }} />
                <Typography variant="caption">Similar to target</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 12, height: 12, bgcolor: '#ff9800', mr: 1 }} />
                <Typography variant="caption">Somewhat lower</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 12, height: 12, bgcolor: '#f44336', mr: 1 }} />
                <Typography variant="caption">Much lower</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: 2, height: 12, bgcolor: '#4c6ef5', mr: 1 }} />
                <Typography variant="caption">Target reference</Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }

  interface SummarySectionInterface {
    disease: string | undefined;
    nodeId: string | undefined;
    selectedTissueType?: string | null;
  }

  // Add this after the existing interfaces and before the function definitions
  interface NodeProcessPathsContextType {
    efficacyData: TargetEfficacyData | null;
    selectedNodeName: string;
    selectedTissueType: string | null;
    projectData: TVProjectType | null;
    loading: boolean;
    fdaApprovedDrugsData: any;
    setFdaApprovedDrugsData: React.Dispatch<React.SetStateAction<any>>;
  }

  // Modify the SummarySection function
  function SummarySection({ disease, nodeId, selectedTissueType }: SummarySectionInterface) {
    let endpointsDisplay = '';
    let endpointNames: string[] = [];
    
    // Format endpoints for display
    if (efficacyData?.interrupted_paths) {
      // Create a map to store unique endpoint details - using Map to preserve insertion order
      const uniqueEndpoints = new Map<string, string>();
      
      // First collect all endpoints with their display names
      efficacyData.interrupted_paths.forEach((path: InterruptedPath) => {
        const endpoint = path.endpoint;
        // Get endpoint name from protein info if available, or use endpoint ID
        const displayName = efficacyData?.protein_info?.[endpoint]?.preferredName || 
          (endpoint.includes('.') ? endpoint.split('.').pop() || endpoint : endpoint);
        
        // Only add if this endpoint isn't already in our map
        if (!uniqueEndpoints.has(endpoint)) {
          uniqueEndpoints.set(endpoint, displayName);
        }
      });
      
      // Convert the map values to an array
      endpointNames = Array.from(uniqueEndpoints.values());
      
      // Create a formatted string of endpoints
      endpointsDisplay = endpointNames.length > 0 
        ? endpointNames.join(', ') 
        : 'No disease progression nodes found';
    } else {
      endpointsDisplay = 'No data available';
    }
    
    // Get the node preferred name
    let nodePreferredName = 'Unknown';
    if (efficacyData?.protein_info && nodeId && efficacyData.protein_info[nodeId]) {
      nodePreferredName = efficacyData.protein_info[nodeId].preferredName || 'Unknown';
    }
    
    // Format AI prediction cutoff
    let formattedCutoff = 'Unknown';
    if (typeof aiPredCutoff === 'number') {
      formattedCutoff = aiPredCutoff.toFixed(2);
    }
                    
                    return (
      <div>
        <Box sx={{ marginTop: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <SummaryItem 
                mainText={String(nodePreferredName)} 
                descriptor="Selected Target" 
                isTargetItem={true}
                stringId={nodeId}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SummaryItem
                    mainText={(efficacyData?.score ?? 0).toFixed(SCORES_DECIMAL)}
                    descriptor="Overall Score"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SummaryItem 
                    mainText={projectData?.model?.name || "Unknown Model"} 
                    descriptor="Model"
                  />
                </Grid>
                {/* Add tissue type to summary if available */}
                {selectedTissueType && (
                  <Grid item xs={12} sm={6}>
                  <SummaryItem
                      mainText={getTissueTypeName(selectedTissueType)}
                      descriptor="Tissue Type"
                  />
                </Grid>
                )}
                <Grid item xs={12} sm={selectedTissueType ? 6 : 12}>
                  <SummaryItem 
                    mainText={disease ? disease_name_readable(disease) : "Unknown"} 
                    descriptor="Disease"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* Improved Endpoints Display */}
              <Paper sx={{ p: 2, borderRadius: 2, boxShadow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Disease progression Nodes
                </Typography>
                  <Tooltip title="Each disease progression node may have multiple possible paths connecting it to the target. The highest scoring path for each node is marked as 'Best'." arrow>
                    <InfoIcon sx={{ ml: 1, fontSize: '1rem', color: 'text.secondary', cursor: 'help' }} />
                  </Tooltip>
                </Box>
                {endpointNames.length > 0 ? (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {endpointNames.map((name, index) => (
                      <Chip 
                        key={`endpoint-${index}`}
                        label={name}
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ 
                          fontWeight: 'medium',
                          '&:hover': { boxShadow: 1 } 
                        }}
                      />
                    ))}
                          </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No disease progression nodes found
                  </Typography>
                )}
        </Paper>
            </Grid>
          </Grid>
      </Box>
      </div>
    );
  }

  // Fix the return structure to revert context changes
  return (
    <Box sx={{ 
      maxWidth: '100%', 
      margin: '0 auto',
      padding: asPage ? '20px' : 0
    }}>
        <GlobalStyles
          styles={{
            '.pathway-graph-container, .pathway-graph-container > svg': {
              width: '100% !important',
              maxWidth: '100%'
            },
            '.pathway-graph-container .node': {
              cursor: 'pointer'
            }
          }}
        />
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
        gap: 4
      }}>
        {/* Top section with project details and note button */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <TopOverlay 
            projectData={projectData} 
            modelId={modelId} 
            disease={disease} 
            nodeId={nodeId}
            selectedNodeName={selectedNodeName} 
          />
          
          {/* Note-taking button */}
            <Button
              variant="contained"
              color="primary"
              startIcon={<NotesIcon />}
              className="action-button primary-action-button"
              onClick={() => {
                // Open the notepad dialog
                const notepadDialog = document.getElementById('notepad-section');
                if (notepadDialog) {
                  if (notepadDialog.style.display === 'none' || !notepadDialog.style.display) {
                    notepadDialog.style.display = 'block';
                  } else {
                    notepadDialog.style.display = 'none';
                  }
                }
              }}
            sx={{ ml: 2 }}
            >
              Project Notes
            </Button>
          </Box>
          
          {/* Notepad Section (initially hidden) */}
          <Box id="notepad-section" sx={{ display: 'none', mb: 3 }}>
            <div className="titlesection">
              <p>Project Notes</p>
            </div>
            <Paper className="roundedBorderContainer">
              <Notepad 
                projectId={_id} 
                projectNotes={projectData?.notes || []}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const notepadDialog = document.getElementById('notepad-section');
                    if (notepadDialog) {
                      notepadDialog.style.display = 'none';
                    }
                  }}
                  sx={{ mt: 2 }}
                >
                  Close Notes
                </Button>
              </Box>
            </Paper>
          </Box>
          
        {/* Main content sections */}
        <div>
          {/* 1. Summary Section - Improved with more compact endpoint display */}
          <SummarySection disease={disease} nodeId={nodeId} selectedTissueType={selectedTissueType} />
          
          {/* 2. Disease Introduction */}
          {disease && selectedNodeName && (
            <Box sx={{ mt: 4, mb: 2 }}>
              <DiseaseIntroductionCard 
                diseaseName={disease_name_readable(disease)} 
                targetName={selectedNodeName} 
              />
            </Box>
          )}
          
          {/* 3. FDA Approved Drugs Section */}
            <Box sx={{ mt: 4, mb: 3 }}>
            <div className="titlesection">
              <p>FDA Approved Drugs</p>
            </div>
            
            {/* Display tissue type information */}
            {selectedTissueType && (
              <Box sx={{ mt: -1, mb: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.9rem', mr: 1 }}>
                  Requested Tissue Type:
                </Typography>
                <Chip 
                  size="small" 
                  label={getTissueTypeName(selectedTissueType)} 
                  color="primary" 
                  variant="outlined" 
                  sx={{ fontWeight: 'medium' }}
                />
                
                {/* Show actual tissue type if different */}
                {fdaApprovedDrugsData && (fdaApprovedDrugsData as any).actualTissueType && 
                 (fdaApprovedDrugsData as any).actualTissueType !== selectedTissueType && (
                  <>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.9rem', ml: 2, mr: 1 }}>
                      Actual Data:
                    </Typography>
                    <Chip 
                      size="small" 
                      label={getTissueTypeName((fdaApprovedDrugsData as any).actualTissueType)} 
                      color="warning" 
                      variant="outlined" 
                      sx={{ fontWeight: 'medium' }}
                    />
                    <Tooltip title="The requested tissue type data was not available, so data from another tissue type is being shown instead">
                      <WarningIcon sx={{ ml: 0.5, color: 'warning.main', fontSize: 16 }} />
                    </Tooltip>
                  </>
                )}
            </Box>
          )}
            
            {fdaApprovedDrugsData ? (
              <Paper className="roundedBorderContainer">
                {/* Removing refresh button and its functionality */}
                <FdaApprovedDrugs fdaDrugs={fdaApprovedDrugsData} currentTargetId={nodeId} selectedTissueType={selectedTissueType} isLoading={loading} />
              </Paper>
            ) : (
              <Paper className="roundedBorderContainer" sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', py: 2 }}>
                  {loading ? (
                    <>
                      <CircularProgress size={30} sx={{ mb: 2 }} />
                      <Typography variant="body1" align="center" color="text.secondary" gutterBottom>
                        Loading FDA approved drugs data...
                      </Typography>
                      {selectedTissueType && (
                        <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                          Fetching data for {getTissueTypeName(selectedTissueType)}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant="body1" align="center" color="text.secondary" gutterBottom>
                        {selectedTissueType ? "No FDA approved drugs found for this tissue type" : "No FDA approved drugs data available"}
                      </Typography>
                      {selectedTissueType && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                              Tissue Type:
                            </Typography>
                            <Chip 
                              size="small" 
                              label={getTissueTypeName(selectedTissueType)} 
                              color="primary" 
                              variant="outlined" 
                              sx={{ fontWeight: 'medium' }}
                            />
                          </Box>
                          <Button 
                            variant="text" 
                            size="small"
                            onClick={async () => {
                              try {
                                // KEEP THE TISSUE TYPE but try again with different request
                                console.log(`Trying to load FDA approved drugs for ${getTissueTypeName(selectedTissueType)} again...`);
                                const accessToken = await getAccessTokenSilently();
                                
                                // CRITICAL: Always include the tissue_type in the request!!!
                                const url = `/api/target-validation/fda_approved_drugs?tissue_type=${encodeURIComponent(selectedTissueType)}`;
                                console.log("RETRY: Making FDA drugs request WITH tissue type:", url);
                                
                                const response = await fetch(url, {
                                  headers: { 
                                    Authorization: `Bearer ${accessToken}`,
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                  }
                                });
                                
                                if (response.ok) {
                                  const data = await response.json();
                                  if (data.status === 'success' && 
                                      data.data && 
                                      selectedTissueType && 
                                      data.data[selectedTissueType]) {
                                    
                                    // ONLY use the specific tissue type data, no fallbacks!
                                    const tissueData = data.data[selectedTissueType];
                                    
                                    // Create a properly formatted data structure
                                    const processedData = {
                                      ensembl_ids: tissueData.ensembl_ids || {},
                                      gene_symbols: tissueData.gene_symbols || {},
                                      requestedTissueType: selectedTissueType,
                                      actualTissueType: selectedTissueType,
                                      tissueTypeDisplay: `${getTissueTypeName(selectedTissueType)} Tissue`
                                    };
                                    
                                    setFdaApprovedDrugsData(processedData);
                                    // Only show success in console to reduce UI clutter
                                    console.log(`FDA approved drugs data for ${getTissueTypeName(selectedTissueType)} loaded successfully`);
                                    console.log(`Specific tissue data loaded for ${selectedTissueType}:`, processedData);
                                  } else {
                                    // No data for this tissue type - show empty result
                                    console.warn(`No FDA drug data available for ${getTissueTypeName(selectedTissueType)}`);
                                    setFdaApprovedDrugsData({
                                      ensembl_ids: {},
                                      gene_symbols: {},
                                      requestedTissueType: selectedTissueType,
                                      actualTissueType: null,
                                      tissueTypeDisplay: `${getTissueTypeName(selectedTissueType)} (No Data Available)`
                                    });
                                    console.error("No data available for this tissue type:", data);
                                  }
                                } else {
                                  console.error("Error loading tissue-specific FDA data, status:", response.status);
                                  // Only show console error, not toast, to reduce UI clutter
                                  console.error(`Server error (${response.status}). Please try again later.`);
                                }
                              } catch (error) {
                                console.error("Error loading tissue-specific FDA data:", error);
                                // Only show console error, not toast, to reduce UI clutter
                                console.error(`Could not load FDA data for ${getTissueTypeName(selectedTissueType)}. Please try again later.`);
                              }
                            }}
                          >
                            Retry for {selectedTissueType ? getTissueTypeName(selectedTissueType) : "Current Tissue"}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Paper>
            )}
          </Box>
          
          {/* 3. Interrupted Pathways Section */}
          <InterruptedPathwaysSection 
            effScore={efficacyData.score} 
            gridItems={gridItems} 
            nodeId={nodeId} 
          />
          
          {/* 4. Endpoint Heatmap Analysis */}
          {efficacyData?.interrupted_paths && efficacyData.interrupted_paths.length > 0 && (
            <Box sx={{ mb: 4, mt: 4 }}>
              <div className="titlesection">
                <p>Disease Progression Nodes Heatmap Analysis</p>
              </div>
              <Paper className="roundedBorderContainer">
                <EndpointHeatmap 
                  interruptedPaths={efficacyData.interrupted_paths} 
                  proteinInfo={efficacyData.protein_info || {}}
                  targetName={selectedNodeName || nodeId || 'Target'}
                  targetId={nodeId || ''}
                  fdaApprovedDrugs={fdaApprovedDrugsData}
                />
              </Paper>
            </Box>
          )}
          
          {/* Download buttons */}
       
        </div>
      </Box>
      
      {/* Glossary Modal */}
      <GlossaryModal 
        open={glossaryModalOpen} 
        onClose={() => setGlossaryModalOpen(false)} 
      />
    </Box>
  );
}

export default NodeProcessPathsPage;




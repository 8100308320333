import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '_configs';

// List of restricted paths
const restrictedPaths = [
  config.clientUrls.VISUALIZATION,
  config.clientUrls.BIOMARKERS,
  config.clientUrls.MODELS,
  config.clientUrls.FILES,
];

// Check if the current path is restricted
const isRestricted = (path) => {
  return restrictedPaths.some(restrictedPath => {
    // Check exact matches
    if (restrictedPath === path) return true;
    
    // Check for paths with parameters
    if (restrictedPath.includes(':')) {
      const baseRestrictedPath = restrictedPath.split('/:')[0];
      return path.startsWith(baseRestrictedPath);
    }
    
    return false;
  });
};

const RestrictedRouteGuard = ({ component: Component, ...rest }) => {
  useEffect(() => {
    if (isRestricted(rest.path)) {
      toast.info("This feature will be available soon!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [rest.path]);

  return (
    <Route
      {...rest}
      render={props =>
        isRestricted(rest.path) ? (
          <Redirect
            to={{
              pathname: config.clientUrls.HOME,
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default RestrictedRouteGuard; 
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5005";

export function useIsSandboxUser() {
    const { user, getAccessTokenSilently } = useAuth0();
    const [isSandboxUser, setIsSandboxUser] = useState<boolean>(false);

    useEffect(() => {
        const fetchUserFromDB = async () => {
            if (!user?.email) return;
        
            try {
                const response = await axios.get(`${API_URL}/api/harbor/users/me?email=${user.email}`);
        
                console.log("DEBUG: User Data from DB ->", response.data);
        
                if (response.data?.roles?.includes("sandbox-user")) {
                    setIsSandboxUser(true);
                } else {
                    setIsSandboxUser(false);
                }
            } catch (error: any) {
                console.error(" Error fetching user roles from DB:", error);
            }
        };
        
        fetchUserFromDB();
    }, [user, getAccessTokenSilently]);

    return isSandboxUser;
}

import history from '_helpers/history';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import BiotechIcon from '@mui/icons-material/Biotech';
import StorageIcon from '@mui/icons-material/Storage';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BalanceIcon from '@mui/icons-material/Balance';
import SettingsIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import InventoryIcon from '@mui/icons-material/Inventory';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import HelpIcon from '@mui/icons-material/Help';
import { useTheme } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import './drawer.scss';
import config from '_configs';
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import { Collapse, alpha, Chip, Tooltip } from '@mui/material';
const drawerWidth = 300;

export default function PermanentDrawerLeft() {
  const theme = useTheme();
  const { user, logout } = useAuth0();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const buttonTextSize = 16;

  // Texts / ids for the buttons
  const HomeText = 'Home';
  const TargetValidationText = 'BioTarget Scoring';
  const MyProjectText = 'My Projects';
  const MyDataText = 'My Data';
  const ModelsText = 'Models';
  const VisualizationText = 'Visualization';
  const BiomarkersText = 'Omics Signatures Tool';
  const AccountText = 'Account';
  const LogoutText = 'Logout';
  const HelpText = 'Help';

  // Define which features are restricted
  const restrictedFeatures = [
    VisualizationText,
    BiomarkersText,
    ModelsText,
    MyDataText
  ];

  // Helper function to check if a feature is restricted
  const isRestricted = (featureName) => restrictedFeatures.includes(featureName);

  // mapping of buttons
  const mainButtons = [{ text: HomeText, icon: HomeIcon, page: config.clientUrls.HOME }];
  const productsButtons = [
    { text: TargetValidationText, icon: VaccinesIcon, page: config.clientUrls.TargetValidation },
    { text: VisualizationText, icon: AnalyticsIcon, page: config.clientUrls.VISUALIZATION, restricted: true },
    { text: BiomarkersText, icon: CoronavirusIcon, page: config.clientUrls.BIOMARKERS, restricted: true },
  ];
  const accessButtons = [
    { text: ModelsText, icon: ViewInArIcon, page: config.clientUrls.MODELS, restricted: true },
    { text: MyProjectText, icon: BiotechIcon, page: config.clientUrls.PROJECTS },
    { text: MyDataText, icon: StorageIcon, page: config.clientUrls.FILES, restricted: true },
  ];
  const secondaryButtons = [
    { text: AccountText, icon: PersonIcon, page: config.clientUrls.PROFILE },
    { text: HelpText, icon: HelpIcon, page: config.clientUrls.HELP },
  ];
  function HandleGoToPage(page) {
    history.push(page);
  }
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, button, index) => {
    // Don't navigate if feature is restricted
    if (button.restricted) {
      return;
    }
    
    setSelectedIndex(index);
    HandleGoToPage(button.page);
  };

  // Enhanced styling for list items with active indicator
  const ListItemButtonStyle = {
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      borderRight: `3px solid ${theme.palette.secondary.main}`,
      transition: 'all 0.3s ease',
    },
    '&.Mui-focusVisible': {
      backgroundColor: alpha(theme.palette.primary.tinted, 0.2),
    },
    ':hover': {
      backgroundColor: alpha(theme.palette.primary.tinted, 0.2),
      transition: 'all 0.3s ease',
    },
    height: 50,
    borderRadius: '8px 0 0 8px',
    marginBottom: '4px',
    margin: '2px 8px 2px 8px',
    paddingLeft: '16px',
  };

  // Enhanced styling for restricted items
  const restrictedItemStyle = {
    ...ListItemButtonStyle,
    opacity: 0.5,
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'transparent',
    }
  };

  // Enhanced styling for section dividers
  const SectionDivider = () => (
    <Divider 
      sx={{ 
        my: 1.5, 
        mx: 2, 
        borderColor: alpha('#fff', 0.1),
        opacity: 0.5,
      }} 
    />
  );

  // Enhanced styling for the secondary divider
  const SecondaryDivider = () => (
    <Divider 
      sx={{ 
        my: 1, 
        mx: 2, 
        borderColor: alpha('#fff', 0.1),
      }} 
    />
  );

  const AppBarElement = (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        height: '65px',
        ml: `${drawerWidth}px`,
        backgroundColor: theme.palette.secondary.lightgrey,
        color: 'black',
        boxSizing: 'border-box',
      }}
    >
      <Toolbar sx={{ display: 'flex', position: 'sticky' }}>
        <Typography variant="h6" noWrap component="div">
          Home &gt;
        </Typography>
      </Toolbar>
    </AppBar>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer
        className="mydrawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: `linear-gradient(160deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.tinted} 100%)`,
            color: 'white',
            fontSize: 16,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box className="topcontainer" sx={{ 
          padding: '24px 16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 1
        }}>
          <img 
            src="/assets/img/logo-reverse-text.png" 
            style={{
              maxWidth: '220px',
              filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))'
            }}
            alt="Simmunome" 
          />
        </Box>
        
        <SectionDivider />
        
        <List sx={{ px: 1, pt: 2 }}>
          {mainButtons.map((button, index) => (
            <ListItem key={button.text} disablePadding onClick={(event) => handleListItemClick(event, button, index)}>
              <ListItemButton selected={selectedIndex === index} sx={ListItemButtonStyle}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  {<button.icon sx={{ color: 'white', fontSize: '20px' }} />}
                </ListItemIcon>
                <ListItemText 
                  primary={button.text} 
                  primaryTypographyProps={{ 
                    fontSize: buttonTextSize, 
                    fontWeight: selectedIndex === index ? 'bold' : 'normal' 
                  }} 
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Typography variant="caption" sx={{ 
          opacity: 0.6, 
          px: 4, 
          pt: 1, 
          pb: 0.5, 
          fontSize: '12px',
          letterSpacing: '0.5px', 
          textTransform: 'uppercase'
        }}>
          Products
        </Typography>
        
        <List sx={{ px: 1 }}>
          {productsButtons.map((button, index) => (
            <ListItem 
              key={button.text} 
              disablePadding
              onClick={(event) => handleListItemClick(event, button, index + mainButtons.length)}
            >
              <Tooltip title={button.restricted ? "Coming soon!" : ""} placement="right">
                <ListItemButton 
                  selected={!button.restricted && selectedIndex === index + mainButtons.length} 
                  sx={button.restricted ? restrictedItemStyle : ListItemButtonStyle}
                >
                  <ListItemIcon sx={{ minWidth: '40px' }}>
                    {<button.icon sx={{ color: 'white', fontSize: '20px' }} />}
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {button.text}
                        {button.restricted && 
                          <Chip 
                            label="Soon" 
                            size="small" 
                            sx={{ 
                              ml: 1, 
                              height: '18px', 
                              fontSize: '10px',
                              bgcolor: theme.palette.secondary.main,
                              color: 'white',
                              fontWeight: 'bold',
                            }} 
                          />
                        }
                      </Box>
                    }
                    primaryTypographyProps={{ 
                      fontSize: buttonTextSize, 
                      fontWeight: !button.restricted && selectedIndex === index + mainButtons.length ? 'bold' : 'normal' 
                    }} 
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>

        <Typography variant="caption" sx={{ 
          opacity: 0.6, 
          px: 4, 
          pt: 1.5, 
          pb: 0.5, 
          fontSize: '12px',
          letterSpacing: '0.5px', 
          textTransform: 'uppercase'
        }}>
          Workspace
        </Typography>
        
        <List sx={{ px: 1 }}>
          <ListItem disablePadding>
            <ListItemButton 
              onClick={handleClick} 
              sx={{ 
                ...ListItemButtonStyle,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography sx={{ fontSize: buttonTextSize }}>
                Components
              </Typography>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {accessButtons.map((button, index) => (
                <ListItem
                  key={button.text}
                  disablePadding
                  onClick={(event) =>
                    handleListItemClick(event, button, index + mainButtons.length + productsButtons.length)
                  }
                >
                  <Tooltip title={button.restricted ? "Coming soon!" : ""} placement="right">
                    <ListItemButton
                      selected={!button.restricted && selectedIndex === index + mainButtons.length + productsButtons.length}
                      sx={{ 
                        ...(button.restricted ? restrictedItemStyle : ListItemButtonStyle), 
                        pl: 4,
                        ml: 2 
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        {<button.icon sx={{ color: 'white', fontSize: '18px' }} />}
                      </ListItemIcon>
                      <ListItemText 
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {button.text}
                            {button.restricted && 
                              <Chip 
                                label="Soon" 
                                size="small" 
                                sx={{ 
                                  ml: 1, 
                                  height: '18px', 
                                  fontSize: '10px',
                                  bgcolor: theme.palette.secondary.main,
                                  color: 'white',
                                  fontWeight: 'bold',
                                }} 
                              />
                            }
                          </Box>
                        }
                        primaryTypographyProps={{ 
                          fontSize: buttonTextSize - 1, 
                          fontWeight: !button.restricted && selectedIndex === index + mainButtons.length + productsButtons.length ? 'bold' : 'normal' 
                        }} 
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>

        <Box sx={{ flexGrow: 1 }} />

        <SectionDivider />
        
        <List sx={{ px: 1 }}>
          {secondaryButtons.map((button, index) => (
            <ListItem
              key={button.text}
              disablePadding
              onClick={(event) =>
                handleListItemClick(
                  event,
                  button,
                  index + mainButtons.length + productsButtons.length + accessButtons.length,
                )
              }
            >
              <ListItemButton
                selected={selectedIndex === index + mainButtons.length + productsButtons.length + accessButtons.length}
                sx={ListItemButtonStyle}
              >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  {<button.icon sx={{ color: 'white', fontSize: '20px' }} />}
                </ListItemIcon>
                <ListItemText 
                  primary={button.text} 
                  primaryTypographyProps={{ 
                    fontSize: buttonTextSize, 
                    fontWeight: selectedIndex === index + mainButtons.length + productsButtons.length + accessButtons.length ? 'bold' : 'normal'
                  }} 
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        
        <SecondaryDivider />
        
        <List sx={{ px: 1, pb: 1 }}>
          <ListItem disablePadding onClick={() => logoutWithRedirect()}>
            <ListItemButton sx={ListItemButtonStyle}>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                {<LogoutIcon sx={{ color: 'white', fontSize: '20px' }} />}
              </ListItemIcon>
              <ListItemText 
                primary={LogoutText} 
                primaryTypographyProps={{ fontSize: buttonTextSize }} 
              />
            </ListItemButton>
          </ListItem>
        </List>
        
        <Box sx={{ px: 3, py: 2 }}>
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '14px', 
              opacity: 0.7,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box 
              component="span" 
              sx={{ 
                width: '30px', 
                height: '30px', 
                borderRadius: '50%', 
                bgcolor: alpha('#fff', 0.2), 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                mr: 1,
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              {user.email.charAt(0).toUpperCase()}
            </Box>
            {user.email}
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
}

export enum ProjectType {
    TargetValidation = "targetvalidation",
    Visualization = "visualization",
    Biomarkers = "biomarkers",
    Clustering = "clustering",
    Admin = "admin",
    Root = "root",
};

export const ProjectTypeDisplayDict = {
    [ProjectType.TargetValidation]: "BioTarget Scoring",
    [ProjectType.Visualization]: "Visualization",
    [ProjectType.Biomarkers]: "Biomarkers",
    [ProjectType.Clustering]: "Clustering",
    [ProjectType.Admin]: "Admin",
    [ProjectType.Root]: "Root",
}

export const projectTypeNames: Record<ProjectType, string> = {
  [ProjectType.Biomarkers]: "Biomarkers",
  [ProjectType.Clustering]: "Clustering",
  [ProjectType.TargetValidation]: "BioTarget Scoring",
  [ProjectType.Visualization]: "Visualization",
  [ProjectType.Admin]: "Admin",
  [ProjectType.Root]: "Root",
};
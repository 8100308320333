const TEXT_COLOR = '#000000';
const MAX_LINE_WIDTH_DEFAUL = 300;
const TRIM_WORDS_DEFAULT = 6;

/**
 * Takes first @n words
 * @param {String} text
 * @param {Number} n
 */
function trimNWords(text, n) {
  if (!text) return null;
  const words = text.split(' ');
  return words.slice(0, n).join(' ') + (words.length > n ? '...' : '');
}

/**
 * Split text into lines of text of at most specific length in pixels
 * @param {CanvasRenderingContext2D} ctx
 * @param {String} text
 * @param {Number} maxWidth
 * @returns
 */
function getLines(ctx, text, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const { width } = ctx.measureText(`${currentLine} ${word}`);
    if (width < maxWidth) {
      currentLine += ` ${word}`;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}

/**
 * This function draw in the input canvas 2D context a rectangle.
 * It only deals with tracing the path, and does not fill or stroke.
 * @param {CanvasRenderingContext2D} ctx
 * @param {number} x
 * @param {number} y
 * @param {number} width
 * @param {number} height
 * @param {number} radius
 */
export function drawRoundRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

/**
 * Custom hover renderer,
 * accepts full annotations (no trim) and wraps
 * @param {CanvasRenderingContext2D} context
 * @param {PlainObject} data
 * @param {Settings} settings
 * @param {Number} wrapWidth [optional]
 */
export function drawHoverFullWrap(context, data, settings, wrapWidth = MAX_LINE_WIDTH_DEFAUL) {
  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;
  const stringIdSize = size - 2;
  const { label } = data;
  const { stringId } = data;
  const annotationLines = getLines(context, data.annotation, wrapWidth);
  const maxAnnLine = annotationLines.reduce(
    (a, b) => (a.length > b.length ? a : b),
  );

  // Then we draw the label background
  context.beginPath();
  context.fillStyle = '#fff';
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 2;
  context.shadowBlur = 8;
  context.shadowColor = '#000';

  context.font = `${weight} ${size}px ${font}`;
  const labelWidth = context.measureText(label).width;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  const stringIdWidth = stringId ? context.measureText(stringId).width : 0;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  const annotationLabelWidth = maxAnnLine ? context.measureText(maxAnnLine).width : 0;

  const textWidth = Math.max(labelWidth, stringIdWidth, annotationLabelWidth);

  const x = Math.round(data.x);
  const y = Math.round(data.y);
  const w = Math.round(textWidth + size / 2 + data.size + 3);
  const hLabel = Math.round(size / 2 + 4);
  const hSubLabel = stringId ? Math.round(stringIdSize / 2 + 9) : 0;
  const hAnnotationLabel = Math.round(stringIdSize / 2 + 9) * annotationLines.length;

  drawRoundRect(context, x, y - hSubLabel - 12, w, hAnnotationLabel + hLabel + hSubLabel + 12, 5);
  context.closePath();
  context.fill();

  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;
  context.shadowBlur = 0;

  // And finally we draw the labels
  context.fillStyle = TEXT_COLOR;
  context.font = `${weight} ${size}px ${font}`;
  context.fillText(label, data.x + data.size + 3, data.y + size / 3);

  if (stringId) {
    context.fillStyle = TEXT_COLOR;
    context.font = `${weight} ${stringIdSize}px ${font}`;
    context.fillText(stringId, data.x + data.size + 3, data.y - (2 * size) / 3 - 2);
  }

  context.fillStyle = data.color;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  for (let i = 0; i < annotationLines.length; i++) {
    const line = annotationLines[i];
    context.fillText(
      line,
      data.x + data.size + 3,
      data.y + size / 3 + (3 + stringIdSize) * (i + 1),
    );
  }
}

export function drawHover(context, data, settings, trimWordsCount = TRIM_WORDS_DEFAULT) {
  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;
  const stringIdSize = size - 2;

  const { 
    label, 
    stringId, 
    annotation, 
    // Get both old and new expression data
    expression, 
    SD,
    // Get dual expression data
    healthy_expression,
    disease_expression,
    log2_fold_change,
    effect_size
  } = data;
  
  const trimmedAnnotation = trimNWords(annotation, trimWordsCount);

  // Measure widths
  context.font = `${weight} ${size}px ${font}`;
  const labelWidth = label ? context.measureText(label).width : 0;

  context.font = `${weight} ${stringIdSize}px ${font}`;
  const stringIdWidth = stringId ? context.measureText(stringId).width : 0;
  const annotationWidth = trimmedAnnotation ? context.measureText(trimmedAnnotation).width : 0;
  
  // Measure widths for dual expression data
  const healthyExprWidth = healthy_expression ? context.measureText(`Healthy: ${healthy_expression.toFixed(2)}`).width : 0;
  const diseaseExprWidth = disease_expression ? context.measureText(`Disease: ${disease_expression.toFixed(2)}`).width : 0;
  const foldChangeWidth = log2_fold_change ? context.measureText(`Log2 FC: ${log2_fold_change.toFixed(2)}`).width : 0;
  const effectSizeWidth = effect_size ? context.measureText(`Effect Size: ${effect_size.toFixed(2)}`).width : 0;
  
  // Fallback to old expression data if dual data is not available
  const expressionWidth = expression ? context.measureText(`Expression: ${Math.floor(expression)}`).width : 0;
  const SDWidth = SD ? context.measureText(`Std: ${Math.floor(SD)}`).width : 0;

  const textWidth = Math.max(
    labelWidth, 
    stringIdWidth, 
    annotationWidth, 
    healthyExprWidth,
    diseaseExprWidth,
    foldChangeWidth,
    effectSizeWidth,
    expressionWidth, 
    SDWidth
  );

  // Dynamically determine the number of lines to display
  let lineCount = 0;

  // Decide dynamically which fields to display
  if (label) lineCount++;
  if (stringId) lineCount++;
  if (trimmedAnnotation) lineCount++;
  
  // Prefer dual expression data if available
  if (healthy_expression !== -1 && healthy_expression !== undefined) {
    lineCount++; // Healthy expression
    lineCount++; // Disease expression
    lineCount++; // Log2 fold change
    lineCount++; // Effect size
  } 
  // Fallback to old expression data
  else if (expression !== -1 && expression !== undefined) {
    lineCount++; // Expression
    if (SD !== -1 && SD !== undefined) lineCount++; // SD
  }

  // Background box dimensions
  const x = Math.round(data.x);
  const y = Math.round(data.y);
  const lineHeight = stringIdSize + 5; // Dynamic line spacing
  const padding = 10; // Padding at top and bottom
  const totalHeight = lineCount * lineHeight + 2 * padding;

  const w = Math.round(textWidth + size / 2 + data.size + 3);
  const h = totalHeight; // Box height matches line count and padding

  // Center the text vertically in the rectangle
  let currentY = y - h / 2 + padding + lineHeight / 2; // Adjust starting Y based on box height

  // Draw the rounded rectangle
  context.beginPath();
  context.fillStyle = '#fff';
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 2;
  context.shadowBlur = 8;
  context.shadowColor = '#000';
  drawRoundRect(context, x, y - h / 2, w, h, 5); // Centered rectangle
  context.closePath();
  context.fill();

  // Reset shadow
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;
  context.shadowBlur = 0;

  context.fillStyle = TEXT_COLOR;

  // Draw label
  if (label) {
    context.font = `${weight} ${size}px ${font}`;
    context.fillText(label, x + data.size + 3, currentY);
    currentY += lineHeight;
  }

  // Draw stringId
  if (stringId) {
    context.font = `${weight} ${stringIdSize}px ${font}`;
    context.fillText(stringId, x + data.size + 3, currentY);
    currentY += lineHeight;
  }

  // Draw annotation
  if (trimmedAnnotation) {
    context.font = `${weight} ${stringIdSize}px ${font}`;
    context.fillText(trimmedAnnotation, x + data.size + 3, currentY);
    currentY += lineHeight;
  }

  // Draw dual expression data if available
  if (healthy_expression !== -1 && healthy_expression !== undefined) {
    context.font = `${weight} ${stringIdSize}px ${font}`;
    
    // Healthy expression
    context.fillText(
      `Healthy: ${healthy_expression.toFixed(2)}`,
      x + data.size + 3,
      currentY
    );
    currentY += lineHeight;
    
    // Disease expression
    context.fillText(
      `Disease: ${disease_expression.toFixed(2)}`,
      x + data.size + 3,
      currentY
    );
    currentY += lineHeight;
    
    // Log2 fold change
    context.fillText(
      `Log2 FC: ${log2_fold_change.toFixed(2)}`,
      x + data.size + 3,
      currentY
    );
    currentY += lineHeight;
    
    // Effect size
    context.fillText(
      `Effect Size: ${effect_size.toFixed(2)}`,
      x + data.size + 3,
      currentY
    );
  }
  // Fallback to old expression data
  else if (expression !== -1 && expression !== undefined) {
    context.font = `${weight} ${stringIdSize}px ${font}`;
    context.fillText(
      `Expression: ${expression.toFixed(2)}`,
      x + data.size + 3,
      currentY
    );
    currentY += lineHeight;
    
    // Draw SD
    if (SD !== -1 && SD !== undefined) {
      context.font = `${weight} ${stringIdSize}px ${font}`;
      context.fillText(
        `Standard Deviation: ${SD.toFixed(2)}`,
        x + data.size + 3,
        currentY
      );
    }
  }
}

/**
 * Custom label renderer
 * @param {CanvasRenderingContext2D} context
 * @param {PartialButFor<NodeDisplayData, "x" | "y" | "size" | "label" | "color">} data
 * @param {Settings} settings
 */
export default function drawLabel(context, data, settings) {
  if (!data.label) return;

  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;

  context.font = `${weight} ${size}px ${font}`;
  const width = context.measureText(data.label).width + 8;

  context.fillStyle = '#ffffffcc';
  context.fillRect(data.x + data.size, data.y + size / 3 - 15, width, 20);

  context.fillStyle = '#000';
  context.fillText(data.label, data.x + data.size + 3, data.y + size / 3);
}

import { useState, useEffect } from 'react';
import { Typography, Box, Button, FormControl, FormLabel, Select, MenuItem, Paper, InputLabel, Divider, Alert } from '@mui/material';
import Models from '_pages/Models/Models';
import InfoTooltipSurrounder from '_components/base/InfoTooltip';
import RowType from '_components/base/table/RowType';
import HeadCellType from '_components/base/table/HeadCellType';

interface TVRoutineProps {
  onSubmit: (model: RowType) => void;
}

const modelsIdHeadCell: HeadCellType = {
  id: '_id',
  numeric: false,
  disablePadding: false,
  label: 'ID',
};

interface TissueInfo {
  id: string;
  name: string;
}

// Define the tissue types available for each high-level type
const tissuesByType: Record<string, TissueInfo[]> = {
  brain: [
    { id: "brain_FCX", name: "Brain - Frontal Cortex" },
    { id: "brain_cereb", name: "Brain - Cerebellum" },
    { id: "brain_motor", name: "Brain - Motor Cortex" }
  ],
  spinal: [
    { id: "spinal_cervical", name: "Spinal Cord - Cervical" },
    { id: "spinal_lumbar", name: "Spinal Cord - Lumbar" }
  ],
  blood: [
    { id: "blood_blood", name: "Blood" }
  ]
};

// Define what high-level tissue types are available for each disease
const availableTissueTypesByDisease: Record<string, string[]> = {
  als: ["brain", "spinal", "blood"],
  parkinsons: ["brain", "blood"],
  alzheimers: ["brain", "blood"],
  ms: ["brain", "spinal", "blood"],
  default: ["brain"] // Default options for any disease
};

export default function TVRoutine({ onSubmit }: TVRoutineProps) {
  const [selectedModel, setSelectedModel] = useState<RowType | null>(null);
  const [selectedTissueType, setSelectedTissueType] = useState<string>("");
  const [availableTissueCategories, setAvailableTissueCategories] = useState<string[]>([]);
  const [availableTissueTypes, setAvailableTissueTypes] = useState<TissueInfo[]>([]);
  const [tissueCategory, setTissueCategory] = useState<string>("");

  // Effect to update available tissue types based on selected model
  useEffect(() => {
    if (selectedModel) {
      // Determine disease from the model, ensuring it's a string
      const modelDisease = selectedModel.disease;
      const disease = typeof modelDisease === 'string' ? modelDisease.toLowerCase() : 'default';
      
      // Get available tissue categories for this disease
      const categories = availableTissueTypesByDisease[disease] || availableTissueTypesByDisease.default;
      setAvailableTissueCategories(categories);
      
      // Reset selections
      setTissueCategory("");
      setSelectedTissueType("");
      setAvailableTissueTypes([]);
    }
  }, [selectedModel]);

  // Effect to update available tissue types when category changes
  useEffect(() => {
    if (tissueCategory && tissueCategory in tissuesByType) {
      const tissues = tissuesByType[tissueCategory as keyof typeof tissuesByType];
      setAvailableTissueTypes(tissues);
      // Default to first tissue type in the category
      if (tissues.length > 0) {
        setSelectedTissueType(tissues[0].id);
      }
    } else {
      setAvailableTissueTypes([]);
      setSelectedTissueType("");
    }
  }, [tissueCategory]);

  // Check if we have enough information to enable the confirm button
  const isButtonDisabled =
    !selectedModel?.[modelsIdHeadCell.id] ||
    selectedModel[modelsIdHeadCell.id] === '' ||
    !selectedTissueType;

  // Handle submission with enhanced model object
  const handleSubmit = () => {
    if (isButtonDisabled) return;
    
    // Create a copy of the selected model and add tissue type information
    const enhancedModel = {
      ...selectedModel,
      tissueType: selectedTissueType
    };
    
    onSubmit(enhancedModel);
  };

  return (
    <>
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h4" component="h1">
          BioTarget Scoring
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Select a tissue type to analyze protein expression data in context of disease.
        </Typography>
      </Box>
      
      {/* Model Selection Section */}
      <Box sx={{ pb: 3, mt: 2 }}>
        <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
          <InfoTooltipSurrounder description="Different models for the same disease may reflect differences in disease indications or population subtypes.">
            <FormLabel>
              <Typography variant="h6" fontWeight="medium" mb={2}>Step 1: Select a Disease Model</Typography>
            </FormLabel>
          </InfoTooltipSurrounder>
          
          <Box>
            <Models
              asPage={false}
              allowCreateProject={false}
              allowDelete={false}
              allowInsert={false}
              allowRename={false}
              allowMultipleSelect={false}
              onRowsSelected={(rows: RowType[]) => {
                if (rows.length !== 1) return setSelectedModel(null);
                setSelectedModel(rows[0]);
              }}
            />
          </Box>
          
          {selectedModel?.name && (
            <Alert severity="info" sx={{ mt: 2 }}>
              Selected Model: <strong>{selectedModel?.name}</strong>
              {selectedModel?.disease && <> for {selectedModel.disease}</>}
            </Alert>
          )}
        </Paper>
      </Box>
      
      {/* Tissue Type Selection Section - Only show when a model is selected */}
      {selectedModel && (
        <Box sx={{ pb: 3 }}>
          <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
            <InfoTooltipSurrounder description="Different tissue types may show different expression patterns and validation results for the same target.">
              <FormLabel>
                <Typography variant="h6" fontWeight="medium" mb={2}>Step 2: Select Tissue Type</Typography>
              </FormLabel>
            </InfoTooltipSurrounder>
            
            {availableTissueCategories.length > 0 ? (
              <>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="tissue-category-label">Tissue Category</InputLabel>
                  <Select
                    labelId="tissue-category-label"
                    id="tissue-category-select"
                    value={tissueCategory}
                    label="Tissue Category"
                    onChange={(e) => setTissueCategory(e.target.value)}
                  >
                    {availableTissueCategories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
                {tissueCategory && availableTissueTypes.length > 0 && (
                  <FormControl fullWidth>
                    <InputLabel id="tissue-type-label">Specific Tissue</InputLabel>
                    <Select
                      labelId="tissue-type-label"
                      id="tissue-type-select"
                      value={selectedTissueType}
                      label="Specific Tissue"
                      onChange={(e) => setSelectedTissueType(e.target.value)}
                    >
                      {availableTissueTypes.map((tissue) => (
                        <MenuItem key={tissue.id} value={tissue.id}>
                          {tissue.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                
                {selectedTissueType && (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    You have selected: <strong>
                      {availableTissueTypes.find(t => t.id === selectedTissueType)?.name}
                    </strong>
                  </Alert>
                )}
              </>
            ) : (
              <Alert severity="warning">
                No tissue types available for this model. Please select a different model.
              </Alert>
            )}
          </Paper>
        </Box>
      )}
      
      {/* Submit Button */}
      <Box sx={{ pb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedTissueType}
          onClick={handleSubmit}
          size="large"
          sx={{ 
            minWidth: '220px', 
            fontSize: '1.1rem',
            mt: 3
          }}
        >
          Continue to BioTarget Scoring
        </Button>
        
        {isButtonDisabled && !selectedModel && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Please select a model first.
          </Typography>
        )}
        
        {isButtonDisabled && selectedModel && !selectedTissueType && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Please select a tissue type.
          </Typography>
        )}
      </Box>
    </>
  );
}

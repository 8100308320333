import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';
import { Box, Typography, Tooltip } from '@mui/material';
import { SCORES_DECIMAL } from './NodeProcessPathsPage';

interface FDAPathData {
  endpoint: string;
  path: string[];
  score: number;
}

interface EndpointHeatmapProps {
  interruptedPaths: Array<{
    endpoint: string;
    score: number;
  }>;
  // Information about protein names
  proteinInfo: {
    [key: string]: {
      preferredName: string;
      [key: string]: any;
    }
  };
  // The target being validated
  targetName: string;
  targetId: string;
  // FDA approved drugs data
  fdaApprovedDrugs?: {
    ensembl_ids?: {
      [key: string]: {
        gene_symbol: string;
        drugs: string[];
        efficacy_score: number;
        endpoint_paths?: FDAPathData[];
      }
    };
    gene_symbols?: {
      [key: string]: {
        ensembl_id: string;
        drugs: string[];
        efficacy_score: number;
        endpoint_paths?: FDAPathData[];
      }
    };
  };
}

/**
 * A component that displays a heatmap showing endpoints on the x-axis 
 * and targets (validated and FDA approved) on the y-axis with scores represented as colors.
 * This allows comparison between validated targets and FDA approved drug targets.
 */
const EndpointHeatmap: React.FC<EndpointHeatmapProps> = ({ 
  interruptedPaths, 
  proteinInfo,
  targetName,
  targetId,
  fdaApprovedDrugs
}) => {
  // Debug logging for FDA drugs data
  React.useEffect(() => {
    if (fdaApprovedDrugs) {
      console.log('EndpointHeatmap received FDA drugs data:', fdaApprovedDrugs);
      
      // Check for ensembl_ids and endpoint_paths
      if (fdaApprovedDrugs.ensembl_ids) {
        console.log('FDA drugs data has ensembl_ids:', Object.keys(fdaApprovedDrugs.ensembl_ids).length);
        
        // Check first ensembl_id for endpoint_paths
        const firstId = Object.keys(fdaApprovedDrugs.ensembl_ids)[0];
        if (firstId) {
          const firstData = fdaApprovedDrugs.ensembl_ids[firstId];
          console.log('First ensembl_id data:', firstData);
          
          if (firstData && firstData.endpoint_paths) {
            console.log('First ensembl_id has endpoint_paths:', firstData.endpoint_paths.length);
          } else {
            console.log('First ensembl_id does NOT have endpoint_paths');
          }
        }
      } else {
        console.log('FDA drugs data has NO ensembl_ids');
      }
    } else {
      console.log('EndpointHeatmap received NO FDA drugs data');
    }
  }, [fdaApprovedDrugs]);
  // Generate the data for the heatmap
  const { endpointNames, rowLabels, heatmapData, textLabels, hoverTexts } = useMemo(() => {
    if (!interruptedPaths || interruptedPaths.length === 0) {
      return { 
        endpointNames: [], 
        rowLabels: [], 
        heatmapData: [[]], 
        textLabels: [[]],
        hoverTexts: [[]]
      };
    }

    // Deduplicate paths by endpoint, keeping the highest scoring path for each endpoint
    const uniqueEndpointMap = new Map<string, { path: any, score: number }>();
    
    interruptedPaths.forEach(path => {
      const endpoint = path.endpoint;
      
      // If this endpoint is not in our map yet, or this path has a higher score than the one we've seen
      if (!uniqueEndpointMap.has(endpoint) || path.score > uniqueEndpointMap.get(endpoint)!.score) {
        uniqueEndpointMap.set(endpoint, { path, score: path.score });
      }
    });
    
    // Convert back to array, keeping only the highest scoring path for each endpoint
    const deduplicatedPaths = Array.from(uniqueEndpointMap.values()).map(entry => entry.path);
    
    // Sort paths by score in descending order
    const sortedPaths = [...deduplicatedPaths].sort((a, b) => b.score - a.score);
    
    // Extract endpoint names and scores
    const endpointNames = sortedPaths.map(path => {
      const name = proteinInfo[path.endpoint]?.preferredName || path.endpoint;
      return name;
    });
    
    // Create mapping from endpoint IDs to indices in the heatmap
    const endpointIdToIndex: {[key: string]: number} = {};
    sortedPaths.forEach((path, index) => {
      endpointIdToIndex[path.endpoint] = index;
    });
    
    console.log('DEBUG: EndpointHeatmap created mapping from endpoint IDs to indices:', endpointIdToIndex);
    
    // Start with the validated target's scores
    const targetScores = sortedPaths.map(path => path.score);
    const heatmapData = [targetScores];
    const textLabels = [targetScores.map(score => score.toFixed(SCORES_DECIMAL))];
    const hoverTexts = [targetScores.map((score, i) => 
      `Endpoint: ${endpointNames[i]}<br>Target: ${targetName}<br>Score: ${score.toFixed(SCORES_DECIMAL)}`
    )];
    
    // Start row labels with the validated target
    const rowLabels = [targetName];
    
    // Find FDA drug targets that have endpoint paths
    const fdaDrugTargets = new Map<string, {
      label: string;
      endpointPaths: FDAPathData[];
      drugsLabel: string;
    }>();
    
    if (fdaApprovedDrugs?.ensembl_ids) {
      console.log('Processing FDA drugs ensembl_ids for heatmap...');
      
      Object.entries(fdaApprovedDrugs.ensembl_ids).forEach(([ensemblId, data]) => {
        console.log(`Processing ensembl_id: ${ensemblId}`, data);
        
        // Safety check for endpoint_paths - make sure it has the right structure
        if (data.endpoint_paths && Array.isArray(data.endpoint_paths) && data.endpoint_paths.length > 0) {
          console.log(`Found ${data.endpoint_paths.length} endpoint paths for ${ensemblId}`);
          
          // Use gene symbol as the label if available
          const label = data.gene_symbol || ensemblId.split('.').pop() || ensemblId;
          const drugs = Array.isArray(data.drugs) ? data.drugs : []; 
          
          fdaDrugTargets.set(ensemblId, {
            label: `${label} (${drugs.join(', ')})`,
            endpointPaths: data.endpoint_paths,
            drugsLabel: drugs.join(', ')
          });
        } else {
          console.log(`No endpoint paths found for ${ensemblId}`);
        }
      });
    } else {
      console.log('No FDA drugs ensembl_ids available for heatmap');
    }
    
    // Add FDA drug target rows to the heatmap
    fdaDrugTargets.forEach(({label, endpointPaths, drugsLabel}, ensemblId) => {
      // Skip the current target if it's already displayed
      if (ensemblId === targetId) return;
      
      // Debug the FDA endpoint paths
      console.log(`DEBUG: Processing FDA drug target ${ensemblId} with ${endpointPaths.length} endpoint paths`);
      console.log('DEBUG: Sample FDA endpoint paths:', endpointPaths.slice(0, 2));
      
      // Create a row for this FDA drug target
      const fdaScores = new Array(endpointNames.length).fill(0);
      const fdaTextLabels = new Array(endpointNames.length).fill('-');
      const fdaHoverTexts = new Array(endpointNames.length).fill('');
      
      // Map FDA endpoint paths to the endpoints in our heatmap
      endpointPaths.forEach((pathData: FDAPathData) => {
        const endpointIndex = endpointIdToIndex[pathData.endpoint];
        console.log(`DEBUG: Mapping FDA endpoint ${pathData.endpoint} to index ${endpointIndex}`);
        
        if (endpointIndex !== undefined) {
          fdaScores[endpointIndex] = pathData.score;
          fdaTextLabels[endpointIndex] = pathData.score.toFixed(SCORES_DECIMAL);
          fdaHoverTexts[endpointIndex] = `Endpoint: ${endpointNames[endpointIndex]}<br>Target: ${label}<br>Score: ${pathData.score.toFixed(SCORES_DECIMAL)}<br>Drug: ${drugsLabel}`;
        } else {
          // Handle case where the endpoint doesn't match exactly - try to find by name
          const endpointName = proteinInfo[pathData.endpoint]?.preferredName;
          if (endpointName) {
            const nameIndex = endpointNames.findIndex(name => name === endpointName);
            if (nameIndex >= 0) {
              console.log(`DEBUG: Found match by name at index ${nameIndex}`);
              fdaScores[nameIndex] = pathData.score;
              fdaTextLabels[nameIndex] = pathData.score.toFixed(SCORES_DECIMAL);
              fdaHoverTexts[nameIndex] = `Endpoint: ${endpointNames[nameIndex]}<br>Target: ${label}<br>Score: ${pathData.score.toFixed(SCORES_DECIMAL)}<br>Drug: ${drugsLabel}`;
            }
          } else {
            console.log(`DEBUG: Could not find endpoint ${pathData.endpoint} in heatmap`);
          }
        }
      });
      
      // Only add this row if it has at least one score (otherwise it would be all zeroes)
      if (fdaScores.some(score => score > 0)) {
        // Add this row to our heatmap data
        heatmapData.push(fdaScores);
        textLabels.push(fdaTextLabels);
        hoverTexts.push(fdaHoverTexts);
        rowLabels.push(label);
      } else {
        console.log(`DEBUG: Skipping FDA drug target ${ensemblId} because it has no matching endpoints`);
      }
    });
    
    return { endpointNames, rowLabels, heatmapData, textLabels, hoverTexts };
  }, [interruptedPaths, proteinInfo, targetName, targetId, fdaApprovedDrugs]);

  // Don't render if there's no data
  if (interruptedPaths.length === 0) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="body1" color="text.secondary">
          No endpoint data available for heatmap visualization.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="endpoint-heatmap-container" sx={{ mt: 2, mb: 2, p: 3 }}>
      <Typography variant="h6" gutterBottom sx={{ mb: 2, textAlign: 'center', color: 'var(--primary-dark)' }}>
        Disease Progression Nodes Comparison
      </Typography>
      
      {rowLabels.length > 1 ? (
        <Typography variant="body2" gutterBottom sx={{ mb: 2, textAlign: 'center' }}>
          Comparing your target with FDA approved drug targets for the same endpoints
        </Typography>
      ) : (
        <Typography variant="body2" gutterBottom sx={{ mb: 2, textAlign: 'center' }}>
          No FDA approved drug targets with endpoint path data available for comparison
        </Typography>
      )}
      
      <Box sx={{ 
        width: '100%', 
        overflowX: 'auto', 
        display: 'flex', 
        justifyContent: 'center',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'var(--primary-light)',
          borderRadius: '4px'
        }
      }}>
        <Plot
          data={[
            {
              z: heatmapData,
              x: endpointNames,
              y: rowLabels,
              type: 'heatmap',
              colorscale: 'Reds',
              showscale: true,
              hoverinfo: 'text',
              text: textLabels as any, // Cast to any to bypass type checking
              hovertext: hoverTexts as any, // Cast to any to bypass type checking
              colorbar: {
                title: 'Score',
                titleside: 'right',
                tickformat: `.${SCORES_DECIMAL}f`,
                len: 0.9,
                thickness: 20,
                titlefont: { size: 14, family: 'Arial, sans-serif', color: '#333333' },
                tickfont: { size: 12, family: 'Arial, sans-serif', color: '#333333' },
                nticks: 7,
                ticklen: 6,
                tickwidth: 1,
                ticks: 'outside',
                outlinewidth: 0,
                y: 0.5,
                ypad: 10,
                xpad: 8
              },
              xgap: 5,
              ygap: 5
            }
          ]}
          layout={{
            title: '',
            margin: { l: 150, r: 50, t: 30, b: 150 },
            xaxis: {
              title: { 
                text: 'Endpoints',
                font: { size: 14 }
              },
              tickangle: -45,
              tickfont: { size: 12 },
              automargin: true
            },
            yaxis: {
              title: {
                text: 'Targets',
                font: { size: 14 }
              },
              tickfont: { size: 12 },
              automargin: true
            },
            autosize: true
          }}
          config={{
            responsive: true,
            displayModeBar: true,
            displaylogo: false,
            modeBarButtonsToRemove: ['lasso2d', 'select2d']
          }}
          style={{ 
            width: '100%',
            minHeight: '250px',
            maxHeight: rowLabels.length > 2 ? '400px' : '300px'
          }}
          useResizeHandler={true}
        />
      </Box>
      
      {rowLabels.length > 1 && (
        <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block', textAlign: 'center' }}>
          The heatmap shows pathway scores from targets (rows) to disease progression nodes (columns).
          Higher scores (darker colors) indicate stronger connections.
        </Typography>
      )}
    </Box>
  );
};

export default EndpointHeatmap; 
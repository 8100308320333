import { Content } from 'pdfmake/interfaces';

interface Definition {
  term: string;
  definition: string;
}

interface Abbreviation {
  acronym: string;
  fullName: string;
}

// Function to create glossary content for the PDF report
export function createGlossarySection(): Content {
  // Abbreviation definitions
  const abbreviations: Abbreviation[] = [
    { acronym: 'STRING', fullName: 'Search Tool for the Retrieval of Interacting Genes/Proteins, a database of known and predicted protein-protein interactions.' },
    { acronym: 'KEGG', fullName: 'Kyoto Encyclopedia of Genes and Genomes, a collection of various databases, including KEGG PATHWAY, which describes known disease pathways.' },
    { acronym: 'TPM', fullName: 'Transcripts Per Million, a normalization method for RNA sequencing data that accounts for sequencing depth and gene length.' },
    { acronym: 'nTPM', fullName: 'Normalized Transcripts Per Million, a further normalized version of TPM used for cross-sample comparisons.' },
    { acronym: 'ALS', fullName: 'Amyotrophic Lateral Sclerosis, a neurodegenerative disease affecting motor neurons.' },
    { acronym: 'FCX', fullName: 'Frontal Cortex' },
    { acronym: 'Motor CX', fullName: 'Motor Cortex' },
    { acronym: 'Lumbar SC', fullName: 'Lumbar Spinal Cord' },
    { acronym: 'Cervical SC', fullName: 'Cervical Spinal Cord' },
    { acronym: 'CNS', fullName: 'Central Nervous System' },
  ];

  // Term definitions
  const definitions: Definition[] = [
    { term: 'Node', definition: 'A protein, generally also identified with its associated gene.' },
    { term: 'Interaction', definition: 'A protein-protein interaction, either from the STRING database (based on experimental evidence), or from our AI-model.' },
    { term: 'Target', definition: 'A specific protein or gene implicated in the disease\'s pathogenesis. It is the bio-molecular entity that a drug interacts with to produce a therapeutic effect.' },
    { term: 'Canonical pathway', definition: 'A well-characterized, conserved biological pathway with established functional annotations, often curated in pathway databases such as KEGG or Reactome.' },
    { term: 'Disease progression node', definition: 'A node representing the end of a canonical pathway of a disease. In a cascade of biological interactions, a disease progression node is the closest node (i.e. directly pointing to) to disease phenotypes.' },
    { term: 'Interaction confidence level', definition: 'A quantitative measure of the reliability of interaction existing between a pair of biological molecule. It is computed based on experimental or computational evidence. It can be interpreted as a probability of the interaction.' },
    { term: 'Path', definition: 'A series of protein-protein interactions. In this report, one extremity of a path is a target, and the other is a disease progression node.' },
    { term: 'Path score', definition: 'For a given path and a given sample, the score is a value representing both the confidence of the existence of each of its interactions, as well as the level of dysregulation of the proteins. Using these, one can compute various other scores, either for targets, for target-disease progression node pairs, or for paths. Those path scores are computed over all samples per tissue type.' },
    { term: 'Neurological Control (NEURCTL)', definition: 'Refers to a control sample taken from a patient who passed away due to a condition unrelated to any diagnosed neurological disease.' },
  ];

  // Create the glossary content
  const content: Content = {
    stack: [
      // Title
      {
        text: 'Glossary',
        style: 'sectionHeader',
        margin: [0, 10, 0, 10],
      },
      // Introduction
      {
        text: 'This glossary provides definitions of key terms and abbreviations used throughout this report.',
        style: 'normal',
        margin: [0, 0, 0, 15],
      },
      // Abbreviations section
      {
        text: 'Abbreviations',
        style: 'subheader',
        margin: [0, 5, 0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: ['20%', '80%'],
          body: [
            [
              { text: 'Acronym', style: 'tableHeader', fillColor: '#f0f0f0' },
              { text: 'Definition', style: 'tableHeader', fillColor: '#f0f0f0' }
            ],
            ...abbreviations.map(item => [
              { text: item.acronym, style: 'tableCell' },
              { text: item.fullName, style: 'tableCell' }
            ])
          ]
        },
        layout: {
          fillColor: function(rowIndex) {
            return (rowIndex % 2 === 0) ? '#ffffff' : '#f9f9f9';
          }
        },
        margin: [0, 0, 0, 20],
      },
      // Definitions section
      {
        text: 'Definitions',
        style: 'subheader',
        margin: [0, 5, 0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: ['25%', '75%'],
          body: [
            [
              { text: 'Term', style: 'tableHeader', fillColor: '#f0f0f0' },
              { text: 'Definition', style: 'tableHeader', fillColor: '#f0f0f0' }
            ],
            ...definitions.map(item => [
              { text: item.term, style: 'tableCell', bold: true },
              { text: item.definition, style: 'tableCell' }
            ])
          ]
        },
        layout: {
          fillColor: function(rowIndex) {
            return (rowIndex % 2 === 0) ? '#ffffff' : '#f9f9f9';
          }
        },
      },
    ],
    pageBreak: 'before'
  };

  return content;
} 
import { Router, Switch, Route } from 'react-router-dom';
import history from '_helpers/history';
import config from '_configs';
import FileUploads from '_pages/FileUploads/FileUploads';
import Visualization from '_pages/Visualization/Visualization';
import VisProject from '_pages/Visualization/VisProject';
import TVProject from '_pages/TargetValidation/TVProject';
import TargetValidation from '_pages/TargetValidation/TargetValidation';
import GraphDiseasePage from '_pages/TargetValidation/GraphDisease/GraphDiseasePage';
import NodeProcessPathsPage from '_pages/TargetValidation/NodeProcessComponents/NodeProcessPathsPage';
import Profile from '_pages/Profile/Profile';
import FrontPage from '_pages/Frontpage/Frontpage';
import seedrandom from 'seedrandom';
import { ToastContainer } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Loading from '_components/Loading';
import Footer from '_components/layout/Footer';
import PermanentDrawerLeft from '_components/layout/DrawerComponent';
import SSEHub from '_components/sse/SSEHub';
import RestrictedRouteGuard from '_components/RestrictedRouteGuard';
import 'react-toastify/dist/ReactToastify.css';
import '_css/layout.css';
import Projects from '_pages/Projects/Projects';
import Models from '_pages/Models/Models';
import Biomarkers from '_pages/biomarkers/Biomarkers';
import Home from '_pages/Home';
import theme from '_theme/theme';
import Help from '_pages/Help';
import BMProject from '_pages/biomarkers/project/BMProject';
import NotFoundPage from '_pages/FileUploads/NotFoundPage';
import React, { useEffect, useState } from 'react';
import EmailVerificationWait from '_pages/EmailVerificationWait';
import AccountSetupLoader from '_components/AccountSetupLoader';

seedrandom(process.env.REACT_APP_RANDOM_SEED || 1, { global: true });

function App() {
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  const [accountReady, setAccountReady] = useState(false);
  useEffect(() => {
    if (!isAuthenticated || !user || !user.email || accountReady) 
      return;
    
    const checkAccount = async () => {
      console.log('Polling account for:', user.email);
      try {
        const res = await fetch(`/api/harbor/users/me?email=${user.email}`);
        console.log('Response status:', res.status);
        if (res.ok) {
          const data = await res.json();
          console.log('Response data:', data);
          if (data.email) {
            setAccountReady(true);
          }}
      } catch (e) {
        console.log('Error fetching account:', e);
      }
    };
  
    if (isAuthenticated && user && user.email) {
      checkAccount();
      const interval = setInterval(checkAccount, 2000);
      return () => clearInterval(interval);
    }
  }, [isAuthenticated, user, accountReady]);
  
  const searchParams = new URLSearchParams(window.location.search);
  const auth0Error = searchParams.get('error_description');
  const isEmailVerifyError = auth0Error && auth0Error.includes('Please verify your email');

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    if (error.message?.includes('Please verify your email')) {
      return <EmailVerificationWait />;
    }
    return <div>Oops... {error.message}</div>;
  }

  if (isEmailVerifyError) {
    return <EmailVerificationWait />;
  }

  if (!isAuthenticated) {
    return <FrontPage />;
  }

  if (isAuthenticated && user && !user.email_verified) {
    return <EmailVerificationWait />;
  }

  if (isAuthenticated && user && user.email_verified && !accountReady) {
    return <AccountSetupLoader  userEmail={user.email} />;
  }

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Box sx={{ display: 'flex', paddingTop: '35px' }}>
          <PermanentDrawerLeft />
          <SSEHub />
          <Box component="main" sx={{ flexGrow: 1, pl: 3, pr: 3, pb: 3 }}>
            <Switch>
              <Route exact path={config.clientUrls.TargetValidation} component={TargetValidation} />
              <RestrictedRouteGuard exact path={config.clientUrls.FILES} component={FileUploads} />
              <RestrictedRouteGuard exact path={config.clientUrls.VISUALIZATION} component={Visualization} />
              <RestrictedRouteGuard exact path={config.clientUrls.VISUALIZATION_PROJECT(':projectId')} component={VisProject} />
              <Route exact path={config.clientUrls.TARGET_VALIDATION_PROJECT(':projectId')} component={TVProject} />
              <Route exact path={config.clientUrls.HOME} component={Home} />
              <Route exact path={config.clientUrls.GRAPHS_DISEASE(':modelId')} component={GraphDiseasePage} />
              <Route exact path={config.clientUrls.PROFILE} component={Profile} />
              <Route exact path={config.clientUrls.PROJECTS} component={Projects} />
              <RestrictedRouteGuard exact path={config.clientUrls.MODELS} component={Models} />
              <RestrictedRouteGuard exact path={config.clientUrls.BIOMARKERS} component={Biomarkers} />
              <RestrictedRouteGuard exact path={config.clientUrls.BIOMARKERS_PROJECT(':projectId')} component={BMProject} />
              <Route exact path={config.clientUrls.HELP} component={Help} />
              <Route
                exact
                path={config.clientUrls.NODE_PROCESS_PATHS__TV_PROJECT(':projectId')}
                component={NodeProcessPathsPage}
              />
              <Route exact path="*" component={NotFoundPage} />
            </Switch>
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;

import { useState } from 'react';
import { ControlsContainer, FullScreenControl, SigmaContainer, ZoomControl } from '@react-sigma/core';
import { DirectedGraph } from 'graphology';
import GraphEventsController from '_pages/TargetValidation/GraphDisease/Graphology/GraphEventsController.Demo';
import GraphDataController from './Graphology/GraphDataController';
import GraphSettingsController from '_pages/TargetValidation/GraphDisease/Graphology/GraphSettingsController';
import { IconButton, Paper, Tooltip } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import customTheme from '_theme/theme';
import { Box } from '@mui/system';
import NodeCounter from './Graphology/UIControls/NodeCounter';
import '@react-sigma/core/lib/react-sigma.min.css';
import SearchField from './Graphology/UIControls/SearchField';
import Filters from './Graphology/UIControls/Filters';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TuneIcon from '@mui/icons-material/Tune';
import GraphLegendTable from './GraphLegendTable';
import ViewListIcon from '@mui/icons-material/ViewList';

function CollapsibleFilters({ fullNodeList, onSearch, filters }) {
  const [toggleFilters, setToggleFilters] = useState(true);
  function onToggle() {
    setToggleFilters(!toggleFilters);
  }
  return (
    <Box>
      <ControlsContainer className="noBorderControlsContainer" position="top-right">
        <Box sx={{ float: 'right' }}>
          <Tooltip title="Show/Hide Filters">
            <IconButton onClick={onToggle}>
              {toggleFilters ? <VisibilityOffIcon /> : <TuneIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        {toggleFilters && (
          <Paper
            variant="outlined"
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
              backgroundColor: customTheme.palette.background.default,
            }}
          >
            <Box>
              {/* Pass fullNodeList, onSearch and filters to SearchField */}
              <SearchField filters={filters} fullNodeList={fullNodeList} onSearch={onSearch} />
              <Filters />
            </Box>
          </Paper>
        )}
      </ControlsContainer>
    </Box>
  );
}


function CollapsibleLegend() {
  const [toggleLegend, setToggleLegend] = useState(true);
  function onToggle() {
    setToggleLegend(!toggleLegend);
  }
  return (
    <Box>
      <ControlsContainer className="noBorderControlsContainer" position="top-left">
        <Box sx={{ float: 'left' }}>
          <Tooltip title="Show/Hide Legend">
            <IconButton onClick={() => onToggle()}>
              {!toggleLegend ? <ViewListIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        {toggleLegend ? <GraphLegendTable isCompact={true}></GraphLegendTable> : <></>}
      </ControlsContainer>
    </Box>
  );
}

const SIMGA_SETTINGS = {
  labelColor: { color: "#2E8B57"},
  defaultEdgeType: 'arrow',
  defaultEdgeColor: '#bcbcbc',
  labelDensity: 0.07,
  labelGridCellSize: 60,
  labelFont: 'Lato, sans-serif',
  zIndex: true,
};

function SigmaDiseaseGraph({ diseaseData, fullNodeList, onGetNodeInfo, onResetEdges, onNodeClick, onSearch }) {
  const [hoveredNode, setHoveredNode] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <SigmaContainer
      graphOptions={{ type: 'directed' }}
      settings={SIMGA_SETTINGS}
      style={{
        border: '1px solid grey',
        backgroundColor: darkMode ? customTheme.palette.primary.main : customTheme.palette.primary.default,
      }}
      graph={DirectedGraph}
    >
      <NodeCounter />
      <ControlsContainer position="bottom-right" className="noBorderControlsContainer">
        <Paper variant="outlined" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
          <ZoomControl />
          <FullScreenControl />
          <Tooltip title="Toggle Dark Mode">
            <IconButton onClick={toggleDarkMode} sx={{ padding: 0.5, margin: 0 }}>
              {darkMode ? <Brightness7Icon fontSize="small" /> : <Brightness4Icon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Paper>
      </ControlsContainer>
      {/* Pass the fullNodeList and onSearch callback here */}
      <CollapsibleLegend />
      <CollapsibleFilters fullNodeList={fullNodeList} onSearch={onSearch} filters={diseaseData} />
      <GraphSettingsController hoveredNode={hoveredNode} darkMode={darkMode} />
      <GraphDataController diseaseData={diseaseData} darkMode={darkMode} />
      <GraphEventsController
        onGetNodeInfo={onGetNodeInfo}
        setHoveredNode={setHoveredNode}
        onResetEdges={onResetEdges}
        onNodeClick={onNodeClick}
      />
    </SigmaContainer>
  );
}

export default SigmaDiseaseGraph;

import { Box, Chip, Stack } from '@mui/material';
import HeadCellType from '_components/base/table/HeadCellType';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import ContentTable from '_components/base/table/ContentTable';
import { isInternalIdNameObjArrayType } from '_components/base/table/InternalIdNameObjAPI';
import { withServiceCallHandling } from '_helpers/decorators';
import baseModelsServices from '_services/harbor/basemodels.service';
import { useIsSandboxUser } from '_hooks/useIsSandboxUser';

const defaultIdHeadCell: HeadCellType = {
  id: '_id',
  numeric: false,
  disablePadding: false,
  label: 'ID',
};

const defaultHeadCells: HeadCellType[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    renderElementImpl: (name: RowElementType, obj: RowType) => {
      return (
        <Box sx={{ pl: 1, display: 'flex', alignItems: 'center' }}>
          <span style={{ 
            color: obj.sandbox ? 'grey.500' : 'inherit',
            fontWeight: 500
          }}>{name as String}</span>
          
          {obj.placeholder && (
            <Box 
              component="span" 
              sx={{ 
                ml: 1, 
                fontSize: '0.7rem', 
                color: 'text.secondary',
                bgcolor: 'action.hover',
                px: 0.8,
                py: 0.3,
                borderRadius: '4px'
              }}
            >
              Coming Soon
            </Box>
          )}
        </Box>
      );
    },
  },
  { id: 'current_version', numeric: false, disablePadding: false, label: 'Version' },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    isDate: true,
  },
  { 
    id: 'disease', 
    numeric: false, 
    disablePadding: false, 
    label: 'Disease',
    renderElementImpl: (disease: RowElementType, obj: RowType) => {
      // Function to determine color based on disease
      const getChipColor = (diseaseName: string) => {
        const lowerDisease = diseaseName.toLowerCase();
        if (lowerDisease.includes('als') || lowerDisease.includes('amyotrophic')) {
          return { light: '#E3F2FD', dark: '#2196F3' }; // Blue
        } else if (lowerDisease.includes('breast')) {
          return { light: '#FCE4EC', dark: '#E91E63' }; // Pink
        } else if (lowerDisease.includes('lung')) {
          return { light: '#E8F5E9', dark: '#4CAF50' }; // Green
        } else if (lowerDisease.includes('alzheimer')) {
          return { light: '#EDE7F6', dark: '#673AB7' }; // Purple
        }
        return { light: '#EEEEEE', dark: '#757575' }; // Grey default
      };
      
      const colors = getChipColor(disease as string);
      
      return (
        <Chip 
          label={disease as string} 
          size="small"
          sx={{ 
            backgroundColor: colors.light,
            color: colors.dark,
            fontWeight: 500,
            '& .MuiChip-label': { 
              px: 1 
            }
          }}
        />
      );
    }
  }
];

export interface BaseModelsProps {
  asPage?: boolean;
  tableTitle?: string;
  entityName?: string;
  allowInsert?: boolean;
  allowDelete?: boolean;
  allowFilter?: boolean;
  allowRename?: boolean;
  allowSelect?: boolean;
  allowCreateProject?: boolean;
  allowMultipleSelect?: boolean;
  initRows?: RowType[];
  initRowsSelected?: RowType[];
  headCells?: HeadCellType[];
  idHeadCell?: HeadCellType;
  onRowsSelected?: (rows: RowType[]) => void;
}
function BaseModels({
  initRows = [],
  initRowsSelected = [],
  tableTitle = 'Available Models',
  entityName = 'Model',
  allowSelect = false,
  allowFilter = true,
  headCells = defaultHeadCells,
  idHeadCell = defaultIdHeadCell,
  onRowsSelected,
}: BaseModelsProps) {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const isSandboxUser: boolean = useIsSandboxUser();

  const [baseModels, setBaseModels] = useState<RowType[]>(initRows);
  const [rowsSelected, setRowsSelected] = useState<RowType[]>(initRowsSelected);

  async function listBaseModelsImpl() {
    const accessToken = await getAccessTokenSilently();
    return baseModelsServices.listBaseModels(accessToken);
  }
  const listBaseModels = withServiceCallHandling(
    listBaseModelsImpl,
    (data) => {
      console.log("Original base models data:", data);
      
      // Filter out any "Mock Test Graph" model
      let dataImpl = data.filter((model: RowType) => 
        !model.name?.toString().toLowerCase().includes('mock test graph'));
      
      console.log("After filtering mock models:", dataImpl);
      
      // Check if we have an ALS model
      const hasALS = dataImpl.some((model: RowType) => 
        model.name?.toString().toLowerCase().includes('als') || 
        model.disease?.toString().toLowerCase().includes('als'));
      
      // Add placeholder models for each disease if they don't exist
      if (!hasALS) {
        console.log("Adding ALS model placeholder");
        dataImpl.push({
          _id: 'als-model',
          name: 'ALS Model',
          disease: 'Amyotrophic Lateral Sclerosis',
          current_version: '1.0',
          created_at: new Date().toISOString(),
          selectable: true,
          active: true,
          projects: [
            { _id: 'als-project-1', name: 'ALS Research Project' },
            { _id: 'als-project-2', name: 'ALS Target Validation' }
          ]
        });
      }
      
      // Apply sandbox user filtering
      if (isSandboxUser) {
        dataImpl = dataImpl.map((d: RowType) => ({ ...d, selectable: d.sandbox }));
      }
      
      console.log("Final base models:", dataImpl);
      setBaseModels(dataImpl);
    },
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    if (!initRows) listBaseModels();
  }, []);

  function handleRowsSelected(selRows: RowType[]) {
    if (onRowsSelected) onRowsSelected(selRows);
    setRowsSelected(selRows);
  }

  const baseModelBody = (
    <ContentTable
      idHeadCell={idHeadCell}
      headCells={headCells}
      rows={baseModels}
      rowsSelected={rowsSelected}
      allowSelect={allowSelect}
      allowFilter={allowFilter}
      onRowsSelected={handleRowsSelected}
      InsertRecordComponent={false}
      DeleteRecordComponent={false}
      renameRecordFunction={undefined}
      tableTitle={tableTitle}
      entityName={entityName}
      allowMultipleSelect={false}
    />
  );
  return baseModelBody;
}

export default BaseModels;

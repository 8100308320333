import { useSigma } from '@react-sigma/core';
import { useEffect } from 'react';

import { drawHover } from '_helpers/canvasUtils';
import useDebounce from '_hooks/useDebounce';
import { graphTheme } from '_theme/graphTheme';

const NODE_FADE_COLOR = '#bbb';
const EDGE_FADE_COLOR = '#eee';

function GraphSettingsController({ children, hoveredNode }) {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  // Here we debounce the value to avoid having too much highlights refresh when
  // moving the mouse over the graph:
  const debouncedHoveredNode = useDebounce(hoveredNode, 40);

    /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    sigma.setSetting('hoverRenderer', (context, sigmaData, settings) => {
      // Get all node data including dual expression data
      const nodeData = sigma.getNodeDisplayData(sigmaData.key);
      const nodeAttributes = sigma.getGraph().getNodeAttributes(sigmaData.key);
      
      // Debug logging for hover data
     
      
      // Debug logging for dual expression data
   
      
      // Combine display data with node attributes to ensure all dual expression data is included
      const combinedData = { 
        ...nodeData, 
        ...sigmaData,
        // Explicitly include dual expression data
        healthy_expression: nodeAttributes.healthy_expression,
        disease_expression: nodeAttributes.disease_expression,
        log2_fold_change: nodeAttributes.log2_fold_change,
        effect_size: nodeAttributes.effect_size,
        healthy_CI: nodeAttributes.healthy_CI,
        disease_CI: nodeAttributes.disease_CI
      };
      
      
      return drawHover(context, combinedData, settings);
    });
  }, [sigma, graph]);

    /**
   * Update node and edge reducers when a node is hovered, to highlight its
   * neighborhood:
   */
  useEffect(() => {
    const hoveredColor = debouncedHoveredNode ? sigma.getNodeDisplayData(debouncedHoveredNode)?.color : '';
    const zoomThreshold = 0.6; // Define the minimum zoom level to display labels
    const zoomLevel = sigma.getCamera().ratio; // Current zoom level
  
    sigma.setSetting(
      'nodeReducer',
      debouncedHoveredNode
        ? (node, data) => {
            let isNeighbor = false;
            const isHovered = node === debouncedHoveredNode;

            // Use forEachNeighbor to check for visible edges
            if (!isHovered) {
              graph.forEachNeighbor(debouncedHoveredNode, (neighbor) => {
                if (neighbor === node) {
                  const edge = graph.edge(debouncedHoveredNode, neighbor) || graph.edge(neighbor, debouncedHoveredNode);
                  if (edge && !graph.getEdgeAttribute(edge, 'hidden')) {
                    isNeighbor = true;
                  }
                }
              });
            }

            return isHovered || isNeighbor
              ? { ...data, zIndex: 1, label: data.label, forceLabel: zoomLevel > zoomThreshold ? false : true } // Mark label to force display
              : {
                  ...data,
                  zIndex: 0,
                  label: '', // Hide label for non-neighboring nodes
                  color: NODE_FADE_COLOR,
                  image: null,
                  highlighted: false,
                };
          }
        : null,
    );

    sigma.setSetting(
      'edgeReducer',
      debouncedHoveredNode
        ? (edge, data) =>
            graph.hasExtremity(edge, debouncedHoveredNode)
              ? { ...data, color: data.color, size: 3 }
              : { ...data, color: EDGE_FADE_COLOR, hidden: true }
        : null,
    );
  }, [debouncedHoveredNode]);

  return children;
}

export default GraphSettingsController;

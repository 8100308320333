import React, { useEffect, useState } from 'react';
import history from '_helpers/history';
import { 
  Card, CardContent, CardMedia, Typography, CardActionArea, 
  Container, Divider, Grid, Box, Button, Paper, Chip, 
  useTheme, useMediaQuery, Avatar
} from '@mui/material';
import config from '_configs';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import BiotechIcon from '@mui/icons-material/Biotech';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DatasetIcon from '@mui/icons-material/Dataset';
import ScienceIcon from '@mui/icons-material/Science';

// Particle Canvas for background effect
const ParticleCanvas = () => {
  useEffect(() => {
    const canvas = document.getElementById('particle-canvas') as HTMLCanvasElement;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const particles: {x: number, y: number, size: number, speedX: number, speedY: number, color: string}[] = [];
    const particleCount = 40;
    
    // Set canvas size
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    
    // Create particles
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 2 + 1,
        speedX: (Math.random() - 0.5) * 0.5,
        speedY: (Math.random() - 0.5) * 0.5,
        color: 'rgba(17, 48, 70, 0.4)' // Using the primary color with transparency
      });
    }
    
    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      particles.forEach(particle => {
        // Draw particle
        ctx.fillStyle = particle.color;
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fill();
        
        // Move particle
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        
        // Wrap around screen edges
        if (particle.x < 0) particle.x = canvas.width;
        if (particle.x > canvas.width) particle.x = 0;
        if (particle.y < 0) particle.y = canvas.height;
        if (particle.y > canvas.height) particle.y = 0;
        
        // Draw connections between particles
        particles.forEach(otherParticle => {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < 150) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(17, 48, 70, ${0.1 * (1 - distance/150)})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });
    };
    
    animate();
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);
  
  return <canvas id="particle-canvas" style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

// Motion-enabled Card for extra fluid animations
const MotionCard = motion(Card);

// Styled StatsCard with hover effect
const StatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
  borderRadius: '16px',
  transition: 'all 0.3s ease',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 36px rgba(0, 0, 0, 0.15)',
  },
}));

// Hero section with a blurred translucent background overlay
const HeroSection = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: '7rem 2rem',
  background: 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(12px)',
  borderRadius: '30px',
  marginBottom: theme.spacing(6),
  boxShadow: '0 20px 80px rgba(17, 48, 70, 0.12)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    left: '-50%',
    width: '200%',
    height: '200%',
    background: 'radial-gradient(circle at center, rgba(254, 206, 97, 0.08) 0%, rgba(17, 48, 70, 0.03) 50%, rgba(255, 255, 255, 0) 100%)',
    zIndex: 0,
  },
}));

// Gradient overlay for hero section
const GradientOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(135deg, rgba(17, 48, 70, 0.05) 0%, rgba(254, 206, 97, 0.05) 100%)',
  zIndex: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, rgba(17, 48, 70, 0.6) 0%, rgba(254, 206, 97, 0.6) 100%)',
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
  }
});

// Styled CardActionArea with subtle hover transform
const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  transition: 'all 0.4s ease',
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: theme.shadows[8],
  },
}));

// Enhanced card styling
const EnhancedCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  height: '100%',
  '&:hover': {
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
  },
}));

// Bold card title styling
const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(1),
}));

// Fancy Section Divider
const FancyDivider = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    height: '1px',
    background: theme.palette.divider,
  },
}));

// Section Title
const SectionTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  position: 'relative',
  marginBottom: theme.spacing(4),
  fontWeight: 600,
  '&::after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    bottom: '-12px',
    width: '60px',
    height: '3px',
    background: theme.palette.primary.main,
    transform: 'translateX(-50%)',
    borderRadius: '2px',
  },
}));

// Data for the primary cards
const cardsData = [
  {
    name: 'BioTarget Scoring',
    pathurl: config.clientUrls.TargetValidation,
    descriptionText: 'Disease models to score target efficacy',
    imagePath: '/assets/img/cards/targetvalidation.webp',
    icon: <ScienceIcon fontSize="large" />,
    restricted: false
  },
  {
    name: 'Visualization',
    pathurl: config.clientUrls.VISUALIZATION,
    descriptionText: 'Visualize your multiomics data in innovative ways using advanced plotting techniques.',
    imagePath: '/assets/img/cards/visualization.webp',
    icon: <TrendingUpIcon fontSize="large" />,
    restricted: true
  },
  {
    name: 'Omics Signatures Tool',
    pathurl: config.clientUrls.BIOMARKERS,
    descriptionText: 'Predict Biomarkers to identify signatures for disease progression drugs response, resistance, and more',
    imagePath: '/assets/img/cards/biomarkers.jpg',
    icon: <BiotechIcon fontSize="large" />,
    restricted: true
  },
];

// Data for the secondary cards
const secondaryCardsData = [
  {
    name: 'Models',
    pathurl: config.clientUrls.MODELS,
    descriptionText: 'Explore diverse disease models or build your own.',
    imagePath: '/assets/img/cards/models.webp',
    icon: <DatasetIcon fontSize="large" />,
    restricted: true
  },
  {
    name: 'My Projects',
    pathurl: config.clientUrls.PROJECTS,
    descriptionText: 'Effortlessly manage and resume your projects.',
    imagePath: '/assets/img/cards/projects.webp',
    icon: <DatasetIcon fontSize="large" />,
    restricted: false
  },
  {
    name: 'My Data',
    pathurl: config.clientUrls.FILES,
    descriptionText: 'Upload and curate your data with ease.',
    imagePath: '/assets/img/cards/data.webp',
    icon: <DatasetIcon fontSize="large" />,
    restricted: true
  },
];

// Stats data
const statsData = [
  { 
    label: 'Proteins Analyzed', 
    value: '20K+', 
    icon: <BiotechIcon style={{ fontSize: 40 }} color="primary" /> 
  },
  { 
    label: 'Disease Models', 
    value: '2', 
    icon: <ScienceIcon style={{ fontSize: 40 }} color="primary" /> 
  },
  { 
    label: 'Pathways Identified', 
    value: '5+', 
    icon: <TrendingUpIcon style={{ fontSize: 40 }} color="primary" /> 
  },
];

function HandleGoToPage(page: string) {
  history.push(page);
}

function PageCard({ name, pathurl, descriptionText, imagePath, icon, restricted = false }: { name: string; pathurl: string; descriptionText: string; imagePath: string; icon?: React.ReactNode; restricted?: boolean }) {
  return (
    <EnhancedCard sx={restricted ? { opacity: 0.7 } : {}}>
      <StyledCardActionArea 
        onClick={restricted ? undefined : () => HandleGoToPage(pathurl)}
        sx={restricted ? { 
          cursor: 'default',
          '&:hover': {
            transform: 'none',
            boxShadow: 'none'
          }
        } : {}}
      >
        <CardMedia component="img" height="180" image={imagePath} alt={name} />
        <CardContent 
          sx={{ 
            height: '200px', 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '24px !important' // Override default CardContent padding
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" mb={1}>
              {icon && <Box mr={1} color={restricted ? "text.disabled" : "primary.main"}>{icon}</Box>}
              <CardTitle variant="h5">{name}</CardTitle>
            </Box>
            <Typography 
              variant="body2" 
              color={restricted ? "text.disabled" : "text.secondary"}
              sx={{ 
                minHeight: '80px',
                overflow: 'hidden'
              }}
            >
              {descriptionText}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            {restricted ? (
              <Chip 
                label="Coming Soon" 
                color="secondary" 
                size="small" 
                sx={{ 
                  fontWeight: 'bold',
                }} 
              />
            ) : (
              <Chip 
                label="Explore" 
                color="primary" 
                size="small" 
                sx={{ 
                  fontWeight: 'bold',
                  '&:hover': { backgroundColor: 'primary.dark' } 
                }} 
              />
            )}
          </Box>
        </CardContent>
      </StyledCardActionArea>
    </EnhancedCard>
  );
}

function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [animateHero, setAnimateHero] = useState(false);

  useEffect(() => {
    // Trigger hero animation after mount
    setTimeout(() => setAnimateHero(true), 100);
  }, []);

  return (
    <>
      <ParticleCanvas />
      <Container sx={{ py: 6 }}>
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <HeroSection>
            <GradientOverlay />

            <Box position="relative" zIndex={1}>
              <motion.div
                animate={animateHero ? { scale: [0.9, 1], opacity: [0, 1] } : {}}
                transition={{ duration: 0.7 }}
              >
                <Typography 
                  variant={isMobile ? "h4" : "h3"} 
                  color="primary" 
                  sx={{ 
                    fontWeight: 'bold', 
                    mb: 1.5, 
                    letterSpacing: '0.5px',
                    textShadow: '0 2px 10px rgba(0,0,0,0.05)',
                    background: 'linear-gradient(90deg, #113046 0%, #294559 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Welcome to Simmunome
                </Typography>
                <Box display="flex" justifyContent="center" mb={3}>
                  <Avatar 
                    src="/assets/img/simmunome-192px.png" 
                    sx={{ 
                      width: 100, 
                      height: 100, 
                      mb: 2,
                      border: '3px solid rgba(254, 206, 97, 0.3)',
                      boxShadow: '0 8px 25px rgba(17, 48, 70, 0.15)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 10px 30px rgba(17, 48, 70, 0.2)',
                      }
                    }} 
                  />
                </Box>
                <Typography 
                  variant="h6" 
                  color="text.secondary" 
                  sx={{ 
                    mb: 4, 
                    maxWidth: '800px', 
                    mx: 'auto',
                    fontWeight: 400,
                    lineHeight: 1.5,
                    letterSpacing: '0.2px',
                    opacity: 0.9
                  }}
                >
                  Advanced protein network analysis tools that revolutionize biomedical research and drug discovery.
                </Typography>
                <Box display="flex" justifyContent="center" gap={2.5} flexWrap="wrap">
                  <Button 
                    variant="contained" 
                    size="large" 
                    onClick={() => HandleGoToPage(config.clientUrls.TargetValidation)}
                    sx={{ 
                      px: 4.5, 
                      py: 1.5, 
                      borderRadius: '50px',
                      fontSize: '1rem',
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      background: 'linear-gradient(45deg, #113046 0%, #294559 100%)',
                      boxShadow: '0 10px 20px rgba(17, 48, 70, 0.2)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        boxShadow: '0 12px 25px rgba(17, 48, 70, 0.3)',
                        transform: 'translateY(-2px)',
                      }
                    }}
                  >
                    Explore Now
                  </Button>
                  <Button 
                    variant="outlined" 
                    size="large" 
                    onClick={() => HandleGoToPage(config.clientUrls.PROJECTS)}
                    sx={{ 
                      px: 4.5, 
                      py: 1.5, 
                      borderRadius: '50px',
                      fontSize: '1rem',
                      fontWeight: 600,
                      letterSpacing: '0.5px',
                      borderWidth: '2px',
                      borderColor: 'primary.main',
                      backgroundColor: 'rgba(255,255,255,0.4)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        borderColor: 'primary.dark',
                        borderWidth: '2px',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 8px 15px rgba(17, 48, 70, 0.1)',
                      }
                    }}
                  >
                    My Projects
                  </Button>
                </Box>
              </motion.div>
            </Box>
          </HeroSection>
        </motion.div>

        {/* Stats Section */}
        
        {/* Primary Cards */}
        <Box mb={8}>
          <SectionTitle variant="h4">
            Research Tools
          </SectionTitle>
          <Grid container spacing={4} justifyContent="center">
            {cardsData.map((card, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.5 }}
                >
                  <PageCard {...card} />
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        <FancyDivider>
          <Chip label="Additional Tools" sx={{ background: 'white', fontWeight: 'bold', zIndex: 1, px: 2 }} />
        </FancyDivider>

        {/* Secondary Cards */}
        <Box mt={4}>
          <SectionTitle variant="h4">
            Your Workspace
          </SectionTitle>
          <Grid container spacing={4} justifyContent="center">
            {secondaryCardsData.map((card, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6 + index * 0.2, duration: 0.5 }}
                >
                  <PageCard {...card} />
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Home;

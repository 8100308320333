import { handleResponse } from '_helpers/middleware';

/**
 * lists diseases
 * @returns Promise (data=Array(diseases names))
 */
function listDiseases(accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return fetch('/api/disease/available-diseases', requestOptions).then(handleResponse);
}

/**
 * Get data per disease to build a graph
 * In case concFilename is provided - use conc logic on the backend
 * @param {string} modelId - The model ID
 * @param {string} accessToken - The access token
 * @param {string} tissueType - Optional tissue type parameter (e.g., "brain_FCX")
 * @returns Promise
 */
function getDiseaseData(modelId, accessToken, tissueType) {
  // Add client-side caching to prevent repeated requests
  const cacheKey = `disease_data_${modelId}_${tissueType || 'default'}`;
  const cachedData = sessionStorage.getItem(cacheKey);
  
  // If we have cached data, use it
  if (cachedData) {
    console.log("Disease service - getDiseaseData - Using cached data for:", cacheKey);
    return Promise.resolve(JSON.parse(cachedData));
  }
  
  const requestOptions = {
    method: 'GET',
    headers: { 
      Authorization: `Bearer ${accessToken}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-None-Match': ''
    },
    // Add a longer timeout for large models
    timeout: 300000 // 5 minutes - matching the frontend timeout
  };
  
  // Build URL with tissue type parameter if provided
  let url = `/api/disease/graph-data/${modelId}`;
  if (tissueType) {
    url += `?tissueType=${encodeURIComponent(tissueType)}`;
    console.log("Disease service - getDiseaseData - Including tissue type:", tissueType);
  }
  
  console.log("Disease service - getDiseaseData - Request URL:", url);
  console.log("Disease service - getDiseaseData - Request options:", requestOptions);
  
  return fetch(url, requestOptions)
    .then(response => {
      console.log("Disease service - getDiseaseData - Response status:", response.status);
      
      // No special handling for loading in progress - just wait for the complete response
      return handleResponse(response);
    })
    .then(data => {
      console.log("Disease service - getDiseaseData - Processed data:", data);
      
      // Validate the data structure
      if (!data) {
        console.error("Disease service - getDiseaseData - Received null or undefined data");
        throw new Error("Failed to load graph data. The server returned an empty response.");
      }
      
      // Check if the data has nodes and links
      const graphData = data.data || data;
      if (!graphData.nodes || !graphData.links) {
        console.error("Disease service - getDiseaseData - Data is missing nodes or links:", graphData);
        throw new Error("Failed to load graph data. The data is incomplete or malformed.");
      }
      
      // Cache the result regardless of loading status
      console.log("Disease service - getDiseaseData - Caching the result");
      
      // Cache the result for future requests
      try {
        // Only save minimal data to avoid storage limits
        const cacheableData = {
          ...graphData,
          _isCached: true,
          _cachedAt: new Date().toISOString()
        };
        
        sessionStorage.setItem(cacheKey, JSON.stringify(cacheableData));
        console.log("Disease service - getDiseaseData - Data cached successfully!");
      } catch (e) {
        console.warn("Disease service - getDiseaseData - Failed to cache data:", e);
      }
      
      // Ensure we return an object with a data property if it doesn't already have one
      if (data && !data.hasOwnProperty('data')) {
        console.log("Disease service - getDiseaseData - Wrapping data in data property");
        return { data: graphData };
      }
      return data;
    })
    .catch(error => {
      console.error("Disease service - getDiseaseData - Error:", error);
      
      // Check if this is a timeout error
      if (error.name === 'AbortError' || (error.message && error.message.includes('timeout'))) {
        throw new Error("The request timed out. The graph may be too large to load. Please try a smaller model or try again later.");
      }
      
      // Re-throw the error to be handled by the caller
      throw error;
    });
}

/**
 * Get node info per disease (if any)
 * @returns Promise
 */
function getDiseaseNodeInfo(nodeId, accessToken) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      nodes_ids: [nodeId],
    }),
  };
  return fetch(`/api/disease/nodes-info`, requestOptions).then(handleResponse);
}

/**
 * Upload concentration file
 * @returns Promise
 */
function uploadConcentrationFile(file, accessToken) {
  const formData = new FormData();
  formData.append('concfile', file);
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: formData,
  };
  return fetch('/api/concentration', requestOptions).then(handleResponse);
}

/**
 * Get Node Process Paths
 * @returns Promise
 */
function getNodeProcessPaths(disease, nodeId, accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return fetch(`/api/diseases/${disease}/nodes/${nodeId}/process_paths`, requestOptions).then(handleResponse);
}

const diseaseService = {
  listDiseases,
  getDiseaseData,
  getDiseaseNodeInfo,
  getNodeProcessPaths,
  uploadConcentrationFile,
};

export default diseaseService;

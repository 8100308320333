import { Content } from 'pdfmake/interfaces';

interface DatasetRow {
  tissue: string;
  genotype: string;
  samples: string;
  included: string;
}

interface Dataset {
  id: string;
  title: string;
  description: string;
  tables: Array<{
    title: string;
    columns: string[];
    rows: DatasetRow[];
  }>;
}

// Map of disease names to their datasets
const diseaseDatasets: Record<string, Dataset[]> = {
  'ALS': [
    {
      id: 'dataset1',
      title: 'Dataset 1: ALS Patient Samples from Mayo Clinic',
      description: `All ALS patients in this study were recruited at Mayo Clinic Jacksonville and independently
diagnosed according to the El Escorial criteria. Diagnosis was confirmed through neurological
examinations, electromyography, laboratory testing, and, when necessary, neurological imaging.
Neuropathological confirmation was performed by the Mayo Clinic Jacksonville Brain Bank for
Neurodegenerative Disorders.

Genetic testing methods, including assessments for C9orf72 repeat expansions and mutations in SOD1, TARDBP, FUS, and PFN1 genes, are described in detail in the GSE67196
dataset, which is part of the Gene Expression Omnibus (GEO) database.
The collection and processing of frozen postmortem human brain tissues, as well as RNA extraction and integrity assessment protocols, are also outlined. Additionally, RNA sequencing
procedures, including library preparation, sequencing platforms, and data preprocessing, are
comprehensively documented within the GSE67196 dataset.

Samples were distributed across three genotypes (sALS (sporadic), C9orf72 ALS (c9ALS),
and healthy controls) and two tissue types (cerebellum, frontal cortex). In our analysis, both tissue types are kept separate. All samples were used in the model. We thus included 18 patient samples and 8 controls for Cerebellum, and 18 patient samples and 9 controls for Frontal cortex.`,
      tables: [
        {
          title: 'Tissue Type: Cerebellum and Frontal Cortex',
          columns: ['Tissue', 'Genotype', 'Number of Samples', 'Included in Model'],
          rows: [
            { tissue: 'Cerebellum', genotype: 'sALS (sporadic)', samples: '10', included: 'Yes' },
            { tissue: 'Cerebellum', genotype: 'ALS (C9orf72)', samples: '8', included: 'Yes' },
            { tissue: 'Cerebellum', genotype: 'Healthy', samples: '8', included: 'Yes' },
            { tissue: 'Frontal cortex', genotype: 'sALS (sporadic)', samples: '10', included: 'Yes' },
            { tissue: 'Frontal cortex', genotype: 'ALS (C9orf72)', samples: '8', included: 'Yes' },
            { tissue: 'Frontal cortex', genotype: 'Healthy', samples: '9', included: 'Yes' }
          ]
        }
      ]
    },
    {
      id: 'dataset2',
      title: 'Dataset 2: Brain and Post-mortem Spinal Cord Tissues',
      description: `The integrated data in Dataset 2 include samples from the following tissue types:

• Brain: A summary of the disease state for the samples is presented in the table. We excluded samples with disease state FTD (frontotemporal dementia) and ALS+FTD. Controls and Neurological Control (NEURCTL) are grouped together into a single control group. Given the functional and anatomical similarities between these brain regions and due to small sample size of their associated control groups, we have grouped the lateral motor cortex, medial motor cortex, and motor cortex, into a single group. We excluded the frontal cortex group due to insufficient number of controls. We thus have 148 patient samples and 7 control samples.

• Post-mortem spinal cord tissues: We excluded samples with disease state FTD or ALS+FTD. Controls and NEURCTL are grouped together into a single control group. The cervical and lumbar spinal cord groups are kept separate, while the general spinal cord samples are not used due to small sample size. We thus have 113 patient samples and 12 control samples for cervical spinal cord, and 102 patient samples and 10 control samples for lumbar spinal cord.

Dataset Selection: The data analyzed in this study are exclusively sourced from the AnswerALS project, which provides a large-scale, harmonized collection of transcriptomic data across multiple central nervous system (CNS) regions.`,
      tables: [
        {
          title: 'Brain: Disease State Distribution',
          columns: ['Tissue', 'Disease State', 'Number of Samples', 'Included in Model'],
          rows: [
            { tissue: 'Lateral motor cortex', genotype: 'ALS + FTD', samples: '3', included: 'No' },
            { tissue: 'Lateral motor cortex', genotype: 'FTD', samples: '4', included: 'No' },
            { tissue: 'Lateral motor cortex', genotype: 'ALS', samples: '34', included: 'Yes' },
            { tissue: 'Lateral motor cortex', genotype: 'CTL (control)', samples: '3', included: 'Yes' },
            { tissue: 'Medial motor cortex', genotype: 'ALS', samples: '31', included: 'Yes' },
            { tissue: 'Medial motor cortex', genotype: 'ALS + FTD', samples: '2', included: 'No' },
            { tissue: 'Medial motor cortex', genotype: 'FTD', samples: '4', included: 'No' },
            { tissue: 'Medial motor cortex', genotype: 'CTL (control)', samples: '3', included: 'Yes' },
            { tissue: 'Motor cortex', genotype: 'ALS', samples: '83', included: 'Yes' },
            { tissue: 'Motor cortex', genotype: 'CTL', samples: '1', included: 'Yes' },
            { tissue: 'Motor cortex', genotype: 'ALS + FTD', samples: '4', included: 'No' },
            { tissue: 'Frontal cortex', genotype: 'ALS', samples: '102', included: 'No' },
            { tissue: 'Frontal cortex', genotype: 'ALS + FTD', samples: '6', included: 'No' },
            { tissue: 'Frontal cortex', genotype: 'NEURCTL', samples: '1', included: 'No' },
            { tissue: 'Frontal cortex', genotype: 'FTD', samples: '3', included: 'No' },
            { tissue: 'Frontal cortex', genotype: 'CTL (control)', samples: '5', included: 'No' }
          ]
        },
        {
          title: 'Brain: Genotype Distribution',
          columns: ['Genotype', 'Number of Samples', 'Included in Model'],
          rows: [
            { tissue: '', genotype: 'Unknown (sporadic)', samples: '115', included: 'Yes' },
            { tissue: '', genotype: 'C9orf72', samples: '2', included: 'Yes' },
            { tissue: '', genotype: 'PNPLA6', samples: '2', included: 'Yes' },
            { tissue: '', genotype: 'SOD1', samples: '2', included: 'Yes' },
            { tissue: '', genotype: 'FUS', samples: '2', included: 'Yes' },
            { tissue: '', genotype: 'ATXN2', samples: '1', included: 'Yes' },
            { tissue: '', genotype: 'NEFH', samples: '1', included: 'Yes' },
            { tissue: '', genotype: 'Missing', samples: '23', included: 'Yes' }
          ]
        },
        {
          title: 'Spinal Cord: Disease State Distribution',
          columns: ['Tissue', 'Disease State', 'Number of Samples', 'Included in Model'],
          rows: [
            { tissue: 'Cervical spinal cord', genotype: 'CTL (control)', samples: '11', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'ALS', samples: '113', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'ALS + FTD', samples: '7', included: 'No' },
            { tissue: 'Cervical spinal cord', genotype: 'Unknown', samples: '1', included: 'No' },
            { tissue: 'Cervical spinal cord', genotype: 'NEURCTL', samples: '1', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'Unknown', samples: '1', included: 'No' },
            { tissue: 'Lumbar spinal cord', genotype: 'NEURCTL', samples: '2', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'CTL (control)', samples: '8', included: 'No' },
            { tissue: 'Lumbar spinal cord', genotype: 'ALS', samples: '102', included: 'Yes' },
            { tissue: 'Spinal cord', genotype: 'NEURCTL', samples: '2', included: 'No' },
            { tissue: 'Spinal cord', genotype: 'ALS', samples: '4', included: 'No' },
            { tissue: 'Spinal cord', genotype: 'CTL (control)', samples: '4', included: 'No' }
          ]
        },
        {
          title: 'Spinal Cord: Genotype Distribution',
          columns: ['Tissue', 'Genotype', 'Number of Samples', 'Included in Model'],
          rows: [
            { tissue: 'Cervical spinal cord', genotype: 'Unknown (sporadic)', samples: '77', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'FUS', samples: '2', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'PNPLA6', samples: '1', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'SOD1', samples: '1', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'ATXN2', samples: '1', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'NEFH', samples: '1', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'C9orf72', samples: '1', included: 'Yes' },
            { tissue: 'Cervical spinal cord', genotype: 'Missing', samples: '29', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'Unknown (sporadic)', samples: '69', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'C9orf72', samples: '3', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'FUS', samples: '2', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'PNPLA6', samples: '1', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'SOD1', samples: '1', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'ATXN2', samples: '1', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'NEFH', samples: '1', included: 'Yes' },
            { tissue: 'Lumbar spinal cord', genotype: 'Missing', samples: '24', included: 'Yes' }
          ]
        }
      ]
    },
    {
      id: 'dataset3',
      title: 'Dataset 3: Blood',
      description: `This study utilized whole blood transcriptome data from the GEO dataset with accession GSE112681. The dataset was divided into two sub-series:

• GSE112676: Hybridized on Illumina HumanHT-12 version 3 BeadChips.
• GSE112680: Hybridized on Illumina HumanHT-12 version 4 BeadChips.

For additional details on the preprocessing steps and methodology, refer to GEO website. We excluded MIM (ALS-mimic diseases) samples, resulting in 397 patient samples and 645 controls. Despite repeated attempts to contact the authors of the paper, we were not able to obtain the genotype for these samples, hence we will refer to them as Missing.`,
      tables: [
        {
          title: 'Blood Sample Distribution',
          columns: ['Disease Status', 'Number of Samples', 'Use in Analysis'],
          rows: [
            { tissue: '', genotype: 'Control', samples: '645', included: 'Yes' },
            { tissue: '', genotype: 'ALS', samples: '397', included: 'Yes' },
            { tissue: '', genotype: 'MIM (ALS-mimic diseases)', samples: '73', included: 'No' }
          ]
        }
      ]
    }
  ]
};

// Function to normalize disease names for matching with our datasets map
function normalizeDiseaseNameForMatching(diseaseName: string): string {
  // Convert to lowercase for comparison
  const lowerCaseName = diseaseName.toLowerCase();
  
  // Check for partial matches
  if (lowerCaseName.includes('als') || lowerCaseName.includes('amyotrophic lateral sclerosis')) {
    return 'ALS';
  }
  
  // Return the original name if no match is found
  return diseaseName;
}

// Function to determine if tissue type should match specific dataset
function shouldShowForTissueType(dataset: Dataset, tissueType: string | null): boolean {
  if (!tissueType) {
    return true; // Show all if no tissue type specified
  }
  
  const lowerTissue = tissueType.toLowerCase();
  
  // Check for dataset 1
  if (dataset.id === 'dataset1') {
    // Show for cerebellum or frontal cortex tissue types
    if (lowerTissue.includes('cerebellum') || lowerTissue.includes('brain_cereb')) {
      return true;
    }
    if (lowerTissue.includes('frontal') || lowerTissue.includes('fcx') || lowerTissue.includes('brain_fcx')) {
      return true;
    }
    return false;
  }
  
  // Check for dataset 2
  if (dataset.id === 'dataset2') {
    // Motor related brain tissue types
    if (lowerTissue.includes('brain_motor') || lowerTissue.includes('motor')) {
      return true;
    }
    
    // Frontal cortex can also appear in dataset 2
    if (lowerTissue.includes('frontal') || lowerTissue.includes('fcx') || lowerTissue.includes('brain_fcx')) {
      return true;
    }
    
    // Spinal cord tissue types
    if (lowerTissue.includes('spinal_cervical') || lowerTissue.includes('cervical')) {
      return true;
    }
    
    if (lowerTissue.includes('spinal_lumbar') || lowerTissue.includes('lumbar')) {
      return true;
    }
    
    // General brain
    if (lowerTissue.includes('brain')) {
      return true;
    }
    
    return false;
  }
  
  // Check for dataset 3
  if (dataset.id === 'dataset3') {
    return lowerTissue.includes('blood');
  }
  
  return false;
}

// Filter tables within a dataset based on tissue type
function filterTablesForTissueType(dataset: Dataset, tissueType: string | null): Dataset {
  if (!tissueType) {
    return dataset; // Return all tables if no tissue specified
  }
  
  const lowerTissue = tissueType.toLowerCase();
  const filteredTables = dataset.tables.filter(table => {
    const title = table.title.toLowerCase();
    
    // Dataset 1: Filter for relevant tissue type
    if (dataset.id === 'dataset1') {
      // For cerebellum and frontal cortex, show the table
      return true; // We'll filter rows later
    }
    
    // Dataset 2: Filter tables based on tissue type
    if (dataset.id === 'dataset2') {
      // For frontal cortex, show relevant tables
      if (lowerTissue.includes('frontal') || lowerTissue.includes('fcx') || lowerTissue.includes('brain_fcx')) {
        // Check if table has frontal cortex data
        if (title.includes('brain') && 
            table.rows.some(row => row.tissue && row.tissue.toLowerCase().includes('frontal'))) {
          return true;
        }
      }
      
      // For brain_motor tissue type, show only brain-related tables
      if (lowerTissue.includes('brain_motor') || lowerTissue.includes('motor')) {
        return title.includes('brain');
      }
      
      // For cervical spinal cord, show only spinal-related tables
      if (lowerTissue.includes('cervical') || lowerTissue.includes('spinal_cervical')) {
        return title.toLowerCase().includes('spinal');
      }
      
      // For lumbar spinal cord, show only spinal-related tables
      if (lowerTissue.includes('lumbar') || lowerTissue.includes('spinal_lumbar')) {
        return title.toLowerCase().includes('spinal');
      }
      
      // For general brain tissue, show brain tables
      if (lowerTissue.includes('brain')) {
        return title.includes('brain');
      }
    }
    
    // Dataset 3: Only for blood tissue type
    if (dataset.id === 'dataset3') {
      return lowerTissue.includes('blood');
    }
    
    return false;
  });
  
  // Create a copy of the dataset with filtered tables
  return {
    ...dataset,
    tables: filteredTables
  };
}

// Function to filter and customize dataset description based on tissue type
function customizeDatasetForTissueType(dataset: Dataset, tissueType: string | null): Dataset {
  if (!tissueType) {
    return dataset; // Return unchanged if no tissue specified
  }
  
  const lowerTissue = tissueType.toLowerCase();
  let customizedDescription = dataset.description;
  
  // Dataset 1: Customize description for cerebellum or frontal cortex
  if (dataset.id === 'dataset1') {
    // Keep the whole description but add a note about which tissue type is being used
    let tissue = '';
    if (lowerTissue.includes('cerebellum') || lowerTissue.includes('brain_cereb')) {
      tissue = 'cerebellum';
    } else if (lowerTissue.includes('frontal') || lowerTissue.includes('fcx') || lowerTissue.includes('brain_fcx')) {
      tissue = 'frontal cortex';
    }
    
    if (tissue) {
      // Add a note at the end of the description highlighting the selected tissue
      customizedDescription += `\n\nFor this analysis, we are focusing specifically on the ${tissue} samples.`;
    }
  }
  
  // Dataset 2: Customize description based on tissue type
  if (dataset.id === 'dataset2') {
    const descriptionParts = dataset.description.split('•');
    let relevantParts: string[] = [];
    
    // Add the intro part
    if (descriptionParts[0]) {
      relevantParts.push(descriptionParts[0]);
    }
    
    // For frontal cortex, include brain section (if not already shown in dataset 1)
    if (lowerTissue.includes('frontal') || lowerTissue.includes('fcx') || lowerTissue.includes('brain_fcx')) {
      const brainPart = descriptionParts.find(part => part.trim().startsWith('Brain:'));
      if (brainPart) {
        relevantParts.push('• ' + brainPart);
      }
    }
    
    // For brain_motor tissue, include only brain section
    else if (lowerTissue.includes('brain_motor') || lowerTissue.includes('motor')) {
      const brainPart = descriptionParts.find(part => part.trim().startsWith('Brain:'));
      if (brainPart) {
        relevantParts.push('• ' + brainPart);
      }
    }
    
    // For cervical spinal cord, include only spinal cord section
    else if (lowerTissue.includes('cervical') || lowerTissue.includes('spinal_cervical')) {
      const spinalPart = descriptionParts.find(part => part.trim().startsWith('Post-mortem spinal cord'));
      if (spinalPart) {
        // Modify to emphasize cervical
        let modifiedPart = spinalPart;
        modifiedPart = modifiedPart.replace('We thus have 113 patient samples and 12 control samples for cervical spinal cord, and 102 patient samples and 10 control samples for lumbar spinal cord.', 
          'We thus have 113 patient samples and 12 control samples for cervical spinal cord.');
        relevantParts.push('• ' + modifiedPart);
      }
    }
    
    // For lumbar spinal cord, include only spinal cord section
    else if (lowerTissue.includes('lumbar') || lowerTissue.includes('spinal_lumbar')) {
      const spinalPart = descriptionParts.find(part => part.trim().startsWith('Post-mortem spinal cord'));
      if (spinalPart) {
        // Modify to emphasize lumbar
        let modifiedPart = spinalPart;
        modifiedPart = modifiedPart.replace('We thus have 113 patient samples and 12 control samples for cervical spinal cord, and 102 patient samples and 10 control samples for lumbar spinal cord.', 
          'We thus have 102 patient samples and 10 control samples for lumbar spinal cord.');
        relevantParts.push('• ' + modifiedPart);
      }
    }
    
    // Add the dataset selection part
    const datasetSelectionPart = descriptionParts.find(part => part.trim().startsWith('Dataset Selection:'));
    if (datasetSelectionPart) {
      relevantParts.push('• ' + datasetSelectionPart);
    }
    
    // If we found relevant parts, join them back together
    if (relevantParts.length > 1) {
      customizedDescription = relevantParts.join('');
    }
  }
  
  return {
    ...dataset,
    description: customizedDescription,
  };
}

// Function to create datasets content for the PDF report
export function createDatasetsSectionForPdf(diseaseName: string, tissueType: string | null = null): Content {
  const normalizedDiseaseName = normalizeDiseaseNameForMatching(diseaseName);
  const datasets = diseaseDatasets[normalizedDiseaseName] || [];
  
  if (datasets.length === 0) {
    return {
      text: 'Dataset information is not available for this disease.',
      style: 'italic',
      color: '#666666',
      margin: [0, 10, 0, 0],
    };
  }
  
  // Filter datasets by tissue type if specified
  const filteredDatasets = tissueType 
    ? datasets.filter(dataset => shouldShowForTissueType(dataset, tissueType))
    : datasets;
  
  if (filteredDatasets.length === 0) {
    return {
      text: `Dataset information for ${tissueType} tissue type is not available for this disease.`,
      style: 'italic',
      color: '#666666',
      margin: [0, 10, 0, 0],
    };
  }
  
  // Create content for each dataset with customized info based on tissue type
  const datasetContent: Content[] = [];
  
  filteredDatasets.forEach(dataset => {
    // Customize and filter dataset based on tissue type
    const customizedDataset = customizeDatasetForTissueType(dataset, tissueType);
    const filteredDataset = filterTablesForTissueType(customizedDataset, tissueType);
    
    // Skip datasets that have no tables after filtering
    if (filteredDataset.tables.length === 0) {
      return;
    }
    
    // Add dataset title
    datasetContent.push({
      text: filteredDataset.title,
      style: 'subsectionTitle',
      margin: [0, 15, 0, 10],
    });
    
    // Add dataset description
    datasetContent.push({
      text: filteredDataset.description,
      style: 'normal',
      margin: [0, 0, 0, 10],
    });
    
    // Add dataset tables
    filteredDataset.tables.forEach(table => {
      // Add table title
      const modifiedTitle = modifyTableTitleForTissue(table.title, tissueType);
      datasetContent.push({
        text: modifiedTitle,
        style: 'normal',
        bold: true,
        margin: [0, 10, 0, 5],
      });
      
      // Create table body with headers row
      const tableBody = [
        // Header row
        table.columns.filter(column => 
          column !== 'Included in Model' && column !== 'Use in Analysis'
        ).map(column => ({
          text: column,
          style: 'tableHeader',
          alignment: column === 'Number of Samples' ? 'center' : 'left',
        }))
      ];
      
      // Filter rows based on tissue type
      let filteredRows = [...table.rows];
      
      // First filter: Remove rows with "No" in "Included in Model" or "Use in Analysis" columns
      filteredRows = filteredRows.filter(row => 
        row.included.toLowerCase() === 'yes'
      );
      
      if (tissueType) {
        const lowerTissue = tissueType.toLowerCase();
        
        // Dataset 1: Filter rows to match exact tissue type
        if (dataset.id === 'dataset1' && table.title.includes('Cerebellum and Frontal Cortex')) {
          if (lowerTissue.includes('cerebellum') || lowerTissue.includes('brain_cereb')) {
            filteredRows = filteredRows.filter(row => row.tissue.toLowerCase().includes('cerebellum'));
          } else if (lowerTissue.includes('frontal') || lowerTissue.includes('fcx') || lowerTissue.includes('brain_fcx')) {
            filteredRows = filteredRows.filter(row => row.tissue.toLowerCase().includes('frontal'));
          }
        }
        
        // Dataset 2: Filter rows to match exact tissue type
        if (dataset.id === 'dataset2') {
          // For frontal cortex in dataset 2
          if (lowerTissue.includes('frontal') || lowerTissue.includes('fcx') || lowerTissue.includes('brain_fcx')) {
            if (table.title.includes('Disease State Distribution')) {
              filteredRows = filteredRows.filter(row => row.tissue.toLowerCase().includes('frontal'));
            }
          }
          
          // For cervical spinal cord
          else if (lowerTissue.includes('cervical') || lowerTissue.includes('spinal_cervical')) {
            if (table.title.includes('Disease State Distribution')) {
              filteredRows = filteredRows.filter(row => row.tissue.toLowerCase().includes('cervical'));
            } else if (table.title.includes('Genotype Distribution')) {
              filteredRows = filteredRows.filter(row => 
                !row.tissue || row.tissue === '' || row.tissue.toLowerCase().includes('cervical'));
            }
          }
          
          // For lumbar spinal cord
          else if (lowerTissue.includes('lumbar') || lowerTissue.includes('spinal_lumbar')) {
            if (table.title.includes('Disease State Distribution')) {
              filteredRows = filteredRows.filter(row => row.tissue.toLowerCase().includes('lumbar'));
            } else if (table.title.includes('Genotype Distribution')) {
              filteredRows = filteredRows.filter(row => 
                !row.tissue || row.tissue === '' || row.tissue.toLowerCase().includes('lumbar'));
            }
          }
          
          // For brain_motor
          else if (lowerTissue.includes('brain_motor') || lowerTissue.includes('motor')) {
            if (table.title.includes('Disease State Distribution')) {
              filteredRows = filteredRows.filter(row => row.tissue.toLowerCase().includes('motor'));
            }
          }
        }
      }
      
      // Add rows for each data point
      filteredRows.forEach(row => {
        const tableRow = [
          { text: row.tissue, style: 'tableCell', alignment: 'left' },
          { text: row.genotype, style: 'tableCell', alignment: 'left' },
          { text: row.samples, style: 'tableCell', alignment: 'center' }
        ];
        
        // If the table doesn't have a tissue column, adjust the row
        if (table.columns[0] !== 'Tissue') {
          tableRow.shift();
        }
        
        tableBody.push(tableRow);
      });
      
      // Only add table if it has rows (besides header)
      if (tableBody.length > 1) {
        // Create the table
        datasetContent.push({
          table: {
            headerRows: 1,
            // Adjust widths based on column count - now with one fewer column
            widths: table.columns.filter(column => 
              column !== 'Included in Model' && column !== 'Use in Analysis'
            ).length === 3 
              ? ['30%', '40%', '30%'] 
              : (table.columns.filter(column => 
                column !== 'Included in Model' && column !== 'Use in Analysis'
              ).length === 2 ? ['60%', '40%'] : ['*', '*', '*']),
            body: tableBody
          },
          layout: {
            fillColor: function(rowIndex: number) {
              return (rowIndex === 0) ? '#f2f2f2' : null;
            },
            hLineWidth: function() { return 1; },
            vLineWidth: function() { return 1; },
            hLineColor: function() { return '#ddd'; },
            vLineColor: function() { return '#ddd'; }
          },
          margin: [0, 0, 0, 15]
        });
      }
    });
  });

  // Create the main content
  const content: Content = {
    stack: [
      {
        text: tissueType 
          ? `Datasets Used for ${diseaseName} (${tissueType})`
          : `Datasets Used for ${diseaseName}`,
        style: 'sectionHeader',
        margin: [0, 10, 0, 15],
      },
      {
        text: tissueType
          ? `The following datasets contain information relevant to ${diseaseName} in the ${tissueType} tissue type:`
          : 'The following datasets were used in the development of this model:',
        style: 'normal',
        margin: [0, 0, 0, 10],
      },
      ...datasetContent
    ],
    pageBreak: 'before'
  };
  
  return content;
}

// Helper function to modify table titles for tissue specificity
function modifyTableTitleForTissue(title: string, tissueType: string | null): string {
  if (!tissueType) {
    return title;
  }
  
  const lowerTissue = tissueType.toLowerCase();
  
  // Modify Dataset 1 table title
  if (title === 'Tissue Type: Cerebellum and Frontal Cortex') {
    if (lowerTissue.includes('cerebellum') || lowerTissue.includes('brain_cereb')) {
      return 'Tissue Type: Cerebellum';
    }
    if (lowerTissue.includes('frontal') || lowerTissue.includes('fcx') || lowerTissue.includes('brain_fcx')) {
      return 'Tissue Type: Frontal Cortex';
    }
  }
  
  // Modify Dataset 2 titles
  if (title.includes('Spinal Cord: Disease State Distribution')) {
    if (lowerTissue.includes('cervical') || lowerTissue.includes('spinal_cervical')) {
      return 'Cervical Spinal Cord: Disease State Distribution';
    }
    if (lowerTissue.includes('lumbar') || lowerTissue.includes('spinal_lumbar')) {
      return 'Lumbar Spinal Cord: Disease State Distribution';
    }
  }
  
  if (title.includes('Spinal Cord: Genotype Distribution')) {
    if (lowerTissue.includes('cervical') || lowerTissue.includes('spinal_cervical')) {
      return 'Cervical Spinal Cord: Genotype Distribution';
    }
    if (lowerTissue.includes('lumbar') || lowerTissue.includes('spinal_lumbar')) {
      return 'Lumbar Spinal Cord: Genotype Distribution';
    }
  }
  
  return title;
} 
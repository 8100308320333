import { Box, Grid, Input, Slider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AiPredStore from '_pages/TargetValidation/FilterAIPredictionStore';
import { filterStringEdges, toggleAIPredictedEdges } from '_helpers/sigmaEvents';
import { useSigma } from '@react-sigma/core';
import { string } from 'prop-types';

interface AiSliderProps {
  showPredictionSlider: boolean;
}

export default function AiSlider({ showPredictionSlider }: AiSliderProps) {
  const { filterCutoff, stringCutoff } = AiPredStore(); // Retrieve both cutoffs from the store
  const [aiActivity, setAiActivity] = useState<number>(filterCutoff);
  const [stringActivity, setStringActivity] = useState<number>(stringCutoff);
  const sigma = useSigma();

  const setAiPredCutoffStore = AiPredStore((state) => state.setCutoff);
  const setStringCutoffStore = AiPredStore((state) => state.setStringCutoff);

  function onChangeAiSlider(value: number | number[]) {
    const newValue = Number(value);
    setAiActivity(newValue);
    setAiPredCutoffStore(newValue);

    // Adjust STRING level if AI is lowered below STRING
    if (newValue < stringActivity) {
      setStringActivity(newValue);
      setStringCutoffStore(newValue);
    }
  }

  function onChangeStringSlider(value: number | number[]) {
    const newValue = Number(value);
    setStringActivity(newValue);
    setStringCutoffStore(newValue);

    // Ensure AI cutoff is always >= STRING cutoff
    if (newValue > aiActivity) {
      setAiActivity(newValue);
      setAiPredCutoffStore(newValue);
    }
  }

  useEffect(() => {
    toggleAIPredictedEdges(sigma, AiPredStore.getState().filterBooleanValue, aiActivity, stringActivity);
    filterStringEdges(sigma.getGraph(), stringActivity, aiActivity);
  }, [aiActivity, stringActivity]);

  return (
    <Box>

      <Typography align="center">String Level</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            onChange={(_, value) => onChangeStringSlider(value)}
            value={stringActivity}
            step={0.01}
            valueLabelDisplay="auto"
            min={0.1}
            max={1}
            track="inverted"
          />
        </Grid>
        <Grid item>
          <Input
            value={stringActivity}
            size="small"
            sx={{ width: 50 }}
            onChange={(e) => onChangeStringSlider(Number(e.target.value))}
            inputProps={{
              step: 0.01,
              min: 0.1,
              max: 1,
              type: 'number',
              'aria-labelledby': 'string-slider',
            }}
          />
        </Grid>
      </Grid>


      {showPredictionSlider && (
        <>
          <Typography align="center"  mt={1}>Predictions Level</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                onChange={(_, value) => onChangeAiSlider(value)}
                value={aiActivity}
                step={0.01}
                valueLabelDisplay="auto"
                min={0.1}
                max={1}
                track="inverted"
              />
            </Grid>
            <Grid item>
              <Input
                value={aiActivity}
                size="small"
                sx={{ width: 50 }}
                onChange={(e) => onChangeAiSlider(Number(e.target.value))}
                inputProps={{
                  step: 0.01,
                  min: 0.1,
                  max: 1,
                  type: 'number',
                  'aria-labelledby': 'ai-slider',
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

import { Badge, Container, IconButton, Tooltip, Chip, Button } from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import HeadCellType from '_components/base/table/HeadCellType';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { ReactNode, useEffect, useState } from 'react';
import projectsService from '_services/harbor/projects.service';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import ContentTable from '_components/base/table/ContentTable';
import AddIcon from '@mui/icons-material/Add';
import BasicModal from '_components/base/BasicModal';
import CreateProjectRoutine from './Routines/CreateProjectRoutine';
import { ProjectType } from './ProjectTypeEnum';
import PageHeader from '_components/base/PageHeader';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useHistory } from 'react-router-dom';
import { withServiceCallHandling } from '_helpers/decorators';
import { convertProjectType2ProjectUrl } from '_helpers/data';
import DeleteButton from '_components/base/DeleteButton';
import { Note } from '_components/notepad';
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography, Grid, CardContent, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import StorageIcon from '@mui/icons-material/Storage';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BiotechIcon from '@mui/icons-material/Biotech';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';


interface NotesWithBadgeProps {
  row: RowType
}

export function NotesWithBadge({ row }: NotesWithBadgeProps) {
  if (row.notes) {
    var notes = row.notes as Note[]
    const noteNumber = notes.length
    return (
      <Badge badgeContent={notes.length} color="secondary">
        <Tooltip title={`This project has ${noteNumber} notes.`}>
          <NotesIcon />
        </Tooltip>
      </Badge>
    )
  } else {
    return (<></>)
  }
}

interface HopBackInProjectButtonProps {
  onClickFunction: Function
}

export function HopBackInProjectButton({ onClickFunction }: HopBackInProjectButtonProps) {
  return (
    <Tooltip title='Hop back into the project'>
      <IconButton
        onClick={() => {
          onClickFunction()
        }}
      >
        <ArrowCircleRightIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}

const styles = {
  insertModalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '70%',
    minWidth: '40%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
};
const defaultIdHeadCell: HeadCellType = {
  id: '_id',
  numeric: false,
  disablePadding: false,
  label: 'ID',
};

interface ProjectsProps {
  tableTitle?: string;
  entityName?: string;
  allowInsert?: boolean;
  allowDelete?: boolean;
  allowFilter?: boolean;
  allowRename?: boolean;
  allowMultipleSelect?: boolean;
  initRows?: RowType[];
  initRowsSelected?: RowType[];
  headCells?: HeadCellType[];
  idHeadCell?: HeadCellType;
  onRowsSelected?: (rows: RowType[]) => void;
  fixedType?: ProjectType;
  asPage?: boolean;
}
function Projects({
  asPage = true,
  initRows = [],
  initRowsSelected = [],
  allowMultipleSelect = true,
  allowInsert = false,
  allowDelete = true,
  allowFilter = true,
  allowRename = true,
  tableTitle = 'Projects',
  entityName = 'Project',
  headCells = [],
  idHeadCell = defaultIdHeadCell,
  onRowsSelected,
  fixedType,
}: ProjectsProps) {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [animate, setAnimate] = useState(false);

  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();

  const [rows, setRows] = useState<RowType[]>(initRows);
  const [rowsSelected, setRowsSelected] = useState<RowType[]>(initRowsSelected);
  const [insertModalOpen, setInsertModalOpen] = useState<boolean>(false);
  
  useEffect(() => {
    setTimeout(() => setAnimate(true), 100);
  }, []);

  async function listProjectsImpl() {
    const accessToken = await getAccessTokenSilently();
    const args = [];
    if (fixedType) args.push(fixedType);
    return projectsService.listProjects(accessToken, ...args);
  }
  const listProjects = withServiceCallHandling(
    listProjectsImpl,
    (data) => {
      console.log("Projects data received:", data);
      // Log a few projects to inspect structure
      if (data && data.length > 0) {
        console.log("First project structure:", JSON.stringify(data[0], null, 2));
      }
      setRows(data);
    },
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    listProjects();
  }, []);

  if (!headCells || headCells.length < 1)
    headCells = [
      { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
      { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
      { 
        id: 'model', 
        numeric: false, 
        disablePadding: false, 
        label: 'Model',
        renderElementImpl: (_id: RowElementType, row: RowType) => {
          // Only show for targetvalidation projects
          if (row.type === 'targetvalidation' && row.model_id) {
            // Format model ID for display
            const shortModelId = typeof row.model_id === 'string' 
              ? row.model_id.substring(0, 6) + '...' 
              : 'Unknown';
            
            // Return a label with the disease, which is available in project data
            const labelText = row.disease 
              ? `${row.disease} Model` 
              : `Model ID: ${shortModelId}`;
              
            return <Chip 
              label={labelText} 
              size="small" 
              color="primary" 
              variant="outlined" 
              title={`Full Model ID: ${row.model_id}`}
            /> as unknown as RowElementType;
          }
          
          // Return nothing for non-TV projects or projects without model_id
          return null as unknown as RowElementType;
        }
      },
      {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Creation Date',
        isDate: true,
      },
      {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'Last Updated',
        isDate: true,
      },
      {
        id: 'notes',
        numeric: false,
        disablePadding: false,
        label: '',
        isEmpty: true,
        isSelfClickable: false,
        renderElementImpl: (_id: RowElementType, row: RowType) => {
          return (
            <NotesWithBadge row={row} />
          )
        }
      },
      {
        id: '_id',
        numeric: false,
        disablePadding: false,
        label: '',
        isEmpty: true,
        isSelfClickable: true,
        renderElementImpl: (_id: RowElementType, row: RowType) => {
          function goToProject() {
            history.push(convertProjectType2ProjectUrl(row.type as ProjectType, _id as string))
          }
          return (
            <HopBackInProjectButton onClickFunction={goToProject} />

          )
        }
      }
    ];

  async function renameProjectImpl(row: RowType, name: string, newName: string) {
    const accessToken = await getAccessTokenSilently();
    return projectsService.renameProject(accessToken, row[idHeadCell.id] as string, name, newName);
  }
  const renameProject = withServiceCallHandling(
    renameProjectImpl,
    (data, response) => {
      const newRows: RowType[] = rows.map((r) => {
        if (r[idHeadCell.id] !== data._id) return r;
        return { ...r, name: data.newName };
      });
      setRows(newRows);
    },
    undefined,
    'Renaming...',
    () => isMounted(),
  );

  const handleModalOpen = () => {
    setInsertModalOpen(true);
  };
  const handleModalClose = () => {
    setInsertModalOpen(false);
  };

  function handleRowsSelected(selRows: RowType[]) {
    if (onRowsSelected) onRowsSelected(selRows);
    setRowsSelected(selRows);
  }

  async function deleteProjectsImpl() {
    if (!rowsSelected || rowsSelected.length < 1) return;
    const accessToken = await getAccessTokenSilently();
    return projectsService.deleteProjects(
      accessToken,
      rowsSelected.map((row) => row._id as string),
    );
  }
  const handleDeleteSelected = withServiceCallHandling(
    deleteProjectsImpl,
    (data) => {
      const newRows = rows.filter((row) => !data.project_ids.includes(row[idHeadCell.id]));
      setRows(newRows);
      handleRowsSelected([]);
    },
    undefined,
    false,
    () => isMounted() && rowsSelected && rowsSelected.length > 0,
  );

  const DeleteRecordComponent: ReactNode = <DeleteButton handleDelete={handleDeleteSelected} />;

  const InsertRecordComponent: ReactNode = (
    <div>
      <Tooltip title="Create new project">
        <IconButton onClick={handleModalOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <BasicModal
        open={insertModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        boxSx={styles.insertModalStyle}
      >
        <CreateProjectRoutine
          onCancel={handleModalClose}
          onSubmit={() => {
            listProjects();
            handleModalClose();
          }}
        />
      </BasicModal>
    </div>
  );

  const body = (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 3, 
        borderRadius: 3, 
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 8px 32px rgba(31, 38, 135, 0.1)',
      }}
    >
    <ContentTable
      idHeadCell={idHeadCell}
      headCells={headCells}
      rows={rows}
      rowsSelected={rowsSelected}
      onRowsSelected={handleRowsSelected}
      InsertRecordComponent={allowInsert ? InsertRecordComponent : undefined}
      DeleteRecordComponent={allowDelete ? DeleteRecordComponent : undefined}
      renameRecordFunction={allowRename ? renameProject : undefined}
      entityName={entityName}
      tableTitle={tableTitle}
      allowFilter={allowFilter}
      allowMultipleSelect={allowMultipleSelect}
    />
    </Paper>
  );
  
  if (!asPage) return body;
  
  // Project type statistics for the dashboard view
  const projectStats = [
    {
      type: "BioTarget Scoring",
      count: rows.filter(r => r.type === ProjectType.TargetValidation).length,
      icon: <BiotechIcon style={{ fontSize: 40, color: theme.palette.primary.main }} />,
      description: "Validate drug targets in disease networks"
    },
    {
      type: "Visualization",
      count: rows.filter(r => r.type === ProjectType.Visualization).length,
      icon: <AssessmentIcon style={{ fontSize: 40, color: theme.palette.primary.main }} />,
      description: "Interactive visualization of complex data"
    },
    {
      type: "Biomarkers",
      count: rows.filter(r => r.type === ProjectType.Biomarkers).length,
      icon: <StorageIcon style={{ fontSize: 40, color: theme.palette.primary.main }} />,
      description: "Identify and analyze biomarkers"
    }
  ];
  
  return (
    <>
      <ParticleCanvas />
      <Container sx={{ py: 4 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <EnhancedPageHeader>
            <GradientOverlay />
            <Box position="relative" zIndex={1}>
              <motion.div
                animate={animate ? { scale: [0.98, 1], opacity: [0, 1] } : {}}
                transition={{ duration: 0.7 }}
              >
                <Box mb={2} display="flex" justifyContent="center">
                  <img 
                    src="/assets/img/cards/projects.webp" 
                    alt="Projects" 
                    style={{ 
                      height: 140, 
                      borderRadius: '12px',
                      boxShadow: '0 5px 15px rgba(0,0,0,0.1)' 
                    }}
                  />
                </Box>
                <Typography 
                  variant={isMobile ? "h4" : "h3"} 
                  color="primary" 
                  sx={{ 
                    fontWeight: 'bold', 
                    mb: 2,
                    textShadow: '0 2px 10px rgba(0,0,0,0.05)',
                    background: 'linear-gradient(90deg, #31708f 0%, #5085a5 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Project Dashboard
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 3, maxWidth: '800px', mx: 'auto' }}>
                  Manage and continue your progress on your saved projects across all features.
                </Typography>
                {allowInsert && (
                  <Box>
                    <Tooltip title="Create new project">
                      <Button 
                        variant="contained" 
                        onClick={handleModalOpen}
                        startIcon={<AddIcon />}
                        sx={{
                          borderRadius: '50px',
                          padding: theme.spacing(1.5, 4),
                          textTransform: 'none',
                          fontWeight: 600,
                          boxShadow: '0 4px 15px rgba(49, 112, 143, 0.3)',
                          background: 'linear-gradient(90deg, #31708f 0%, #5085a5 100%)',
                          '&:hover': {
                            boxShadow: '0 6px 20px rgba(49, 112, 143, 0.4)',
                          }
                        }}
                      >
                        Create New Project
                      </Button>
                    </Tooltip>
                  </Box>
                )}
              </motion.div>
            </Box>
          </EnhancedPageHeader>
        </motion.div>
        
        {/* Project Type Statistics */}
        <Box sx={{ mb: 6 }}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <StyledChip label="Project Summary" />
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 600, 
                mb: 1,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '50%',
                  bottom: '-12px',
                  width: '60px',
                  height: '3px',
                  background: theme.palette.primary.main,
                  transform: 'translateX(-50%)',
                  borderRadius: '2px',
                }
              }}
            >
              Your Project Portfolio
            </Typography>
          </Box>
          
          <Grid container spacing={4}>
            {projectStats.map((stat, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.5 }}
                >
                  <ProjectCard>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      height: '100%',
                      textAlign: 'center',
                    }}>
                      <Box mb={2}>{stat.icon}</Box>
                      <Typography variant="h3" color="primary" fontWeight="bold" mb={1}>
                        {stat.count}
                      </Typography>
                      <Typography variant="h6" color="text.primary" fontWeight="bold" mb={1}>
                        {stat.type} Projects
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {stat.description}
                      </Typography>
                    </Box>
                  </ProjectCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
        
        {/* Projects Table */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.7 }}
        >
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <StyledChip label="All Projects" />
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 600, 
                mb: 1,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  left: '50%',
                  bottom: '-12px',
                  width: '60px',
                  height: '3px',
                  background: theme.palette.primary.main,
                  transform: 'translateX(-50%)',
                  borderRadius: '2px',
                }
              }}
            >
              Project Management
            </Typography>
          </Box>
      {body}
        </motion.div>
    </Container>
      
      <BasicModal
        open={insertModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        boxSx={styles.insertModalStyle}
      >
        <CreateProjectRoutine
          onCancel={handleModalClose}
          onSubmit={() => {
            listProjects();
            handleModalClose();
          }}
        />
      </BasicModal>
    </>
  );
}

// Styled components
const EnhancedPageHeader = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4, 2, 5, 2),
  background: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: '24px',
  marginBottom: theme.spacing(4),
  boxShadow: '0 8px 32px rgba(31, 38, 135, 0.15)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  position: 'relative',
  overflow: 'hidden',
}));

const GradientOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(135deg, rgba(49, 112, 143, 0.05) 0%, rgba(114, 183, 209, 0.05) 100%)',
  zIndex: 0,
});

const ProjectCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  padding: theme.spacing(0.5, 0),
  marginBottom: theme.spacing(1.5),
}));

// Particle Canvas for background effect
const ParticleCanvas = () => {
  useEffect(() => {
    const canvas = document.getElementById('projects-particle-canvas') as HTMLCanvasElement;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const particles: {x: number, y: number, size: number, speedX: number, speedY: number, color: string}[] = [];
    const particleCount = 25;
    
    // Set canvas size
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    
    // Create particles
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 1.5 + 0.5,
        speedX: (Math.random() - 0.5) * 0.3,
        speedY: (Math.random() - 0.5) * 0.3,
        color: 'rgba(49, 112, 143, 0.3)'
      });
    }
    
    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      particles.forEach(particle => {
        // Draw particle
        ctx.fillStyle = particle.color;
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fill();
        
        // Move particle
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        
        // Wrap around screen edges
        if (particle.x < 0) particle.x = canvas.width;
        if (particle.x > canvas.width) particle.x = 0;
        if (particle.y < 0) particle.y = canvas.height;
        if (particle.y > canvas.height) particle.y = 0;
        
        // Draw connections between particles
        particles.forEach(otherParticle => {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < 120) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(49, 112, 143, ${0.05 * (1 - distance/120)})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });
    };
    
    animate();
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);
  
  return <canvas id="projects-particle-canvas" style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

export default Projects;

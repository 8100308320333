export async function handleResponse(response) {
  const { status } = response;
  console.log("Middleware handleResponse - Response status:", status);
  
  // Clone the response to avoid consuming the body more than once
  const responseClone = response.clone();
  
  // For non-200 responses, try to get the response body first
  if (status !== 200) {
    try {
      const text = await responseClone.text();
      console.log("Middleware handleResponse - Response text:", text);
      
      let data;
      try {
        // Attempt to parse as JSON
        data = JSON.parse(text);
        console.log("Middleware handleResponse - Parsed data:", data);
      } catch (jsonError) {
        console.log("Middleware handleResponse - Not valid JSON:", jsonError);
        // If not JSON, keep the text as is
        data = { error: text };
      }
      
      // Check if this is a timeout or server error
      if (status >= 500) {
        // If we have a specific error message from the server, use it
        if (data && data.error) {
          throw Error(data.error);
        }
        // Otherwise, provide a more helpful message for timeouts
        throw Error('Server is processing a large request. Please be patient or try again later.');
      }
      
      // Handle other client errors (400-499)
      if (status >= 400) {
        throw Error(data.error || 'Client error');
      }
      
      return data;
    } catch (parseError) {
      console.error("Middleware handleResponse - Error parsing response:", parseError);
      // If we can't parse the response, fall back to a generic error
      if (status >= 500) {
        throw Error('Server is processing a large request. Please be patient or try again later.');
      }
      if (status >= 400) {
        throw Error('Client error');
      }
    }
  }
  
  // For successful responses, parse and return the data
  try {
    const data = await response.json();
    console.log("Middleware handleResponse - Response data:", data);
    return data;
  } catch (error) {
    console.error("Middleware handleResponse - Error parsing JSON from successful response:", error);
    throw Error('Failed to parse response data');
  }
}

export async function handleResponseFile(response) {
  const { status } = response;
  console.log("Middleware handleResponseFile - Response status:", status);
  
  // Clone the response to avoid consuming the body more than once
  const responseClone = response.clone();
  
  // Handle server errors (500+)
  if (status >= 500) {
    try {
      const text = await responseClone.text();
      let data;
      try {
        data = JSON.parse(text);
      } catch (jsonError) {
        data = { error: text };
      }
      throw Error(data.error || 'Server is processing a large request. Please be patient or try again later.');
    } catch (parseError) {
      throw Error('Server is processing a large request. Please be patient or try again later.');
    }
  }
  
  // Handle client errors (400-499)
  if (status >= 400) {
    try {
      const text = await responseClone.text();
      let data;
      try {
        data = JSON.parse(text);
      } catch (jsonError) {
        data = { error: text };
      }
      throw Error(data.error || 'Client error');
    } catch (parseError) {
      throw Error('Client error');
    }
  }
  
  return response.blob();
}

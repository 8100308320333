import { useEffect, useRef } from 'react';
import { useLoadGraph, useSigma } from '@react-sigma/core';
import { DirectedGraph } from 'graphology';
import { isNone } from '_helpers/data';
import { graphTheme } from '_theme/graphTheme';
import ForceAtlas2 from 'graphology-layout-forceatlas2';
import AiPredStore from '_pages/TargetValidation/FilterAIPredictionStore';
import { getEdgeColor } from '_helpers/sigmaEvents';

const DEFAULT_NODE_SIZE = 6;
const MAX_NODE_SIZE = 16;
const MIN_EFFECT_SIZE = 0.1; // Minimum effect size to consider
const MAX_EFFECT_SIZE = 1.0; // Maximum effect size to scale against
const DEFAULT_EFFECT_SIZE = 0.2; // Default effect size for nodes without metrics
const DEFAULT_LOG2_FOLD_CHANGE = 0; // Default log2 fold change for nodes without metrics

function GraphDataController({ diseaseData, darkMode }) {
  const loadGraph = useLoadGraph();
  const sigma = useSigma(); // Access the Sigma instance
  const graphRef = useRef(null);
  const resetAiPredStore = AiPredStore((state) => state.reset);

  function getNodeColor(isEndpoint, log2_fold_change, preserve_endpoint_color) {
    // If this is an endpoint node or has the preserve_endpoint_color flag set, always use endpoint color
    if (isEndpoint || preserve_endpoint_color === true) {
      return darkMode ? graphTheme.ENDPOINT_COLOR : graphTheme.ENDPOINT_COLOR;
    }
    
    // For non-endpoint nodes, apply coloring based on log2_fold_change
    if (log2_fold_change !== undefined && log2_fold_change < 0) {
      return "#FFFACD"; // Pale yellow color for negative fold change
    }
    
    // Default node color
    return darkMode ? graphTheme.NODE_DEFAULT_COLOR_DARK : graphTheme.NODE_DEFAULT_COLOR;
  }
  
  // Calculate node size based on effect size
  function calculateNodeSize(effect_size) {
    if (effect_size === undefined || effect_size === null || isNaN(effect_size)) {
      return DEFAULT_NODE_SIZE;
    }
    
    // Clamp effect size between MIN and MAX
    const clampedEffectSize = Math.max(MIN_EFFECT_SIZE, Math.min(MAX_EFFECT_SIZE, Math.abs(effect_size)));
    
    // Scale effect size to node size range
    const sizeRange = MAX_NODE_SIZE - DEFAULT_NODE_SIZE;
    const scaleFactor = (clampedEffectSize - MIN_EFFECT_SIZE) / (MAX_EFFECT_SIZE - MIN_EFFECT_SIZE);
    const size = DEFAULT_NODE_SIZE + (sizeRange * scaleFactor);
    
    return size;
  }

  useEffect(() => {
    if (isNone(diseaseData.disease) || graphRef.current) return;
    resetAiPredStore();

    try {
      console.log("DEBUG: Creating graph from disease data:", {
        nodesCount: diseaseData.nodes?.length || 0,
        linksCount: diseaseData.links?.length || 0,
        disease: diseaseData.disease
      });

      // Validate that we have nodes and links
      if (!diseaseData.nodes || !diseaseData.links || diseaseData.nodes.length === 0) {
        console.error("ERROR: Disease data is missing nodes or links:", diseaseData);
        return;
      }

      const graph = new DirectedGraph();

      diseaseData.nodes.forEach((node) => {
        try {
          // Ensure node has effect_size and log2_fold_change values
          const effectSize = node.effect_size !== undefined && node.effect_size !== null ? 
                          node.effect_size : DEFAULT_EFFECT_SIZE;
          
          const log2FoldChange = node.log2_fold_change !== undefined && node.log2_fold_change !== null ? 
                              node.log2_fold_change : DEFAULT_LOG2_FOLD_CHANGE;
          
          // Calculate node size based on effect size
          const nodeSize = calculateNodeSize(effectSize);
          
          // Get node color based on log2_fold_change
          const nodeColor = getNodeColor(node.is_endpoint, log2FoldChange, node.preserve_endpoint_color);
          
          graph.addNode(node.id, {
            id: node.id,
            x: Math.random(),
            y: Math.random(),
            label: node.name,
            originalLabel: node.name,
            size: nodeSize,
            color: nodeColor,
            originalColor: nodeColor,
            stringId: node.stringId,
            annotation: node.annotation,
            expression: node.expression || -1,
            SD: node.SD || -1,
            CI: node.CI || -1,
            healthy_expression: node.healthy_expression || -1,
            disease_expression: node.disease_expression || -1,
            log2_fold_change: log2FoldChange,
            effect_size: effectSize,
            healthy_CI: node.healthy_CI || -1,
            disease_CI: node.disease_CI || -1,
            keywords: node.keywords || [],
            filteron: false,
            hidden: false,
            is_endpoint: node.is_endpoint || false,
          });
        } catch (nodeError) {
          console.error("ERROR: Failed to add node:", node, nodeError);
        }
      });

      console.log("DEBUG: Added all nodes to graph, now adding links");

      diseaseData.links.forEach((link) => {
        try {
          // Validate that source and target exist in the graph
          if (!graph.hasNode(link.source) || !graph.hasNode(link.target)) {
            console.warn("WARNING: Link references non-existent node:", link);
            return;
          }

          graph.addDirectedEdgeWithKey(`${link.source}>${link.target}`, link.source, link.target, {
            predicted: link.predicted,
            ai_relation: link.ai_relation,
            string_relation: link.string_relation,
            relation: link.predicted ? link.ai_relation : link.string_relation,
            color: getEdgeColor(link.predicted, link.ai_relation, link.string_relation, darkMode),
            originalColor: getEdgeColor(link.predicted, link.ai_relation, link.string_relation, darkMode),
            specialColor: null,
            size: 0.001,
            type: 'line',
            hidden: false,
          });
        } catch (linkError) {
          console.error("ERROR: Failed to add link:", link, linkError);
        }
      });

      console.log("DEBUG: Added all links to graph, now applying layout");

      const sensibleSettings = ForceAtlas2.inferSettings(graph);
      const customSettings = {
        ...sensibleSettings,
        barnesHutOptimize: true,
      };

      ForceAtlas2.assign(graph, {
        iterations: 200,
        settings: customSettings,
      });

      console.log("DEBUG: Layout applied, loading graph into Sigma");
      loadGraph(graph);
      graphRef.current = graph;
      console.log("DEBUG: Graph loaded successfully");
    } catch (error) {
      console.error("ERROR: Failed to create graph:", error);
    }
  }, [loadGraph, diseaseData]);

  useEffect(() => {
    const graph = graphRef.current;
    if (!graph) return;

    // Update node colors
    graph.forEachNode((node, attributes) => {
      graph.setNodeAttribute(node, 'color', getNodeColor(attributes.is_endpoint, attributes.log2_fold_change, attributes.preserve_endpoint_color));
      graph.setNodeAttribute(node, 'originalColor', getNodeColor(attributes.is_endpoint, attributes.log2_fold_change, attributes.preserve_endpoint_color));
    });

    // Update edge colors
    graph.forEachEdge((edge, attributes) => {
      graph.setEdgeAttribute(
        edge,
        'color',
        getEdgeColor(attributes.predicted, attributes.ai_relation, attributes.string_relation, darkMode),
      );
      graph.setEdgeAttribute(
        edge,
        'originalColor',
        getEdgeColor(attributes.predicted, attributes.ai_relation, attributes.string_relation, darkMode),
      );
    });
    sigma.refresh();
  }, [sigma, darkMode]);

  return null;
}

export default GraphDataController;

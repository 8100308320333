import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import projectsService from '_services/harbor/projects.service';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SaveProjectModal from '_pages/Projects/Routines/SaveProjectModal';
import { Box, Chip, Container, Stack, Typography } from '@mui/material';
import { withServiceCallHandling } from '_helpers/decorators';
import GraphDiseasePage from './GraphDisease/GraphDiseasePage';
import TVProjectType from './TVProjectType';
import NodeProcessPathsPage from './NodeProcessComponents/NodeProcessPathsPage';
import TVProjectStore from './TVProjectStore';
import { toast } from 'react-toastify';
import history from '_helpers/history';
import config from '_configs';
import AiPredStore from './FilterAIPredictionStore';
import disease_name_readable from '_helpers/format';

export default function TVProject() {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const [projectData, setProjectData] = useState<TVProjectType>();
  const { projectId } = useParams<{ projectId: string }>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nodeProcessProps, setNodeProcessProps] = useState<{
    nodeId: string;
    filterToggle: boolean;
    filterValue: number;
    disease: string;
  }>();
  const resetTVProjectStore = TVProjectStore((state) => state.reset);
  const resetAiPredStore = AiPredStore((state) => state.reset);
  const tvProjectModel = TVProjectStore((state) => state.initTVProject);

  useEffect(() => {
    // If no model data, redirect back
    if (!tvProjectModel) {
      toast.error('No model selected. Please select a model first.');
      history.push(config.clientUrls.TargetValidation);
      return;
    }

    // simulate existing project to initiate
    setProjectData({
      name: '',
      model_id: tvProjectModel._id as string,
      disease: tvProjectModel.disease as string,
      model: {
        _id: tvProjectModel._id as string,
        name: tvProjectModel.name,
        disease: tvProjectModel.disease as string,
      },
    });
    return () => resetTVProjectStore();
  }, []);

  async function saveProjectImpl(name: string) {
    if (!projectData) {
      toast.error('Project data is missing');
      return;
    }
    
    // Show a loading toast that this might take a few minutes
    const loadingToastId = toast.info(
      "Starting validation process... This may take several minutes to complete. You will be redirected automatically when it finishes.", 
      { autoClose: false }
    );
    
    const accessToken = await getAccessTokenSilently();
    console.log("DEBUG: Creating TV project with data:", {
      model_id: projectData.model_id,
      disease: nodeProcessProps?.disease || projectData.disease,
      nodeId: nodeProcessProps?.nodeId,
      filterToggle: nodeProcessProps?.filterToggle,
      filterValue: nodeProcessProps?.filterValue,
      tissueType: tvProjectModel?.tissueType
    });
    
    try {
      const result = await projectsService.createTVProject(accessToken, name, {
        model_id: projectData.model_id,
        disease: nodeProcessProps?.disease || projectData.disease,
        nodeId: nodeProcessProps?.nodeId,
        filterToggle: nodeProcessProps?.filterToggle,
        filterValue: nodeProcessProps?.filterValue,
        tissue_type: tvProjectModel?.tissueType
      });
      
      // Check if the server response indicates an existing project
      if (result.existing_project && result.existing_project_id) {
        // Close the loading toast
        toast.dismiss(loadingToastId);
        
        // Show info toast about using existing project
        toast.info(result.message || "Using existing project with the same parameters");
        
        // Redirect to the existing project instead of creating a new one
        setOpenModal(false);
        resetAiPredStore();
        history.push(config.clientUrls.NODE_PROCESS_PATHS__TV_PROJECT(result.existing_project_id));
        
        return result;
      }
      
      // Close the loading toast
      toast.dismiss(loadingToastId);
      
      // DO NOT show "completed" toast here - it's misleading since calculation continues in the backend
      
      // Just return the result - further handling is in handleSaveProject
      return result;
    } catch (error) {
      // Close the loading toast
      toast.dismiss(loadingToastId);
      
      // Show error toast
      toast.error("Validation process failed. Please try again.");
      throw error;
    }
  }

  // Update handleSaveProject to properly handle promises
  const handleSaveProject = async (name: string): Promise<void> => {
    try {
      // Use the original withServiceCallHandling approach
      await withServiceCallHandling(
        saveProjectImpl, 
        (data) => {
          if (data) {
            setProjectData({ ...projectData, ...data });
            setOpenModal(false);
            resetAiPredStore();
            
            // Do NOT show a toast saying loading results - the NodeProcessPathsPage will handle this
            
            // Redirect to the node process paths page with the project ID
            history.push(config.clientUrls.NODE_PROCESS_PATHS__TV_PROJECT(data._id));
          }
        }
      )(name);
    } catch (error) {
      console.error("Error saving project:", error);
      // Error is already handled by withServiceCallHandling
    }
  };

  async function getTVProjectImpl() {
    const accessToken = await getAccessTokenSilently();
    return projectsService.getTVProject(accessToken, projectId);
  }

  const getTVProjectInfo = withServiceCallHandling(
    getTVProjectImpl,
    (data) => setProjectData(data),
    undefined,
    false,
    () => isMounted(),
  );

  if (!projectData) return <></>;

  async function handleGoToNodeProcessPage(nodeId: string, aiPredIsOn: boolean, aiPredCutoff: number, diseaseFromGraph: string) {
    // Show info toast that this is a validation process
    toast.info(
      "Starting target validation process. After naming your project, the system will analyze pathways and calculate efficacy scores. This may take a few minutes.",
      { autoClose: 10000 }
    );
    
    setOpenModal(true);
    setNodeProcessProps({ nodeId, filterToggle: aiPredIsOn, filterValue: aiPredCutoff, disease: diseaseFromGraph });
  }

  return (
    <>
      <SaveProjectModal
        description="To save results of this analysis, name current project."
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
        }}
        onSubmit={handleSaveProject}
      />
      <Container disableGutters sx={{ maxWidth: '100%', margin: 'auto' }}>
        <Stack sx={{ marginTop: '10px' }} direction="row" spacing={2} justifyContent="flex-end">
          {projectData._id && (
            <Typography variant="body1" color="primary">
              Project: {projectData.name}
            </Typography>
          )}

          <Box flexDirection="row" justifyContent="start">
            <Typography variant="body1" color="primary" fontStyle="italic" align="left" display="inline">
              Disease:{' '}
            </Typography>
            <Chip label={disease_name_readable(projectData.model.disease)} size="medium" />
          </Box>

          <Box flexDirection="row" justifyContent="start">
            <Typography variant="body1" color="primary" fontStyle="italic" align="left" display="inline">
              Model:
            </Typography>
            <Chip label={projectData.model.name} size="medium" />
          </Box>
        </Stack>
      </Container>

      <GraphDiseasePage modelId={projectData.model_id} onGoToNodeProcessPage={handleGoToNodeProcessPage} />
    </>
  );
}
